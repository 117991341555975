<template>
    <!---->
    <template v-for="item in items" :key="item.code">
        <template v-if="item.type==1 || item.type==5">
            <div class="flow-row">
                <div class="flow-col">
                    <el-card :class="item.type==1?'box-card border-approval':'box-card border-cc'" @click="item.drawer = true">
                        <template #header>
                            <div class="card-header">
                                <span>{{item.title}}</span>
                                <el-button class="button branch-del" type="text" icon="el-icon-delete" @click="removeModule(item.code)" v-if="item.code!='C-1'"></el-button>
                                <el-button class="button branch-del" type="text" v-else></el-button>
                            </div>
                        </template>
                        <div>
                            {{getContent(item.whereList)}}
                        </div>
                    </el-card>
                    <el-drawer :title="item.title"
                               loading="item.isloading"
                               v-model="item.drawer"
                               direction="rtl"
                               size="600px">
                        <el-form :model="item" :rules="rules" ref="'form-'+item.code" label-width="20px" class="workFlow-form" v-if="item.code!='C-1'">
                            <el-form-item label="" prop="title">
                                <el-input v-model="item.title"></el-input>
                            </el-form-item>
                            <el-divider>{{$t('message.vueWorkFlow.divider1')}}</el-divider>
                            <template v-for="(where, index) in item.whereList" :key="index">
                                <el-form-item label="" prop="whereType">
                                    <el-col :span="6">
                                        <el-select v-model="where.whereType" filterable :placeholder="$t('message.pleaseSelect')" style="width: 100%;" v-if="item.type==5">
                                            <el-option v-for="o in wheres.whereTypeCC"
                                                       :key="o.value"
                                                       :label="o.label"
                                                       :value="o.value">
                                            </el-option>
                                        </el-select>
                                        <el-select v-model="where.whereType" filterable :placeholder="$t('message.pleaseSelect')" style="width: 100%;" v-else>
                                            <el-option v-for="o in wheres.whereTypeApproval"
                                                       :key="o.value"
                                                       :label="o.label"
                                                       :value="o.value">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                    <el-col :span="16">
                                        <template v-if="where.whereType!=1">
                                            <span>&nbsp;:&nbsp;</span>
                                        </template>
                                        <template v-if="where.whereType==2">
                                            <el-select v-model="where.whereUsers"
                                                       multiple
                                                       filterable
                                                       remote
                                                       reserve-keyword
                                                       :placeholder="$t('message.vueWorkFlow.enterNumberName')"
                                                       :remote-method="(query)=>{getUsers(query,where)}"
                                                       :loading="where.isloading">
                                                <el-option v-for="o in wheres.whereUsers"
                                                           :key="o.value"
                                                           :label="o.label"
                                                           :value="o.value">
                                                </el-option>
                                            </el-select>
                                        </template>
                                        <template v-else-if="where.whereType==3||where.whereType==4">
                                            <el-select v-model="where.whereMaxGrade" :placeholder="$t('message.vueWorkFlow.selMaxGrade')" style="width:46%">
                                                <el-option v-for="o in wheres.whereGrades"
                                                           :key="o.value"
                                                           :label="o.label"
                                                           :value="o.value">
                                                </el-option>
                                            </el-select>
                                            <el-select v-model="where.whereMaxRank" :placeholder="$t('message.vueWorkFlow.selMaxRank')" style="width:46%">
                                                <el-option v-for="o in wheres.whereRanks"
                                                           :key="o.value"
                                                           :label="o.label"
                                                           :value="o.value">
                                                </el-option>
                                            </el-select>
                                        </template>
                                    </el-col>
                                </el-form-item>
                            </template>
                        </el-form>
                    </el-drawer>
                </div>
            </div>
            <div class="flow-row">
                <div class="flow-line">
                    <div class="flow-plus">
                        <el-popover placement="right"
                                    :width="320"
                                    trigger="click">
                            <template #reference>
                                <el-button type="primary" class="btn-plus" icon="el-icon-plus" size="mini" circle></el-button>
                            </template>
                            <el-row>
                                <el-button type="primary" @click="addModule(item.code,1)" size="small" plain round>{{$t('message.vueWorkFlow.nodeApproval')}}</el-button>
                                <el-button type="success" @click="addModule(item.code,2)" size="small" plain round>{{$t('message.vueWorkFlow.nodeCondition')}}</el-button>
                                <el-button type="info" @click="addModule(item.code,5)" size="small" plain round>{{$t('message.vueWorkFlow.nodeCC')}}</el-button>
                            </el-row>
                        </el-popover>
                    </div>
                </div>
            </div>
            <FlowAddOrEditSub :items="item.subWorkFlowList" :wheres="wheres" />
        </template>
        <!---->
        <template v-else-if="item.type==2">
            <div class="flow-row">
                <el-button class="branch-add" type="success" size="mini" @click="addModule(item.code,3)" plain round>{{$t('message.vueWorkFlow.nodeAddCondition')}}</el-button>
            </div>
            <div class="flow-row">
                <template v-for="sub in item.subWorkFlowList" :key="sub.code">
                    <div class="flow-col col-box">
                        <div class="box-middle-line"></div>
                        <div class="box-right-line"></div>
                        <el-card class="box-card border-condition" @click="sub.drawer = true">
                            <template #header>
                                <div class="card-header">
                                    <span>{{sub.title}}</span>
                                    <el-button class="button branch-del" type="text" icon="el-icon-delete" @click="removeModule(sub.code)"></el-button>
                                </div>
                            </template>
                            <div>
                                {{getContent(sub.whereList)}}
                            </div>
                        </el-card>
                        <el-drawer :title="sub.title"
                                   loading="sub.isloading"
                                   v-model="sub.drawer"
                                   direction="rtl"
                                   size="600px">
                            <el-form :model="sub" :rules="rules" ref="'form-'+sub.code" label-width="20px" class="workFlow-form">
                                <el-form-item label="" prop="title">
                                    <el-input v-model="sub.title"></el-input>
                                </el-form-item>
                                <el-divider>{{$t('message.vueWorkFlow.divider1')}}</el-divider>
                                <template v-for="(where, index) in sub.whereList" :key="index">
                                    <el-form-item label="" prop="whereType">
                                        <el-col :span="2">
                                            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeWhere(sub.whereList,index)"></el-button>
                                        </el-col>
                                        <el-col :span="2">&nbsp;&nbsp;</el-col>
                                        <el-col :span="6">
                                            <el-select v-model="where.whereType" filterable :placeholder="$t('message.pleaseSelect')" style="width: 100%;">
                                                <el-option v-for="o in wheres.whereTypeCondition"
                                                           :key="o.value"
                                                           :label="o.label"
                                                           :value="o.value">
                                                </el-option>
                                            </el-select>
                                        </el-col>
                                        <el-col :span="14">
                                            <template v-if="where.whereType!=1">
                                                <span>&nbsp;:&nbsp;</span>
                                            </template>
                                            <template v-if="where.whereType==1001||where.whereType==1002||where.whereType==1007||where.whereType==1008||where.whereType==1009||where.whereType==1010">
                                                <el-select v-model="where.whereUsers"
                                                           multiple
                                                           filterable
                                                           remote
                                                           reserve-keyword
                                                           :placeholder="$t('message.vueWorkFlow.enterNumberName')"
                                                           :remote-method="(query)=>{getUsers(query,where)}"
                                                           :loading="where.isloading">
                                                    <el-option v-for="o in wheres.whereUsers"
                                                               :key="o.value"
                                                               :label="o.label"
                                                               :value="o.value">
                                                    </el-option>
                                                </el-select>
                                            </template>
                                            <template v-else-if="where.whereType==1003||where.whereType==1004">
                                                <el-select v-model="where.whereDepartments"
                                                           multiple
                                                           filterable
                                                           remote
                                                           reserve-keyword
                                                           :placeholder="$t('message.vueWorkFlow.enterNumberName')"
                                                           :remote-method="(query)=>{getDepartments(query,where)}"
                                                           :loading="where.isloading">
                                                    <el-option v-for="o in wheres.whereDepartments"
                                                               :key="o.value"
                                                               :label="o.label"
                                                               :value="o.value">
                                                    </el-option>
                                                </el-select>
                                            </template>
                                            <template v-else-if="where.whereType==1005||where.whereType==1006">
                                                <el-select v-model="where.whereCompanys"
                                                           multiple
                                                           filterable
                                                           remote
                                                           reserve-keyword
                                                           :placeholder="$t('message.vueWorkFlow.enterNumberName')"
                                                           :remote-method="(query)=>{getCompanys(query,where)}"
                                                           :loading="where.isloading">
                                                    <el-option v-for="o in wheres.whereCompanys"
                                                               :key="o.value"
                                                               :label="o.label"
                                                               :value="o.value">
                                                    </el-option>
                                                </el-select>
                                            </template>
                                            <template v-else-if="where.whereType==1011||where.whereType==1012">
                                                <el-select v-model="where.whereGrades"
                                                           multiple
                                                           filterable
                                                           :placeholder="$t('message.pleaseSelect')">
                                                    <el-option v-for="o in wheres.whereGrades"
                                                               :key="o.value"
                                                               :label="o.label"
                                                               :value="o.value">
                                                    </el-option>
                                                </el-select>
                                            </template>
                                            <template v-else-if="where.whereType==1013||where.whereType==1014">
                                                <el-select v-model="where.whereRanks"
                                                           multiple
                                                           filterable
                                                           :placeholder="$t('message.pleaseSelect')">
                                                    <el-option v-for="o in wheres.whereRanks"
                                                               :key="o.value"
                                                               :label="o.label"
                                                               :value="o.value">
                                                    </el-option>
                                                </el-select>
                                            </template>
                                        </el-col>
                                    </el-form-item>
                                </template>
                                <el-form-item>
                                    <el-col :span="20" :offset="2">
                                        <el-button type="primary" size="mini" @click="addWhere(sub.code)">{{$t('message.vueWorkFlow.nodeAddCondition')}}</el-button>
                                    </el-col>
                                </el-form-item>
                            </el-form>
                        </el-drawer>
                        <div class="flow-row">
                            <div class="flow-line">
                                <div class="flow-plus">
                                    <el-popover placement="right"
                                                :width="320"
                                                trigger="click">
                                        <template #reference>
                                            <el-button type="primary" class="btn-plus" icon="el-icon-plus" size="mini" circle></el-button>
                                        </template>
                                        <el-row>
                                            <el-button type="primary" @click="addModule(sub.code,1)" size="small" plain round>{{$t('message.vueWorkFlow.nodeApproval')}}</el-button>
                                            <el-button type="success" @click="addModule(sub.code,2)" size="small" plain round>{{$t('message.vueWorkFlow.nodeCondition')}}</el-button>
                                            <el-button type="info" @click="addModule(sub.code,5)" size="small" plain round>{{$t('message.vueWorkFlow.nodeCC')}}</el-button>
                                        </el-row>
                                    </el-popover>
                                </div>
                            </div>
                        </div>
                        <FlowAddOrEditSub :items="sub.subWorkFlowList" :wheres="wheres" />
                    </div>
                </template>
            </div>
        </template>
        <!---->
        <template v-else-if="item.type==4">
            <div class="flow-row">
                <div class="flow-line">
                    <div class="flow-plus">
                        <el-popover placement="right"
                                    :width="320"
                                    trigger="click">
                            <template #reference>
                                <el-button type="primary" class="btn-plus" icon="el-icon-plus" size="mini" circle></el-button>
                            </template>
                            <el-row>
                                <el-button type="primary" @click="addModule(item.code,1)" class="btn_add"
                                           size="small" plain round>{{$t('message.vueWorkFlow.nodeApproval')}}</el-button>
                                <el-button type="success" @click="addModule(item.code,2)" class="btn_add"
                                           size="small" plain round>{{$t('message.vueWorkFlow.nodeCondition')}}</el-button>
                                <el-button type="info" @click="addModule(item.code,5)" class="btn_add"
                                           size="small" plain round>{{$t('message.vueWorkFlow.nodeCC')}}</el-button>
                            </el-row>
                        </el-popover>
                    </div>
                </div>
            </div>
            <FlowAddOrEditSub :items="item.subWorkFlowList" :wheres="wheres" />
        </template>
    </template>
</template>
<style>
    .btn_add {
        font-weight: bolder;
    }
</style>
<script>   
    import FlowAddOrEditSub from "./FlowAddOrEditSub.vue"
    export default {
        name: 'FlowAddOrEditSub',
        components: { FlowAddOrEditSub },
        props: {
            items: {
                type: Array,
                default: function () { return [] }
            },
            wheres: {
                type: Object,
                default() {
                    return null
                }
            }
        },
        methods: {
            removeModule(itemCode) {
                this.$parent.removeModule(itemCode);
            },
            addModule(itemCode, type = 1) {
                this.$parent.addModule(itemCode, type);
            },
            removeWhere(whereList, index) {
                this.$parent.removeWhere(whereList, index);
            },
            addWhere(itemCode) {
                this.$parent.addWhere(itemCode);
            },
            getUsers(query, where) {
                this.$parent.getUsers(query, where);
            },
            getDepartments(query, where) {
                this.$parent.getDepartments(query, where);
            },
            getCompanys(query, where) {
                this.$parent.getCompanys(query, where);
            },
            getContent(whereList) {
              return this.$parent.getContent(whereList);
            },
        },
    }
</script>