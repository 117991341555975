<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/TaskOptions' }">{{$t('message.vueTaskOptions.taskOptionspage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ShowTaskLog' }">{{$t('message.vueTaskOptions.taskLogspage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="showTaskLogs">
        <el-table :data="tableData" border size="mini">
            <el-table-column prop="JobActionType" :label="$t('message.vueTaskOptions.JobActionType')"
                             width="130">
                <template #default="scope">
                    <span>{{scope.row.jobActionType}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="RunTime" :label="$t('message.vueTaskOptions.RunTime')"
                             width="130">
                <template #default="scope">
                    <span>{{scope.row.strRunTime}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="Status" :label="$t('message.vueTaskOptions.Status')"
                             width="130">
                <template #default="scope">
                    <span>{{scope.row.status}}</span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="pageIndex"
                       :page-sizes="[10, 25, 50, 75, 100]"
                       :page-size="pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="count">
        </el-pagination>
    </div>
</template>

<style>
    
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'ShowTaskLog',
        data() {
            return {
                tableData: null,
                language: AppStorage.getLanguage(),
                pageIndex: 1,
                pageSize: 50,
                count: 0
            }
        },
        methods: {
            loadPage() {
                var id = this.$route.query.Id;
                if (id <= 0) {
                    return;
                }

                this.axios
                    .get('/api/TaskOptions/GetTaskLogByTaskOptionsId', {
                        params: {
                            TaskOptionsId: id,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        },
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.tableData = infos.items;
                        }
                    })
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            },
        },
        mounted() {
            this.loadPage();
        },
        watch: {
            $route() {
                //响应路由参数的变化
                this.submitSearch();
            }
        }
    };
</script>
