<template>
    <div id ="workflow-info" class="workflow-info" v-loading="isloading" style="width:100%">
        <div id ="workflow-check" class="workflow-check">
            <el-form :model="checkForm" :rules="ruleChecks" ref="checkForm" label-width="80px" class="checkForm">
                <el-form-item :label="$t('message.vueWorkFlow.labPerson')" prop="users">
                    <InputTag v-model="checkForm.users" @clickTag="getUserSelect" @close="tagClose" :tags="tags" />
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('checkForm',4)"
                               class="btn_add" :disabled="disabledSubmit">{{$t('message.vueWorkFlow.btnCheck6')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
    <userSelect ref="userSelect" v-on:getUserEven="getUser"></userSelect>
</template>
<style>
    .btn_add {
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    import InputTag from "../Input/InputTag.vue";
    import UserSelect from '../../components/User/UserSelect'
    var strUsers = ''
    export default {
        name: 'OIHWorkFlowInfo',
        components: { InputTag, UserSelect },
        props: {
            loadTimes: {
                type: Number,
                default: 0
            },
            formType: {
                type: Number,
                default:0
            },
            formId: {
                type: Number,
                default:0
            },
            orderNumber: {
                type: String,
                default: ''
            },
            saporderNumber: {
                type: String,
                default: ''
            },
            goUrl: {
                type: String,
                default: ''
            },
            ischeck: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                tags: [],
                userNumber: AppStorage.getNumber(),
                disabledSubmit: false,
                checkForm: {
                    content: '',
                    users: ''
                },
                checkButtons: {
                    btn4: false
                },
                isloading: false,
            };
        },
        methods: {
            submitForm(formName, operate, returnOrderNo = 0) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/workflow/mailsend', {
                                UserNumber: this.userNumber,
                                FormType: this.formType,
                                FormId: this.formId,
                                CheckContent: this.checkForm.content,
                                Operates: operate,
                                UserNumbers: this.checkForm.users,
                                ReturnOrderNo: returnOrderNo,
                                OrderNumber: this.orderNumber,
                                SAPOrderNumber: this.saporderNumber,
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    var msg = response.data.msg.replace('审批流程不存在', this.$t('message.vueWorkFlow.msgResponse1'))
                                        .replace('无法操作当前流程', this.$t('message.vueWorkFlow.msgResponse2'))
                                        .replace('请选择转发人员', this.$t('message.vueWorkFlow.msgResponse3'))
                                        .replace('请选择抄送人员', this.$t('message.vueWorkFlow.msgResponse4'))
                                        .replace('人员不存在或离职', this.$t('message.vueWorkFlow.msgResponse5'))
                                        .replace('无法撤回，单据已被他人操作', this.$t('message.vueWorkFlow.msgResponse6'))
                                        .replace('无法撤回，单据未提交', this.$t('message.vueWorkFlow.msgResponse7'))
                                        .replace('无法回退，当前是第一个审批人', this.$t('message.vueWorkFlow.msgResponse8'));
                                    alert(msg);
                                } else {
                                    if (this.goUrl == "") {
                                        location.reload();
                                    }
                                    else {
                                        this.$router.push(this.goUrl);
                                    }
                                    strUsers = "";
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                strUsers = "";
                                console.log(error);
                                alert(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });

                    } else {
                        return false;
                    }
                });
            },
            getUserSelect() {
                this.$refs.userSelect.init();
            },
            getUser(users) {
                //var strUsers = '';
                users.forEach(u => {
                    strUsers += u.number + ",";
                    if (this.tags == null || this.tags.length == 0) {
                        this.tags = [];
                        this.tags.push({ name: u.name, number: u.number, type: '' });
                    } else {
                        var tagIndex = this.tags.findIndex((t) => {
                            return t.number === u.number;
                        });
                        if (tagIndex == -1) {
                            this.tags.push({ name: u.name, number: u.number, type: '' });
                        }
                    }
                });
                if (strUsers != '') {
                    this.checkForm.users = strUsers.substring(0, strUsers.length - 1);
                }
            },
            tagClose(tag) {
                var strUsers = (this.checkForm.users + ",").replace(tag.number + ',', '');
                this.checkForm.users = strUsers.substring(0, strUsers.length - 1);
                this.tags.splice(this.tags.indexOf(tag), 1);
            },
        },
        mounted() {
        },
        watch: {
        }
    }
</script>
<style>
    .workflow-check {
        border: 2px solid #eee;
        border-bottom:0;
        padding: 30px 20px;
    }
    .workflow-check .check-tag{
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        padding: 5px 15px;
    }
    .workflow-tree {
        width: 100%;
        border: 2px solid #eee;
        padding: 30px 20px;
    }
   .workflow-tree .tree-row {
        width:100%;
   }
   .workflow-tree .el-card__body {
       padding:10px 20px;
   }
   .workflow-tree .tree-h4 {
       margin:0;
   }
   .workflow-tree .tree-p {
       margin-top:10px;
       margin-bottom:0;
   }
   .workflow-tree .p-warning {
      color:#E6A23C
   }
</style>