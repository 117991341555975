<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/UserList' }">{{$t('message.vueUser.crumbUserManager')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="userForm.id==0">{{$t('message.add')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-else>{{$t('message.vueMenu.btnPassword')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div id="PointOut" class="PointOut">
        <span class="PointOut-words">The length of password is 6-8 digits!</span><br />
        <span class="PointOut-words">Contains at least one capital letter!</span><br />
        <span class="PointOut-words">Consists numbers and letters!</span>
    </div>
    <el-divider></el-divider>
    <el-form ref="userForm" :model="userForm" :rules="rules" label-width="200px" class="demo-ruleForm">
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueUser.labAccount')" prop="account">
                    <el-input v-model="userForm.account" style="max-width:217px" :disabled="true"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueUser.labName')" prop="name">
                    <el-input v-model="userForm.name" style="max-width:217px" :disabled="true"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueUser.labEmail')" prop="email">
                    <el-input v-model="userForm.email" style="max-width:400px" :disabled="true"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueUser.labNewPassword')+':'" prop="changepassword">
                    <el-input v-model="userForm.changepassword" :type="pwdType">
                        <template #suffix>
                            <i class="el-input__icon el-icon-view" @click="changeType"></i>
                        </template>
                    </el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueUser.labConfirmPassword')+':'" prop="confirmpassword">
                    <el-input v-model="userForm.confirmpassword" :type="pwdType2">
                        <template #suffix>
                            <i class="el-input__icon el-icon-view" @click="changeType2"></i>
                        </template>
                    </el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item>
                    <el-button class="button_submit" size="mini" @click="submitForm('userForm')"
                               :disabled="disabledSubmit">{{$t('message.submit')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'UserChangePassword',
        data() {
            return {
                disabledSubmit: false,
                
                userForm: {
                    id: 0,
                    name: '',
                    account:'',
                    email: '',
                    number: '',
                    changepassword: '',
                    confirmpassword: '',
                    userNumber: AppStorage.getNumber()
                },
                seen: true,
            }
        },
        computed: {
            rules() {
                const rules = {
                    account: [
                        { required: true, message: this.$t('message.vueUser.verifyAccountRequired'), trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: this.$t('message.vueUser.verifyNameRequired'), trigger: 'blur' }
                    ],
                    email: [
                        { required: false, message: this.$t('message.vueUser.verifyEmail'), trigger: 'blur', type: 'email'  }
                    ],
                    confirmpassword: [
                        { required: true, message: this.$t('message.vueUser.verifyPasswordRequired'), trigger: 'blur' },
                    ],
                    changepassword: [
                        { required: true, message: this.$t('message.vueUser.verifyPasswordRequired'), trigger: 'blur' },
                        { min: 6, max: 8, message: this.$t('message.vueUser.verifyPasswordLen'), trigger: 'blur' }
                    ],
                };
                return rules;
            }
        },
        methods: {
            changeType() {
                this.pwdType = this.pwdType === 'password' ? 'text' : 'password';
            },
            changeType2() {
                this.pwdType2 = this.pwdType2 === 'password' ? 'text' : 'password';
            },
            getInfo() {
                var infoId = this.$route.query.id;
                if (infoId === '0') {
                    return;
                }
                this.axios
                    .get('/api/user/getuserbyid', {
                        params: {
                            Id: infoId,
                        }
                    }) .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.userForm.id = info.id;
                            this.userForm.number = info.number;
                            this.userForm.name = info.name;
                            this.userForm.account = info.account;
                            this.userForm.email = info.email;
                            this.seen = false;
                        }
                    });
            },
            submitForm(formName) {
                const regNumber = /(?=.*[\d])/;
                //是否包含一位字母
                const regLetter = /(?=.*[a-zA-Z])/;
                //是否包含一位大写字母
                const regBigLetter = /(?=.*[A-Z])/;
                //是否包含一位特殊字符
                //const regCharacter = /(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、])/;

                if (this.userForm.changepassword == null) {
                    this.$message.error(this.$t('message.vueUser.verifypassword'));
                    return false;
                } else if (this.userForm.confirmpassword == null) {
                    this.$message.error(this.$t('message.vueUser.verifyconfirmpassword'));
                    return false;
                } else if (this.userForm.changepassword != this.userForm.confirmpassword) {
                    this.$message.error(this.$t('message.vueUser.verifyConfirmPassword'));
                    return false;
                } else if (this.userForm.changepassword.length < 6) {
                    this.$message.error(this.$t('message.vueUser.verifypassword2'));
                    return false;
                } else if (this.userForm.changepassword.length > 8) {
                    this.$message.error(this.$t('message.vueUser.verifypassword3'));
                    return false;
                } else if (!regNumber.test(this.userForm.changepassword)) {
                    this.$message.error(this.$t('message.vueUser.verifyConfirmPassword2'));
                    return false;
                } else if (!regLetter.test(this.userForm.changepassword)) {
                    this.$message.error(this.$t('message.vueUser.verifyConfirmPassword2'));
                    return false;
                } else if (!regBigLetter.test(this.userForm.changepassword)) {
                    this.$message.error(this.$t('message.vueUser.verifyConfirmPassword3'));
                    return false;
                }

                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/user/changeuserpassword', {
                                Id: this.userForm.id,
                                Password: this.userForm.changepassword,
                                UserNumber: AppStorage.getNumber(),
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    if (response.data.msg == '存在账号') {
                                        this.$message.error(this.$t('message.vueUser.msgExistAccount'));
                                    } else {
                                        this.$message.error(this.$t('message.errer'));
                                    }
                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    this.$router.push({ path: "/Login" });
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });

                    } else {
                        return false;
                    }
                });
            },
        },
        mounted() {
            this.getInfo();
        },
    }
</script>
<style>
    .PointOut-words {
        padding-top: 20px;
        font-size: 20px;
        color: red;
    }
    .PointOut {
        padding-top: 0px;
        margin-top: 0px;
    }
    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>