<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/WeekSalesDataList' }">{{$t('message.vueSales.weeksalesdatapage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/WeekSalesData' }">{{$t('message.vueSales.weeksalesdatapage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="SalesDataForm" :model="SalesDataForm" :rules="rules" label-width="100px" size="small" class="demo-ruleForm">
        <el-row>
            <el-col :span="7">
                <el-form-item :label="$t('message.vueSales.year')" prop="year">
                    <el-date-picker v-model="SalesDataForm.year" type="year" :start-placeholder="$t('message.pleaseSelect')" :end-placeholder="$t('message.pleaseSelect')"
                                    format="YYYY" value-format="YYYY" :disabled="dis" @change="conditionsChange"></el-date-picker>
                </el-form-item>
            </el-col>
            <el-col :span="7">
                <el-form-item :label="$t('message.vueSales.month')" prop="month">
                    <el-date-picker v-model="SalesDataForm.month" type="month" :start-placeholder="$t('message.pleaseSelect')" :end-placeholder="$t('message.pleaseSelect')"
                                    format="MM" value-format="MM" :disabled="dis" @change="conditionsChange"></el-date-picker>
                </el-form-item>
            </el-col>
            <el-col :span="7">
                <el-form-item :label="$t('message.vueSales.country')" prop="country">
                    <el-select v-model="SalesDataForm.country" filterable remote reserve-keyword :placeholder="$t('message.pleaseSelect')" :disabled="dis"
                               :remote-method="(query)=>{ getCountrys(query) }" :loading="SalesDataForm.isloading" style="width:220px">
                        <el-option v-for="item in allCountrys" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" border style="width: 100%" size="mini" fit>
                    <el-table-column v-for="(item, index) in tableLabelOption"
                                     :key="index"
                                     :label="item.label" :prop="item.iType"
                                     align="center" width="200px">
                        <el-table-column :label="$t('message.vueSales.uploadAmount')+''" prop="uploadAmount" width="110px">
                            <template #default="scope">
                                <el-input v-model="scope.row.SalesDataDetailSubs[item.iType].UploadAmount"
                                          oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
                                          :disabled="operate">{{scope.row.SalesDataDetailSubs[item.iType].UploadAmount}}</el-input>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item>
                    <el-button @click="submitForm('SalesDataForm',1)"
                               :disabled="disabledSubmit || operate" size="mini" class="button_submit">
                        {{$t('message.save')}}
                    </el-button>
                    <el-button @click="returnForm()" size="mini" class="button_return">{{$t('message.return')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<style>
    .el-form .el-select {
        width: 100%;
    }

    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }

    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage';
    import { parseTime, getWeekNum } from '@/common/utils/datetime';
    import { GetCompanyList } from '@/api/system/company';

    export default {
        name: '',
        components: {
            //挂载组件
        },
        data() {
            return {
                disabledSubmit: false,
                SalesDataForm: {
                    id: 0,
                    year: '',
                    month: '',
                    country: '',
                    tableData: [],
                    userNumber: AppStorage.getNumber()
                },
                tableData: [{
                    Id: 0,
                    SalesDataDetailSubs: [
                        {
                            SalesDataDate: new Date(),
                            UploadAmount: 0.0,
                        }],
                    AddTime: new Date(),
                    AddUserNumber: '',
                    EditTime: new Date(),
                    EditUserNumber: '',
                    DisabledTime: new Date(),
                    DisabledUserNumber: '',
                    SalesDataId: 0,
                }],
                SalesDataDetailSubs: [
                    {
                        SalesDataDate: new Date(),
                        UploadAmount: 0.0,
                    },
                    {
                        SalesDataDate: new Date(),
                        UploadAmount: 0.0,
                    },
                    {
                        SalesDataDate: new Date(),
                        UploadAmount: 0.0,
                    },
                    {
                        SalesDataDate: new Date(),
                        UploadAmount: 0.0,
                    },
                    {
                        SalesDataDate: new Date(),
                        UploadAmount: 0.0,
                    }, {
                        SalesDataDate: new Date(),
                        UploadAmount: 0.0,
                    },
                    {
                        SalesDataDate: new Date(),
                        UploadAmount: 0.0,
                    }
                ],
                tableLabelOption: [

                ],
                selectDate: null,
                dis: this.$route.query.readonly == "true" ? true : false,
                operate: this.$route.query.operate == "query" ? true : false,
                allCountrys: [],
                allproduct: [],
            }
        },

        computed: {
            rules() {
                const rules = {
                    country: [
                        { required: true, message: this.$t('message.vueSales.verifySalesTargetRequired'), trigger: 'blur' }
                    ]
                };
                return rules;
            },
        },

        methods: {
            getInfo() {
                this.SalesDataForm.id = this.$route.query.id;

                if (this.SalesDataForm.id > 0) {
                    this.SalesDataForm.country = this.$route.query.country;
                    this.SalesDataForm.year = this.$route.query.yearS;
                    this.SalesDataForm.month = this.$route.query.monthS;

                    this.axios
                        .get('/api/salesData/getweeksalesdatapage2', {
                            params: {
                                Year: this.SalesDataForm.year,
                                Month: this.SalesDataForm.month,
                                Country: this.SalesDataForm.country,
                            }
                        })
                        .then(response => {
                            var info = response.data.data;
                            if (response.data.code == 1 && info != null) {
                                this.tableLabelOption.splice(0, this.tableLabelOption.length);
                                console.log(info.length);
                                for (var z = 0; z < info.length; z++) {
                                    this.tableLabelOption.push({
                                        label: parseTime(info[z].datePeriod, '{m}月{d}日'),
                                        iType: z,
                                    });
                                }
                                for (var i = 0; i < 1; i++) {
                                    this.tableData[i].Id = info[i].id;

                                    for (var j = 0; j < info.length; j++) {
                                        this.tableData[i].SalesDataDetailSubs[j].SalesDataDate = info[j].datePeriod;
                                        this.tableData[i].SalesDataDetailSubs[j].UploadAmount = info[j].weekAmount;
                                    }
                                    this.addTableData();
                                }
                                this.tableData.splice(i, 1);
                            }
                        });
                }
            },

            getCountrys(query, isAll = true) {
                this.SalesDataForm.isloading = true;
                GetCompanyList(null, 0, true).then(response => {
                    var infos = response.data.data;
                    if (response.data.code == 1 && infos != null) {
                        this.allCountrys = infos.map(function (m) {
                            return {
                                value: m.companyNumber,
                                label: m.companyName + "(" + m.companyNumber + ")"
                            }
                        });
                        this.allCountrys.unshift({
                            value: "",
                            label: this.$t('message.all')
                        });
                    }
                    this.SalesDataForm.isloading = false;
                })
                    .catch(error => {
                        console.log(error);
                        this.SalesDataForm.isloading = false;
                    });
            },

            addTableData() {
                this.tableData.push({
                    Id: 0,
                    SalesDataDetailSubs: [
                        {
                            SalesDataDate: this.SalesDataDetailSubs[0].SalesDataDate,
                            UploadAmount: 0.0,
                        },
                        {
                            SalesDataDate: this.SalesDataDetailSubs[1].SalesDataDate,
                            UploadAmount: 0.0,
                        },
                        {
                            SalesDataDate: this.SalesDataDetailSubs[2].SalesDataDate,
                            UploadAmount: 0.0,
                        },
                        {
                            SalesDataDate: this.SalesDataDetailSubs[3].SalesDataDate,
                            UploadAmount: 0.0,
                        },
                        {
                            SalesDataDate: this.SalesDataDetailSubs[4].SalesDataDate,
                            UploadAmount: 0.0,
                        }, {
                            SalesDataDate: this.SalesDataDetailSubs[5].SalesDataDate,
                            UploadAmount: 0.0,
                        },
                        {
                            SalesDataDate: this.SalesDataDetailSubs[6].SalesDataDate,
                            UploadAmount: 0.0,
                        }
                    ],
                    AddTime: new Date(),
                    AddUserNumber: '',
                    EditTime: new Date(),
                    EditUserNumber: '',
                    DisabledTime: new Date(),
                    DisabledUserNumber: '',
                    SalesDataId: 0,
                });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;

                        this.axios
                            .post('/api/salesData/addoreditweeksalesdata', {
                                Id: this.SalesDataForm.id,
                                Country: this.SalesDataForm.country,
                                YearS: this.SalesDataForm.year,
                                MonthS: this.SalesDataForm.month,
                                UserNumber: this.SalesDataForm.userNumber,
                                SalesDataDetailEntities: this.tableData,
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    this.$message.error(this.$t(response.data.msg));

                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    this.$router.push("/WeekSalesDataList");
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.exception') + ':' + error);
                                this.disabledSubmit = false;
                            });

                    } else {
                        return false;
                    }
                });
            },
            returnForm() {
                this.$router.go(-1);
            },
            // 初始化日期
            getDay() {
                var date = new Date();
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var day = date.getDate();
                if (month < 10) {
                    month = '0' + month;
                }
                if (day < 10) {
                    day = '0' + day;
                }
                let nowDate = year + '-' + month + '-' + '01';
                this.SalesDataForm.year = year.toString();
                var newDate = new Date(nowDate);
                //let weekday = newDate.getDay() || 7;
                //newDate = new Date(newDate.setDate(newDate.getDate() - weekday - 1));
                this.SalesDataForm.month = month;

                newDate = new Date(newDate.setDate(newDate.getDate() + 1));
                this.chargeTableHeader(newDate);


            },
            chargeTableHeader(firstDate) {
                this.tableLabelOption.splice(0, this.tableLabelOption.length);
                this.tableData.splice(0, this.tableData.length);
                this.SalesDataDetailSubs.splice(0, this.SalesDataDetailSubs.length);

                let daycount = new Date(this.SalesDataForm.year, this.SalesDataForm.month, 0).getDate();
                var j = 0;

                for (var i = 0; i < daycount; i++) {
                    var salesData = new Date(firstDate.setDate(firstDate.getDate() + 1));
                    if (salesData.getDay() == 0) {
                        this.tableLabelOption.push({
                            label: parseTime(salesData, '{m}月{d}日'),
                            //iType: i,
                            iType: j,
                        });
                        this.SalesDataDetailSubs.push({
                            SalesDataDate: salesData,
                            UploadAmount: 0.0,
                        })
                        j++;
                    }
                    
                }

                this.tableData.push({
                    Id: 0,
                    SalesDataDetailSubs: this.SalesDataDetailSubs,
                    AddTime: new Date(),
                    AddUserNumber: '',
                    EditTime: new Date(),
                    EditUserNumber: '',
                    DisabledTime: new Date(),
                    DisabledUserNumber: '',
                    SalesDataId: 0,
                });
            }
        },
        mounted() {
            this.getInfo();
            this.getDay();
        },
        created() {
            this.getCountrys();
        }
    }
</script>