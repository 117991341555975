<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/RoleList' }">{{$t('message.vueRole.crumbAuthorityManager')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="170px" size="mini">
        <el-form-item :label="$t('message.vueRole.labCompanys')+' :'">
            <el-select v-model="formSearch.companyNumber"
                       style="width: 170px"
                       filterable
                       remote
                       reserve-keyword
                       :placeholder="$t('message.pleaseSelect')"
                       :remote-method="(query)=>{getCompanys(query)}"
                       :loading="formSearch.isloading"
                       size="mini">
                <el-option v-for="o in allCompanys"
                           :key="o.value"
                           :label="o.label"
                           :value="o.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('message.vueRole.labDepartments')+' :'">
            <el-select v-model="formSearch.departmentNumber"
                       style="width: 170px"
                       filterable
                       remote
                       reserve-keyword
                       :placeholder="$t('message.pleaseSelect')"
                       :remote-method="(query)=>{getDepartments(query)}"
                       :loading="formSearch.isloading"
                       size="mini">
                <el-option v-for="o in allDepartments"
                           :key="o.value"
                           :label="o.label"
                           :value="o.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('message.vueRole.labUsers')+' :'">
            <el-select v-model="formSearch.userNumber"
                       style="width: 170px"
                       filterable
                       remote
                       reserve-keyword
                       :placeholder="$t('message.pleaseSelect')"
                       :remote-method="(query)=>{getUsers(query)}"
                       :loading="formSearch.isloading"
                       size="mini">
                <el-option v-for="o in allUsers"
                           :key="o.value"
                           :label="o.label"
                           :value="o.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('message.vueRole.labRoleName')+' :'">
            <el-input v-model="formSearch.roleName" :placeholder="$t('message.pleaseEnter')" size="mini" style="width: 170px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('message.vueRole.labIsDisabled')+' :'">
            <el-select v-model="formSearch.isDisabled" :placeholder="$t('message.all')" style="width:100px" size="mini">
                <el-option :label="$t('message.all')" value=""></el-option>
                <el-option :label="$t('message.vueRole.selIsDisabledYes')" value="true"></el-option>
                <el-option :label="$t('message.vueRole.selIsDisabledNo')" value="false"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button class="btn_search" @click="submitSearch"
                       icon="el-icon-search" size="mini">{{$t('message.search')}}</el-button>
        </el-form-item>
        <el-row>
            <el-form-item>
                <el-button type="primary" @click="add"
                           icon="el-icon-circle-plus-outline" size="mini" class="btn_add">{{$t('message.vueProduct.button_add')}}</el-button>
            </el-form-item>
        </el-row>
    </el-form>
    <el-table :data="tableData"
              border style="width: 100%" size="mini"
              :stripe="true"
              :header-cell-style="{'background':'#81761852','color':'#2F4F4F','font-size': '12px','text-align':'center'}">
        <el-table-column prop="roleName"
                         :label="$t('message.vueRole.labRoleName')"
                         width="200">
        </el-table-column>
        <el-table-column prop="companyNames"
                         :label="$t('message.vueRole.labCompanys')"
                         width="200">
        </el-table-column>
        <el-table-column prop="departmentNames"
                         :label="$t('message.vueRole.labDepartments')"
                         width="200">
        </el-table-column>
        <el-table-column prop="userNames"
                         :label="$t('message.vueRole.labUsers')"
                         width="200">
        </el-table-column>
        <el-table-column prop="isDisabled"
                         :label="$t('message.vueRole.labDisabled')"
                         width="100">
            <template #default="scope">
                <el-tooltip :content="$t('message.vueRole.labIsDisabled')" placement="top" style="margin-right:10px">
                    <el-switch v-model="scope.row.isDisabled" active-color="#ff4949" inactive-color="#13ce66" :loading="scope.row.isLoading" :before-change="()=>changeIsDisabled(scope.row)"></el-switch>
                </el-tooltip>
                <span v-if="scope.row.isDisabled===true">
                    {{$t('message.vueRole.selIsDisabledYes')}}
                </span>
                <span v-else>
                    {{$t('message.vueRole.selIsDisabledNo')}}
                </span>
            </template>
        </el-table-column>
        <el-table-column :label="$t('message.operate')"
                         min-width="300">
            <template #default="scope">
                <el-button size="mini"
                           icon="el-icon-tickets"
                           class="button_detail"
                           @click="query(scope.row)">{{$t('message.details')}}</el-button>
                <el-button size="mini"
                           icon="el-icon-edit"
                           class="button_edit"
                           @click="edit(scope.row)">{{$t('message.edit')}}</el-button>
                <el-button size="mini"
                           icon="el-icon-delete"
                           type="danger"
                           class="button_delete"
                           :disabled="scope.row.isDisabledDelete"
                           @click="del(scope.$index,scope.row)">{{$t('message.delete')}}</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="pageIndex"
                   :page-sizes="[10, 25, 50, 75, 100]"
                   :page-size="pageSize"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="count">
    </el-pagination>
</template>
<style>
    .btn_search {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }

    .btn_add {
        font-weight: bolder;
    }

    .button_delete {
        font-weight: bolder;
    }

    .button_detail {
        font-weight: bolder;
    }

    .button_edit {
        color: #fff;
        background-color: orange;
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'RoleList',
        data() {
            return {
                tableData: null,
                pageIndex: 1,
                pageSize: 50,
                count:0,
                formSearch: {
                    isloading: false,
                    isDisabled: 'false',
                    companyNumber: '',
                    departmentNumber: '',
                    userNumber: '',
                    roleName: '',

                },
                allCompanys: [],
                allDepartments: [],
                allUsers: [],
            }
        },
        methods: {
            loadPage() {
                this.axios
                    .get('/api/role/getrolepage', {
                        params: {
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize,
                            isDisabled: this.formSearch.isDisabled,
                            companyNumber: this.formSearch.companyNumber,
                            departmentNumber: this.formSearch.departmentNumber,
                            userNumber: this.formSearch.userNumber,
                            roleName: this.formSearch.roleName,
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos!= null) {
                            this.tableData = infos.items;
                            this.count = infos.count;
                        } else {
                            this.tableData = null;
                            this.count = 0;
                        }
                    });
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            },
            add() {
                this.$router.push({
                    path: "/RoleAdd",
                    query: { id: 0} });
            },
            query(row) {
                this.$router.push({
                    path: "/RoleDetails",
                    query: { id: row.id }
                });
            },
            edit(row) {
                this.$router.push({
                    path: "/RoleEdit",
                    query: { id: row.id }
                });
            },
            del(index,row) {
                this.$confirm(this.$t('message.messageBox.msgDelete'), this.$t('message.messageBox.titleTips'), {
                    confirmButtonText: this.$t('message.messageBox.btnConfirm'),
                    cancelButtonText: this.$t('message.messageBox.btnCancel'),
                    type: 'warning'
                }).then(() => {
                    row.isDisabledDelete = true;
                    this.axios
                        .post('/api/role/delrole', {
                            Id: row.id,
                            UserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isDisabledDelete  = false;
                            if (response.data.code != 1) {
                                if (response.data.msg == '角色不存在') {
                                    this.$message.error(this.$t('message.vueRole.msgRoleNull'));
                                    return;
                                }
                                this.$message.error(this.$t('message.error'));
                            } else {
                                this.$message.success(this.$t('message.successfullyDeleted'));
                                this.tableData.splice(index, 1);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isDisabledDelete = false;
                            this.$message.error(this.$t('message.messageBox.connecterror'));
                        });
                }).catch(() => {
                });
            },
            changeIsDisabled(row) {
                var isDisabled = false;
                if (row.isDisabled == false) {
                    isDisabled = true;
                }
                row.isLoading = true;
                return new Promise(resolve => {
                    this.axios
                        .post('/api/role/disabledrole', {
                            Id: row.id,
                            IsDisabled: isDisabled,
                            UserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isLoading = false;
                            if (response.data.code != 1) {
                                this.$message.error(this.$t('message.error'));
                                return resolve(false)
                            } else {
                                this.$message.success(this.$t('message.success'));
                                return resolve(true);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isLoading = false;
                            this.$message.error(this.$t('message.messageBox.connecterror'));
                            return resolve(false);
                        });
                })
            },
            getUsers(query, isAll = true) {
                this.formSearch.isloading = true;
                this.axios
                    .get('/api/user/getuserlist', {
                        params: {
                            companyId: null,
                            departmentId: null,
                            keyword: query,
                            isAll: isAll
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allUsers = infos.map(function (m) {
                                return {
                                    value: m.number,
                                    label: m.name + "(" + m.number + ")"
                                }
                            });
                            this.allUsers.unshift({
                                value: "",
                                label: this.$t('message.all')
                            });
                        }
                        this.formSearch.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.formSearch.isloading = false;
                    });
            },
            getDepartments(query, isAll = true) {
                this.formSearch.isloading = true;
                this.axios
                    .get('/api/department/getdepartmentlist', {
                        params: {
                            companyId: null,
                            keyword: query,
                            userNumber: AppStorage.getNumber(),
                            isAll: isAll
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allDepartments = infos.map(function (m) {
                                return {
                                    value: m.departmentNumber,
                                    label: m.departmentName + "(" + m.departmentNumber + ")"
                                }
                            });
                            this.allDepartments.unshift({
                                value: "",
                                label: this.$t('message.all')
                            });
                        }
                        this.formSearch.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.formSearch.isloading = false;
                    });
            },
            getCompanys(query, isAll = true) {
                this.formSearch.isloading = true;
                this.axios
                    .get('/api/company/getcompanylist', {
                        params: {
                            isFactory: 2,
                            userNumber: AppStorage.getNumber(),
                            keyword: query,
                            isAll: isAll
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allCompanys = infos.map(function (m) {
                                return {
                                    value: m.companyNumber,
                                    label: m.companyName + "(" + m.companyNumber + ")"
                                }
                            });
                            this.allCompanys.unshift({
                                value: "",
                                label: this.$t('message.all')
                            });
                        }
                        this.formSearch.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.formSearch.isloading = false;
                    });
            },
        },
        mounted() {
            this.getUsers('', true);
            this.getDepartments('', true);
            this.getCompanys('', true);
            this.submitSearch();
        }

    };
</script>