<template>
    <div class="login-logo">
        <el-image :src="srcLogo" class="logo-img"></el-image>
        <span class="logo-title">Sales Data System Platform</span>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0" class="login-ruleForm">
        <el-form-item label="" prop="number">
            <el-input v-model="ruleForm.number" @blur="getLanguage()" :placeholder="$t('message.vueLogin.labNumber')">
                <template #suffix>
                    <i class="el-input__icon el-icon-user"></i>
                </template>
            </el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
            <el-input v-model="ruleForm.password" :type="pwdType" @blur="getLanguage()" :placeholder="$t('message.vueLogin.labPassword')">
                <template #suffix>
                    <i class="el-input__icon el-icon-view" @click="changeType"></i>
                </template>
            </el-input>
        </el-form-item>
        <el-form-item label="" prop="language" v-if="ruleForm.number!=''&&ruleForm.password!=''">
            <el-select v-model="ruleForm.language" :placeholder="$t('message.vueLogin.labLanguage')" @change="selectLanguage(ruleForm.language)" style="width: 100%;">
                <el-option label="English" value="en"></el-option>
                <el-option label="繁體中文" value="zh-tw"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button type="success" @click="submitForm('ruleForm')" class="login-submit">{{$t('message.vueLogin.btnSubmitForm')}}</el-button>
            <el-button type="success" @click="forgetPassword('ruleForm')" class="login-forgetpassword">{{$t('message.vueLogin.btnForgetPassword')}}</el-button>
        </el-form-item>
    </el-form>
<div class="login-bottom">
    <span class="logo-words">Copyright@ 2023 Ogawa International Holding Ltd</span><br />
    <span class="logo-words">Copyright@ 2023 Sales Data Platform</span>
</div>
<userChangePassword ref="msgBtn" :formType="userId"></userChangePassword>
</template>
<script>
    import AppStorage from '@/common/storage/app-storage'
    import userChangePassword from '@/components/User/UserChangePassword'
    var userId = 0
    export default {
        name: 'Login',
        components: {
            //挂载组件
            userChangePassword, //自定义的标签
        },
        data() {
            return {
                pwdType: 'password',
                srcLogo: require('@/assets/img/OGAWALOGO.png'),
                disabledSubmit: true,
                ruleForm: {
                    number: '',
                    password: '',
                    language: localStorage.getItem('language') || 'en'
                }
            }
        },
        computed: {
            rules() {
                const rules = {
                    number: [
                        { required: true, message: this.$t('message.vueLogin.verifyNumberRequired'), trigger: 'blur' }
                    ],
                    language: [
                    ]
                };
                return rules;
            }
        },
        methods: {
            changeType() {
                this.pwdType = this.pwdType === 'password' ? 'text' : 'password';
            },
            getLanguage() {
                if (this.ruleForm.number != '' && this.ruleForm.password != '') {
                    this.disabledSubmit = true;
                    this.axios
                        .post('/api/user/getuser', {
                            Number: this.ruleForm.number,
                            Password: this.ruleForm.password
                        })
                        .then(response => {
                            if (response.data.code == 1 && response.data.data != null && response.data.data.language != null) {
                                this.ruleForm.language = response.data.data.language;
                                this.$i18n.locale = response.data.data.language;
                            }
                            this.disabledSubmit = false;
                        }).catch(error => {
                            console.log(error);
                            alert(this.$t('message.messageBox.connecterror'));
                            this.disabledSubmit = false;
                        });
                }
            },
            changepassword() {
                this.$refs.msgBtn.init(userId);
            },
            selectLanguage(val) {
                this.$i18n.locale = val;
            },
            getInfo() {
                this.axios
                    .get('/api/user/getuserbyname', {
                        params: {
                            userName: AppStorage.getName(),
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            userId = info.id;
                        }
                    });
            },
            submitForm(formName) {
                var that = this;

                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/user/login', {
                                Number: this.ruleForm.number,
                                Password: this.ruleForm.password,
                                Language: this.ruleForm.language,
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    alert(this.$t(response.data.msg));
                                } else {
                                    //token
                                    localStorage.setItem('language', this.ruleForm.language)
                                    AppStorage.set(JSON.stringify(response.data.data));

                                    this.getInfo();

                                    if (this.ruleForm.password == "123456") {
                                        this.$confirm(this.$t('message.messageBox.msgLoginAgain2'), this.$t('message.messageBox.titleTips'), {
                                            confirmButtonText: this.$t('message.messageBox.btnConfirm'),
                                            type: 'warning'
                                        }).then(() => {
                                            console.log(userId);
                                            that.$refs.msgBtn.init(userId);
                                        }).catch(() => {
                                        });
                                    } else {
                                        var goUrl = "/";
                                        var redirect = this.$route.query.redirect;
                                        if (redirect != undefined && redirect != "undefined" && redirect != null && redirect != "") {
                                            goUrl = redirect;
                                        }
                                        this.$router.push(goUrl);
                                    }
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error.response.data.msg);
                                if (error.response.data.msg != "") {
                                    alert(this.$t(error.response.data.msg));
                                } else
                                {
                                    alert(this.$t('message.messageBox.connecterror'));
                                }

                                this.disabledSubmit = false;
                            });

                    } else {
                        return false;
                    }
                });
            },
            forgetPassword(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/workflow/mailsend2', {
                                UserNumber: this.ruleForm.number,
                                FormType: 9,
                                FormId: 0,
                                CheckContent: "Forget Password",
                                Operates: 1,
                                UserNumbers: this.ruleForm.number,
                                ReturnOrderNo: 0,
                                OrderNumber: "",
                                SAPOrderNumber: "",
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    alert(this.$t(response.data.msg));
                                } else {
                                    alert(this.$t(response.data.msg));
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                alert(this.$t('message.exception'));
                                this.disabledSubmit = false;
                            });
                    } else {
                        return false;
                    }
                });
            }
        },
        mounted() {
            this.$i18n.locale = this.ruleForm.language;
        }
    }
</script>
<style>
    .login-ruleForm {
        width: 260px;
        margin: 2% auto 20px;
        padding: 50px 30px 10px 30px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }

    .login-logo {
        width: 100%;
        display: block;
        text-align: center;
    }

    .login-logo .logo-img {
        width: 330px;
        height: 90px;
        margin: 5% auto 30px;
        display: block;
    }

    .login-logo .logo-title {
        font-size: 20px;
        font-weight: bolder;
    }

    .login-submit {
        margin-top: 20px;
        width: 100px;
        background-color: blue;
    }
    .login-forgetpassword {
        background-color: blue;
    }

    .login-bottom {
        width: 330px;
        height: 90px;
        margin: 4% auto 30px;
        display: block;
    }

    .logo-words {
        font-size: 14px;
        color: #606266;
    }
</style>

