<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/Vendor' }">{{$t('message.vueVendor.vendorpage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="vendor">
        <el-form ref="vendorForm" :model="vendorForm" :inline="true" class="vendorForm-inline">
            <el-form-item :label="$t('message.vueCompany.labIsDisabled')+' :'">
                <el-select size="mini" v-model="vendorForm.isDisabled" :placeholder="$t('message.pleaseSelect')" style="width:100px">
                    <el-option :label="$t('message.pleaseSelect')" value=""></el-option>
                    <el-option :label="$t('message.vueCompany.selIsDisabledYes')" value="true"></el-option>
                    <el-option :label="$t('message.vueCompany.selIsDisabledNo')" value="false"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('message.vueVendor.vendor_name')+' :'">
                <el-input size="mini" v-model="vendorForm.input_vendorname"></el-input>
            </el-form-item>
            <el-form-item :label="$t('message.vueVendor.vendor_number')+' :'">
                <el-input size="mini" v-model="vendorForm.input_vendornumber"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button size="mini" class="btn_search"
                           @click="submitSearch" icon="el-icon-search">{{$t('message.vueCategory.button_search')}}</el-button>
            </el-form-item>
            <el-row>
                <el-form-item>
                    <el-button size="mini" type="primary" class="btn_add"
                               @click="goAddVendor" icon="el-icon-circle-plus-outline">{{$t('message.vueProduct.button_add')}}</el-button>
                </el-form-item>
            </el-row>
        </el-form>
        <el-table :data="tableData" size="mini" border
                  :stripe="true"
                  :header-cell-style="{'background':'#81761852','color':'#2F4F4F','font-size': '12px','text-align':'center'}">
            <el-table-column :label="$t('message.operate')" min-width="195">
                <template #default="scope">
                    <el-button size="mini"
                               icon="el-icon-tickets"
                               class="button_detail"
                               @click="query(scope.row)">{{$t('message.details')}}</el-button>
                    <el-button size="mini"
                               icon="el-icon-edit"
                               class="button_edit"
                               @click="goEdit(scope.row)">{{$t('message.vueCategory.button_update')}}</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="Vendor_Number" :label="$t('message.vueVendor.vendor_number')" width="125">
                <template #default="scope">
                    <span>{{scope.row.vendorNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="CompanyType" :label="$t('message.vueVendor.companyType')" width="125">
                <template #default="scope">
                    <span>{{scope.row.companyType}}</span>
                </template>
            </el-table-column>

            <el-table-column prop="Vendor_Name" :label="$t('message.vueVendor.vendor_name')" width="600">
                <template #default="scope">
                    <span>{{scope.row.vendorName}}</span>
                </template>
            </el-table-column>

            <el-table-column prop="Paymentterm" :label="$t('message.vueVendor.paymentterm')" width="480">
                <template #default="scope">
                    <span>{{scope.row.paymentterm}}</span>
                </template>
            </el-table-column>

            <el-table-column prop="Country" :label="$t('message.vueVendor.country')" width="80">
                <template #default="scope">
                    <span>{{scope.row.country}}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$t('message.operate')" min-width="192">
                <template #default="scope">
                    <el-button size="mini"
                               icon="el-icon-tickets"
                               class="button_detail"
                               @click="query(scope.row)">{{$t('message.details')}}</el-button>
                    
                </template>
            </el-table-column>
        </el-table>
        <el-divider></el-divider>
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="pageIndex"
                       :page-sizes="[10, 25, 50, 75, 100]"
                       :page-size="pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="count">
        </el-pagination>
    </div>
</template>

<style>
    .vendorForm-inline {
        margin: 5px;
        font-size: 90%;
    }

    .label-vendorname {
        margin: 5px;
        font-size: 90%;
    }

    .input-vendorname {
        margin: 5px;
    }

    .label-vendornumber {
        margin: 5px;
        font-size: 90%;
    }

    .input-vendornumber {
        margin: 5px;
    }
    .btn_search {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }

    .btn_add {
        font-weight: bolder;
    }

    .btn_delete {
        font-weight: bolder;
        background-color: red;
    }

    .button_detail {
        font-weight: bolder;
    }

    .button_edit {
        color: #fff;
        background-color: orange;
        font-weight: bolder;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'Vendor',
        data() {
            return {
                tableData: null,
                language: AppStorage.getLanguage(),
                vendorForm: {
                    isDisabled: "false",
                    input_vendorname: '',
                    input_vendornumber: ''
                },
                pageIndex: 1,
                //pageSize: 10,
                pageSize: 50,
                count: 0
            }
        },
        methods: {
            goAddVendor() {
                this.$router.push({ path: "/AddVendor" });
            },
            loadPage() {
                this.axios
                    .get('/api/Vendor/getvendorpage', {
                        params: {
                            isDisabled: this.vendorForm.isDisabled,
                            VendorName: this.vendorForm.input_vendorname,
                            VendorNumber: this.vendorForm.input_vendornumber,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.tableData = infos.items;
                            this.count = infos.count;
                        } else {
                            this.tableData = null;
                            this.count = 0;
                        }
                    });
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            },
            goEdit(row) {
                this.$router.push({
                    path: "/EditVendor",
                    //query: { VendorNumber: row.vendorNumber }
                    query: { Id: row.id }
                });
            },
            query(row) {
                this.$router.push({
                    path: "/VendorDetail",
                    query: { Id: row.id }
                });
            },
            changeIsDisabled(row) {
                var isDisabled = false;
                if (row.isDisabled == false) {
                    isDisabled = true;
                }
                row.isLoading = true;
                return new Promise(resolve => {
                    this.axios
                        .post('/api/Vendor/disabledvendor', {
                            VendorName: row.vendorName,
                            VendorNumber: row.vendorNumber,
                            IsDisabled: isDisabled,
                            UserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isLoading = false;
                            if (response.data.code != 1) {
                                this.$message.error(this.$t('message.error'));
                                return resolve(false);
                            } else {
                                this.$message.success(this.$t('message.success'));
                                this.loadPage();
                                return resolve(true);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isLoading = false;
                            this.$message.error(this.$t('message.messageBox.connecterror'));
                            return resolve(false);
                        });
                })
            }
        },
        //这个组件内的方法，函数
        mounted() {
            this.loadPage();
        }
    };
</script>
