<template>
    <div id="msgbox">
        <el-dialog v-model="dialogTableVisible"
                   class="dialogTableVisible"
                   width="500px"
                   @open="openDialog">
            <div id="PointOut" class="PointOut">
                <span class="PointOut-words">The length of password is 6-8 digits!</span><br />
                <span class="PointOut-words">Contains at least one capital letter!</span><br />
                <span class="PointOut-words">Consists numbers and letters!</span>
                <el-divider></el-divider>
            </div>
            <el-form ref="userForm" :model="userForm" :rules="rules" class="demo-ruleForm" :inline="true">
                <el-form-item :label="$t('message.vueUser.labOldPassword')+':'" prop="oldpassword" label-width="180px">
                    <el-input v-model="userForm.oldpassword"></el-input>
                </el-form-item>

                <el-form-item :label="$t('message.vueUser.labNewPassword')+':'" prop="password" label-width="180px">
                    <el-input v-model="userForm.password" :type="pwdType">
                        <template #suffix>
                            <i class="el-input__icon el-icon-view" @click="changeType"></i>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item :label="$t('message.vueUser.labConfirmPassword')+':'" prop="confirmpassword" label-width="180px">
                    <el-input v-model="userForm.confirmpassword" :type="pwdType2">
                        <template #suffix>
                            <i class="el-input__icon el-icon-view" @click="changeType2"></i>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item class="button">
                    <el-button type="primary" id="btn_confirm" @click="confirm">{{$t('message.vueMenu.btnPassword')}}</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        </div>
</template>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: '',
        data() {
            return {
                pwdType: 'password',
                pwdType2: 'password',
                language: AppStorage.getLanguage(),
                userForm: {
                    id: 0,
                    oldpassword: '',
                    password: '',
                    confirmpassword: '',
                },
                dialogTableVisible: false,
            }
        },
        computed: {
            rules() {
                const rules = {
                    oldpassword: [
                        { required: true, message: this.$t('message.vueUser.verifyPasswordRequired'), trigger: 'blur' },
                    ],
                    confirmpassword: [
                        { required: true, message: this.$t('message.vueUser.verifyPasswordRequired'), trigger: 'blur' },
                    ],
                    password: [
                        { required: true, message: this.$t('message.vueUser.verifyPasswordRequired'), trigger: 'blur' },
                        { min: 6, max: 8, message: this.$t('message.vueUser.verifyPasswordLen'), trigger: 'blur' }
                    ],
                };
                return rules;
            }
        },
        methods: {
            init(id) {
                this.userForm.id = id;
                this.dialogTableVisible = true;
            },
            changeType() {
                this.pwdType = this.pwdType === 'password' ? 'text' : 'password';
            },
            changeType2() {
                this.pwdType2 = this.pwdType2 === 'password' ? 'text' : 'password';
            },
            checkPass(pass) {
                if (pass.length < 6 || pass.length > 8) {
                    return 0;
                }
                var str = 0;
                if (pass.match(/([a-z])+/)) {
                    str++;
                }
                if (pass.match(/([0-9])+/)) {
                    str++;
                }
                if (pass.match(/([A-Z])+/)) {
                    str++;
                }
                if (pass.match(/[^a-zA-Z0-9]+/)) {
                    str++;
                }
                return str;
            },
            confirm() {
                const regNumber = /(?=.*[\d])/;
                //是否包含一位字母
                const regLetter = /(?=.*[a-zA-Z])/;
                //是否包含一位大写字母
                const regBigLetter = /(?=.*[A-Z])/;
                //是否包含一位特殊字符
                //const regCharacter = /(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、])/;

                if (this.userForm.oldpassword == null) {
                    this.$message.error(this.$t('message.vueUser.verifyoldpassword'));
                    return false;
                } else if (this.userForm.password == null) {
                    this.$message.error(this.$t('message.vueUser.verifypassword'));
                    return false;
                } else if (this.userForm.confirmpassword == null) {
                    this.$message.error(this.$t('message.vueUser.verifyconfirmpassword'));
                    return false;
                } else if (this.userForm.password != this.userForm.confirmpassword) {
                    this.$message.error(this.$t('message.vueUser.verifyConfirmPassword'));
                    return false;
                } else if (this.userForm.password.length < 6) {
                    this.$message.error(this.$t('message.vueUser.verifypassword2'));
                    return false;
                } else if (this.userForm.password.length > 8) {
                    this.$message.error(this.$t('message.vueUser.verifypassword3'));
                    return false;
                } else if (!regNumber.test(this.userForm.password)) {
                    this.$message.error(this.$t('message.vueUser.verifyConfirmPassword2'));
                    return false;
                } else if (!regLetter.test(this.userForm.password)) {
                    this.$message.error(this.$t('message.vueUser.verifyConfirmPassword2'));
                    return false;
                } else if (!regBigLetter.test(this.userForm.password)) {
                    this.$message.error(this.$t('message.vueUser.verifyConfirmPassword3'));
                    return false;
                }

                this.axios
                    .post('/api/user/changeuserpassword', {
                        Id: this.userForm.id,
                        OldPassword: this.userForm.oldpassword,
                        Password: this.userForm.password,
                        UserNumber: AppStorage.getNumber(),
                    })
                    .then(response => {
                        if (response.data.code != 1) {
                            this.$message.error(this.$t(response.data.msg));
                        } else {
                            this.$message.success(this.$t(response.data.msg));

                            this.$confirm(this.$t('message.messageBox.msgLoginAgain'), this.$t('message.messageBox.titleTips'), {
                                confirmButtonText: this.$t('message.messageBox.btnConfirm'),
                                type: 'warning'
                            }).then(() => {
                                this.$router.push({ path: "/Login" });
                            }).catch(() => {
                            });
                        }
                    }).catch(error => {
                        console.log(error);
                        this.$message.error(this.$t('message.messageBox.connecterror'));
                    });
                this.dialogTableVisible = false;
            },
            openDialog() {
                this.userForm.password = "";
                this.userForm.confirmpassword = "";
                this.userForm.oldpassword = "";
            },
        }
    };
</script>
<style>
    .el-icon-view {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        display: block;
        width: 34px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        pointer-events: auto;
        cursor: pointer;
    }
    .button {
        text-align: center;
        margin:0 auto;
    }
    .demo-ruleForm {
        padding-top: 0px;
        margin: 0 auto;
    }
    .PointOut-words {
        padding-top: 20px;
        font-size: 20px;
        color: red;
    }
    .PointOut {
        padding-top: 0px;
        margin-top: 0px;
    }
    .dialogTableVisible {
        background-color: cornflowerblue;
        padding-top: 0px;
        margin-top: 0px;
    }
</style>
