<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ProductCol' }">{{$t('message.vueProductCol.productcolpage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/AddProductCol' }">{{$t('message.vueProductCol.addproductcolpage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="addproductcolForm" :model="addproductcolForm" :rules="rules" label-width="200px" class="demo-addproductcolForm">
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductCol.ProcuctColour')+' :'" prop="input_productcol">
                    <el-input size="mini" v-model="addproductcolForm.input_productcol" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductCol.ProductColourNumber')+' :'" prop="input_productcoln">
                    <el-input size="mini" v-model="addproductcolForm.input_productcoln" :disabled="true" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item>
                    <el-button class="button_submit" size="mini"
                               @click="submitForm('addproductcolForm')" :disabled="disabledSubmit">{{$t('message.vueCategory.button_sava')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>
<style>
    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'AddProductCol',
        data() {
            return {
                language: AppStorage.getLanguage(),
                addproductcolForm: {
                    input_productcol: '',
                    input_productcoln: '',
                    userNumber: AppStorage.getNumber()
                },
                seen: true,
            }
        },
        computed: {
            rules() {
                const rules = {
                    input_productcol: [
                        { required: true, message: this.$t('message.vueProductCol.verifyProductcolRequired'), trigger: 'blur' }
                    ]/*,
                    input_productcoln: [
                        { required: true, message: this.$t('message.vueProductCol.verifyProductcolNumberRequired'), trigger: 'blur' }
                    ]*/
                };
                return rules;
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.addproductcolForm.input_productcol != '') {
                            this.disabledSubmit = true;
                            this.axios
                                .post('/api/ProductCol/addproductcol', {
                                    Id: 0,
                                    ProductCol: this.addproductcolForm.input_productcol,
                                    //ProductColNa: this.addproductcolForm.input_productcoln,
                                    UserNumber: AppStorage.getNumber()
                                })
                                .then(response => {
                                    if (response.data.code != 1) {
                                        if (response.data.msg == 'this colour is exist') {
                                            this.$message.error(this.$t('message.vueProductCol.msgExistProductCol'));
                                        } else if (response.data.msg == 'this colour number is exist') {
                                            this.$message.error(this.$t('message.vueProductCol.msgExistEditProductCol'));
                                        } else {
                                            this.$message.error(this.$t('message.errer'));
                                        }
                                    } else {
                                        this.$message.success(this.$t('message.success'));
                                        this.$router.push({ path: "/ProductCol" });
                                    }
                                    this.disabledSubmit = false;
                                }).catch(error => {
                                    console.log(error);
                                    this.$message.error(this.$t('message.messageBox.connecterror'));
                                    this.disabledSubmit = false;
                                });
                        } else {
                            alert(this.$t('message.vueProductCol.addproductcolerror'));
                        }
                    } else {
                        return false;
                    }
                });
            } 
        },
        mounted() {
        }
    };
</script>
