<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/RoleList' }">{{$t('message.vueRole.crumbAuthorityManager')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="ruleForm.id==0">{{$t('message.add')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-else>{{$t('message.edit')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="">
        <el-form-item :label="$t('message.vueRole.labRoleName')" prop="roleName">
            <el-input v-model="ruleForm.roleName" style="max-width:217px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('message.vueRole.labUsers')" prop="users">
            <el-select v-model="ruleForm.users"
                       multiple
                       filterable
                       remote
                       reserve-keyword
                       :placeholder="$t('message.pleaseSelect')"
                       :remote-method="(query)=>{getUsers(query)}"
                       :loading="ruleForm.isloading">
                <el-option v-for="o in allUsers"
                           :key="o.value"
                           :label="o.label"
                           :value="o.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('message.vueRole.labCompanys')" prop="companys">
            <el-select v-model="ruleForm.companys"
                       multiple
                       filterable
                       remote
                       reserve-keyword
                       :placeholder="$t('message.pleaseSelect')"
                       :remote-method="(query)=>{getCompanys(query)}"
                       :loading="ruleForm.isloading">
                <el-option v-for="o in allCompanys"
                           :key="o.value"
                           :label="o.label"
                           :value="o.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('message.vueRole.labDepartments')" prop="departments">
            <el-select v-model="ruleForm.departments"
                       multiple
                       filterable
                       remote
                       reserve-keyword
                       :placeholder="$t('message.pleaseSelect')"
                       :remote-method="(query)=>{getDepartments(query)}"
                       :loading="ruleForm.isloading">
                <el-option v-for="o in allDepartments"
                           :key="o.value"
                           :label="o.label"
                           :value="o.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-tabs type="border-card">
            <el-tab-pane :label="$t('message.vueRole.labMenu')">
                <el-form-item label="" prop="menuIds" label-width="0">
                    <el-checkbox v-model="isAllChecked" @change="checkAll()">{{$t('message.checkAll')}}</el-checkbox>
                    <el-tree :data="menuTree"
                             show-checkbox
                             node-key="id"
                             ref="tree"
                             default-expand-all
                             :expand-on-click-node="false"
                             :default-checked-keys="ruleForm.menuIds"
                             :props="defaultProps"
                             :check-strictly="true"
                             @check-change="checkChange"
                             class="role-tree">
                        <template #default="{ node, data }">
                            <span class="menu-tree-node">
                                <span class="node-label">{{ node.label }}</span>
                                <el-tag type="success" effect="plain" size="mini" v-if="data.type== 1">{{$t('message.menu')}}</el-tag>
                                <el-tag type="" effect="plain" size="mini" v-if="data.type== 2">{{$t('message.button')}}</el-tag>
                            </span>
                            <el-checkbox v-model="data.allData" class="node-check"  @change="checkMenuAllData(data.allData,data.id)" v-if="data.isData==true">{{$t('message.vueRole.labMenuAllData')}}</el-checkbox>
                        </template>
                    </el-tree>
                </el-form-item>
            </el-tab-pane>
        </el-tabs>
        <el-form-item label="" prop="">
        </el-form-item>
        <el-form-item>
            <el-button class="button_submit" size="mini" @click="submitForm('ruleForm')"
                       :disabled="disabledSubmit">{{$t('message.submit')}}</el-button>
            <el-button @click="returnForm()" class="button_return" size="mini">{{$t('message.return')}}</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: '',
        data() {
            return {
                disabledSubmit: false,
                ruleForm: {
                    id: 0,
                    roleName: '',
                    companys: '',
                    departments: '',
                    users: '',
                    menuIds: null,
                    menuAllData: null,
                    userNumber: AppStorage.getNumber()
                },
                isAllChecked: false,
                allMenuIds: null,
                menuTree:null,
                defaultProps: {
                    children: 'submenuList',
                    label: function (data) {
                        var label = '';
                        var language= AppStorage.getLanguage();
                        if (language == 'en') {
                            label += data.menuNameEN;
                        } else{
                            label += data.menuNameZHTW;
                        }
                        return label;
                    }
                },
                allCompanys: [],
                allDepartments: [],
                allUsers: [],
            }
        },
        computed: {
            rules() {
                const rules = {
                    roleName: [
                        { required: true, message: this.$t('message.vueRole.verifyRoleNameRequired'), trigger: 'blur' }
                    ],
                };
                return rules;
            }
        },
        methods: {
            getMenuTree() {
                this.axios
                    .get('/api/menu/getmenutree')
                    .then(response => {
                        var infos=response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.menuTree = infos;
                            this.allMenuIds = this.getAllMenuIds(null, this.menuTree);
                        }
                    });
            },
            getInfo() {
                var infoId = this.$route.query.id;
                if (infoId === '0') {
                    return;
                }
                this.axios
                    .get('/api/role/getrolebyid', {
                        params: {
                            id: infoId
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.ruleForm.id = info.id;
                            this.ruleForm.roleName = info.roleName;
                            this.ruleForm.companys = info.companys == null || info.companys == '' ? '' : info.companys.split(',');
                            this.ruleForm.departments = info.departments == null || info.departments == '' ? '' : info.departments.split(',');
                            this.ruleForm.users = info.users == null || info.users == '' ? '' : info.users.split(',');
                            this.ruleForm.menuIds = info.menuIds == null || info.menuIds == '' ? '' : info.menuIds.split(',');
                            this.ruleForm.menuAllData = info.menuAllData == null || info.menuAllData == '' ? '' : info.menuAllData.split(',');
                            this.allMenuIds = this.getAllMenuIds(null, this.menuTree);
                        }
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.ruleForm.menuIds = this.$refs.tree.getCheckedKeys();
                        this.axios
                            .post('/api/role/addoreditrole', {
                                Id: this.ruleForm.id,
                                RoleName: this.ruleForm.roleName,
                                Companys: this.ruleForm.companys?.toString(),
                                Departments: this.ruleForm.departments?.toString(),
                                Users: this.ruleForm.users?.toString(),
                                MenuIds: this.ruleForm.menuIds?.toString(),
                                MenuAllData: this.ruleForm.menuAllData?.toString(),
                                UserNumber: this.ruleForm.userNumber
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    var msg = response.data.msg.replace('角色不存在', this.$t('message.vueRole.msgRoleNull'))
                                        .replace('角色名称已存在', this.$t('message.vueRole.msgRoleNameExist'))
                                        .replace('添加失败', this.$t('message.errer'));
                                    this.$message.error(msg);
                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    this.$router.push("/RoleList");
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });

                    } else {
                        return false;
                    }
                });
            },
            returnForm() {
                this.$router.go(-1);
            },
            getUsers(query, isAll = true) {
                this.ruleForm.isloading = true;
                this.axios
                    .get('/api/user/getuserlist', {
                        params: {
                            companyId: null,
                            departmentId: null,
                            keyword: query,
                            isAll: isAll
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allUsers = infos.map(function (m) {
                                return {
                                    value: m.number,
                                    label: m.name + "(" + m.number + ")"
                                }
                            });
                        }
                        this.ruleForm.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.ruleForm.isloading = false;
                    });
            },
            getDepartments(query, isAll = true) {
                this.ruleForm.isloading = true;
                this.axios
                    .get('/api/department/getdepartmentlist', {
                        params: {
                            companyId: null,
                            keyword: query,
                            isAll: isAll,
                            userNumber: AppStorage.getNumber(),
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allDepartments = infos.map(function (m) {
                                return {
                                    value: m.departmentNumber,
                                    label: m.departmentName + "(" + m.departmentNumber + ")"
                                }
                            });
                        }
                        this.ruleForm.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.ruleForm.isloading = false;
                    });
            },
            getCompanys(query, isAll = true) {
                this.ruleForm.isloading = true;
                this.axios
                    .get('/api/company/getcompanylist', {
                        params: {
                            //isFactory: 2,
                            isFactory: 0,
                            keyword: query,
                            isAll: isAll,
                            userNumber: AppStorage.getNumber(),
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allCompanys = infos.map(function (m) {
                                return {
                                    value: m.companyNumber,
                                    label: m.companyName + "(" + m.companyNumber + ")"
                                }
                            });
                        }
                        this.ruleForm.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.ruleForm.isloading = false;
                    });
            },
            checkChange() {
                if (this.allMenuIds != null && this.allMenuIds.length == this.$refs.tree.getCheckedKeys().length) {
                    this.isAllChecked = true;
                } else {
                    this.isAllChecked = false;
                }
            },
            checkAll() {
                if (this.isAllChecked) {
                    this.$refs.tree.setCheckedKeys(this.allMenuIds);
                } else {
                    this.$refs.tree.setCheckedKeys([]);
                }
            },
            checkMenuAllData(allData, id) {
                if (this.ruleForm.menuAllData == null || this.ruleForm.menuAllData == '') {
                    this.ruleForm.menuAllData = [];
                }
                if (allData == true) {
                    this.ruleForm.menuAllData.push(id);
                } else {
                    this.ruleForm.menuAllData.splice(this.ruleForm.menuAllData .indexOf(id), 1);
                }
                this.ruleForm.menuAllData.sort(); 
            },
            getAllMenuIds(menuIds, menus) {
                if (menuIds == null) {
                    menuIds = [];
                }
                var menuAllData = "," + this.ruleForm.menuAllData?.toString() + ",";
                menus.forEach((item) => {
                    item.allData = false;
                    if (menuAllData.indexOf("," + item.id + ",") > -1) {
                        item.allData = true;
                    }
                    menuIds.push(item.id);
                    if (item.submenuList != null && item.submenuList.length > 0) {
                        menuIds = this.getAllMenuIds(menuIds, item.submenuList);
                    }
                });
                return menuIds;
            },
        },
        mounted() {
            this.getUsers('', true);
            this.getDepartments('', true);
            this.getCompanys('', true);
            this.getMenuTree();
            this.getInfo();
            this.checkChange();
        }
    }
</script>
<style>
    .menu-tree-node .node-label {
        margin-right: 20px;
        font-size: 14px;
    }
    .role-tree .node-check {
        margin-left:20px;
    }
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }

    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>

