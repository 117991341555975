<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/TaskOptions' }">{{$t('message.vueTaskOptions.taskOptionspage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="taskOptions">
        <el-form ref="taskOptionsForm" :model="taskOptionsForm" :inline="true" class="taskOptionsForm-inline">
            <el-form-item :label="$t('message.vueTaskOptions.labtaskName')+' :'">
                <el-input size="mini" v-model="taskOptionsForm.taskName"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button size="mini" @click="submitSearch" icon="el-icon-search" class="button_search">
                    {{$t('message.vueCategory.button_search')}}
                </el-button>
            </el-form-item>
            <el-row>
                <el-form-item>
                    <el-button size="mini" type="primary" @click="goAddTaskOptions" icon="el-icon-circle-plus-outline" class="button_add">
                        {{$t('message.vueProduct.button_add')}}
                    </el-button>
                </el-form-item>
            </el-row>
        </el-form>
        <el-table :data="tableData" border size="mini"
                  highlight-current-row
                  :stripe="true"
                  :header-cell-style="{'background':'#81761852','color':'#2F4F4F','font-size': '12px','text-align':'center'}">
            <el-table-column prop="TaskName" :label="$t('message.vueTaskOptions.labtaskName')" width="200">
                <template #default="scope">
                    <span>{{scope.row.taskName}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="GroupName" :label="$t('message.vueTaskOptions.labGroupName')" width="200">
                <template #default="scope">
                    <span>{{scope.row.groupName}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="LastRunTime" :label="$t('message.vueTaskOptions.labLastRunTime')" width="140">
                <template #default="scope">
                    <span>{{scope.row.lastRunTime}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="Interval" :label="$t('message.vueTaskOptions.labInterval')" width="140">
                <template #default="scope">
                    <span>{{scope.row.interval}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="Status" :label="$t('message.vueTaskOptions.labStatus')" width="140">
                <template #default="scope">
                    <span class="status_style" style="background-color: #0acb0a" v-if="scope.row.status == 0">正常</span>
                    <span class="status_style" style="background-color: #ed4014" v-else-if="scope.row.status == 1">暂停</span>
                    <span class="status_style" style="background-color: #fc2f2f" v-else-if="scope.row.status == 2">完成</span>
                    <span class="status_style" style="background-color: #607D8B" v-else-if="scope.row.status == 3">异常</span>
                    <span class="status_style" style="background-color: #607D8B" v-else-if="scope.row.status == 4">阻塞</span>
                    <span class="status_style" style="background-color: #607D8B" v-else-if="scope.row.status == 5">停止</span>
                    <span class="status_style" style="background-color: #ed4014" v-else>不存在</span>
                </template>
            </el-table-column>
            <el-table-column prop="Describe" :label="$t('message.vueTaskOptions.labDescribe')" width="140">
                <template #default="scope">
                    <span>{{scope.row.describe}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="ApiUrl" :label="$t('message.vueTaskOptions.labApiUrl')" width="140">
                <template #default="scope">
                    <span>{{scope.row.apiUrl}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="RequestType" :label="$t('message.vueTaskOptions.labRequestType')" width="140">
                <template #default="scope">
                    <span>{{scope.row.requestType}}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$t('message.operate')" min-width="300">
                <template #default="scope">
                    <el-button size="mini"
                               icon="el-icon-tickets"
                               class="button_detail"
                               @click="goShowTaskLog(scope.row)">{{$t('message.vueTaskOptions.button_log')}}</el-button>
                    <el-button size="mini"
                               icon="el-icon-edit"
                               class="button_edit"
                               @click="goEditTaskOptions(scope.row)">{{$t('message.vueTaskOptions.button_update')}}</el-button>
                    <el-button size="mini"
                               icon="el-icon-video-pause"
                               class="button_pause"
                               @click="tiggerAction(scope.row, 'Pause')">{{$t('message.vueTaskOptions.button_Pause')}}</el-button>
                    <el-button size="mini"
                               icon="el-icon-video-play"
                               class="button_start"
                               @click="tiggerAction(scope.row, 'Start')">{{$t('message.vueTaskOptions.button_Start')}}</el-button>
                    <el-button size="mini"
                               icon="el-icon-cpu"
                               class="button_cpu"
                               @click="tiggerAction(scope.row, 'Run')">{{$t('message.vueTaskOptions.button_ExecuteNow')}}</el-button>
                    <el-button size="mini"
                               icon="el-icon-delete"
                               class="button_delete"
                               :disabled="scope.row.isDisabledDelete"
                               @click="tiggerAction(scope.row, 'Delete')">{{$t('message.delete')}}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="pageIndex"
                       :page-sizes="[10, 25, 50, 75, 100]"
                       :page-size="pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="count">
        </el-pagination>
    </div>
</template>

<style>
    .el-table .success-row {
        background: #DFEAF5;
    }
    .category-label {
        margin: 5px;
        font-size: 90%;
    }

    .category-input {
        margin: 5px;
    }
    .button_add {
        font-weight: bolder;
    }
    .button_search {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }
    .button_edit {
        color: #fff;
        background-color: orange;
        font-weight: bolder;
    }

    .button_pause {
        color: #fff;
        background-color: magenta;
        font-weight: bolder;
    }

    .button_start {
        color: #fff;
        background-color: blue;
        font-weight: bolder;
    }

    .button_cpu {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }

    .button_log {
        color: #fff;        
        font-weight: bolder;
    }

    .button_delete {
        color: #fff;
        background-color: red;
        font-weight: bolder;
    }

    .status_style {
        color: white;
        padding: 3px 10px;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'TaskOptions',
        data() {
            return {
                tableData: null,
                language: AppStorage.getLanguage(),
                taskOptionsForm: {
                    taskName: ''
                },
                pageIndex: 1,
                pageSize: 50,
                count: 0
            }
        },
        methods: {
            goAddTaskOptions() {
                this.$router.push({ path: "/AddTaskOptions" });
            },
            goEditTaskOptions(row) {
                this.$router.push({
                    path: "/EditTaskOptions",
                    query: { Id: row.id }
                });
            },
            goShowTaskLog(row) {
                this.$router.push({
                    path: "/ShowTaskLog",
                    query: {Id: row.id}
                })
            },
            loadPage() {
                this.axios
                    .get('/api/TaskOptions/getTaskOptionspage', {
                        params: {
                            Keyword: this.taskOptionsForm.taskName,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        },
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.tableData = infos.items;
                            this.count = infos.count;
                        }
                    })
            },
            tiggerAction(row, action) {
                console.log(row.id);
                this.axios
                    .post('/api/TaskOptions/' + action, {
                        Id: row.id,
                        UserNumber: AppStorage.getNumber(),
                    })
                    .then(response => {
                        if (response.data.code != 1) {
                            this.$message.error(this.$t('message.errer'));
                        } else {
                            this.$message.success(this.$t('message.success'));
                            this.$router.push({ path: "/TaskOptions" });
                            this.loadPage();
                        }
                        this.disabledSubmit = false;
                    }).catch(error => {
                        console.log(error);
                        this.$message.error(this.$t('message.messageBox.connecterror'));
                        this.disabledSubmit = false;
                    })
            },
            del(index, row) {
                this.$confirm(this.$t('message.messageBox.msgDelete'), this.$t('message.messageBox.titleTips'), {
                    confirmButtonText: this.$t('message.messageBox.btnConfirm'),
                    cancelButtonText: this.$t('message.messageBox.btnCancel'),
                    type: 'warning'
                }).then(() => {
                    row.isDisabledDelete = true;
                    this.axios
                        .post('/api/TaskOptions/delTaskOptions', {
                            Id: row.id,
                            UserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isDisabledDelete = false;
                            if (response.data.code != 1) {
                                this.$message.error(this.$t('message.error'));
                            } else {
                                this.$message.success(this.$t('message.successfullyDeleted'));
                                this.tableData.splice(index, 1);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isDisabledDelete = false;
                            this.$message.error(this.$t('message.messageBox.connecterror'));
                        });
                }).catch(() => {
                });
            },

            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            },
        },
        mounted() {
            this.loadPage();
        },
        watch: {
            $route() {
                //响应路由参数的变化
                this.submitSearch();
            }
        }
    };
</script>
