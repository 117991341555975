<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ProductMod' }">{{$t('message.vueProductMod.productmodpage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="productmodel">
        <el-form ref="productmodForm" :model="productmodForm" :inline="true" class="productmodForm-inline">
            <el-form-item :label="$t('message.vueCompany.labIsDisabled')+' :'">
                <el-select size="mini" v-model="productmodForm.isDisabled" :placeholder="$t('message.pleaseSelect')" style="width:100px">
                    <el-option :label="$t('message.pleaseSelect')" value=""></el-option>
                    <el-option :label="$t('message.vueCompany.selIsDisabledYes')" value="true"></el-option>
                    <el-option :label="$t('message.vueCompany.selIsDisabledNo')" value="false"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('message.vueProductMod.ProcuctModel')+' :'">
                <el-input size="mini" v-model="productmodForm.productmodelcondition"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button size="mini" class="btn_search" @click="submitSearch" icon="el-icon-search">{{$t('message.vueCategory.button_search')}}</el-button>
            </el-form-item>
            <el-row>
                <el-form-item>
                    <el-button size="mini" type="primary"
                               @click="goAddProductmod" icon="el-icon-circle-plus-outline" class="btn_add">{{$t('message.vueProduct.button_add')}}</el-button>
                </el-form-item>
            </el-row>
        </el-form>
        <el-table :data="tableData" border size="mini"
                  :stripe="true"
                  :header-cell-style="{'background':'#81761852','color':'#2F4F4F','font-size': '12px','text-align':'center'}">
            <el-table-column prop="ModelName" :label="$t('message.vueProductMod.ModelName')" width="170">
                <template #default="scope">
                    <span>{{scope.row.modelName}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="ModelType" :label="$t('message.vueProductMod.ModelType')" width="140">
                <template #default="scope">
                    <span>{{scope.row.modelType}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="ProcuctModel" :label="$t('message.vueProductMod.ProcuctModel')" width="340">
                <template #default="scope">
                    <span>{{scope.row.productMod}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="ModelLevel" :label="$t('message.vueProductMod.ModelLevel')" width="80">
                <template #default="scope">
                    <span>{{scope.row.modelLevel}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="DataType" :label="$t('message.vueProductMod.DataType')" width="80">
                <template #default="scope">
                    <span>{{scope.row.dataType}}</span>
                </template>
            </el-table-column>

            <el-table-column prop="Remarks" :label="$t('message.vueProductMod.remarks')" width="150">
                <template #default="scope">
                    <span>{{scope.row.remarks}}</span>
                </template>
            </el-table-column>

            <el-table-column prop="ProductModNa" :label="$t('message.vueProductMod.ProductModelNumber')" width="120">
                <template #default="scope">
                    <span>{{scope.row.productModNa}}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$t('message.operate')" min-width="200">
                <template #default="scope">
                    <el-button size="mini"
                               icon="el-icon-edit"
                               class="button_edit"
                               @click="goEdit(scope.row)">{{$t('message.vueCategory.button_update')}}</el-button>
                </template>
            </el-table-column>

        </el-table>
        <el-divider></el-divider>
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="pageIndex"
                       :page-sizes="[10, 25, 50, 75, 100]"
                       :page-size="pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="count">
        </el-pagination>
    </div>
</template>

<style>
    .productcolForm-inline {
        margin: 5px;
        font-size: 90%;
    }

    .label-productmodel {
        margin: 5px;
        font-size: 90%;
    }

    .input-productmodel {
        margin: 5px;
    }
    .btn_search {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }

    .btn_add {
        font-weight: bolder;
    }

    .button_edit {
        color: #fff;
        background-color: orange;
        font-weight: bolder;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'ProductMod',
        data() {
            return {
                tableData: null,
                language: AppStorage.getLanguage(),
                productmodForm: {
                    isDisabled: "false",
                    productmodelcondition: ''
                },
                pageIndex: 1,
                pageSize: 50,
                count: 0
            }
        },
        methods: {
            goAddProductmod() {
                this.$router.push({ path: "/AddProductMod" });
            },
            goEdit(row) {
                this.$router.push({
                    path: "/EditProductMod",
                    query: { Id: row.id }
                });
            },
            loadPage() {
                this.axios
                    .get('/api/ProductMod/getproductmodpage', {
                        params: {
                            isDisabled: this.productmodForm.isDisabled,
                            ProductMod: this.productmodForm.productmodelcondition,
                            OgawaModel: "YES",
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.tableData = infos.items;
                            this.count = infos.count;
                        } else {
                            this.tableData = null;
                            this.count = 0;
                        }
                    });
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            },
            changeIsDisabled(row) {
                var isDisabled = false;
                if (row.isDisabled == false) {
                    isDisabled = true;
                }
                row.isLoading = true;
                return new Promise(resolve => {
                    this.axios
                        .post('/api/ProductMod/disabledproductmod', {
                            Id: row.id,
                            ProductMod: row.productMod,
                            IsDisabled: isDisabled,
                            UserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isLoading = false;
                            if (response.data.code != 1) {
                                this.$message.error(this.$t('message.error'));
                                return resolve(false);
                            } else {
                                this.$message.success(this.$t('message.success'));
                                this.loadPage();
                                return resolve(true);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isLoading = false;
                            this.$message.error(this.$t('message.messageBox.connecterror'));
                            return resolve(false);
                        });
                })
            }
            
        },
        mounted() {
            this.loadPage();
        }
    };
</script>
