<template>
    <div class="SDP-tag" @click="clickTag">
        <span class="el-input__prefix"><i class="el-input__icon el-icon-search"></i></span>
        <div class="tag-box">
            <el-tag v-for="tag in tags"
                    :key="tag.name"
                    closable
                    :type="tag.type"
                    @close="handleClose(tag)">
                {{tag.name}}
            </el-tag>
        </div>
        <input class="el-input__inner tag-inp" placeholder="请选择" disabled="disabled" v-if="tags==null||tags.length==0" />
    </div>
</template>

<script>
    export default {
        name: 'InputTag',
        props: {
            tags: {
                type: Array,
                default: function () { return null; }
            },
        },
        methods: {
            clickTag() {
                this.$emit("clickTag");
            },
            handleClose(tag) {
                this.$emit("close", tag);
            },
        },
        mounted() {
        },
    }
</script>
<style>
   .SDP-tag{
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        padding: 0 15px;
        position:relative;
    }
    .SDP-tag .el-tag{
      margin-right:10px;
    }
    .SDP-tag .tag-box {
        padding-left: 20px;
    }
    .SDP-tag .tag-inp {
        -webkit-appearance: none;
        background-color: transparent;
        background-image: none;
        border: 0;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: none;
        padding: 0 0 0 20px;
        transition: border-color .2s cubic-bezier(.645,.045,.355,1);
        width: 100%;
    }
</style>