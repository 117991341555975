<template>
    <div class="workflow-info" v-loading="isloading">
        <div class="workflow-check" v-if="ischeck&&(checkButtons.btn_1||checkButtons.btn_2||checkButtons.btn2||checkButtons.btn3||checkButtons.btn3_1||checkButtons.btn4||checkButtons.btn5)">
            <el-form :model="checkForm" :rules="ruleChecks" ref="checkForm" label-width="80px" class="checkForm">
                <el-form-item :label="$t('message.vueWorkFlow.labApprovalContent')" prop="content" v-if="checkButtons.btn2||checkButtons.btn3||checkButtons.btn3_1||checkButtons.btn4||checkButtons.btn5">
                    <el-input type="textarea" v-model="checkForm.content" :autosize="{ minRows: 3, maxRows: 5}" maxlength="1000" show-word-limit></el-input>
                </el-form-item>
                <el-form-item :label="$t('message.vueWorkFlow.labPerson')" prop="users" v-if="checkButtons.btn2||checkButtons.btn3||checkButtons.btn3_1||checkButtons.btn4||checkButtons.btn5">
                    <InputTag v-model="checkForm.users" @clickTag="getUserSelect" @close="tagClose" :tags="tags"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitRecall('checkForm',-1)" :disabled="disabledSubmit" v-if="checkButtons.btn_1">{{$t('message.vueWorkFlow.btnCheck_1')}}</el-button>
                    <el-button type="primary" @click="submitWithdraw('checkForm',-2)" :disabled="disabledSubmit" v-if="checkButtons.btn_2">{{$t('message.vueWorkFlow.btnCheck_2')}}</el-button>
                    <el-button type="primary" @click="submitForm('checkForm',2)" :disabled="disabledSubmit" v-if="checkButtons.btn2">{{$t('message.vueWorkFlow.btnCheck2')}}</el-button>
                    <el-button type="primary" @click="submitForm('checkForm',4)" :disabled="disabledSubmit" v-if="checkButtons.btn4">{{$t('message.vueWorkFlow.btnCheck4')}}</el-button>
                    <el-button type="primary" @click="submitForm('checkForm',5)" :disabled="disabledSubmit" v-if="checkButtons.btn5">{{$t('message.vueWorkFlow.btnCheck5')}}</el-button>
                    <el-button type="danger" @click="submitReturn('checkForm',3)" :disabled="disabledSubmit" v-if="checkButtons.btn3">{{$t('message.vueWorkFlow.btnCheck3')}}</el-button>
                    <el-button type="danger" @click="submitReturnToApplicant('checkForm',3,1)" :disabled="disabledSubmit" v-if="checkButtons.btn3_1">{{$t('message.vueWorkFlow.btnCheck3_1')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="workflow-tree">
            <div class="tree-row" v-if="workFlowList!=null&&workFlowList.length>1">
                <el-timeline>
                    <el-timeline-item v-for="(workFlow, index) in workFlowList"
                                      :key="index"
                                      :color="workFlow.elColor"
                                      :timestamp="workFlow.checkDateString" placement="top">
                        <el-card>
                            <h4 class="tree-h4">{{workFlow.userName}}</h4>
                            <p class="tree-p p-warning">{{workFlow.elInfo}}</p>
                            <p class="tree-p">{{workFlow.elContent}}</p>
                        </el-card>
                    </el-timeline-item>
                </el-timeline>
            </div>
            <div class="tree-row" v-else>
                <el-result icon="error" :title="$t('message.vueWorkFlow.titleWorkflowError')">
                    <template #extra>
                    </template>
                </el-result>
            </div>
        </div>
    </div>
    <userSelect ref="userSelect" v-on:getUserEven="getUser"></userSelect>
</template>

<script>
    import AppStorage from '@/common/storage/app-storage'
    import InputTag from "../Input/InputTag.vue";
    import UserSelect from '../../components/User/UserSelect'
    export default {
        name: 'WorkFlowInfo',
        components: { InputTag, UserSelect },
        props: {
            loadTimes: {
                type: Number,
                default: 0
            },
            formType: {
                type: Number,
                default:0
            },
            formId: {
                type: Number,
                default:0
            },
            orderNumber: {
                type: String,
                default: ''
            },
            saporderNumber: {
                type: String,
                default: ''
            },
            goUrl: {
                type: String,
                default: ''
            },
            ischeck: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                tags: [],
                userNumber: AppStorage.getNumber(),
                disabledSubmit: false,
                checkForm: {
                    content: '',
                    users: ''
                },
                checkButtons: {
                    btn_1: false,
                    btn_2: false,
                    btn2: false,
                    btn3: false,
                    btn3_1: false,
                    btn4: false,
                    btn5: false
                },
                isloading: false,
                workFlowInfo: null,
                workFlowList: null,
            };
        },
        methods: {
            getInfo() {
                this.isloading = true;
                this.axios
                    .get('/api/workflow/getworkflowinfo', {
                        params: {
                            userNumber: this.userNumber,
                            formType: this.formType,
                            formId: this.formId
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code != 1 || info == null || info.workFlowList == null) {
                            this.isloading = false;
                            return;
                        }
                        this.workFlowInfo = info;
                        for (let i = 0; i < info.workFlowList.length; i++) {
                            var wf = info.workFlowList[i];
                            wf.elColor = '';
                            wf.elContent = '';
                            wf.elInfo = '';
                            if (wf.sign > 0) {
                                wf.elColor = '#409EFF';
                            }
                            if (wf.operates == 3 && info.lastCheckGrade == wf.orderNo && info.signment == 0) {
                                wf.elColor = '#F56C6C';
                            }
                            wf.elSign = '待审批'.replace('待审批', this.$t('message.vueWorkFlow.sign0'));
                            if (wf.sign == 1) {
                                wf.elSign = '审批中'.replace('审批中', this.$t('message.vueWorkFlow.sign1'));
                            }
                            if (wf.sign == 2) {
                                wf.elSign = '已审批'.replace('已审批', this.$t('message.vueWorkFlow.sign2'));
                            }
                            if ((wf.sign == 0 || wf.sign == 1) && wf.operates == 5) {
                                wf.elSign = '未抄送'.replace('未抄送', this.$t('message.vueWorkFlow.signCC0_1'));
                            }
                            if (wf.sign == 2 && wf.operates == 5) {
                                wf.elSign = '已抄送'.replace('已抄送', this.$t('message.vueWorkFlow.signCC2'));
                            }
                            if (wf.operates == 3) {
                                wf.elSign = '已退回'.replace('已退回', this.$t('message.vueWorkFlow.operates3'));
                            }
                            if (wf.operates == 4) {
                                wf.elSign = '抛转'.replace('抛转', this.$t('message.vueWorkFlow.operates4'));
                            }
                            if (wf.orderNo == 1 && wf.operates == 1) {
                                wf.elSign = '提交单据'.replace('提交单据', this.$t('message.vueWorkFlow.operates1'));
                            }
                            if (wf.orderNo > 1 && wf.operates == 1) {
                                wf.elSign = '重新提交单据'.replace('重新提交单据', this.$t('message.vueWorkFlow.operates1_1'));
                            }
                            if (wf.sign == 2 && wf.orderNo > 1 && wf.operates == 2) {
                                wf.elContent = wf.checkContent;
                            }
                            if (wf.sign == 2 && wf.orderNo > 1 && wf.operates == 4 && wf.transmitInfo != '' && wf.transmitInfo != null) {
                                wf.elInfo = wf.transmitInfo.replace('抛转：', this.$t('message.vueWorkFlow.infoTransmit'));
                                wf.elContent = wf.checkContent;
                            }
                            if (wf.sign == 2 && wf.orderNo > 1 && wf.operates == 2 && wf.ccInfo != '' && wf.ccInfo != null) {
                                wf.elInfo = wf.ccInfo.replace('抄送：', this.$t('message.vueWorkFlow.infoCC'));
                                wf.elContent = wf.checkContent;
                            }
                            if (wf.sign == 2 && wf.orderNo > 1 && wf.operates == 3) {
                                wf.elInfo = wf.returnInfo.replace('退回：', this.$t('message.vueWorkFlow.infoReturn'));
                                wf.elContent = wf.checkContent;
                            }
                            wf.checkDateString = "[" + wf.elSign + "] " + wf.checkDateString;
                            info.workFlowList[i] = wf;
                            //审批按钮
                            if (info.workFlowOperates != null) {
                                for (let i = 0; i < info.workFlowOperates.length; i++) {
                                    switch (info.workFlowOperates[i]) {
                                        case "2":
                                            this.checkButtons.btn2 = true;
                                            break;
                                        case "3":
                                            this.checkButtons.btn3 = true;
                                            break;
                                        case "3_1":
                                            this.checkButtons.btn3_1 = true;
                                            break;
                                        case "4":
                                            this.checkButtons.btn4 = true;
                                            break;
                                        case "5":
                                            this.checkButtons.btn5 = true;
                                            break;
                                        case "-1":
                                            this.checkButtons.btn_1 = true;
                                            break;
                                        case "-2":
                                            this.checkButtons.btn_2 = true;
                                            break;
                                    }
                                }
                            }
                        }
                        this.workFlowList = info.workFlowList;
                        this.$emit("getWorkFlowInfo", this.workFlowInfo);
                        this.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.isloading = false;
                    });
            },
            submitForm(formName, operate, returnOrderNo = 0) {
                /*if ((operate == 2 || operate == 3 || operate == 4 || operate == 5) && this.checkForm.content=='') {
                    alert(this.$t('message.vueWorkFlow.msgEnterApprovalContent'));
                    return;
                }*/
                if ((operate == 4 || operate == 5) && this.checkForm.users == '') {
                    alert(this.$t('message.vueWorkFlow.msgSelectPersonnel'));
                    return;
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/workflow/checkworkflow', {
                                UserNumber: this.userNumber,
                                FormType: this.formType,
                                FormId: this.formId,
                                CheckContent: this.checkForm.content,
                                Operates: operate,
                                UserNumbers: this.checkForm.users,
                                ReturnOrderNo: returnOrderNo,
                                OrderNumber: this.orderNumber,
                                SAPOrderNumber: this.saporderNumber,
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    var msg = response.data.msg.replace('审批流程不存在', this.$t('message.vueWorkFlow.msgResponse1'))
                                        .replace('无法操作当前流程', this.$t('message.vueWorkFlow.msgResponse2'))
                                        .replace('请选择转发人员', this.$t('message.vueWorkFlow.msgResponse3'))
                                        .replace('请选择抄送人员', this.$t('message.vueWorkFlow.msgResponse4'))
                                        .replace('人员不存在或离职', this.$t('message.vueWorkFlow.msgResponse5'))
                                        .replace('无法撤回，单据已被他人操作', this.$t('message.vueWorkFlow.msgResponse6'))
                                        .replace('无法撤回，单据未提交', this.$t('message.vueWorkFlow.msgResponse7'))
                                        .replace('无法回退，当前是第一个审批人', this.$t('message.vueWorkFlow.msgResponse8'));
                                    alert(msg);
                                } else {
                                    if (this.goUrl == "") {
                                        location.reload();
                                    }
                                    else {
                                        this.$router.push(this.goUrl);
                                    }
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                alert(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });

                    } else {
                        return false;
                    }
                });
            },
            submitRecall(formName, operate, returnOrderNo = 0) {
                this.disabledSubmit = true;
                this.$confirm(this.$t('message.vueWorkFlow.msgConfirmRecall'), this.$t('message.messageBox.titleTips'), {
                    confirmButtonText: this.$t('message.messageBox.btnConfirm'),
                    cancelButtonText: this.$t('message.messageBox.btnCancel'),
                    type: 'warning'
                }).then(() => {
                    this.disabledSubmit = false;
                    this.submitForm(formName, operate, returnOrderNo);
                }).catch(() => {
                    this.disabledSubmit = false;
                });
            },
            submitWithdraw(formName, operate, returnOrderNo = 0) {
                this.disabledSubmit = true;
                this.$confirm(this.$t('message.vueWorkFlow.msgConfirmWithdraw'), this.$t('message.messageBox.titleTips'), {
                    confirmButtonText: this.$t('message.messageBox.btnConfirm'),
                    cancelButtonText: this.$t('message.messageBox.btnCancel'),
                    type: 'warning'
                }).then(() => {
                    this.disabledSubmit = false;
                    this.submitForm(formName, operate, returnOrderNo);
                }).catch(() => {
                    this.disabledSubmit = false;
                });
            },
            submitReturn(formName, operate, returnOrderNo = 0) {
                this.disabledSubmit = true;
                this.$confirm(this.$t('message.vueWorkFlow.msgConfirmReturn'), this.$t('message.messageBox.titleTips'), {
                    confirmButtonText: this.$t('message.messageBox.btnConfirm'),
                    cancelButtonText: this.$t('message.messageBox.btnCancel'),
                    type: 'warning'
                }).then(() => {
                    this.disabledSubmit = false;
                    this.submitForm(formName, operate, returnOrderNo);
                }).catch(() => {
                    this.disabledSubmit = false;
                });
            },
            submitReturnToApplicant(formName, operate, returnOrderNo = 0) {
                this.disabledSubmit = true;
                this.$confirm(this.$t('message.vueWorkFlow.msgConfirmReturnToApplicant'), this.$t('message.messageBox.titleTips'), {
                    confirmButtonText: this.$t('message.messageBox.btnConfirm'),
                    cancelButtonText: this.$t('message.messageBox.btnCancel'),
                    type: 'warning'
                }).then(() => {
                    this.disabledSubmit = false;
                    this.submitForm(formName, operate, returnOrderNo);
                }).catch(() => {
                    this.disabledSubmit = false;
                });
            },
            getUserSelect() {
                this.$refs.userSelect.init();
            },
            getUser(users) {
                var strUsers = '';
                users.forEach(u => {
                    strUsers += u.number + ",";
                    if (this.tags == null || this.tags.length == 0) {
                        this.tags = [];
                        this.tags.push({ name: u.name, number: u.number, type: '' });
                    } else {
                        var tagIndex = this.tags.findIndex((t) => {
                            return t.number === u.number;
                        });
                        if (tagIndex == -1) {
                            this.tags.push({ name: u.name, number: u.number, type: '' });
                        }
                    }
                });
                if (strUsers != '') {
                    this.checkForm.users = strUsers.substring(0, strUsers.length - 1);
                }
            },
            tagClose(tag) {
                var strUsers = (this.checkForm.users + ",").replace(tag.number + ',', '');
                this.checkForm.users = strUsers.substring(0, strUsers.length - 1);
                this.tags.splice(this.tags.indexOf(tag), 1);
            },
        },
        mounted() {
        },
        watch: {
            loadTimes() {
                this.getInfo();
            }
        }
    }
</script>
<style>
    .workflow-check {
        border: 2px solid #eee;
        border-bottom:0;
        padding: 30px 20px;
    }
    .workflow-check .check-tag{
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        padding: 5px 15px;
    }
    .workflow-tree {
        border: 2px solid #eee;
        padding: 30px 20px;
    }
   .workflow-tree .tree-row {
        width:100%;
   }
   .workflow-tree .el-card__body {
       padding:10px 20px;
   }
   .workflow-tree .tree-h4 {
       margin:0;
   }
   .workflow-tree .tree-p {
       margin-top:10px;
       margin-bottom:0;
   }
   .workflow-tree .p-warning {
      color:#E6A23C
   }
</style>