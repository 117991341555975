<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/Product' }">{{$t('message.vueProduct.productpage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/EditProduct' }">{{$t('message.vueProduct.editproductpage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="editproductForm" :model="editproductForm" label-width="200px" class="demo-editproductForm">
        <el-row>
            <el-col :span="8">
                <el-form-item :label="$t('message.vueProduct.product_type')+' :'">
                    <el-select v-model="editproductForm.input_producttype" size="mini" :disabled="true" :placeholder="$t('message.pleaseSelect')">
                        <el-option v-for="item in typelist"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="7">
                <el-form-item :label="$t('message.vueProduct.product_code')+' :'">
                    <el-input size="mini" v-model="editproductForm.input_productcode" :disabled="true" style="width:130px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <!--<el-row>
        <el-col :span="24">
            <el-form-item :label="$t('message.vueProduct.product_code')+' :'">
                <el-input size="mini" v-model="editproductForm.input_productcode" :disabled="true" style="width:200px"></el-input>
            </el-form-item>
        </el-col>
    </el-row>-->

        <el-row>
            <el-col :span="8">
                <el-form-item :label="$t('message.vueProduct.item_code')+' :'">
                    <el-input size="mini" v-model="editproductForm.item_code" :disabled="true" style="width:130px"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="7">
                <el-form-item :label="$t('message.vueProduct.factory_code')+' :'">
                    <el-input size="mini" v-model="editproductForm.input_factorycode" style="width:130px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <!--<el-row>
        <el-col :span="24">
            <el-form-item :label="$t('message.vueProduct.factory_code')+' :'">
                <el-input size="mini" v-model="editproductForm.input_factorycode" style="width:200px"></el-input>
            </el-form-item>
        </el-col>
    </el-row>-->
        <el-row>
            <el-col :span="8">
                <el-form-item :label="$t('message.vueProduct.product_category')+' :'">
                    <el-select v-model="editproductForm.input_category" size="mini"
                               style="width:100%"
                               :disabled="true" value-key="categoryNumber" :placeholder="$t('message.pleaseSelect')" filterable>
                        <el-option v-for="item in categorylist"
                                   :key="item.categoryNumber"
                                   :label="item.category"
                                   :value="item" />
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item :label="$t('message.vueProduct.model_no')+' :'">
                    <el-select v-model="editproductForm.input_modelno" size="mini"
                               style="width:100%"
                               :disabled="true" value-key="productModNa" :placeholder="$t('message.pleaseSelect')" filterable>
                        <el-option v-for="item in modellist"
                                   :key="item.productModNa"
                                   :label="item.productMod"
                                   :value="item" />
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6">
                <el-form-item :label="$t('message.vueProduct.product_colour')+' :'">
                    <el-select v-model="editproductForm.input_productcolour" size="mini"
                               style="width:100%"
                               :disabled="true" value-key="productColNa" :placeholder="$t('message.pleaseSelect')">
                        <el-option v-for="item in colourlist"
                                   :key="item.productColNa"
                                   :label="item.productCol"
                                   :value="item" />
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProduct.product_name')+' :'">
                    <el-input size="mini" v-model="editproductForm.input_productname" style="width:500px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6">
                <el-form-item :label="$t('message.vueProduct.product_currency')+' :'">
                    <el-select v-model="editproductForm.input_currency" size="mini" value-key="currencyNumber" :placeholder="$t('message.pleaseSelect')">
                        <el-option v-for="item in currencylist"
                                   :key="item.currencyNumber"
                                   :label="item.currency"
                                   :value="item" />
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="5">
                <el-form-item :label="$t('message.vueProduct.price')+' :'">
                    <el-input-number size="mini" v-model="editproductForm.input_price" />
                </el-form-item>
            </el-col>
            <el-col :span="5">
                <el-form-item :label="$t('message.vueProduct.unit_price')+' :'">
                    <el-input-number v-if="(editproductForm.userNumber === 'OIH0004')" size="mini"
                                     v-model="editproductForm.input_unitprice" />
                    <el-input-number v-if="(editproductForm.userNumber !== 'OIH0004')" size="mini"
                                     :disabled="true" v-model="editproductForm.input_unitprice" />
                </el-form-item>
            </el-col>
        </el-row>        
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProduct.uom')+' :'">
                    <el-input size="mini" v-model="editproductForm.input_uom" style="width:100px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueWorkFlow.labCompanys')+' :'" prop="companys">
                    <el-select v-model="editproductForm.companys"
                               size="mini"
                               multiple
                               filterable
                               remote
                               reserve-keyword
                               :placeholder="$t('message.pleaseSelect')"
                               :remote-method="(query)=>{getCompanys(query)}"
                               :loading="editproductForm.isloading">
                        <el-option v-for="o in allCompanys"
                                   :key="o.value"
                                   :label="o.label"
                                   :value="o.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item>
                    <el-button size="mini" class="button_submit"
                               @click="submitForm('editproductForm')" :disabled="disabledSubmit">{{$t('message.vueCategory.button_sava')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>
<style>
    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    var categoryname = ''
    var getcolourName = ''
    var getmodelName = ''
    var getcurrencyName = ''
    export default {
        name: 'EditProduct',
        data() {
            return {
                language: AppStorage.getLanguage(),
                categorylist: [],
                modellist: [],
                colourlist: [],
                currencylist: [],
                typelist: [{
                    value: '1',
                    label: 'Finished Goods New'
                }, {
                    value: '2',
                    label: 'Accessories'
                }, {
                    value: '3',
                    label: 'Spare Parts'
                }, {
                    value: '4',
                    label: 'Gifts'
                }, {
                    value: '5',
                    label: 'Non-Trade Item'
                }, {
                    value: '6',
                    label: 'Finished Goods Demo'
                }, {
                    value: 'P',
                    label: 'Promotion Package'
                }],
                editproductForm: {
                    isloading: false,
                    input_producttype: '',
                    input_productcode: '',
                    item_code: '',
                    input_factorycode: '',
                    input_category: '',
                    input_modelno: '',
                    input_productname: '',
                    input_productcolour: '',
                    input_unitprice: 0,
                    input_price: 0,
                    input_currency: '',
                    input_uom: '',
                    companys: '',
                    userNumber: AppStorage.getNumber()
                },
                seen: true,
            }
        },
        methods: {
            getCategory() {
                this.axios
                    .get('/api/Category/getcategorylist', {
                        params: {
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.categorylist = infos;
                        }
                    })
            },
            getCurrency() {
                this.axios
                    .get('/api/Currency/getcurrencylist', {
                        params: {
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.currencylist = infos;
                        }
                    })
            },
            getModel() {
                this.axios
                    .get('/api/ProductMod/getproductmodlist', {
                        params: {
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.modellist = infos;
                        }
                    })
            },
            getColour() {
                this.axios
                    .get('/api/ProductCol/getproductcollist', {
                        params: {
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.colourlist = infos;
                        }
                    })
            },
            getCurrencyName(currency) {
                this.editproductForm.input_currency = null;
                if (currency != "") {
                    this.axios
                        .get('/api/Currency/getcurrencylist', {
                            params: {
                                keyword: currency,
                            }
                        }).then(response => {
                            if (response.data.code == 1) {
                                var info0 = response.data.data;
                                getcurrencyName = info0[0].currency;
                            }
                        })
                }
            },
            getColourName(colour) {
                this.editproductForm.input_productcolour = null;
                if (colour != "") {
                    this.axios
                        .get('/api/ProductCol/getproductcollist', {
                            params: {
                                keyword: colour,
                            }
                        }).then(response => {
                            if (response.data.code == 1) {
                                var info0 = response.data.data;
                                getcolourName = info0[0].productCol;
                            }
                        })
                }
            },

            getModelName(modelNo) {
                this.editproductForm.input_modelno = null;
                this.axios
                    .get('/api/ProductMod/getproductmodlist', {
                        params: {
                            keyword: modelNo,
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            getmodelName = infos[0].productMod;
                        }
                    })
            },

            getCategoryName(categoryNumber) {
                this.editproductForm.input_category = null;
                this.axios
                    .get('/api/Category/getcategorylist', {
                        params: {
                            keyword: categoryNumber,
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            categoryname = infos[0].category;
                        }
                    })
            },
            getCompanys(query, isAll = true) {
                this.editproductForm.isloading = true;
                this.axios
                    .get('/api/company/getcompanylist', {
                        params: {
                            isFactory: 2,
                            userNumber: AppStorage.getNumber(),
                            keyword: query,
                            isAll: isAll
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allCompanys = infos.map(function (m) {
                                return {
                                    value: m.companyNumber,
                                    label: m.companyName + "(" + m.companyNumber + ")"
                                }
                            });
                        }
                        this.editproductForm.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.editproductForm.isloading = false;
                    });
            },
            getInfo() {
                var productCode = this.$route.query.ProductCode;
                if (productCode == null || productCode == "") {
                    return;
                }
                this.axios
                    .get('/api/Product/getproductbycode', {
                        params: {
                            ProductCode: productCode
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.editproductForm.input_producttype = info.productType;
                            this.editproductForm.input_productcode = info.productCode;
                            this.editproductForm.input_factorycode = info.factoryCode;
                            this.editproductForm.item_code = info.itemCode;
                            
                            this.getCategoryName(info.categoryNumber);
                            this.editproductForm.input_category = {
                                category: categoryname,
                                categoryNumber: info.categoryNumber
                            };

                            this.getModelName(info.modelNo);
                            this.editproductForm.input_modelno = {
                                productModNa: info.modelNo,
                                productMod: getmodelName
                            }

                            this.editproductForm.input_productname = info.productName;

                            this.getColourName(info.productColour);
                            this.editproductForm.input_productcolour = {
                                productColNa: info.productColour,
                                productCol: getcolourName
                            }

                            this.editproductForm.input_unitprice = info.unitPrice;
                            this.editproductForm.input_price = info.price;
                            //this.editproductForm.input_currency = info.currency;
                            this.getCurrencyName(info.currency);
                            this.editproductForm.input_currency = {
                                currencyNumber: info.currency,
                                currency: getcurrencyName
                            }
                            //this.editproductForm.companys = info.companys;
                            this.editproductForm.companys = info.companys == null || info.companys == '' ? '' : info.companys.split(',');
                            this.editproductForm.input_uom = info.uom
                        }
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/Product/addproduct', {
                                Producttype: this.editproductForm.input_producttype,
                                ProductCode: this.editproductForm.input_productcode,
                                ItemCode: this.editproductForm.item_code,
                                FactoryCode: this.editproductForm.input_factorycode,
                                Category: this.editproductForm.input_category.categoryNumber,
                                ModelNo: this.editproductForm.input_modelno.productModNa,
                                ProductName: this.editproductForm.input_productname,
                                ProductColour: this.editproductForm.input_productcolour.productColNa,
                                UnitPrice: this.editproductForm.input_unitprice,
                                Price: this.editproductForm.input_price,
                                Currency: this.editproductForm.input_currency.currencyNumber,
                                Uom: this.editproductForm.input_uom,
                                //Companys:this.editproductForm.companys,
                                Companys: this.editproductForm.companys?.toString(),
                                UserNumber: AppStorage.getNumber()
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    this.$message.error(this.$t('message.errer'));
                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    this.$router.push({ path: "/Product" });
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });

                    } else {
                        return false;
                    }
                });
            } 
        },
        //这个组件内的方法，函数
        mounted() {
            this.getCategory();
            this.getModel();
            this.getColour();
            this.getCurrency();
            this.getInfo();
            this.getCompanys('', true);
        }
    };
</script>