<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/CompanyList' }">{{$t('message.vueCompany.crumbCompanyManager')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="companyForm" :model="companyForm" :inline="true" class="demo-form-inline" size="mini">
        <el-form-item :label="$t('message.vueCompany.labIsDisabled')+' :'">
            <el-select v-model="companyForm.isDisabled" :placeholder="$t('message.pleaseSelect')" style="width:100px" size="mini">
                <el-option :label="$t('message.vueCompany.selIsDisabledYes')" value="true"></el-option>
                <el-option :label="$t('message.vueCompany.selIsDisabledNo')" value="false"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('message.vueCompany.labKeyword')+' :'">
            <el-input v-model="companyForm.keyword" :placeholder="$t('message.vueCompany.plaSearch')" size="mini"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button id="btn_search" @click="submitSearch" icon="el-icon-search" size="mini" class="btn_search">
                {{$t('message.search')}}
            </el-button>
        </el-form-item>
        <el-row>
            <el-form-item>
                <el-button type="primary" id="btn_add" @click="add" class="btn_add" icon="el-icon-circle-plus-outline" size="mini">{{$t('message.vueProduct.button_add')}}</el-button>
            </el-form-item>
        </el-row>
    </el-form>
    <el-table :data="tableData" style="width: 100%" border size="mini"
              highlight-current-row
              :stripe="true"
              :header-cell-style="{'background':'#81761852','color':'#2F4F4F','font-size': '12px','text-align':'center'}">
        <el-table-column prop="Id" :label="$t('message.vueCompany.labId')"
                         width="60">
            <template #default="scope">
                <span>{{scope.row.id}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="Company" :label="$t('message.vueCompany.labCompanyName')"
                         width="170">
            <template #default="scope">
                <span>{{scope.row.companyName}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="CompanyNumber" :label="$t('message.vueCompany.labCompanyNumber')"
                         width="120">
            <template #default="scope">
                <span>{{scope.row.companyNumber}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="Paymentterm" :label="$t('message.vueVendor.paymentterm')"
                         width="480">
            <template #default="scope">
                <span>{{scope.row.paymentterm}}</span>
            </template>
        </el-table-column>

        <el-table-column :label="$t('message.operate')"
                         min-width="300">
            <template #default="scope">
                <el-button size="mini"
                           icon="el-icon-tickets"
                           class = "button_detail"
                           @click="query(scope.row)">{{$t('message.details')}}</el-button>
                
            </template>
        </el-table-column>
    </el-table>
    <div class="block">
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="pageIndex"
                       :page-sizes="[10, 25, 50, 75, 100]"
                       :page-size="pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="count">
        </el-pagination>
    </div>
</template>

<style>
    .el-table .success-row {
        background: #DFEAF5;
    }
    .btn_search {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }
    .btn_add {
        font-weight: bolder;
    }
    .button_edit {
        color: #fff;
        background-color: orange;
        font-weight: bolder;
    }
    .button_detail {
        font-weight: bolder;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'Company',
        data() {

            return {
                tableData: null,
                language: AppStorage.getLanguage(),
                companyForm: {
                    keyword: '',
                    isDisabled: "false"
                },
                pageIndex: 1,
                pageSize: 10,
                count: 0,
            }
        },
        methods: {
            loadPage() {
                this.axios
                    .get('/api/company/getcompanypage', {
                        params: {
                            isDisabled: this.companyForm.isDisabled,
                            keyword: this.companyForm.keyword,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        }
                    }) .then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.tableData = infos.items;
                            this.count = infos.count;
                        }
                    })
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            },
            add() {
                this.$router.push({
                    path: "/CompanyAdd",
                    query: { id: 0 }
                });
            },
            edit(row) {
                this.$router.push({
                    path: "/CompanyEdit",
                    query: { id: row.id }
                });
            },
            query(row) {
                this.$router.push({
                    path: "/CompanyDetails",
                    query: { id: row.id }
                });
            },
            changeIsDisabled(row) {
                var isDisabled = false;
                if (row.isDisabled == false) {
                    isDisabled = true;
                }
                row.isLoading = true;
                return new Promise(resolve => {
                    this.axios
                        .post('/api/company/disabledcompany', {
                            Id: row.id,
                            IsDisabled: isDisabled,
                            UserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isLoading = false;
                            if (response.data.code != 1) {
                                if (response.data.msg == '存在部门') {
                                    this.$message.error(this.$t('message.vueCompany.msgExistDepartment'));
                                    return resolve(false);
                                }
                                this.$message.error(this.$t('message.error'));
                                return resolve(false);
                            }  else {
                                this.$message.success(this.$t('message.success'));
                                return resolve(true);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isLoading = false;
                            this.$message.error(this.$t('message.messageBox.connecterror'));
                            return resolve(false);
                        });
                })
            },
        },
        mounted() {
            this.submitSearch();
        },
        watch: {
            $route() {
                //响应路由参数的变化
                this.submitSearch();
            }
        }

    };
</script>