<template>
    <template v-for="item in items" :key="item.id">
        <el-menu-item :index="item.routePath==''?item.id:item.routePath" v-if="item.submenuList && item.submenuList.length == 0">
            <i :class="item.icon"></i>
            <span v-if="language === 'zh-ch'">{{item.menuNameZHCN}}</span>
            <span v-else-if="language === 'en'">{{item.menuNameEN}}</span>
            <span v-else>{{item.menuNameZHTW}}</span>
        </el-menu-item>
        <el-submenu :index="item.routePath==''?item.id:item.routePath" v-else>
            <template #title>
                <i :class="item.icon"></i>
                <span v-if="language === 'zh-ch'">{{item.menuNameZHCN}}</span>
                <span v-else-if="language === 'en'">{{item.menuNameEN}}</span>
                <span v-else>{{item.menuNameZHTW}}</span>
            </template>
            <MenuSideSub :items="item.submenuList" />
        </el-submenu>
    </template>
</template>

<script>
    import AppStorage from '@/common/storage/app-storage'
    import MenuSideSub from "./MenuSideSub.vue"
    export default {
        name: 'MenuSideSub',
        components: { MenuSideSub },
        props: {
            items: {
                type: Array,
                default: function () { return [] }
            }
        },
        data() {
            return {
                language: AppStorage.getLanguage()
            }
        }
    }
</script>
