import axios from 'axios';

export function GetProductList(keyword, companyNumber, isAll) {
    return axios
           .get('/api/Product/getproductlist', {
               params: {
                   keyword: keyword,
                   company: companyNumber,
                   isAll: isAll
               }
           })
}