<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/Cgy' }">{{$t('message.vueCategory.categorypage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/EditCategory' }">{{$t('message.vueCategory.editcategorypage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="editcategoryForm" :model="editcategoryForm" :rules="rules" label-width="200px" class="adddata">
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueCategory.category_id')+' :'">
                    <el-input size="mini" v-model="editcategoryForm.input_id" :disabled="true" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueCategory.category')+' :'" prop="input_category">
                    <el-input size="mini" v-model="editcategoryForm.input_category" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueCategory.category_number')+' :'">
                    <el-input size="mini" v-model="editcategoryForm.input_categorynumber" :disabled="true" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item>
                    <el-button class="editcategory-button" size="mini"
                               @click="submitForm('editcategoryForm')"
                               :disabled="disabledSubmit">
                        {{$t('message.vueCategory.button_sava')}}
                    </el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<style>
    .editcategory-button {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'EditCategory',
        data() {
            return {
                language: AppStorage.getLanguage(),
                editcategoryForm: {
                    input_id: '',
                    input_category: '',
                    input_categorynumber: ''
                }
            }
        },
        computed: {
            rules() {
                const rules = {
                    input_category: [
                        { required: true, message: this.$t('message.vueCategory.verifyCategoryRequired'), trigger: 'blur' }
                    ]
                };
                return rules;
            }
        },
        methods: {
            getInfo() {
                var categoryId = this.$route.query.Id;
                if (categoryId == 0) {
                    return;
                }
                this.axios
                    .get('/api/Category/getcategorybyid', {
                        params: {
                            Id: categoryId
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.editcategoryForm.input_id = info.id;
                            this.editcategoryForm.input_category = info.category;
                            this.editcategoryForm.input_categorynumber = info.categoryNumber;
                        }
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.editcategoryForm.input_category != '' && this.editcategoryForm.input_categorynumber != '') {
                            this.disabledSubmit = true;
                            this.axios
                                .post('/api/Category/addcategory', {
                                    Id: this.editcategoryForm.input_id,
                                    Category: this.editcategoryForm.input_category,
                                    CategoryNumber: this.editcategoryForm.input_categorynumber,
                                    UserNumber: AppStorage.getNumber()
                                })
                                .then(response => {
                                    if (response.data.code != 1) {
                                        if (response.data.msg == '该品类已经存在,请重新录入') {
                                            this.$message.error(this.$t('message.vueCategory.msgExistCategory'));
                                        } else {
                                            this.$message.error(this.$t('message.errer'));
                                        }
                                    } else {
                                        this.$message.success(this.$t('message.success'));
                                        this.$router.push({ path: "/Cgy" });
                                    }
                                    this.disabledSubmit = false;
                                }).catch(error => {
                                    console.log(error);
                                    this.$message.error(this.$t('message.messageBox.connecterror'));
                                    this.disabledSubmit = false;
                                });
                        } else {
                            alert(this.$t('message.vueCategory.addcategoryerror'));
                        }
                    } else {
                        return false;
                    }
                });
            } 
        },
        //这个组件内的方法，函数
        mounted() {
            this.getInfo();
        }
    };
</script>
