<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/WorkFlowTemplateList' }">{{$t('message.vueWorkFlow.crumbTemplateManager')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form :inline="true" :model="formSearch" class="demo-form-inline" size="mini">
        <el-form-item :label="$t('message.vueWorkFlow.labFormType')+' :'">
            <el-select v-model="formSearch.formType" :placeholder="$t('message.pleaseSelect')" size="mini">
                <el-option v-for="o in formTypes"
                           :key="o.value"
                           :label="o.label"
                           :value="o.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('message.vueWorkFlow.labTemplateName')+' :'">
            <el-input v-model="formSearch.templateName" :placeholder="$t('message.pleaseEnter')" size="mini"></el-input>
        </el-form-item>
        <el-form-item :label="$t('message.vueWorkFlow.labIsDisabled')+' :'">
            <el-select v-model="formSearch.isDisabled" :placeholder="$t('message.all')" style="width:100px" size="mini">
                <el-option :label="$t('message.all')" value=""></el-option>
                <el-option :label="$t('message.vueWorkFlow.selIsDisabledYes')" value="true"></el-option>
                <el-option :label="$t('message.vueWorkFlow.selIsDisabledNo')" value="false"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitSearch"
                       icon="el-icon-search" size="mini" class="btn_search">{{$t('message.search')}}</el-button>
        </el-form-item>
        <el-row>
            <el-form-item>
                <el-button type="primary" @click="templateAdd" icon="el-icon-circle-plus-outline"
                           size="mini" class="btn_add">{{$t('message.vueProduct.button_add')}}</el-button>
            </el-form-item>
        </el-row>
    </el-form>
    <el-table :data="tableData"
              border
              style="width: 100%" 
              size="mini">
        <el-table-column prop="formTypeString"
                         :label="$t('message.vueWorkFlow.labFormType')"
                         width="200">
        </el-table-column>
        <el-table-column prop="templateName"
                         :label="$t('message.vueWorkFlow.labTemplateName')"
                         width="200">
        </el-table-column>
        <el-table-column prop="sort"
                         :label="$t('message.vueWorkFlow.labSort')"
                         width="100">
        </el-table-column>
        
        <el-table-column :label="$t('message.operate')"
                         min-width="300">
            <template #default="scope">
                <el-button size="mini"
                           icon="el-icon-edit"
                           class="button_edit"
                           @click="templateEdit(scope.row)">{{$t('message.edit')}}</el-button>
                <el-button size="mini"
                           icon="el-icon-setting"
                           class="button_edit"
                           @click="setFlows(scope.row)">{{$t('message.vueWorkFlow.btnSetFlows')}}</el-button>
                <el-button size="mini"
                           icon="el-icon-delete"
                           type="danger"
                           class="button_delete"
                           :disabled="scope.row.isDisabledDelete"
                           @click="templateDel(scope.$index,scope.row)">{{$t('message.delete')}}</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="pageIndex"
                   :page-sizes="[10, 25, 50, 75, 100]"
                   :page-size="pageSize"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="count">
    </el-pagination>
</template>
<style>
    .btn_search {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }
    .btn_add {
        font-weight: bolder;
    }
    .button_delete {
        font-weight: bolder;
    }
    .button_edit {
        color: #fff;
        background-color: orange;
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'TemplateList',
        data() {
            return {
                tableData: null,
                pageIndex: 1,
                pageSize: 50,       
                count:0,
                formSearch: {
                    formType: '',
                    templateName: '',
                    isDisabled: 'false',
                },
                formTypes: [],
            }
        },
        methods: {
            loadPage() {
                this.axios
                    .get('/api/workflow/gettemplatepage', {
                        params: {
                            formType: this.formSearch.formType,
                            templateName: this.formSearch.templateName,
                            isDisabled: this.formSearch.isDisabled,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos!= null) {
                            this.tableData = infos.items;
                            this.count = infos.count;
                        } else {
                            this.tableData = null;
                            this.count = 0;
                        }
                    });
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            },
            templateAdd() {
                this.$router.push({
                    path: "/WorkFlowTemplateAdd",
                    query: { id: 0} });
            },
            templateEdit(row) {
                this.$router.push({
                    path: "/WorkFlowTemplateEdit",
                    query: { id: row.id }
                });
            },
            templateDel(index,row) {
                this.$confirm(this.$t('message.messageBox.msgDelete'), this.$t('message.messageBox.titleTips'), {
                    confirmButtonText: this.$t('message.messageBox.btnConfirm'),
                    cancelButtonText: this.$t('message.messageBox.btnCancel'),
                    type: 'warning'
                }).then(() => {
                    row.isDisabledDelete = true;
                    this.axios
                        .post('/api/workflow/deltemplate', {
                            Id: row.id,
                            UserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isDisabledDelete  = false;
                            if (response.data.code != 1) {
                                this.$message.error(this.$t('message.error'));
                            } else {
                                this.$message.success(this.$t('message.successfullyDeleted'));
                                this.tableData.splice(index, 1);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isDisabledDelete = false;
                            this.$message.error(this.$t('message.messageBox.connecterror'));
                        });
                }).catch(() => {
                });
            },
            changeIsDisabled(row) {
                var isDisabled = false;
                if (row.isDisabled == false) {
                    isDisabled = true;
                }
                row.isLoading = true;
                return new Promise(resolve => {
                    this.axios
                        .post('/api/workflow/disabledtemplate', {
                            Id: row.id,
                            IsDisabled: isDisabled,
                            UserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isLoading = false;
                            if (response.data.code != 1) {
                                this.$message.error(this.$t('message.error'));
                                return resolve(false)
                            } else {
                                this.$message.success(this.$t('message.success'));
                                return resolve(true);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isLoading = false;
                            this.$message.error(this.$t('message.messageBox.connecterror'));
                            return resolve(false);
                        });
                })
            },
            getFormTypes() {
                this.axios
                    .get('/api/workflow/getformtypes')
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.formTypes = infos;
                        }
                    }).catch(error => {
                        console.log(error);
                    });
            },
            setFlows(row) {
                this.$router.push({
                    path: "/WorkFlowFlows",
                    query: { id: row.id }
                });
            },
        },
        mounted() {
            this.getFormTypes();
            this.submitSearch();
        }

    };
</script>