<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ProductMod' }">{{$t('message.vueProductMod.productmodpage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/AddProductMod' }">{{$t('message.vueProductMod.addproductmodpage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="addproductmodForm" :model="addproductmodForm" :rules="rules" label-width="200px" class="demo-addproductmodForm">
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductMod.ModelType')+' :'" prop="ModelType">
                    <el-input size="mini" v-model="addproductmodForm.ModelType" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductMod.ModelName')+' :'" prop="ModelName">
                    <el-input size="mini" v-model="addproductmodForm.ModelName" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductMod.ModelLevel')+' :'" prop="ModelLevel">
                    <el-select v-model="addproductmodForm.ModelLevel" size="mini" :placeholder="$t('message.pleaseSelect')" style="width:300px">
                        <el-option :label="$t('message.vueProductMod.pleaseSelectModelLevel')" value=""></el-option>
                        <el-option v-for="item in whereModelLevel"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductMod.DataType')+' :'" prop="DataType">
                    <el-select v-model="addproductmodForm.DataType" size="mini" :placeholder="$t('message.pleaseSelect')" style="width:300px">
                        <el-option :label="$t('message.vueProductMod.pleaseSelectModelType')" value=""></el-option>
                        <el-option v-for="item in whereModelType"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductMod.ProcuctModel')+' :'" prop="input_productmod">
                    <el-input size="mini" v-model="addproductmodForm.input_productmod" :disabled="true" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductMod.ProductModelNumber')+' :'" prop="input_productmodn">
                    <el-input size="mini" v-model="addproductmodForm.input_productmodn" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductMod.remarks')+' :'" prop="Remarks">
                    <el-select v-model="addproductmodForm.Remarks"
                               style="width:300px"
                               size="mini"
                               multiple
                               filterable
                               remote
                               reserve-keyword
                               :placeholder="$t('message.pleaseSelect')"
                               :remote-method="(query)=>{getCompanys(query)}"
                               :loading="addproductmodForm.isloading">
                        <el-option v-for="o in allCompanys"
                                   :key="o.value"
                                   :label="o.label"
                                   :value="o.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item>
                    <el-button class="button_submit" size="mini"
                               @click="submitForm('addproductmodForm')" :disabled="disabledSubmit">{{$t('message.vueCategory.button_sava')}}</el-button>
                    <el-button @click="returnForm()" class="button_return" size="mini">{{$t('message.return')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>
<style>
    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'AddProductMod',
        data() {
            return {
                language: AppStorage.getLanguage(),
                addproductmodForm: {
                    isloading: false,
                    input_productmod: '',
                    input_productmodn: '',
					ModelType: '',
                    ModelName: '',
                    Remarks: '',
                    ModelLevel: '',
                    DataType: '',
                    userNumber: AppStorage.getNumber()
                },
                whereModelLevel: [{
                    value: 'High',
                    label: 'High'
                }, {
                    value: 'Mid',
                    label: 'Mid'
                    },
                    {
                        value: 'Sofa',
                    label: 'Sofa'
                    }, {
                    value: 'Low',
                    label: 'Low'
                }],
                whereModelType: [{
                    value: 'CHAIR',
                    label: 'CHAIR'
                }, {
                    value: 'FOOT',
                    label: 'FOOT'
                }, {
                    value: 'OTHES',
                    label: 'OTHES'
                }],
                seen: true,
            }
        },
        computed: {
            rules() {
                const rules = {
                };
                return rules;
            }
        },
        methods: {
            getCompanys(query, isAll = true) {
                this.addproductmodForm.isloading = true;
                this.axios
                    .get('/api/company/getcompanylist', {
                        params: {
                            isFactory: 2,
                            userNumber: AppStorage.getNumber(),
                            keyword: query,
                            isAll: isAll
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allCompanys = infos.map(function (m) {
                                return {
                                    value: m.companyNumber,
                                    label: m.companyName + "(" + m.companyNumber + ")"
                                }
                            });
                        }
                        this.addproductmodForm.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.addproductmodForm.isloading = false;
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.addproductmodForm.ModelName != '') {
                            this.disabledSubmit = true;
                            this.axios
                                .post('/api/ProductMod/addproductmod', {
                                    Id: 0,
                                    ProductMod: this.addproductmodForm.input_productmod,
                                    ProductModNa: this.addproductmodForm.input_productmodn,
									ModelType: this.addproductmodForm.ModelType,
                                    ModelName: this.addproductmodForm.ModelName,
                                    ModelLevel: this.addproductmodForm.ModelLevel == '' ? null : this.addproductmodForm.ModelLevel,
                                    DataType: this.addproductmodForm.DataType == '' ? null : this.addproductmodForm.DataType,
                                    Remarks: this.addproductmodForm.Remarks?.toString(),
                                    UserNumber: AppStorage.getNumber()
                                })
                                .then(response => {
                                    if (response.data.code != 1) {
                                        if (response.data.msg == 'this model is exist') {
                                            this.$message.error(this.$t('message.vueProductMod.msgExistProductMod'));
                                        } else if (response.data.msg == 'this model number is exist') {
                                            this.$message.error(this.$t('message.vueProductMod.msgExistEditProductMod'));
                                        } else {
                                            this.$message.error(this.$t('message.errer'));
                                        }
                                    } else {
                                        this.$message.success(this.$t('message.success'));
                                        this.$router.push({ path: "/ProductMod" });
                                    }
                                    this.disabledSubmit = false;
                                }).catch(error => {
                                    console.log(error);
                                    this.$message.error(this.$t('message.messageBox.connecterror'));
                                    this.disabledSubmit = false;
                                });
                        } else {
                            alert(this.$t('message.vueProductMod.addproductmoderror'));
                        }
                    } else {
                        return false;
                    }
                });
            },
            returnForm() {
                this.$router.go(-1);
            },
        },
        mounted() {
            this.getCompanys('', true);
        }
    };
</script>
