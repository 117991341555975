<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/Product' }">{{$t('message.vueProduct.productpage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ProductDetail' }">{{$t('message.vueProduct.productdetailpage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-button @click="returnForm()" size="mini" class="button_return">{{$t('message.return')}}</el-button>
    <el-divider></el-divider>
    <el-form ref="editproductForm" :model="editproductForm" label-width="200px" class="demo-editproductForm">
        <el-row>
            <el-col :span="8">
                <el-form-item :label="$t('message.vueProduct.product_type')+' :'">
                    <el-input size="mini" v-model="editproductForm.input_producttype" :disabled="true" style="width:180px"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="7">
                <el-form-item :label="$t('message.vueProduct.product_code')+' :'">
                    <el-input size="mini" v-model="editproductForm.input_productcode" :disabled="true" style="width:130px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <el-form-item :label="$t('message.vueProduct.item_code')+' :'">
                    <el-input size="mini" v-model="editproductForm.item_code" :disabled="true" style="width:130px"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="7">
                <el-form-item :label="$t('message.vueProduct.factory_code')+' :'">
                    <el-input size="mini" v-model="editproductForm.input_factorycode" :disabled="true" style="width:130px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProduct.product_category')+' :'">
                    <el-input size="mini" v-model="editproductForm.input_category" :disabled="true" style="width:200px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProduct.model_no')+' :'">
                    <el-input size="mini" v-model="editproductForm.input_modelno" :disabled="true" style="width:500px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProduct.product_name')+' :'">
                    <el-input size="mini" v-model="editproductForm.input_productname" :disabled="true" style="width:500px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProduct.product_colour')+' :'">
                    <el-input size="mini" v-model="editproductForm.input_productcolour" :disabled="true" style="width:200px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="6">
                <el-form-item :label="$t('message.vueProduct.product_currency')+' :'">
                    <el-input size="mini" v-model="editproductForm.input_currency" :disabled="true" style="width:180px"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="5">
                <el-form-item :label="$t('message.vueProduct.price')+' :'">
                    <el-input-number size="mini" v-model="editproductForm.input_price" :disabled="true" />
                </el-form-item>
            </el-col>
            <el-col :span="5">
                <el-form-item :label="$t('message.vueProduct.unit_price')+' :'">
                    <el-input-number size="mini" v-model="editproductForm.input_unitprice" :disabled="true" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProduct.uom')+' :'">
                    <el-input size="mini" v-model="editproductForm.input_uom" :disabled="true" style="width:100px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueWorkFlow.labCompanys')+' :'">
                    <el-input size="mini" v-model="editproductForm.companys" :disabled="true"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>
<style>
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    var categoryname = ''
    var getcolourName = ''
    var getmodelName = ''
    export default {
        name: 'ProductDetail',
        data() {
            return {
                language: AppStorage.getLanguage(),
                editproductForm: {
                    input_producttype: '',
                    input_productcode: '',
                    item_code: '',
                    input_factorycode: '',
                    input_category: '',
                    input_modelno: '',
                    input_productname: '',
                    input_productcolour: '',
                    input_unitprice: 0,
                    input_price: 0,
                    input_currency: '',
                    input_uom: '',
                    companys: '',
                    userNumber: AppStorage.getNumber()
                },
                seen: true,
            }
        },
        methods: {
            getColourName(colour) {
                this.editproductForm.input_productcolour = null;
                if (colour != "") {
                    this.axios
                        .get('/api/ProductCol/getproductcollist', {
                            params: {
                                keyword: colour,
                            }
                        }).then(response => {
                            if (response.data.code == 1) {
                                var info0 = response.data.data;
                                getcolourName = info0[0].productCol;
                                this.editproductForm.input_productcolour = getcolourName;
                            }
                        })
                }
            },
            returnForm() {
                this.$router.go(-1);
            },

            getModelName(modelNo) {
                this.editproductForm.input_modelno = null;
                this.axios
                    .get('/api/ProductMod/getproductmodlist', {
                        params: {
                            keyword: modelNo,
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            getmodelName = infos[0].productMod;
                            this.editproductForm.input_modelno = getmodelName;
                        }
                    })
            },

            getCategoryName(categoryNumber) {
                this.editproductForm.input_category = null;
                this.axios
                    .get('/api/Category/getcategorylist', {
                        params: {
                            keyword: categoryNumber,
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            categoryname = infos[0].category;
                            this.editproductForm.input_category = categoryname;
                        }
                    })
            },

            getInfo() {
                var productCode = this.$route.query.ProductCode;
                if (productCode == null || productCode == "") {
                    return;
                }
                this.axios
                    .get('/api/Product/getproductbycode', {
                        params: {
                            ProductCode: productCode
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            if (info.productType == '1') {
                                this.editproductForm.input_producttype = 'Finished Goods New';
                            }

                            if (info.productType == '2') {
                                this.editproductForm.input_producttype = 'Accessories';
                            }

                            if (info.productType == '3') {
                                this.editproductForm.input_producttype = 'Spare Parts';
                            }

                            if (info.productType == '4') {
                                this.editproductForm.input_producttype = 'Gifts';
                            }

                            if (info.productType == '5') {
                                this.editproductForm.input_producttype = 'Non-Trade Item';
                            }

                            if (info.productType == '6') {
                                this.editproductForm.input_producttype = 'Finished Goods Demo';
                            }

                            if (info.productType == 'P') {
                                this.editproductForm.input_producttype = 'Promotion Package';
                            }

                            this.editproductForm.input_productcode = info.productCode;
                            this.editproductForm.input_factorycode = info.factoryCode;
                            this.editproductForm.item_code = info.itemCode;
                            this.getCategoryName(info.categoryNumber);
                            this.editproductForm.input_category = categoryname;

                            this.getModelName(info.modelNo);
                            this.editproductForm.input_modelno = getmodelName;

                            this.editproductForm.input_productname = info.productName;

                            this.getColourName(info.productColour);
                            this.editproductForm.input_productcolour = getcolourName;
                            this.editproductForm.companys = info.companys;
                            this.editproductForm.input_unitprice = info.unitPrice;
                            this.editproductForm.input_price = info.price;
                            this.editproductForm.input_currency = info.currency;
                            this.editproductForm.input_uom = info.uom
                        }
                    });
            }
        },
        //这个组件内的方法，函数
        mounted() {
            this.getInfo();
        }
    };
</script>