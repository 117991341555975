<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/TaskOptions' }">{{$t('message.vueTaskOptions.taskOptionspage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/AddTaskOptions' }">{{$t('message.vueTaskOptions.addTaskOptionspage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="edittaskOptionsForm" :model="edittaskOptionsForm" :rules="rules" label-width="200px">
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueTaskOptions.labtaskName')+' :'" prop="TaskName">
                    <el-input size="mini" v-model="edittaskOptionsForm.TaskName" style="width:300px" disabled></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueTaskOptions.labGroupName')+' :'" prop="GroupName">
                    <el-input size="mini" v-model="edittaskOptionsForm.GroupName" style="width:300px" disabled></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueTaskOptions.labInterval')+' :'" prop="Interval">
                    <el-input size="mini" v-model="edittaskOptionsForm.Interval" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueTaskOptions.labApiUrl')+' :'" prop="ApiUrl">
                    <el-input size="mini" v-model="edittaskOptionsForm.ApiUrl" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueTaskOptions.labAuthKey')+' :'" prop="AuthKey">
                    <el-input size="mini" v-model="edittaskOptionsForm.AuthKey" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueTaskOptions.labAuthValue')+' :'" prop="labAuthValue">
                    <el-input size="mini" v-model="edittaskOptionsForm.AuthValue" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueTaskOptions.labRequestType')+' :'" prop="RequestType">
                    <el-input size="mini" v-model="edittaskOptionsForm.RequestType" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueTaskOptions.labDescribe')+' :'" prop="Describe">
                    <el-input size="mini" v-model="edittaskOptionsForm.Describe" style="width: 300px;"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item>
                    <el-button class="button-submit"
                               size="mini"
                               @click="submitForm('edittaskOptionsForm')"
                               :disabled="disabledSubmit">
                        {{$t('message.vueTaskOptions.button_sava')}}
                    </el-button>
                    <el-button @click="returnForm()" size="mini" class="button_return">{{$t('message.return')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<style>
    .button-submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'EditTaskOptions',
        data() {
            return {
                language: AppStorage.getLanguage(),
                edittaskOptionsForm: {
                    Id: 0,
                    TaskName: '',
                    GroupName: '',
                    Interval: '',
                    ApiUrl: '',
                    AuthKey: '',
                    AuthValue: '',
                    RequestType: ''
                }
            }
        },
        computed: {
            rules() {
                const rules = {
                    TaskName: [
                        { required: true, message: this.$t('message.vueTaskOptions.verifyTaskOptionsRequired'), trigger: 'blur' }
                    ],
                    GroupName: [
                        { required: true, message: this.$t('message.vueTaskOptions.verifyTaskOptionsGroupNameRequired'), trigger: 'blur' }
                    ],
                    Interval: [
                        { required: true, message: this.$t('message.vueTaskOptions.verifyTaskOptionsIntervalRequired'), trigger: 'blur' }
                    ],
                    ApiUrl: [
                        { required: true, message: this.$t('message.vueTaskOptions.verifyTaskOptionsApiUrlRequired'), trigger: 'blur' }
                    ],
                    RequestType: [
                        { required: true, message: this.$t('message.vueTaskOptions.verifyTaskOptionsRequestTypeRequired'), trigger: 'blur' }
                    ],                    
                };
                return rules;
            }
        },
        methods: {
            getInfo() {
                var id = this.$route.query.Id;
                if (id <= 0) {
                    return;
                }
                this.axios
                    .get('/api/TaskOptions/GetTaskOptionsById', {
                        params: {
                            Id: id
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.edittaskOptionsForm.Id = info.id;
                            this.edittaskOptionsForm.TaskName = info.taskName;
                            this.edittaskOptionsForm.GroupName = info.groupName;
                            this.edittaskOptionsForm.Interval = info.interval;
                            this.edittaskOptionsForm.ApiUrl = info.apiUrl;
                            this.edittaskOptionsForm.AuthKey = info.authKey;
                            this.edittaskOptionsForm.AuthValue = info.authValue;
                            this.edittaskOptionsForm.RequestType = info.requestType;
                            this.edittaskOptionsForm.Describe = info.describe;
                        }
                    });
            },
            submitForm(formName) {
                console.log(formName);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/TaskOptions/Update', {
                                Id: this.edittaskOptionsForm.Id,
                                TaskName: this.edittaskOptionsForm.TaskName,
                                GroupName: this.edittaskOptionsForm.GroupName,
                                Interval: this.edittaskOptionsForm.Interval,
                                ApiUrl: this.edittaskOptionsForm.ApiUrl,
                                AuthKey: this.edittaskOptionsForm.AuthKey,
                                AuthValue: this.edittaskOptionsForm.AuthValue,
                                RequestType: this.edittaskOptionsForm.RequestType,
                                Describe: this.edittaskOptionsForm.Describe,
                                AddUserNumber: AppStorage.getNumber()
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    this.$message.error(this.$t('message.errer'));
                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    this.$router.push({ path: "/TaskOptions" });
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });
                    } else {
                        return false;
                    }
                });
            },
            returnForm() {
                this.$router.go(-1);
            },
        },
        //这个组件内的方法，函数
        mounted() {
            this.getInfo();
        }
    };
</script>
