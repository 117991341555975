<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/Product' }">{{$t('message.vueProduct.productpage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/AddProduct' }">{{$t('message.vueProduct.addproductpage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="addproductForm" :model="addproductForm" :rules="rules" label-width="200px" class="demo-addproductForm">
        <el-row>
            <el-col :span="8">
                <el-form-item :label="$t('message.vueProduct.product_type')+' :'" prop="input_producttype">
                    <el-select v-model="addproductForm.input_producttype" size="mini" :placeholder="$t('message.pleaseSelect')">
                        <el-option v-for="item in typelist"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item :label="$t('message.vueProduct.product_code')+' :'">
                    <el-input size="mini" v-model="addproductForm.input_productcode" :disabled="true" style="width:200px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <el-form-item :label="$t('message.vueProduct.item_code')+' :'">
                    <el-input size="mini" v-model="addproductForm.item_code" style="width:200px"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item :label="$t('message.vueProduct.factory_code')+' :'">
                    <el-input size="mini" v-model="addproductForm.input_factorycode" style="width:200px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <!--<el-row>
        <el-col :span="24">
            <el-form-item :label="$t('message.vueProduct.product_code')+' :'">
                <el-input size="mini" v-model="addproductForm.input_productcode" :disabled="true" style="width:200px"></el-input>
            </el-form-item>
        </el-col>
    </el-row>

    <el-row>
        <el-col :span="24">
            <el-form-item :label="$t('message.vueProduct.item_code')+' :'">
                <el-input size="mini" v-model="addproductForm.item_code" style="width:200px"></el-input>
            </el-form-item>
        </el-col>
    </el-row>

    <el-row>
        <el-col :span="24">
            <el-form-item :label="$t('message.vueProduct.factory_code')+' :'">
                <el-input size="mini" v-model="addproductForm.input_factorycode" style="width:200px"></el-input>
            </el-form-item>
        </el-col>
    </el-row>-->
        <el-row>
            <el-col :span="8">
                <el-form-item :label="$t('message.vueProduct.product_category')+' :'" prop="input_category">
                    <el-select v-model="addproductForm.input_category" size="mini"
                               style="width:100%"
                               value-key="categoryNumber" :placeholder="$t('message.pleaseSelect')" filterable>
                        <el-option v-for="item in categorylist"
                                   :key="item.categoryNumber"
                                   :label="item.category"
                                   :value="item" />
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item :label="$t('message.vueProduct.model_no')+' :'" prop="input_modelno">
                    <el-select v-model="addproductForm.input_modelno" size="mini"
                               style="width:100%"
                               value-key="productModNa" :placeholder="$t('message.pleaseSelect')" filterable>
                        <el-option v-for="item in modellist"
                                   :key="item.productModNa"
                                   :label="item.productMod"
                                   :value="item" />
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProduct.product_colour')+' :'" prop="input_productcolour">
                    <el-select v-model="addproductForm.input_productcolour" size="mini" value-key="productColNa" :placeholder="$t('message.pleaseSelect')" filterable>
                        <el-option v-for="item in colourlist"
                                   :key="item.productColNa"
                                   :label="item.productCol"
                                   :value="item" />
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProduct.product_name')+' :'">
                    <el-input size="mini" v-model="addproductForm.input_productname" style="width:500px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6">
                <el-form-item :label="$t('message.vueProduct.product_currency')+' :'">
                    <el-select v-model="addproductForm.input_currency" size="mini"
                               style="width:100%"
                               value-key="currencyNumber" :placeholder="$t('message.pleaseSelect')">
                        <el-option v-for="item in currencylist"
                                   :key="item.currencyNumber"
                                   :label="item.currency"
                                   :value="item" />
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="5">
                <el-form-item :label="$t('message.vueProduct.price')+' :'">
                    <el-input-number size="mini" v-model="addproductForm.input_price" />
                </el-form-item>
            </el-col>
            <el-col :span="10">
                <el-form-item :label="$t('message.vueProduct.unit_price')+' :'">
                    <el-input-number size="mini" :disabled="true" v-model="addproductForm.input_unitprice" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProduct.uom')+' :'">
                    <el-input size="mini" v-model="addproductForm.input_uom" style="width:100px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueWorkFlow.labCompanys')+' :'" prop="companys">
                    <el-select v-model="addproductForm.companys"
                               size="mini"
                               multiple
                               filterable
                               remote
                               reserve-keyword
                               :placeholder="$t('message.pleaseSelect')"
                               :remote-method="(query)=>{getCompanys(query)}"
                               :loading="addproductForm.isloading">
                        <el-option v-for="o in allCompanys"
                                   :key="o.value"
                                   :label="o.label"
                                   :value="o.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item>
                    <el-button size="mini" class="button_submit"
                               @click="submitForm('addproductForm')" :disabled="disabledSubmit">{{$t('message.vueCategory.button_sava')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>
<style>
    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'AddProduct',
        data() {
            return {
                language: AppStorage.getLanguage(),
                categorylist: [],
                modellist: [],
                colourlist: [],
                currencylist: [],
                typelist: [{
                    value: '1',
                    label: 'Finished Goods New'
                }, {
                        value: '2',
                        label: 'Accessories'
                    }, {
                        value: '3',
                        label: 'Spare Parts'
                    }, {
                        value: '4',
                        label: 'Gifts'
                    }, {
                        value: '5',
                        label: 'Non-Trade Item'
                    }, {
                        value: '6',
                        label: 'Finished Goods Demo'
                    }, {
                        value: 'P',
                        label: 'Promotion Package'
                    }],
                addproductForm: {
                    isloading: false,
                    input_producttype: '',
                    input_productcode: '',
                    item_code: '',
                    input_factorycode: '',
                    input_category: '',
                    input_modelno: '',
                    input_productname: '',
                    input_productcolour: '',
                    input_unitprice: 0,
                    input_price: 0,
                    input_currency: '',
                    input_uom: '',
                    companys: '',
                    userNumber: AppStorage.getNumber()
                },
                seen: true,
            }
        },
        computed: {
            rules() {
                const rules = {
                    input_producttype: [
                        { required: true, message: this.$t('message.vueProduct.verifyProductTypeRequired'), trigger: 'blur' }
                    ],
                    input_category: [
                        { required: true, message: this.$t('message.vueProduct.verifyProductCategoryRequired'), trigger: 'blur' }
                    ],
                    input_modelno: [
                        { required: true, message: this.$t('message.vueProduct.verifyProductModelRequired'), trigger: 'blur' }
                    ],
                    input_productcolour: [
                        { required: true, message: this.$t('message.vueProduct.verifyProductColourRequired'), trigger: 'blur' }
                    ]
                };
                return rules;
            }
        },
        methods: {
            getCategory() {
                this.axios
                    .get('/api/Category/getcategorylist', {
                        params: {
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.categorylist = infos;
                        }
                    })
            },
            getCurrency() {
                this.axios
                    .get('/api/Currency/getcurrencylist', {
                        params: {
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.currencylist = infos;
                        }
                    })
            },
            getModel() {
                this.axios
                    .get('/api/ProductMod/getproductmodlist', {
                        params: {
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.modellist = infos;
                        }
                    })
            },
            getColour() {
                this.axios
                    .get('/api/ProductCol/getproductcollist', {
                        params: {
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.colourlist = infos;
                        }
                    })
            },
            getCompanys(query, isAll = true) {
                this.addproductForm.isloading = true;
                this.axios
                    .get('/api/company/getcompanylist', {
                        params: {
                            isFactory: 2,
                            userNumber: AppStorage.getNumber(),
                            keyword: query,
                            isAll: isAll
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allCompanys = infos.map(function (m) {
                                return {
                                    value: m.companyNumber,
                                    label: m.companyName + "(" + m.companyNumber + ")"
                                }
                            });
                        }
                        this.addproductForm.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.addproductForm.isloading = false;
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/Product/addproduct', {
                                Producttype: this.addproductForm.input_producttype,
                                ProductCode: this.addproductForm.input_productcode,
                                ItemCode: this.addproductForm.item_code,
                                FactoryCode: this.addproductForm.input_factorycode,
                                Category: this.addproductForm.input_category.categoryNumber,
                                ModelNo: this.addproductForm.input_modelno.productModNa,
                                ProductName: this.addproductForm.input_productname,
                                ProductColour: this.addproductForm.input_productcolour.productColNa,
                                UnitPrice: this.addproductForm.input_unitprice,
                                Price: this.addproductForm.input_price,
                                Currency: this.addproductForm.input_currency.currencyNumber,
                                Uom: this.addproductForm.input_uom,
                                //Companys: this.addproductForm.companys,
                                Companys: this.addproductForm.companys?.toString(),
                                UserNumber: AppStorage.getNumber()
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    if (response.data.msg == "该产品号已经存在,请重新录入。") {
                                        alert(this.$t('message.vueProduct.addproducterror'));
                                    } else {
                                        this.$message.error(this.$t('message.errer'));
                                    }
                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    this.$router.push({ path: "/Product" });
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });

                    } else {
                        return false;
                    }
                });
            } 
        },
        //这个组件内的方法，函数
        mounted() {
            this.getCategory();
            this.getModel();
            this.getColour();
            this.getCompanys('', true);
            this.getCurrency();
        }
    };
</script>