/**
 * Created by linjf on 22/11/17.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}
/**
 * @param {string} start  开始时间
 * @param {string} end  2006-12-18格式
 * @returns {Object}
 */
export function dateDiff(start, end) {
    var aDate, oDate1, oDate2, iDays
    start =  parseTime(start, '{y}-{m}-{d}')
    end =  parseTime(end, '{y}-{m}-{d}')
	aDate   =  start.split("-")
	oDate1 = new Date(aDate[0] , aDate[1] ,aDate[2])    //转换为12-18-2006格式
	aDate   =  end.split("-")
	oDate2 = new Date(aDate[0] , aDate[1] , aDate[2])
	iDays   =  parseInt(Math.abs(oDate1  -  oDate2)  /  1000  /  60  /  60  /24)    //把相差的毫秒数转换为天数
	return  iDays+1
}

export function dateMinusMonthOrYear(date,dateType){
  var curDate = new Date(date)
  var year  = curDate.getFullYear();
  var month = (curDate.getMonth()+1) < 10 ? '0'+(curDate.getMonth()+1).toString():(curDate.getMonth()+1).toString()
  if(dateType =='year'){
     year = year -1
  }else{
     month = curDate.getMonth() < 10 ? '0'+curDate.getMonth().toString():curDate.getMonth().toString()
  }
  var day = curDate.getDate() < 10 ? '0'+curDate.getDate().toString():curDate.getDate().toString()
  return year + '-' + month + '-' + day;
}

export function getWeekDate() {
       var now = new Date();
       var day = now.getDay();
    var weeks = new Array("星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日");
       var week = weeks[day];
       return week;
}

export function getWeekNum(val) {
    let firstDay = new Date(val.getFullYear(), 0, 1)
    let dayOfWeek = firstDay.getDay()
    let spendDay = 1
    if (dayOfWeek != 0) {
        spendDay = 7 - dayOfWeek + 1
    }
    firstDay = new Date(val.getFullYear(), 0, 1 + spendDay)
    let d = Math.ceil((val.valueOf() - firstDay.valueOf()) / 86400000)
    let year = val.getFullYear()
    let week = Math.ceil(d / 7) + 1
    /*if (year >= new Date().getFullYear()) {
        week = week - 1
    }*/
    return week;
}

// 根据日期判断是月的第几周
export function getWeekInMonth(t) {
    if (t == undefined || t == '' || t == null) {
        t = new Date();
    } else {
        var _t = new Date();
        _t.setYear(t.getFullYear());
        _t.setMonth(t.getMonth());
        _t.setDate(t.getDate());

        var date = _t.getDate(); //给定的日期是几号

        _t.setDate(1);
        var d = _t.getDay(); //1. 得到当前的1号是星期几。
        var fisrtWeekend = d;
        if (d == 0) {
            fisrtWeekend = 1;
            //1号就是星期天
        } else {
            fisrtWeekend = 7 - d + 1; //第一周的周未是几号
        }
        if (date <= fisrtWeekend) {
            return 1;
        } else {
            return 1 + Math.ceil((date - fisrtWeekend) / 7);
        }
    }
}

export function getNewDate(date) {
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    return { year, month, day };
}

export function getDate(year, month, day) {
    return new Date(year, month, day);
}

export function formatDate(date) {
    date = Number(date);
    return date < 10 ? `0${date}` : date;
}