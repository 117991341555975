<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name: 'App'
    }
</script>

<style>
    .module-box {width: 100%;}
    .module-box .box-sub {width: 100%;margin-bottom: 20px;}
    .el-table__row .el-button {margin-top:2px;}
</style>
