<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/SalesDataList' }">{{$t('message.vueSales.SalesDataList')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="salesData" :model="salesData" :inline="true" class="demo-form-inline" size="small" :rules="rules" label-width="100px">
        <div id="tag_1665471880367668" :style="{textAlign: 'left'}">
            <!--年-->
            <el-form-item :label="$t('message.vueSales.year')">
                <el-date-picker v-model="salesData.year" prop="year" type="year" :start-placeholder="$t('message.pleaseSelect')" :end-placeholder="$t('message.pleaseSelect')" format="YYYY" value-format="YYYY"></el-date-picker>
            </el-form-item>
            <!--月-->
            <el-form-item :label="$t('message.vueSales.month')">
                <el-date-picker v-model="salesData.month" prop="month" type="month" :start-placeholder="$t('message.pleaseSelect')" :end-placeholder="$t('message.pleaseSelect')" format="MM" value-format="MM"></el-date-picker>
            </el-form-item>

            <el-form-item :label="$t('message.vueSales.isDisabled')" placement="top" style="margin-right:10px">
                <el-switch v-model="salesData.disable" active-color="#ff4949" inactive-color="#13ce66"></el-switch>
                <span v-if="salesData.disable===true">
                    {{$t('message.vueSales.IsDisabledYes')}}
                </span>
                <span v-else>
                    {{$t('message.vueSales.IsDisabledNo')}}
                </span>
            </el-form-item>

            <el-form-item>
                <el-button size="mini" type="primary" id="btn_search" class="btn_search" icon="el-icon-circle-check" :disabled="disabledSubmit" @click="submitSearch()">{{$t('message.vueSales.btn_query')}}</el-button>
            </el-form-item>

        </div>
        <el-table :data="tableData" tooltip-effect="dark" :stripe="false" border size="mini" fit
                  @cell-mouse-enter="(row, column, cell, event) => row.__buttonVisible__ = true"
                  @cell-mouse-leave="(row, column, cell, event) => row.__buttonVisible__ = false"
                  :header-cell-style="{'background':'#81761852','color':'#2F4F4F','font-size': '12px','text-align':'center'}">
            <!--序号-->
            <el-table-column :label="$t('message.vueSales.indexNo')" prop="" :show-overflow-tooltip="false" align="left" width="50px">
                <template #default="scope">
                    <span>{{scope.$index+1 + (pageIndex - 1) * pageSize}}</span>
                </template>
            </el-table-column>
            <!--周期-->
            <el-table-column :label="$t('message.vueSales.datePeriod')" prop="year" :show-overflow-tooltip="false" align="left" width="120px">
                <template #default="scope">
                    <span>{{scope.row.datePeriod}}</span>
                </template>
            </el-table-column>
            <!--国家-->
            <el-table-column :label="$t('message.vueSales.country')" prop="country" :show-overflow-tooltip="false" align="left" width="80px">
                <template #default="scope">
                    <span>{{scope.row.country}}</span>
                </template>
            </el-table-column>
            <!--指标-->
            <el-table-column :label="$t('message.vueSales.rule')" prop="rule" :show-overflow-tooltip="false" align="left" width="80">
                <template #default="scope">
                    <span v-if="scope.row.rules===1">
                        {{$t('message.vueSales.quantity')}}
                    </span>
                    <span v-else>
                        {{$t('message.vueSales.amount')}}
                    </span>
                </template>
            </el-table-column>
            <!--总销售数量-->
            <el-table-column :label="$t('message.vueSales.sumQuantity')" prop="sumQuantity"
                             :show-overflow-tooltip="false" align="right" width="110px">
                <template #default="scope">
                    <span>{{scope.row.sumQuantity}}</span>
                </template>
            </el-table-column>
            <!--总销售金额-->
            <el-table-column :label="$t('message.vueSales.sumAmount')" prop="sumAmount" :show-overflow-tooltip="false" align="right" width="110px">
                <template #default="scope">
                    <span>{{scope.row.sumAmount}}</span>
                </template>
            </el-table-column>

            <!--是否生效-->
            <el-table-column :label="$t('message.vueSales.isDisabled')" prop="isDisabled" :show-overflow-tooltip="false" align="left" width="150px">
                <template #default="scope">
                    <span>{{scope.row.isDisable}}</span>
                    <el-tooltip :content="$t('message.vueSales.isDisabled')" placement="top" style="margin-right:10px">
                        <el-switch v-model="scope.row.isDisabled" active-color="#ff4949" inactive-color="#13ce66" :loading="scope.row.isLoading" :before-change="()=>changeIsDisabled(scope.row)"></el-switch>
                    </el-tooltip>
                    <span v-if="scope.row.isDisabled===false">
                        {{$t('message.vueSales.IsDisabledNo')}}
                    </span>
                    <span v-else>
                        {{$t('message.vueSales.IsDisabledYes')}}
                    </span>
                </template>
            </el-table-column>

            <el-table-column :label="$t('message.operate')"
                             min-width="120px">
                <template #default="scope">
                    <el-button size="mini"
                               icon="el-icon-edit-outline"
                               class="button_detail"
                               @click="query(scope.row)">{{$t('message.details')}}</el-button>
                    <el-button size="mini"
                               icon="el-icon-edit"
                               class="button_edit"
                               @click="edit(scope.row)">{{$t('message.edit')}}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="pageIndex"
                           :page-sizes="[10, 25, 50, 75, 100]"
                           :page-size="pageSize"
                           layout="total, sizes, prev, pager, next, jumper"
                           :total="count">
            </el-pagination>
        </div>

    </el-form>
</template>
<script>
    import AppStorage from '@/common/storage/app-storage';
    import { parseTime, getWeekNum } from '@/common/utils/datetime';
    import { GetCompanyList } from '@/api/system/company';
    import { GetUserList } from '@/api/system/user';    

    export default {
        name: "SalesData",
        components: {

        },
        data() {            
            return {

                tableData: null,

                salesData: {
                    year: '',
                    month: '',
                    country: '',
                    addTime: '',
                    addUserNumber: AppStorage.getNumber(),
                    isDisabled: false,
                },
                user_data: [],
                country: [],
                submit_loading: false,
                pageIndex: 1,
                pageSize: 10,
                count: 0,
                language: AppStorage.getLanguage(),
                dateParam: {
                    yyyy: '',
                    week: '',
                },
                allCountrys: [],
                allUsers: [],
            };
        },
        computed: {
            rules() {
                const rules = {
                };
                return rules;
            }
        },
        methods: {
            async initData() {
                //获取当前时间
                var now = new Date();
                var yearn = now.getFullYear();

                this.salesData.year = yearn.toString();
                this.loadPage();
            },
            loadPage() {
                this.axios
                    .get('/api/salesData/getsalesdatapage', {
                        params: {
                            isDisabled: this.salesData.disable,
                            Year: this.salesData.year,
                            Month: this.salesData.month,
                            addUserNumber: this.salesData.addUserNumber,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.tableData = infos.items;
                            this.count = infos.count;
                        }
                    })
            },
            datetimeFormat(row) {
                if (row.addTime.length > 0) {
                    return parseTime(row.addTime, '{y}-{m}-{d} {h}:{m}:{s}');
                }
                else { return ""; }
            },
            getCountrys(query, isAll = true) {
                this.salesData.isloading = true;
                GetCompanyList(null, 0, true).then(response => {
                        var infos = response.data.data;
                    if (response.data.code == 1 && infos != null) {
                        this.allCountrys = infos.map(function (m) {
                            return {
                                value: m.companyNumber,
                                label: m.companyName + "(" + m.companyNumber + ")"
                            }
                        });
                        this.allCountrys.unshift({
                            value: "",
                            label: this.$t('message.all')
                        });
                    }
                    this.salesData.isloading = false;
                })
                .catch(error => {
                    console.log(error);
                    this.salesData.isloading = false;
                });
            },
            seletCountryChange(val) {
                var obj = {}
                obj = this.allCountrys.find(function (i) {
                    return i.value === val
                });
                this.salesData.country = obj.value;
                this.getUsers('', '');
            },
            getUsers(query, isAll = true) {
                this.salesData.isloading = true;
                GetUserList(this.salesData.country, null, null, true).then(response => {
                    var infos = response.data.data;
                    if (response.data.code == 1 && infos != null) {
                        this.allUsers = infos.map(function (m) {
                            return {
                                value: m.number,
                                label: m.name + "(" + m.number + ")"
                            }
                        });
                        this.allUsers.unshift({
                            value: "",
                            label: this.$t('message.all')
                        });
                    }
                    this.salesData.isloading = false;
                }).catch(error => {
                    console.log(error);
                    this.salesData.isloading = false;
                });
            },
            edit(row) {
                this.$router.push({
                    path: "/SalesDataEdit",
                    query: {
                        id: row.id,
                        datePeriod: row.datePeriod,
                        country: row.country,
                        rules: row.rules,
                        years: row.yearS,
                        months: row.monthS,
                        readonly: false,
                        operate: "edit"
                    }
                });
            },
            query(row) {
                this.$router.push({
                    path: "/SalesDataEdit",
                    query: {
                        id: row.id,
                        datePeriod: row.datePeriod,
                        country: row.country,
                        rules: row.rules,
                        years: row.yearS,
                        months: row.monthS,
                        readonly: true,
                        operate: "query"
                    }
                });
            },
            changeIsDisabled(row) {
                var isDisabled = false;
                if (row.isDisabled == false) {
                    isDisabled = true;
                }
                row.isLoading = true;
                return new Promise(resolve => {
                    this.axios
                        .post('/api/salesData/disabledsalesdata', {
                            Id: row.id,
                            IsDisabled: row.isDisabled,
                            DisabledUserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isLoading = false;
                            if (response.data.code != 1) {
                                this.$message.error(this.$t('message.error'));
                                return resolve(false);
                            } else {
                                this.$message.success(this.$t('message.success'));
                                return resolve(true);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isLoading = false;
                            this.$message.error(this.$t('message.exception' + ':' + error));
                            return resolve(false);
                        });
                })
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            init() {
                this.dialogTableVisible = true;
            },
            openDialog() {
                this.$refs.multipleTable.clearSelection();
            },
            //初始化日期
            getDay() {
                var date = new Date();
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var day = date.getDate();
                if (month < 10) {
                    month = '0' + month;
                }
                if (day < 10) {
                    day = '0' + day;
                }
                let nowDate = year + '-' + month + '-' + day;
                this.dateParam.yyyy = year;
                var newDate = new Date(nowDate);
                let weekday = newDate.getDay() || 7;
                newDate = new Date(newDate.setDate(newDate.getDate() - weekday - 1));
                this.dateParam.week = getWeekNum(newDate);
                this.salesData.datePeriod = year + '-' + month + '-' + day;
            },
            weekChange(val) {
                if (val) {                    
                    this.dateParam.yyyy = new Date(val).getFullYear();
                    this.dateParam.week = getWeekNum(new Date(val.setDate(val.getDate() + 1)));
                }
            },                       
        },
        created() {
            this.getCountrys();
            this.getUsers();
            this.getDay();
        },
        mounted() {
            this.initData();
            //this.submitSearch();            
        },
        watch: {
            $route() {
                //响应路由参数的变化
                this.submitSearch();                
            },            
        }
    };
</script>
<style scoped>
    .btn_search {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }

    .button_detail {
        font-weight: bolder;
    }

    .btn_add {
        font-weight: bolder;
    }

    .j-object-array-form > .el-form-item__label::before {
        content: '' !important;
        color: unset !important;
        margin-right: unset !important;
    }

    .j-object-array-form > .j-object-array-form-header.required::before {
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
    }
</style>