<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/SalesDataReport' }">{{$t('message.vueSDPReport.SalesDataReportPage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="SalesDataReportForm" :model="SalesDataReportForm" :inline="true" class="demo-form-inline" label-width="100px">
        <el-form-item prop="yearmonth" :label="$t('message.vueSDPReport.dateLabel')+' :'">
            <el-date-picker v-model="SalesDataReportForm.yearmonth" type="month" value-format="YYYY-MM" size="mini" style="width: 200px"></el-date-picker>
        </el-form-item>

        <el-form-item :label="$t('message.vueSales.country')+' :'" prop="country">
            <el-select v-model="SalesDataReportForm.country" filterable remote reserve-keyword :placeholder="$t('message.pleaseSelect')" @change="seletCountryChange" :disabled="dis"
                       :remote-method="(query)=>{ getCountrys(query) }" :loading="SalesDataReportForm.isloading" style="width:220px" size="mini">
                <el-option v-for="item in allCountrys" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </el-form-item>

        <el-form-item :label="$t('message.vueSDPReport.categoryLabel')+' :'">
            <el-select v-model="SalesDataReportForm.modelType" size="mini"
                       style="width: 200px" :placeholder="$t('message.vueSDPReport.pleaseSelectModelType')" @change="getModelList2($event)" filterable>
                <el-option :label="$t('message.vueSDPReport.pleaseSelectModelType')" value=""></el-option>
                <el-option v-for="item in whereModelType"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
            </el-select>
            <el-select v-model="SalesDataReportForm.modelLevel" size="mini"
                       style="width: 200px" :placeholder="$t('message.vueSDPReport.pleaseSelectModelLevel')" @change="getModelList($event)" filterable>
                <el-option :label="$t('message.vueSDPReport.pleaseSelectModelLevel')" value=""></el-option>
                <el-option v-for="item in whereModelLevel"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>

        <el-form-item :label="$t('message.vueSDPReport.modelLabel')+' :'">
            <el-select v-model="SalesDataReportForm.model" size="mini"
                       style="width:400px"
                       value-key="productMod" :placeholder="$t('message.pleaseSelect')" filterable>
                <el-option :label="$t('message.pleaseSelect')" value=""></el-option>
                <el-option v-for="item in modellist"
                           :key="item.productMod"
                           :label="item.productMod"
                           :value="item" />
            </el-select>
        </el-form-item>

        <el-form-item>
            <el-button class="btn_search" id="btn_search" @click="submitSearch"
                       icon="el-icon-search" size="mini">{{$t('message.search')}}</el-button>
            <el-button type="primary" @click="exportToExcel" size="mini">{{$t('message.vueSDPReport.excelButton')}}</el-button>
        </el-form-item>
    </el-form>

    <el-table id="SalesData" :data="tableData" border size="mini" style="width: 100%"
              show-summary="true" :summary-method="getSummaries" :header-cell-style="columnbackgroundStyle">
        <el-table-column :label="$t('message.vueSDPReport.categoryLabel')+''" prop="Category" width="200" align="center" fixed="left">
            <el-table-column :label="$t('message.vueSDPReport.modeltypeLabel')+''" prop="dataType" width="100">
                <template #default="scope">
                    <span>{{scope.row.dataType}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('message.vueSDPReport.modellevelLabel')+''" prop="modelLevel" width="100">
                <template #default="scope">
                    <span>{{scope.row.modelLevel}}</span>
                </template>
            </el-table-column>
        </el-table-column>

        <!--<el-table-column :label="$t('message.vueSDPReport.modelLabel')+''" prop="modelName" width="400" fixed="left">-->
        <el-table-column :label="$t('message.vueSDPReport.modelLabel')+''" prop="modelName" width="260" fixed="left">
            <template #default="scope">
                <span>{{scope.row.modelName}}</span>
            </template>
        </el-table-column>

        <el-table-column v-for="(item, index) in tableLabelOption"
                         :key="index"
                         :label="item.label" :prop="item.weekNum"
                         align="center" width="200">
            <el-table-column :label="$t('message.vueSales.uploadQuantity')+''" :prop="item.iType + 'uploadQuantity'" width="80" align="right">
                <template #default="scope">
                    <span>{{scope.row.SalesDataDetailSubs[item.iType].UploadQuantity}}</span>
                </template>
            </el-table-column>
            <!--<el-table-column :label="$t('message.vueSales.uploadAmount')+''" :prop="item.iType + 'uploadAmount'" width="90" align="right">
        <template #default="scope">
            <span>{{scope.row.SalesDataDetailSubs[item.iType].UploadAmount}}</span>
        </template>
    </el-table-column>-->
        </el-table-column>

        <el-table-column :label="$t('message.vueSDPReport.totalDataLebel')+''" prop="totalData" width="90" align="center">
            <el-table-column :label="$t('message.vueSales.uploadQuantity')+''" prop="totalUploadQuantity" width="80" align="right">
                <template #default="scope">
                    <span>{{scope.row.totalUploadQuantity}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('message.vueSales.uploadAmount')+''" prop="totalUploadAmount" width="90" align="right">
                <template #default="scope">
                    <span>{{scope.row.totalUploadAmount}}</span>
                </template>
            </el-table-column>
        </el-table-column>
    </el-table>
    <div class="block">
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="pageIndex"
                       :page-sizes="[10, 25, 50, 75, 100]"
                       :page-size="pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="count">
        </el-pagination>
    </div>
</template>
<style>
    .btn_search {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }

    .table {
        height: 100vh;
        width: 100%;
        padding: 40px;
        box-sizing: border-box;
        background-color: #fff;
        direction: rtl;
    }

    .tableqty {
        direction: rtl;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage';
    import { parseTime, getWeekNum } from '@/common/utils/datetime';
    import { GetCompanyList } from '@/api/system/company';
    import FileSaver from 'file-saver'
    export default {
        name: 'SalesDataReport',
        data() {
            return {
                tableData: [],
                
                SalesDataReportForm: {
                    yearmonth: '',
                    country: '',
                    modelLevel: '',
                    modelType: '',
                    model: '',
                    userNumber: AppStorage.getNumber()
                },
                SalesDataDetailSubs: [
                ],
                tableLabelOption: [
                ],
                language: AppStorage.getLanguage(),
                whereModelLevel: [{
                    value: 'High',
                    label: 'High'
                }, {
                    value: 'Mid',
                    label: 'Mid'
                }, {
                    value: 'Low',
                    label: 'Low'
                }],
                whereModelType: [{
                    value: 'CHAIR',
                    label: 'CHAIR'
                }, {
                    value: 'FOOT',
                    label: 'FOOT'
                }, {
                    value: 'OTHES',
                    label: 'OTHES'
                    }],
                dis: this.$route.query.readonly == "true" ? true : false,
                modellist: [],
                pageIndex: 1,
                pageSize: 20,
                oldPageIndex: 1,
                oldPageSize: 0,
                count: 0
            }
        },
        methods: {
            columnbackgroundStyle({ row, column, rowIndex, columnIndex }) {
                if (columnIndex >= 2 && (rowIndex == 0 || rowIndex == 1) && (column.property == 1 || column.property == 7)) {
                    //让下标为1的列数背景颜色显示为红色（颜色自定义根据大家需求来）
                    return 'background:pink;'
                }
            },
            exportToExcel() {
                this.oldPageSize = this.pageSize;
                this.oldPageIndex = this.pageIndex;
                // 然后将整表数据条数赋值给分页大小，页码赋值为1，加载表格数据
                this.pageIndex = 1;
                this.pageSize = this.count;
                this.loadPage();

                setTimeout(() => {

                    const XLSX = require('xlsx')
                    let fix = document.querySelector('.el-table__fixed');
                    let fileName = 'SalesData' + new Date().getTime() + '.xlsx'
                    let wb;
                    if (fix) { //判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
                        wb = XLSX.utils.table_to_book(document.querySelector('#SalesData').removeChild(fix));
                        document.querySelector('#SalesData').appendChild(fix);
                    } else {
                        wb = XLSX.utils.table_to_book(document.querySelector('#SalesData'));
                    }
                    let wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
                    try {
                        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName)
                    } catch (e) {
                        if (typeof console !== 'undefined') console.log(e, wbout)
                    }

                    this.pageSize = this.oldPageSize;
                    this.pageIndex = this.oldPageIndex;
                    this.loadPage();

                    return wbout
                }, 1000);
            },
            async initData() {
                //获取当前时间
                var now = new Date();
                var monthn = now.getMonth() + 1;
                var yearn = now.getFullYear();
                if (monthn < 10) {
                    monthn = "0" + monthn;
                }
                this.SalesDataReportForm.yearmonth = yearn + "-" + monthn;
                console.log(this.SalesDataReportForm.yearmonth);
                this.loadPage();
            },
            getCountrys(query, isAll = true) {
                this.SalesDataReportForm.isloading = true;
                GetCompanyList(null, 0, true).then(response => {
                    var infos = response.data.data;
                    if (response.data.code == 1 && infos != null) {
                        this.allCountrys = infos.map(function (m) {
                            return {
                                value: m.companyNumber,
                                label: m.companyName + "(" + m.companyNumber + ")"
                            }
                        });
                        this.allCountrys.unshift({
                            value: "",
                            label: this.$t('message.all')
                        });
                    }
                    this.SalesDataReportForm.isloading = false;
                })
                    .catch(error => {
                        console.log(error);
                        this.SalesDataReportForm.isloading = false;
                    });
            },

            seletCountryChange(val) {
                var obj = {}
                obj = this.allCountrys.find(function (i) {
                    return i.value === val
                });
                this.SalesDataReportForm.country = obj.value;
            },
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 2) {
                        //sums[index] = "Total";
                        sums[index] = this.$t('message.vueSDPReport.totalDataLebel');
                        return;
                    }

                    if (column.property !== undefined) {
                        var values = [];

                        if (column.property == "totalUploadQuantity" || column.property == "totalUploadAmount") {
                            values = data.map(item => Number(item[column.property]));
                        }
                        else {
                            if (column.property.includes('uploadQuantity')) {  //判断列是不是动态的uploadQuantity字段
                                const i = parseInt(column.property.split('up')[0]);  //把动态字段的0，1，2读取出来。
                                values = data.map(item => Number(item.SalesDataDetailSubs[i].UploadQuantity))
                            } else if (column.property.includes('uploadAmount')) {  //判断列是不是动态的uploadQuantity字段
                                const i = parseInt(column.property.split('up')[0]);  //把动态字段的0，1，2读取出来。
                                values = data.map(item => Number(item.SalesDataDetailSubs[i].UploadAmount))
                            }
                        }
                        //加了prop属性的el-table-column 才能找到column.property
                        if (!values.every(value => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return prev + curr; //多行相加
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] = sums[index].toFixed(2);
                        }
                    }
                });
                return sums;
            },
            getModel() {
                this.axios
                    .get('/api/ProductMod/getproductmodlist', {
                        params: {
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.modellist = infos;
                        }
                    })
            },
            getModelList(opt) {
                this.modellist = [];
                this.SalesDataReportForm.model = null;
                if (opt.id != "") {
                    this.axios
                        .get('/api/ProductMod/getproductmodlist2', {
                            params: {
                                modelLevel: opt,
                                modelType: this.SalesDataReportForm.modelType,
                                isAll: false
                            }
                        }).then(response => {
                            if (response.data.code == 1) {
                                var infos = response.data.data;
                                this.modellist = infos;
                            }
                        })
                }
            },
            getModelList2(opt) {
                this.modellist = [];
                this.SalesDataReportForm.model = null;
                if (opt.id != "") {
                    this.axios
                        .get('/api/ProductMod/getproductmodlist2', {
                            params: {
                                modelLevel: this.SalesDataReportForm.modelLevel,
                                modelType: opt,
                                isAll: false
                            }
                        }).then(response => {
                            if (response.data.code == 1) {
                                var infos = response.data.data;
                                this.modellist = infos;
                            }
                        })
                }
            },
            loadPage() {
                this.axios
                    .get('/api/SalesDataReport/GetReportPage', {
                        params: {
                            YearMonth: this.SalesDataReportForm.yearmonth,
                            Country: this.SalesDataReportForm.country,
                            Type: this.SalesDataReportForm.modelType == null ? "" : this.SalesDataReportForm.modelType,
                            ModelLevel: this.SalesDataReportForm.modelLevel == null ? "" : this.SalesDataReportForm.modelLevel,
                            Model: this.SalesDataReportForm.model == null ? "" : this.SalesDataReportForm.model.productMod,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        }
                    }).then(response => {
                        this.tableData = [];
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.count = info.length;
                            this.tableLabelOption.splice(0, this.tableLabelOption.length);
                            for (var z = 0; z < info[0].salesDataDetailSubs.length; z++) {
                                var date = new Date(info[0].salesDataDetailSubs[z].salesDataDate);
                                var dateday = date.getDay() + 1;
                                this.tableLabelOption.push({
                                    label: parseTime(info[0].salesDataDetailSubs[z].salesDataDate, '{m}月{d}日'),
                                    iType: z,
                                    weekNum: dateday,
                                });
                            }
                        }
                        for (var i = 0; i < info.length; i++) {
                            
                            this.addTableData(info[i].salesDataDetailSubs.length);
                            this.tableData[i].dataType = info[i].dataType;
                            this.tableData[i].modelLevel = info[i].modelLevel;
                            this.tableData[i].modelName = info[i].modelName;
                            var totaluploadQuantity = 0;
                            var totaluploadAmount = 0;
                            for (var j = 0; j < info[i].salesDataDetailSubs.length; j++) {
                                this.tableData[i].SalesDataDetailSubs[j].UploadQuantity = info[i].salesDataDetailSubs[j].uploadQuantity;
                                this.tableData[i].SalesDataDetailSubs[j].UploadAmount = (info[i].salesDataDetailSubs[j].uploadAmount).toFixed(2);
                                totaluploadQuantity = totaluploadQuantity + info[i].salesDataDetailSubs[j].uploadQuantity;
                                totaluploadAmount = totaluploadAmount + info[i].salesDataDetailSubs[j].uploadAmount;
                            }
                            this.tableData[i].totalUploadQuantity = totaluploadQuantity;
                            this.tableData[i].totalUploadAmount = totaluploadAmount.toFixed(2);
                        }
                        //this.tableData.splice(i, 1);
                    })
            },
            addTableData(datalength) {
                var tempdata = [];

                for (var i = 0; i < datalength; i++) {
                    tempdata.push({
                        SalesDataDate: new Date(),
                        UploadQuantity: 0,
                        UploadAmount: 0.0,
                    })

                }
                this.tableData.push({
                    dataType: "",
                    modelLevel: "",
                    modelName: "",
                    totalUploadQuantity: 0,
                    totalUploadAmount: 0.0,
                    SalesDataDetailSubs: tempdata,
                });
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            }
        },
        mounted() {
            this.getModel();
            this.initData();
        },
        created() {
            this.getCountrys();
        },
        watch: {
            $route() {
                //响应路由参数的变化
                this.submitSearch();
            }
        }
    };
</script>