<template>
    <div class="workflow">
        <el-row class="workflow-top">
            <el-col :span="24">
                <el-button type="primary" size="small" class="button_submit"
                           @click="submitForm('ruleForm')"
                           :disabled="disabledSubmit"
                           :loading="loadingSubmit"
                           v-loading.fullscreen.lock="fullscreenLoading">{{$t('message.submit')}}</el-button>
                <el-button size="small" @click="returnForm()" class="button_return">{{$t('message.return')}}</el-button>
            </el-col>
        </el-row>
        <el-divider></el-divider>
        <div class="workflow-content" v-if="fullscreenLoading==false">
            <div class="flow-col">
                <FlowAddOrEditSub :items="workFlowList" :wheres="wheres" />
                <div class="flow-row">
                    <div class="flow-end">
                        <i class="flow-icon-end"></i>
                        <span>{{$t('message.vueWorkFlow.nodeEnd')}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FlowAddOrEditSub from "./FlowAddOrEditSub.vue";
    import AppStorage from '@/common/storage/app-storage';
    export default {
        name: 'FlowAddOrEdit',
        components: { FlowAddOrEditSub },
        data() {
            return {
                fullscreenLoading: false,
                templateId: 0,
                userNumber: AppStorage.getNumber(),
                disabledSubmit: false,
                loadingSubmit: false,
                workFlowList: null,
                wheres: {
                    whereTypeApproval: [{
                        value: 1,
                        label: this.$t('message.vueWorkFlow.whereType1')
                    }, {
                        value: 2,
                        label: this.$t('message.vueWorkFlow.whereType2')
                    }, {
                        value: 3,
                        label: this.$t('message.vueWorkFlow.whereType3')
                    }, {
                        value: 4,
                        label: this.$t('message.vueWorkFlow.whereType4')
                    }],
                    whereTypeCC: [{
                        value: 2,
                        label: this.$t('message.vueWorkFlow.whereType2')
                    }],
                    whereTypeCondition: [{
                        value: 1001,
                        label: this.$t('message.vueWorkFlow.whereType1001')
                    }, {
                        value: 1002,
                        label: this.$t('message.vueWorkFlow.whereType1002')
                    }, {
                        value: 1003,
                        label: this.$t('message.vueWorkFlow.whereType1003')
                    }, {
                        value: 1004,
                        label: this.$t('message.vueWorkFlow.whereType1004')
                    }, {
                        value: 1005,
                        label: this.$t('message.vueWorkFlow.whereType1005')
                    }, {
                        value: 1006,
                        label: this.$t('message.vueWorkFlow.whereType1006')
                    }, {
                        value: 1007,
                        label: this.$t('message.vueWorkFlow.whereType1007')
                    }, {
                        value: 1008,
                        label: this.$t('message.vueWorkFlow.whereType1008')
                    }, {
                        value: 1009,
                        label: this.$t('message.vueWorkFlow.whereType1009')
                    }, {
                        value: 1010,
                        label: this.$t('message.vueWorkFlow.whereType1010')
                    }, {
                        value: 1011,
                        label:this.$t('message.vueWorkFlow.whereType1011')
                    }, {
                        value: 1012,
                        label: this.$t('message.vueWorkFlow.whereType1012')
                    }, {
                        value: 1013,
                        label: this.$t('message.vueWorkFlow.whereType1013')
                    }, {
                        value: 1014,
                        label: this.$t('message.vueWorkFlow.whereType1014')
                    }],
                    whereGrades: [{
                        value: 1,
                        label: this.$t('message.vueUser.whereGrades1')
                    }, {
                        value: 2,
                        label: this.$t('message.vueUser.whereGrades2')
                    }, {
                        value: 3,
                        label: this.$t('message.vueUser.whereGrades3')
                    }, {
                        value: 4,
                        label: this.$t('message.vueUser.whereGrades4')
                    }, {
                        value: 5,
                        label: this.$t('message.vueUser.whereGrades5')
                    }, {
                        value: 6,
                        label: this.$t('message.vueUser.whereGrades6')
                    }, {
                        value: 7,
                        label: this.$t('message.vueUser.whereGrades7')
                    }, {
                        value: 8,
                        label: this.$t('message.vueUser.whereGrades8')
                    }, {
                        value: 9,
                        label: this.$t('message.vueUser.whereGrades9')
                    }, {
                        value: 10,
                        label: this.$t('message.vueUser.whereGrades10')
                    }, {
                        value: 11,
                        label: this.$t('message.vueUser.whereGrades11')
                    }, {
                        value: 12,
                        label: this.$t('message.vueUser.whereGrades12')
                    }, {
                        value: 13,
                        label: this.$t('message.vueUser.whereGrades13')
                    }, {
                        value: 14,
                        label: this.$t('message.vueUser.whereGrades14')
                    }],
                    whereRanks: [{
                        value: 1,
                        label: this.$t('message.vueUser.whereRanks1')
                    }, {
                        value: 2,
                        label: this.$t('message.vueUser.whereRanks2')
                    }, {
                        value: 3,
                        label: this.$t('message.vueUser.whereRanks3')
                    }, {
                        value: 4,
                        label: this.$t('message.vueUser.whereRanks4')
                    }, {
                        value: 5,
                        label: this.$t('message.vueUser.whereRanks5')
                    }, {
                        value: 6,
                        label: this.$t('message.vueUser.whereRanks6')
                    }, {
                        value: 7,
                        label: this.$t('message.vueUser.whereRanks7')
                    }, {
                        value: 8,
                        label: this.$t('message.vueUser.whereRanks8')
                        }, {
                            value: 9,
                            label: this.$t('message.vueUser.whereRanks9')
                        }, {
                            value: 10,
                            label: this.$t('message.vueUser.whereRanks10')
                        }, {
                            value: 11,
                            label: this.$t('message.vueUser.whereRanks11')
                        }, {
                            value: 12,
                            label: this.$t('message.vueUser.whereRanks12')
                        }, {
                            value: 13,
                            label: this.$t('message.vueUser.whereRanks13')
                        }, {
                            value: 14,
                            label: this.$t('message.vueUser.whereRanks14')
                        }, {
                            value: 15,
                            label: this.$t('message.vueUser.whereRanks15')
                        }, {
                            value: 16,
                            label: this.$t('message.vueUser.whereRanks16')
                        }, {
                            value: 17,
                            label: this.$t('message.vueUser.whereRanks17')
                        }, {
                            value: 18,
                            label: this.$t('message.vueUser.whereRanks18')
                        }, {
                            value: 19,
                            label: this.$t('message.vueUser.whereRanks19')
                        }, {
                            value: 20,
                            label: this.$t('message.vueUser.whereRanks20')
                        }],
                    whereUsers: [],
                    whereDepartments: [],
                    whereCompanys: []
                },
            };
        },
        methods: {
            getInfo() {
                var templateId = parseInt(this.$route.query.id);
                this.templateId = templateId;
                if (isNaN(this.$route.query.id) || templateId === 0) {
                    return;
                }
                this.fullscreenLoading = true;
                this.axios
                    .get('/api/workflow/getflowbytemplateid', {
                        params: {
                            templateId: templateId
                        }
                    })
                    .then(response => {
                        this.fullscreenLoading = false;
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.workFlowList = this.toWorkFlowList(null, info.workFlowList, info.whereList);
                        }
                    }).catch(error => {
                        console.log(error);
                        this.fullscreenLoading = false;
                    });
            },
            toWorkFlowList(tempflows, flows, wheres, pCode = 'C') {
                if (flows.length == 0) {
                    return this.workFlowList;
                }
                var that=this;
                if (pCode == 'C') {
                    tempflows = flows.filter((f) => {
                        return f.parentCode == 'C';
                    }).map(function (m) {
                        return {
                            code: m.code,
                            type: m.type,
                            title: that.translateTitle(m.title),
                            parentCode: m.parentCode,
                            drawer: m.drawer,
                            subWorkFlowList: null,
                            whereList: null,
                        }
                    });
                }
                for (let i = 0; i < tempflows.length; i++) {
                    var temp = tempflows[i];
                    var ws = wheres.filter((f) => { return f.parentCode == temp.code; });
                    if (ws.length > 0) {
                        tempflows[i].whereList = ws.map(function (m) {
                            return {
                                isloading: m.isloading,
                                parentCode: m.parentCode,
                                whereType: m.whereType,
                                whereUsers: m.whereUsers == null || m.whereUsers == '' ? '' : m.whereUsers.split(','),
                                whereDepartments: m.whereDepartments == null || m.whereDepartments == '' ? '' : m.whereDepartments.split(','),
                                whereCompanys: m.whereCompanys == null || m.whereCompanys == '' ? '' : m.whereCompanys.split(','),
                                whereMaxGrade: m.whereMaxGrade,
                                whereMaxRank: m.whereMaxRank,
                                whereGrades: m.whereGrades == null || m.whereGrades == '' ? '' : m.whereGrades.split(',').map(Number),
                                whereRanks: m.whereRanks == null || m.whereRanks == '' ? '' : m.whereRanks.split(',').map(Number),
                                whereIsAddUser: m.whereIsAddUser
                            }
                        });
                    }
                    var fs = flows.filter((f) => { f.title= that.translateTitle(f.title); return f.parentCode == temp.code; });
                    if (fs.length > 0) {
                        tempflows[i].subWorkFlowList = this.toWorkFlowList(fs, flows, wheres, temp.code);
                    }
                }
                return tempflows;
            },
            toFlowAndWhere(temps, all = null) {
                var workFlowTemplateId = this.templateId;
                if (temps == null || this.workFlowList.length <= 1 || workFlowTemplateId == 0) {
                    return all;
                }
                if (all == null) {
                    all = { flows: [], wheres: [] };
                }
                var that = this;
                for (let i = 0; i < temps.length; i++) {
                    all.flows.push({
                        WorkFlowTemplateId: workFlowTemplateId,
                        Code: temps[i].code,
                        Type: temps[i].type,
                        Title: that.translateTitle(temps[i].title,true),
                        ParentCode: temps[i].parentCode,
                        Drawer: temps[i].drawer,
                    });
                    if (temps[i].whereList != null && temps[i].whereList.length > 0) {
                        var w = temps[i].whereList.map(function (item) {
                            return {
                                WorkFlowTemplateId: workFlowTemplateId,
                                Isloading: item.isloading,
                                ParentCode: item.parentCode,
                                WhereType: item.whereType,
                                WhereUsers: item.whereUsers?.toString(),
                                WhereDepartments: item.whereDepartments?.toString(),
                                WhereCompanys: item.whereCompanys?.toString(),
                                WhereMaxGrade: item.whereMaxGrade,
                                WhereMaxRank: item.whereMaxRank,
                                WhereGrades: item.whereGrades?.toString(),
                                WhereRanks: item.whereRanks?.toString(),
                                WhereIsAddUser: item.whereIsAddUser
                            }
                        });
                        all.wheres = all.wheres.concat(w);
                    }
                    if (temps[i].subWorkFlowList != undefined && temps[i].subWorkFlowList != 'undefined' && temps[i].subWorkFlowList != null && temps[i].subWorkFlowList.length > 0) {
                        all = this.toFlowAndWhere(temps[i].subWorkFlowList, all);
                    }
                }
                return all;
            },
            submitForm() {
                this.disabledSubmit = true;
                this.loadingSubmit = true;
                this.fullscreenLoading = true;
                var workFlows = this.toFlowAndWhere(this.workFlowList);
                if (workFlows == null) {
                    this.$message.error(this.$t('message.vueWorkFlow.msgSetFolwAddBranch'));
                    this.disabledSubmit = false;
                    this.loadingSubmit = false;
                    this.fullscreenLoading = false;
                    return;
                }
                this.axios
                    .post('/api/workflow/setflow', {
                        Id: this.templateId,
                        UserNumber: this.userNumber,
                        WorkFlowList: workFlows.flows,
                        WhereList: workFlows.wheres,
                    })
                    .then(response => {
                        if (response.data.code != 1) {
                            var msg = response.data.msg.replace('账号不存在', this.$t('message.vueWorkFlow.msgAccountNotExist'))
                                .replace('账号已禁用', this.$t('message.vueWorkFlow.msgAccountIsDisabled'))
                                .replace('模板不存在', this.$t('message.vueWorkFlow.msgTemplateNotExist'));
                            this.$message.error(msg);
                        } else {
                            this.$message.success(this.$t('message.success'));
                            this.$router.push("/WorkFlowTemplateList");
                        }
                        this.disabledSubmit = false;
                        this.loadingSubmit = false;
                        this.fullscreenLoading = false;
                    }).catch(error => {
                        console.log(error);
                        this.$message.error(this.$t('message.messageBox.connecterror'));
                        this.disabledSubmit = false;
                        this.loadingSubmit = false;
                        this.fullscreenLoading = false;
                    });
            },
            //////////////////////////////
            returnForm() {
                this.$router.go(-1);
            },
            translateTitle(title, isZhCn = false) {
                if (isZhCn) {
                    return title.replace(this.$t('message.vueWorkFlow.nodeApplicant'), '申请人')
                        .replace(this.$t('message.vueWorkFlow.nodeApproval'), '审批人')
                        .replace(this.$t('message.vueWorkFlow.nodeConditionBranch'), '条件分支')
                        .replace(this.$t('message.vueWorkFlow.nodeCondition'), '条件')
                        .replace(this.$t('message.vueWorkFlow.nodeMergeBranch'), '合并分支')
                        .replace(this.$t('message.vueWorkFlow.nodeCC'), '抄送');
                } else {
                    return title.replace('申请人', this.$t('message.vueWorkFlow.nodeApplicant'))
                        .replace('审批人', this.$t('message.vueWorkFlow.nodeApproval'))
                        .replace('条件分支', this.$t('message.vueWorkFlow.nodeConditionBranch'))
                        .replace('条件', this.$t('message.vueWorkFlow.nodeCondition'))
                        .replace('合并分支', this.$t('message.vueWorkFlow.nodeMergeBranch'))
                        .replace('抄送', this.$t('message.vueWorkFlow.nodeCC'));
                }
            },
            removeModule(pCode) {
                this.workFlowList = this.delItem(this.workFlowList, pCode);
            },
            addModule(pCode, type = 1) {
                this.workFlowList = this.addItem(this.workFlowList, pCode, type);
            },
            delItem(temps, itemCode) {
                for (let i = 0; i < temps.length; i++) {
                    if (temps[i].code == itemCode) {
                        temps.splice(i, 1);
                        return temps;
                    }
                    if (temps[i].subWorkFlowList != null && temps[i].subWorkFlowList.length > 0) {
                        temps[i].subWorkFlowList = this.delItem(temps[i].subWorkFlowList, itemCode);
                        if (temps[i].type == 2 && temps[i].subWorkFlowList.length < 2) {
                            temps.splice(i, 2);
                        }
                    }
                }
                return temps;
            },
            addItem(temps, itemCode, type) {
                if (this.workFlowList == null || this.workFlowList.length == 0) {
                    temps = [{
                        code: 'C-1',
                        type: 1,
                        title: this.$t('message.vueWorkFlow.nodeApplicant'),
                        parentCode: 'C',
                        drawer: false,
                        subWorkFlowList: null,
                        whereList: null,
                    }];
                    return temps;
                }
                for (let i = 0; i < temps.length; i++) {
                    var temp = null;
                    var temp2 = null;
                    var pCode = temps[i].parentCode;
                    if (temps[i].code == itemCode) {

                        let num = parseInt(temps[i].code.substring(temps[i].code.length - 1, temps[i].code.length)) + 1;
                        let numMerge = num + 1;
                        let code = pCode + '-' + num;
                        let codeMerge = pCode + '-' + numMerge;
                        switch (type) {
                            case 1:
                                temp = {
                                    code: code,
                                    type: 1,
                                    title: this.$t('message.vueWorkFlow.nodeApproval'),
                                    parentCode: pCode,
                                    drawer: false,
                                    subWorkFlowList: null,
                                    whereList: [{
                                        isloading: false,
                                        parentCode: code,
                                        whereType: 1,
                                        whereUsers: [],
                                        whereDepartments: [],
                                        whereCompanys: [],
                                        whereMaxGrade: null,
                                        whereMaxRank: null,
                                        whereGrades: [],
                                        whereRanks: [],
                                        whereIsAddUser: true
                                    }],
                                };
                                break;
                            case 2:
                                temp = {
                                    code: code,
                                    type: 2,
                                    title: this.$t('message.vueWorkFlow.nodeConditionBranch'),
                                    parentCode: pCode,
                                    subWorkFlowList: [
                                        { code: code + '-1', type: 3, title: this.$t('message.vueWorkFlow.nodeCondition'), parentCode: code, drawer: false, subWorkFlowList: null, whereList: null, },
                                        { code: code + '-2', type: 3, title: this.$t('message.vueWorkFlow.nodeCondition'), parentCode: code, drawer: false, subWorkFlowList: null, whereList: null, },
                                    ],
                                    whereList: null,
                                };
                                temp2 = {
                                    code: codeMerge,
                                    type: 4,
                                    title: this.$t('message.vueWorkFlow.nodeMergeBranch'),
                                    parentCode: pCode,
                                    subWorkFlowList: null,
                                    whereList: null,
                                };
                                break;
                            case 3:
                                temp = {
                                    code: temps[i].code + '-1',
                                    type: 3,
                                    title: this.$t('message.vueWorkFlow.nodeCondition'),
                                    parentCode: temps[i].code,
                                    subWorkFlowList: null,
                                    whereList: null,
                                };
                                break;
                            case 5:
                                temp = {
                                    code: code,
                                    type: 5,
                                    title: this.$t('message.vueWorkFlow.nodeCC'),
                                    parentCode: pCode,
                                    drawer: false,
                                    subWorkFlowList: null,
                                    whereList: [{
                                        isloading: false,
                                        parentCode: code,
                                        whereType: 2,
                                        whereUsers: [],
                                        whereDepartments: [],
                                        whereCompanys: [],
                                        whereMaxGrade: null,
                                        whereMaxRank: null,
                                        whereGrades: [],
                                        whereRanks: [],
                                        whereIsAddUser: true
                                    }],
                                };
                                break;
                        }
                        if (temps[i].type == 2) {
                            temps[i].subWorkFlowList.splice(0, 0, temp);
                        }
                        else if (temps[i].type == 3) {
                            if (temps[i].subWorkFlowList == null || temps[i].subWorkFlowList.length == 0) {
                                temps[i].subWorkFlowList = [];
                            }
                            temps[i].subWorkFlowList.splice(0, 0, temp);
                            if (temp2 != null) {
                                temps[i].subWorkFlowList.splice(1, 0, temp2);
                            }
                        }
                        else {
                            temps.splice(num - 1, 0, temp);
                            if (temp2 != null) {
                                temps.splice(numMerge - 1, 0, temp2);
                            }
                        }
                    }
                    if (temps[i].subWorkFlowList != null && temps[i].subWorkFlowList.length > 0) {
                        temps[i].subWorkFlowList = this.addItem(temps[i].subWorkFlowList, itemCode, type);
                    }
                }
                temps = this.sortItem(temps, pCode);
                return temps;
            },
            sortItem(temps, pCode) {
                for (let i = 0; i < temps.length; i++) {
                    var newCode = pCode + '-' + (i + 1);
                    temps[i].code = newCode;
                    temps[i].parentCode = pCode;
                    if (temps[i].whereList != null && temps[i].whereList.length > 0) {
                        temps[i].whereList = temps[i].whereList.map(function (m) {
                            m.parentCode = newCode;
                            return m;
                        });
                    }
                    if (temps[i].subWorkFlowList != null && temps[i].subWorkFlowList.length > 0) {
                        temps[i].subWorkFlowList = this.sortItem(temps[i].subWorkFlowList, newCode);
                    }
                }
                return temps;
            },
            removeWhere(whereList, index) {
                whereList.splice(index, 1);
            },
            addWhere(itemCode) {
                this.workFlowList = this.addWhereList(this.workFlowList, itemCode);
            },
            addWhereList(temps, itemCode) {
                for (let i = 0; i < temps.length; i++) {
                    var temp = null;
                    if (temps[i].code == itemCode) {
                        temp = {
                            isloading: false,
                            parentCode: itemCode,
                            whereType: 1001,
                            whereUsers: [],
                            whereDepartments: [],
                            whereCompanys: [],
                            whereMaxGrade: null,
                            whereMaxRank: null,
                            whereGrades: [],
                            whereRanks: [],
                            whereIsAddUser: true
                        };
                        if (temps[i].whereList == null || temps[i].whereList.length == 0) {
                            temps[i].whereList = [];
                        }
                        temps[i].whereList.push(temp);
                        return temps;
                    }
                    if (temps[i].subWorkFlowList != null && temps[i].subWorkFlowList.length > 0) {
                        temps[i].subWorkFlowList = this.addWhereList(temps[i].subWorkFlowList, itemCode);
                    }
                }
                return temps;
            },
            getUsers(query, where, isAll = true) {
                where.isloading = true;
                this.axios
                    .get('/api/user/getuserlist', {
                        params: {
                            companyId: null,
                            departmentId: null,
                            keyword: query,
                            isAll: isAll 
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.wheres.whereUsers = infos.map(function (m) {
                                return {
                                    value: m.number,
                                    label: m.name + "(" + m.number+")"
                                }
                            });
                        }
                        where.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        where.isloading = false;
                    });
            },
            getDepartments(query, where, isAll = true) {
                where.isloading = true;
                this.axios
                    .get('/api/department/getdepartmentlist', {
                        params: {
                            companyId: null,
                            keyword: query,
                            userNumber: AppStorage.getNumber(),
                            isAll: isAll
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.wheres.whereDepartments = infos.map(function (m) {
                                return {
                                    value: m.departmentNumber,
                                    label: m.departmentName + "(" + m.departmentNumber + ")"
                                }
                            });
                        }
                        where.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        where.isloading = false;
                    });
            },
            getCompanys(query, where, isAll = true) {
                where.isloading = true;
                this.axios
                    .get('/api/company/getcompanylist', {
                        params: {
                            userNumber: AppStorage.getNumber(),
                            keyword: query,
                            isAll: isAll
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.wheres.whereCompanys = infos.map(function (m) {
                                return {
                                    value: m.companyNumber,
                                    label: m.companyName + "(" + m.companyNumber + ")"
                                }
                            });
                        }
                        where.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        where.isloading = false;
                    });
            },
            getContent(whereList) {
                var strWhere = '';
                var whereType = this.wheres.whereTypeApproval.concat(this.wheres.whereTypeCondition);
                if (whereList == null || whereList.length == 0) {
                    return strWhere;
                }
                for (let i = 0; i < whereList.length; i++) {
                    strWhere += whereType.find(f => f.value == whereList[i].whereType).label;
                    switch (whereList[i].whereType) {
                        case 3: case 4:
                            var grade1 = this.wheres.whereGrades.find(f => f.value == whereList[i].whereMaxGrade);
                            var rank1 = this.wheres.whereRanks.find(f => f.value == whereList[i].whereMaxRank);
                            strWhere += ':';
                            if (grade1 != undefined && grade1 != 'undefined') {
                                strWhere += grade1.label;
                            }
                            strWhere += ',';
                            if (rank1 != undefined && rank1 != 'undefined') {
                                strWhere += rank1.label;
                            }
                            break;
                        case 2: case 1001: case 1002: case 1007: case 1008: case 1009: case 1010:
                            var user = this.wheres.whereUsers.find(f => whereList[i].whereUsers.indexOf(f.value) > -1);
                            if (user != undefined && user != 'undefined') {
                                strWhere += ':' + user.label;
                            }
                            if (whereList[i].whereUsers.length > 1) {
                                strWhere += '...';
                            }
                            break;
                        case 1003: case 1004:
                            var department = this.wheres.whereDepartments.find(f => whereList[i].whereDepartments.indexOf(f.value) > -1);
                            if (department != undefined && department != 'undefined') {
                                strWhere += ':' + department.label;
                            }
                            if (whereList[i].whereDepartments.length > 1) {
                                strWhere += '...';
                            }
                            break;
                        case 1005: case 1006:
                            var company = this.wheres.whereCompanys.find(f => whereList[i].whereCompanys.indexOf(f.value) > -1);
                            if (company != undefined && company != 'undefined') {
                                strWhere += ':' + company.label;
                            }
                            if (whereList[i].whereCompanys.length > 1) {
                                strWhere += '...';
                            }
                            break;
                        case 1011: case 1012:
                            var grade = this.wheres.whereGrades.find(f => whereList[i].whereGrades.indexOf(f.value) > -1);
                            if (grade != undefined && grade != 'undefined') {
                                strWhere += ':' + grade.label;
                            }
                            if (whereList[i].whereGrades.length > 1) {
                                strWhere += '...';
                            }
                            break;
                        case 1013: case 1014:
                            var rank = this.wheres.whereRanks.find(f => whereList[i].whereRanks.indexOf(f.value) > -1);
                            if (rank != undefined && rank != 'undefined') {
                                strWhere += ':' + rank.label;
                            }
                            if (whereList[i].whereRanks.length > 1) {
                                strWhere += '...';
                            }
                            break;
                        default:
                    }
                    strWhere += ";";
                }
                return strWhere;
            },
        },
        mounted() {
            this.addModule('C1', 1);
            this.getUsers('', { isloading: false }, true);
            this.getDepartments('', { isloading: false }, true);
            this.getCompanys('', { isloading: false }, true);
            this.getInfo();
        },
    }
</script>
<style>
    .workflow .workflow-content {
        display: flex;
        width: 100%;
        height: 100%;
        margin:0 auto;
    }
    .workflow .el-card__header {
        padding: 0 20px;
    }
    .workflow .box-card {
        width: 200px;
        height:100px;
    }
    .workflow .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size:14px;
    }
    .workflow .el-card__body {
        padding: 10px 20px;
        font-size: 14px;
    }
    .workflow .flow-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    .workflow .flow-col.col-box {
        position: relative;
        padding: 50px 50px 0;
        border-top: solid 2px #C0C4CC;
        border-bottom: solid 2px #C0C4CC;
    }
    .workflow .flow-col.col-box .box-middle-line {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 2px;
        height: 100%;
        background-color: #C0C4CC;
        z-index: -1;
    }
    .workflow .flow-col.col-box .box-right-line {
        position: absolute;
        top: 0;
        right: -2px;
        width: 4px;
        height: 100%;
        background-color: #fff;
    }
    .workflow .flow-col.col-box:first-child:before {
        content: "";
        position: absolute;
        height: 3px;
        width: 50%;
        background-color: #fff;
        top: -2px;
        left: -1px;
    }
     .workflow .flow-col.col-box:first-child:after {
        content: "";
        position: absolute;
        height: 3px;
        width: 50%;
        background-color: #fff;
        bottom: -2px;
        left: -1px;
    }
      .workflow .flow-col.col-box:last-child:before {
        content: "";
        position: absolute;
        height: 3px;
        width: 50%;
        background-color: #fff;
        top: -2px;
        right: -1px;
    }
     .workflow .flow-col.col-box:last-child:after {
        content: "";
        position: absolute;
        height: 3px;
        width: 50%;
        background-color: #fff;
        bottom: -2px;
        right: -1px;
    }
    .workflow .branch-add {
        position: absolute;
        top: -16px;
        left: -40px;
        z-index: 10;
    }
    .workflow .branch-del {
        color: #F56C6C;
    }
    .workflow .flow-row {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
    }
    .workflow .flow-row:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 2px;
        height: 100%;
        background-color: #fff;
        z-index: -1;
    }
    .workflow .flow-line {
        width: 200px;
        height: 100px;
        position: relative;
    }
    .workflow .flow-line:before {
       content: "";
       position: absolute;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       z-index: -1;
       margin: auto;
       width: 2px;
       height: 100%;
       background-color: #C0C4CC;
    }
    .workflow .flow-plus {
        display: block;
        width: 28px;
        height: 28px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;
    }
    .flow-plus .btn-plus{
        width: 28px;
        height: 28px;
        padding:0;
    }
    .workflow .flow-end {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .workflow .flow-icon-end {
        content: "";
        background-color: #C0C4CC;
        border-radius: 20px;
        width:20px;
        height: 20px;
    }
    .workflow .branch-del {
        padding-top: 5px;
        padding-bottom: 5px;
        min-height: 30px;
    }
    .workflow .border-condition {
        border: solid 1px #c2e7b0;
    }
    .workflow .border-approval {
        border: solid 1px #409EFF;
    }
    .workflow .border-cc {
        border: solid 1px #909399;
    }
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }
    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>