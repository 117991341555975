<template>
    <div id="msgbox">
        <el-dialog v-model="dialogTableVisible"
                   width="100%" @opened="openDialog">
            
            <el-table ref="multipleTable"
                      :data="tableData"
                      tooltip-effect="dark"
                      style="width: 100%"
                      size="mini" border>
                <el-table-column prop="JobActionType" :label="$t('message.vueTaskOptions.JobActionType')"
                                 width="130">
                    <template #default="scope">
                        <span>{{scope.row.jobActionType}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="TaskOptions" :label="$t('message.vueTaskOptions.TaskOptions')"
                                 width="130">
                    <template #default="scope">
                        <span>{{scope.row.taskOptions}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="RunTime" :label="$t('message.vueTaskOptions.RunTime')"
                                 width="130">
                    <template #default="scope">
                        <span>{{scope.row.runTime}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Status" :label="$t('message.vueTaskOptions.Status')"
                                 width="130">
                    <template #default="scope">
                        <span>{{scope.row.status}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               :current-page="pageIndex"
                               :page-sizes="[10, 25, 50, 75, 100]"
                               :page-size="pageSize"
                               layout="total, sizes, prev, pager, next, jumper"
                               :total="count">
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: '',
        data() {
            return {
                tableData: null,
                language: AppStorage.getLanguage(),
                
                pageIndex: 1,
                pageSize: 10,
                count: 0,
                dialogTableVisible: false,
            }
        },
        methods: {
            loadPage() {
                this.axios
                    .get('/api/TaskOptions/GetTaskLogByTaskOptionsId', {
                        params: {
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        }
                    }) .then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.tableData = infos.items;
                            this.count = infos.count;
                        }
                    })
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            init() {
                this.dialogTableVisible = true;
            }
        },
        mounted() {
            this.submitSearch();
        },
        watch: {
            $route() {
                //响应路由参数的变化
                this.submitSearch();
            }
        }

    };
</script>
