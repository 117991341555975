<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/SalesDataList' }">{{$t('message.vueSales.SalesData')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="tableData.id==0">{{$t('message.add')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-else>{{$t('message.edit')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="SalesDataForm" :model="SalesDataForm"
             :rules="rules" label-width="100px" size="small" class="demo-ruleForm">
        <el-row>
            <el-col :span="4">
                <el-form-item :label="$t('message.vueSales.datePeriod')" prop="datePeriod">
                    <el-input v-model="SalesDataForm.datePeriod" :disabled="dis"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="7">
                <el-form-item :label="$t('message.vueSales.country')" prop="country">
                    <el-select v-model="SalesDataForm.country" filterable remote reserve-keyword
                               :placeholder="$t('message.pleaseSelect')" @change="seletCountryChange" :disabled="dis"
                               :remote-method="(query)=>{ getCountrys(query) }" :loading="SalesDataForm.isloading" style="width:220px">
                        <el-option v-for="item in allCountrys" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row v-if="SalesDataForm.rules == '1'">
            <el-col :span="7">
                <el-form-item label-width="25px" v-if="!operate">
                    <el-button class="el-icon-plus" type="primary" @click="addForm('SalesDataForm')" sizi="mini">{{$t('message.vueSales.btnAddForm')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row v-if="SalesDataForm.rules == '1'">
            <el-col :span="24">
                <el-table :data="tableData" border style="width: 100%" size="mini" fit :header-cell-style="columnbackgroundStyle">
                    <el-table-column label="操作" align="center" width="100" fixed="left">
                        <template #default="scope">
                            <el-button size="mini" type="danger" @click="delProduct(scope.$index)" class="button_delete">{{$t('message.delete')}}</el-button>
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.vueSales.indexNo')" prop=""
                                     :show-overflow-tooltip="false" align="left" width="50px" fixed="left">
                        <template #default="scope">
                            <span>{{scope.$index+1}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="productCode" :label="$t('message.vueSales.product')"
                                     align="center" :show-overflow-tooltip="false" width="280px" fixed="left">
                        <template #default="scope">
                            <el-input v-model="scope.row.productCode" @click="examineBtn(scope.row)" readonly></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column v-for="(item, index) in tableLabelOption"
                                     :key="index"
                                     :label="item.label" :prop="item.iType"
                                     align="center" width="200px">
                        <el-table-column :label="$t('message.vueSales.uploadQuantity')+''" prop="uploadQuantity"
                                         width="80px">
                            <template #default="scope">
                                <el-input v-model="scope.row.SalesDataDetailSubs[item.iType].UploadQuantity"
                                          :disabled="operate"
                                          :ref="'Today'+scope.$index+item.iType">
                                    {{scope.row.SalesDataDetailSubs[item.iType].UploadQuantity}}
                                </el-input>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="IsDisabled" :label="$t('message.vueSales.isDisabled')" property="isDisabled" align="center" width="160px">
                        <template #default="scope">
                            <el-tooltip :content="$t('message.vueSales.isDisabled')" placement="top" style="margin-right:10px;">
                                <el-switch v-model="scope.row.IsDisabled" active-color="#ff4949" inactive-color="#13ce66"
                                           :disabled="operate" :loading="scope.row.isLoading" :before-change="()=>changeIsDisabled(scope.row)"></el-switch>
                            </el-tooltip>
                            <span v-if="scope.row.isDisabled===false">
                                {{$t('message.vueSales.IsDisabledNo')}}
                            </span>
                            <span v-else>
                                {{$t('message.vueSales.IsDisabledYes')}}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>

        <el-row v-if="SalesDataForm.rules == '2'">
            <el-col :span="24">
                <div class="cc-calendar">
                    <ul class="calendar-week">
                        <li v-for="(item, index) in calendarTitleArr" :key="index" class="week-item">{{item}}</li>
                    </ul>
                    <ul class="calendar-view" v-for="(item, index) in calendatArr" :key="index">
                        <li v-for="(itemdetail, indexdetail) in calendatArr[index].SalesDataDetailSubs"
                            :key="indexdetail"
                            class="date-view"
                            :class="[
                            {'month-class': !isCurrentMonth(itemdetail.DataDate)},
                            {todayBg: isCurrentDay(itemdetail.DataDate)},
                            {handleDay: itemdetail.ClickDay}
                            ]"
                            @click="handleClickDay(itemdetail)">
                            <span class="date-day"
                                  :style="dayStyle"
                                  :class="[{'opacity-class': !isCurrentMonth(itemdetail.DataDate)}]">
                                {{itemdetail.day}}
                            </span>

                            <el-form-item :label="$t('message.vueSDPReport.corporateLabel')+' :'"
                                          prop="OnlineAmount" v-if="isCurrentMonth(itemdetail.DataDate)">
                                <el-input v-model="itemdetail.OnlineAmount" v-if="isCurrentMonth(itemdetail.DataDate)"
                                          :text-align="right" style="width: 90%" @blur="getAmout"
                                          :disabled="dis">{{itemdetail.OnlineAmount}}</el-input>
                            </el-form-item>

                            <el-form-item :label="$t('message.vueSDPReport.offlineLabel')+' :'"
                                          prop="RetailAmount" v-if="isCurrentMonth(itemdetail.DataDate)">
                                <el-input v-model="itemdetail.RetailAmount" v-if="isCurrentMonth(itemdetail.DataDate)"
                                          :text-align="right" style="width: 90%" @blur="getAmout"
                                          :disabled="dis">{{itemdetail.RetailAmount}}</el-input>
                            </el-form-item>
                        </li>
                    </ul>
                </div>
            </el-col>
        </el-row>

        <el-row v-if="SalesDataForm.rules == '2'">
            <el-col :span="24" class="totalData">
                <el-form-item :label="$t('message.vueSDPReport.totalCorporateData')" prop="totalOnline">
                    <el-input v-model="SalesDataForm.totalOnline" style="width:100px" readonly></el-input>
                </el-form-item>
                <el-form-item :label="$t('message.vueSDPReport.totalOfflineData')" prop="totalRetail">
                    <el-input v-model="SalesDataForm.totalRetail" style="width: 100px" readonly></el-input>
                </el-form-item>

                <el-form-item :label="$t('message.vueSDPReport.totalDataLebel')" prop="totalData">
                    <el-input v-model="SalesDataForm.totalData" style="width: 100px" readonly></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="2">
                <el-form-item>
                    <el-button @click="submitForm('SalesDataForm',1)"
                               :disabled="disabledSubmit || operate" size="max" class="button_submit">
                        {{$t('message.save')}}
                    </el-button>
                </el-form-item>
            </el-col>
            <el-col :span="2">
                <el-form-item>
                    <el-button @click="returnForm()" size="mini" class="button_return">{{$t('message.return')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
        <ProductModSelect ref="msgBtn" :loadTimesModel="loadTimesProduct" v-on:getProductsEven="getProduct"></ProductModSelect>
    </el-form>
</template>

<style>
    .el-form .el-select {
        width: 100%;
    }

    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }

    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }


    .totalData {
        font-size: 20px;
    }

    .cc-calendar {
        width: 100%;
        height: 100%;
        background: #F9FAFC;
        box-sizing: border-box;
    }

        .cc-calendar .calendar-week {
            display: flex;
            width: 100%;
            height: 46px;
            line-height: 46px;
            border: 1px solid #E4E7EA;
            border-right: none;
            padding-inline-start: 0px;
        }

            .cc-calendar .calendar-week .week-item {
                width: 14.285%;
                text-align: center;
                font-size: 14px;
                color: #424953;
                border-right: 1px solid #E4E7EA;
                font-weight: 600;
                display: inline;
            }

    .calendar-view {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-left: 1px solid #E4E7EA;
        padding-inline-start: 0px;
    }
    
    .calendar-view .date-view {
        width: 14.285%;
        height: 140px;
        border-right: 1px solid #E4E7EA;
        border-bottom: 1px solid #E4E7EA;
        box-sizing: border-box;
        cursor: pointer;
        display: inline;
    }
    .calendar-view .date-view .date-day {
        padding: 8px 0px 8px 0px;
        display: block;
        width: 100%;
        font-size: 18px;
        color: red;
    }

    .calendar-view .date-view .calendar-num {
        margin-top: 6px;
        display: block;
        width: 100%;
        text-align: center;
        font-size: 30px;
        color: #424953;
    }

    .opacity-class {
        opacity: .5;
    }

    .month-class {
        background-image: linear-gradient(45deg,rgba(000, 000, 000, .03) 25%,transparent 25%,transparent 50%,rgba(000, 000, 000, .03) 50%,rgba(000, 000, 000, .03) 75%,transparent 75%,transparent);
        background-size: 20px 20px;
    }

    /*.todayBg {
        background: #FFFDEF;
    }*/
    .todayBg {
        background: #DE6;
    }
    .handleDay {
        background: #2061FF !important;
    }
    .handleDay .date-day {
        color: #BCCFFF !important;
        }
    .handleDay .calendar-num {
        color: #fff !important;
        }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage';
    import { parseTime, getWeekNum } from '@/common/utils/datetime';
    import ProductModSelect from '../../components/ProductMod/ProductModSelect'
    import { GetCompanyList } from '@/api/system/company';
    import { GetProductList } from '@/api/system/product';
    import { getNewDate, getDate } from '@/common/utils/datetime';
    var tableLength = 0;

    export default {
        name: '',
        components: {
            //挂载组件
            ProductModSelect, //自定义的标签
        },
        data() {
            return {
                loadTimesProduct: 0,
                disabledSubmit: false,
                SalesDataForm: {
                    id: 0,
                    datePeriod: '',
                    years: '',
                    months: '',
                    rules: '',
                    tableData: [],
                    totalOnline: 0.0,
                    totalRetail: 0.0,
                    totalData: 0.0,
                    userNumber: AppStorage.getNumber()
                },
                tableData: [],
                SalesDataDetailSubs: [
                ],
                tableLabelOption: [
                ],
                selectDate: null,
                dis: this.$route.query.readonly == "true" ? true : false,
                operate: this.$route.query.operate == "query" ? true : false,
                dateParam: {
                    yyyy: '',
                    week: '',
                },
                calendarTitleArr: [
                    'MON',
                    'TUE',
                    'WED',
                    'THU',
                    'FRI',
                    'SAT',
                    'SUN '
                ],
                allCountrys: [],
                allproduct: [],
                calendatArr: [],
            }
        },

        computed: {
            rules() {
                const rules = {
                    datePeriod: [
                        { required: true, message: this.$t('message.vueSales.verifySalesTargetRequired'), trigger: 'blur' }
                    ],
                    country: [
                        { required: true, message: this.$t('message.vueSales.verifySalesTargetRequired'), trigger: 'blur' }
                    ],
                    uploadQuantity: [
                        { required: true, pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{2})$/, message: '请正确输入金额，保留两位小数', trigger: 'blur' }
                    ]
                };
                return rules;
            },
        },

        methods: {
            columnbackgroundStyle({ row, column, rowIndex, columnIndex }) {
                let { year: currentYear, month: currentMonth, day: currentDay } = getNewDate(new Date());
                if (currentDay == 0) { currentDay = currentDay + 1 }

                if (column.property == currentDay - 1) {
                    //让下标为1的列数背景颜色显示为红色（颜色自定义根据大家需求来）
                    return 'background:#DE6;'
                }
            },
            getAmout() {
                console.log(this.calendatArr[0].SalesDataDetailSubs.length);
                var totalamount2 = 0.0;
                var totalamount3 = 0.0;
                for (var i = 0; i < this.calendatArr[0].SalesDataDetailSubs.length; i++) {
                    if (this.calendatArr[0].SalesDataDetailSubs[i].OnlineAmount * 1 > 0) {
                        totalamount2 = totalamount2 + (this.calendatArr[0].SalesDataDetailSubs[i].OnlineAmount * 1);
                    }
                    if (this.calendatArr[0].SalesDataDetailSubs[i].RetailAmount * 1 > 0) {
                        totalamount3 = totalamount3 + (this.calendatArr[0].SalesDataDetailSubs[i].RetailAmount * 1);
                    }
                }
                this.SalesDataForm.totalOnline = totalamount2.toLocaleString();
                this.SalesDataForm.totalRetail = totalamount3.toLocaleString();
                this.SalesDataForm.totalData = (totalamount2 + totalamount3).toLocaleString();
            },
            sumDialogOpen() {
                let { year: currentYear, month: currentMonth, day: currentDay } = getNewDate(new Date());
                if (currentDay == 0) { currentDay = currentDay + 1}
                this.$nextTick(_ => {
                    //this.$refs['Input_' + Number(0)].focus(); // 将第一行的名称input框获取焦点
                    //console.log(this.$refs['Today' + Number(0) + Number(0)]);
                    this.$refs['Today' + Number(0) + Number(currentDay-1)].focus();
                });
            },
            // 是否是当前月
            isCurrentMonth(date) {
                if (date == undefined) {
                    return false;
                }

                //let { year: currentYear, month: currentMonth } = getNewDate(getDate(this.SalesDataForm.years, this.SalesDataForm.months, 1));
                let currentYear = parseInt(this.SalesDataForm.years);
                let currentMonth = parseInt(this.SalesDataForm.months);

                var date2 = new Date(date);
                let { year, month } = getNewDate(date2);
                return currentYear == year && currentMonth == month + 1
            },
            // 点击某一天
            handleClickDay(item) {
                let that = this;
                that.$forceUpdate();
                that.$emit('handleClickDay', item);
                that.$set(item, 'ClickDay', true);
            },
            // 是否是今天 
            isCurrentDay(date) {
                if (date == undefined) {
                    return false;
                }
                let { year: currentYear, month: currentMonth, day: currentDay } = getNewDate(new Date());
                var date2 = new Date(date);
                let { year, month, day } = getNewDate(date2);
                return currentYear == year && currentMonth == month && currentDay == day;
            },

            isCurrentDay2(date) {
                if (date == undefined) {
                    return "OtherDay";
                }
                let { year: currentYear, month: currentMonth, day: currentDay } = getNewDate(new Date());
                var date2 = new Date(date);
                let { year, month, day } = getNewDate(date2);
                if (currentYear == year && currentMonth == month && currentDay == day) {
                    return "Today";
                } else {
                    return "OtherDay";
                }
            },
            examineBtn(row) {
                this.loadTimesProduct += 1;
                this.row = row;
                this.$refs.msgBtn.init();
            },
            getProduct(product) {
                var tempdata = [];
                if (product.length > 0) {
                    for (var i = 0; i < product.length; i++) {

                        for (var j = 0; j < tableLength; j++) {
                            tempdata.push({
                                Id: 0,
                                SalesDataDetailId: 0,
                                SalesDataDate: this.tableLabelOption[j].Date,
                                UploadQuantity: 0,
                                UploadAmount: 0.0,
                                OnlineAmount: 0.0,
                                RetailAmount: 0.0,
                            })
                        }

                        var list = {
                            Id: 0,
                            ProductId: product[i].id,
                            productCode: product[i].productMod,
                            SalesDataDetailSubs: tempdata,
                            IsDisabled: false,
                            AddTime: new Date(),
                            AddUserNumber: '',
                            EditTime: new Date(),
                            EditUserNumber: '',
                            DisabledTime: new Date(),
                            DisabledUserNumber: '',
                            SalesDataId: 0,
                        };
                        this.tableData.push(list);
                    }
                } else {
                    for (var k = 0; k < tableLength; k++) {
                        tempdata.push({
                            Id: 0,
                            SalesDataDetailId: 0,
                            SalesDataDate: this.tableLabelOption[k].Date,
                            UploadQuantity: 0,
                            UploadAmount: 0.0,
                            OnlineAmount: 0.0,
                            RetailAmount: 0.0,
                        })
                    }

                    this.tableData.push({
                        Id: 0,
                        ProductId: 0,
                        productCode: '',
                        SalesDataDetailSubs: tempdata,
                        IsDisabled: false,
                        AddTime: new Date(),
                        AddUserNumber: '',
                        EditTime: new Date(),
                        EditUserNumber: '',
                        DisabledTime: new Date(),
                        DisabledUserNumber: '',
                        SalesDataId: 0,
                    });
                }
            },
            delProduct(index) {
                this.tableData.splice(index, 1);
            },
            getInfo() {
                this.SalesDataForm.id = this.$route.query.id;
                
                if (this.SalesDataForm.id > 0) {
                    this.SalesDataForm.datePeriod = this.$route.query.datePeriod;
                    this.SalesDataForm.country = this.$route.query.country;
                    this.SalesDataForm.rules = this.$route.query.rules;
                    this.SalesDataForm.years = this.$route.query.years;
                    this.SalesDataForm.months = this.$route.query.months;

                    var totalamount = 0;
                    var totalamount1 = 0;
                    var totalamount2 = 0;

                    this.axios
                        .get('/api/salesData/getsalesdatadetailbysalesdataid', {
                            params: {
                                salesDataId: this.SalesDataForm.id,
                                country: this.SalesDataForm.country,
                            }
                        })
                        .then(response => {
                            var info = response.data.data;
                            if (this.SalesDataForm.rules == '1') {
                                tableLength = info[0].salesDataDetailSubs.length;
                            }
                            if (response.data.code == 1 && info != null) {
                                this.GenerateCalendar(info);
                                this.tableLabelOption.splice(0, this.tableLabelOption.length);
                                for (var z = 0; z < info[0].salesDataDetailSubs.length; z++) {
                                    this.tableLabelOption.push({
                                        Date: info[0].salesDataDetailSubs[z].salesDataDate,
                                        label: parseTime(info[0].salesDataDetailSubs[z].salesDataDate, '{m}月{d}日'),
                                        iType: z,
                                    });
                                }
                                for (var i = 0; i < info.length; i++) {
                                    this.addTableData(info[i].salesDataDetailSubs.length);
                                    this.tableData[i].Id = info[i].id;
                                    //this.tableData[i].ProductId = info[i].productId;
                                    this.tableData[i].ProductId = info[i].modelId;

                                    //this.tableData[i].productCode = info[i].productName;
                                    this.tableData[i].productCode = info[i].modelName;

                                    for (var j = 0; j < info[i].salesDataDetailSubs.length; j++) {
                                        this.tableData[i].SalesDataDetailSubs[j].SalesDataDate = info[i].salesDataDetailSubs[j].salesDataDate;
                                        this.tableData[i].SalesDataDetailSubs[j].UploadQuantity = info[i].salesDataDetailSubs[j].uploadQuantity;
                                        this.tableData[i].SalesDataDetailSubs[j].UploadAmount = info[i].salesDataDetailSubs[j].uploadAmount;

                                        this.tableData[i].SalesDataDetailSubs[j].Id = info[i].salesDataDetailSubs[j].id;
                                        this.tableData[i].SalesDataDetailSubs[j].SalesDataDetailId = info[i].salesDataDetailSubs[j].salesDataDetailId;
                                        this.tableData[i].SalesDataDetailSubs[j].OnlineAmount = info[i].salesDataDetailSubs[j].onlineAmount;
                                        this.tableData[i].SalesDataDetailSubs[j].RetailAmount = info[i].salesDataDetailSubs[j].retailAmount;

                                        totalamount = totalamount + this.tableData[i].SalesDataDetailSubs[j].UploadAmount;
                                        totalamount1 = totalamount1 + this.tableData[i].SalesDataDetailSubs[j].OnlineAmount;
                                        totalamount2 = totalamount2 + this.tableData[i].SalesDataDetailSubs[j].RetailAmount;
                                    }
                                    this.tableData[i].IsDisabled = info[i].isDisabled;
                                    this.tableData[i].AddTime = info[i].addTime;
                                    this.tableData[i].AddUserNumber = info[i].addUserNumber;
                                    this.tableData[i].EditTime = info[i].editTime;
                                    this.tableData[i].EditUserNumber = info[i].editUserNumber;
                                    this.tableData[i].DisabledTime = info[i].disabledTime;
                                    this.tableData[i].DisabledUserNumber = info[i].disabledUserNumber;
                                    this.tableData[i].SalesDataId = info[i].salesDataId;
                                }

                                this.SalesDataForm.totalData = (totalamount1 + totalamount2).toLocaleString();
                                this.SalesDataForm.totalOnline = totalamount1.toLocaleString();
                                this.SalesDataForm.totalRetail = totalamount2.toLocaleString();
                            }
                            /*if (this.SalesDataForm.rules == '1') {
                                this.sumDialogOpen();
                            }*/
                        });
                }
            },

            getCountrys(query, isAll = true) {
                this.SalesDataForm.isloading = true;
                GetCompanyList(null, 0, true).then(response => {
                    var infos = response.data.data;
                    if (response.data.code == 1 && infos != null) {
                        this.allCountrys = infos.map(function (m) {
                            return {
                                value: m.companyNumber,
                                label: m.companyName + "(" + m.companyNumber + ")"
                            }
                        });
                        this.allCountrys.unshift({
                            value: "",
                            label: this.$t('message.all')
                        });
                    }
                    this.SalesDataForm.isloading = false;
                })
                .catch(error => {
                    console.log(error);
                    this.SalesDataForm.isloading = false;
                });
            },

            seletCountryChange(val) {
                var obj = {}
                obj = this.allCountrys.find(function (i) {
                    return i.value === val
                });
                if (this.SalesDataForm.country != obj.value) {
                    this.tableData.splice(0, this.tableData.length);
                    this.addTableData();
                }
                this.SalesDataForm.country = obj.value;
                this.getProductId('', '');
            },

            getProductId(query, row, isAll = true) {
                this.SalesDataForm.isloading = true;

                GetProductList(query, this.SalesDataForm.country, true).then(response => {
                    var infos = response.data.data;
                    if (response.data.code == 1 && infos != null) {
                        this.allproduct = infos.map(function (m) {
                            return {
                                value: m.id,
                                label: m.productCode + "(" + m.productName + ")"
                            }
                        });
                        this.allproduct.unshift({
                            value: 0,
                            label: this.$t('message.all')
                        });
                        this.allproduct.isLoading;
                    }
                    this.$forceUpdate();
                    this.SalesDataForm.isloading = false;
                }).catch(error => {
                    console.log(error);
                    this.SalesDataForm.isloading = false;
                });
            },

            delForm(index) {
                this.tableData.splice(index, 1);
            },

            changeIsDisabled(row) {
                var isDisabled = false;
                if (row.isDisabled == false) {
                    isDisabled = true;
                }
                row.isLoading = true;
                return new Promise(resolve => {
                    this.axios
                        .post('/api/salesData/disabledsalesdatadetail', {
                            Id: row.Id,
                            IsDisabled: row.IsDisabled,
                            UserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isLoading = false;
                            if (response.data.code != 1) {
                                this.$message.error(this.$t('message.error') + ':' + response.data.msg);
                                return resolve(false);
                            } else {
                                this.$message.success(this.$t('message.success'));
                                return resolve(true);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isLoading = false;
                            this.$message.error(this.$t('message.exception') + ':' + error);
                            return resolve(false);
                        });
                })
            },

            addForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.axios
                            .post('/api/salesData/salesdataexist', {
                                //DatePeriod: this.dateParam.yyyy + '  第' + this.dateParam.week + '周',
                                DatePeriod: this.SalesDataForm.datePeriod,
                                Country: this.SalesDataForm.country,
                            })
                            .then(response => {
                                var info = response.data.data;
                                if (response.data.code == 1 && info != null) {
                                    if (response.data.data && this.SalesDataForm.id == 0) {
                                        this.$message.error(this.$t('message.vueSales.hasSalesData'));
                                        this.SalesDataForm.month = '';
                                        return;
                                    }
                                    else {
                                        this.addTableData(tableLength);
                                    }
                                }
                            })
                    }
                    else {
                        return false;
                    }
                });
            },

            addTableData(datalength) {
                if (this.SalesDataForm.rules == 2 && this.SalesDataForm.id <= 0) {
                    this.GenerateCalendar(null);
                }
                else {
                    var tempdata = [];

                    for (var i = 0; i < datalength; i++) {
                        tempdata.push({
                            Id: 0,
                            SalesDataDetailId: 0,
                            SalesDataDate: new Date(),
                            UploadQuantity: 0,
                            UploadAmount: 0.0,
                            OnlineAmount: 0.0,
                            RetailAmount: 0.0,
                        })
                    }
                    this.tableData.push({
                        Id: 0,
                        ProductId: 0,
                        productCode: '',
                        SalesDataDetailSubs: tempdata,
                        IsDisabled: false,
                        AddTime: new Date(),
                        AddUserNumber: '',
                        EditTime: new Date(),
                        EditUserNumber: '',
                        DisabledTime: new Date(),
                        DisabledUserNumber: '',
                        SalesDataId: 0,
                    });
                }
            },
            GenerateCalendar(dataArr) {
                if (this.SalesDataForm.rules == 2) {
                    this.calendatArr.splice(0, this.calendatArr.length);
                    let { year, month, day } = getNewDate(getDate(this.SalesDataForm.years, this.SalesDataForm.months, 1));

                    let currentFirstDay = getDate(year, month - 1, 1);
                    // 获取当前月第一天星期几
                    let weekDay = currentFirstDay.getDay();
                    if (weekDay == 0) {
                        weekDay += 7;
                    }
                    let startTime = currentFirstDay - (weekDay - 1) * 24 * 60 * 60 * 1000;
                    let monthDayNum;
                    if (weekDay == 5 || weekDay == 6 || weekDay == 7) {
                        monthDayNum = 42
                    } else {
                        monthDayNum = 35
                    }

                    var tempdata = [];

                    if (dataArr != null && dataArr.length > 0) {
                        var dataArr2 = dataArr[0].salesDataDetailSubs;
                    }

                    for (let i = 0; i < monthDayNum; i++) {
                        var date = new Date(startTime + i * 24 * 60 * 60 * 1000);
                        var uploadAmount = 0.0;
                        var onlineAmount = 0.0;
                        var retailAmount = 0.0;

                        var salesDataDetailId = 0;
                        var id = 0;
                        if (dataArr2 != null && dataArr2.length > 0 && this.isCurrentMonth(date)) {
                            var tempDate = date.getDate() <= 9 ? "0" + date.getDate().toString() : date.getDate().toString();
                            var data = dataArr2.find(item => item.dataDate == tempDate);
                            if (data != null) {
                                id = data.id;
                                uploadAmount = data.uploadAmount;
                                onlineAmount = data.onlineAmount;
                                retailAmount = data.retailAmount;
                                salesDataDetailId = data.salesDataDetailId;
                            }
                        }
                        tempdata.push({
                            Id: id,
                            SalesDataDetailId: salesDataDetailId,
                            SalesDataDate: date,
                            DataDate: date,
                            UploadQuantity: 0,
                            UploadAmount: uploadAmount,
                            OnlineAmount: onlineAmount,
                            RetailAmount: retailAmount,
                            IsCurrentMonth: this.isCurrentMonth(date),
                            ClickDay: false,
                            day: date.getDate(),
                        })
                    }
                    this.calendatArr.push({
                        Id: dataArr[0].id,
                        ProductId: 0,
                        productCode: '',
                        SalesDataDetailSubs: tempdata,
                        IsDisabled: false,
                        AddTime: new Date(),
                        AddUserNumber: '',
                        EditTime: new Date(),
                        EditUserNumber: '',
                        DisabledTime: new Date(),
                        DisabledUserNumber: '',
                        SalesDataId: dataArr[0].salesDataId,
                    });
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;

                        this.axios
                            .post('/api/salesData/salesdataexist', {
                                DatePeriod: this.SalesDataForm.datePeriod,
                                Country: this.SalesDataForm.country,
                            })
                            .then(response => {
                                var info = response.data.data;
                                if (response.data.code == 1 && info != null) {
                                    if (response.data.data && this.SalesDataForm.id == 0) {
                                        this.$message.error(this.$t('message.vueSales.hasSalesData'));
                                        return;
                                    }
                                    else {

                                        if (this.SalesDataForm.rules == '2') {
                                            var tempdata = [];

                                            this.tableData.splice(0, this.tableData.length);
                                            for (var i = 0; i < this.calendatArr[0].SalesDataDetailSubs.length; i++) {
                                                var tempArr = this.calendatArr[0].SalesDataDetailSubs[i];
                                                if (tempArr.IsCurrentMonth) {
                                                    tempdata.push({
                                                        Id: tempArr.Id,
                                                        SalesDataDetailId: tempArr.SalesDataDetailId,
                                                        SalesDataDate: tempArr.SalesDataDate,
                                                        UploadQuantity: 0,
                                                        UploadAmount: tempArr.OnlineAmount * 1 + tempArr.RetailAmount * 1,
                                                        OnlineAmount: tempArr.OnlineAmount,
                                                        RetailAmount: tempArr.RetailAmount,
                                                        IsDisabled: false,
                                                        AddTime: new Date(),
                                                        AddUserNumber: '',
                                                        EditTime: new Date(),
                                                        EditUserNumber: '',
                                                        DisabledTime: new Date(),
                                                        DisabledUserNumber: '',
                                                    })
                                                }
                                            }
                                            this.tableData.push({
                                                Id: this.calendatArr[0].Id,
                                                ProductId: 0,
                                                productCode: '',
                                                SalesDataDetailSubs: tempdata,
                                                IsDisabled: false,
                                                AddTime: new Date(),
                                                AddUserNumber: '',
                                                EditTime: new Date(),
                                                EditUserNumber: '',
                                                DisabledTime: new Date(),
                                                DisabledUserNumber: '',
                                                SalesDataId: this.calendatArr[0].SalesDataId,
                                            });
                                        }

                                        this.axios
                                            .post('/api/salesData/addoreditsalesdata', {
                                                Id: this.SalesDataForm.id,
                                                DatePeriod: this.SalesDataForm.datePeriod,
                                                Country: this.SalesDataForm.country,
                                                UserNumber: this.SalesDataForm.userNumber,
                                                Rules: this.SalesDataForm.rules,
                                                SalesDataDetailEntities: this.tableData,
                                            })
                                            .then(response => {
                                                if (response.data.code != 1) {
                                                    this.$message.error(this.$t(response.data.msg));

                                                } else {
                                                    this.$message.success(this.$t('message.success'));
                                                    this.$router.push("/SalesDataList");
                                                }
                                                this.disabledSubmit = false;
                                            }).catch(error => {
                                                console.log(error);
                                                this.$message.error(this.$t('message.exception') + ':' + error);
                                                this.disabledSubmit = false;
                                            });
                                    }
                                }
                            })

                    } else {
                        return false;
                    }
                });
            },
            returnForm() {
                this.$router.go(-1);
            },            
            // 初始化日期
            
        },
        mounted() {
            this.getInfo();
        },
        created() {
            this.getCountrys();
            this.getProductId('', '');
        }
    }
</script>