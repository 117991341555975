<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/MenuList' }">{{$t('message.vueMenu.crumbMenuManager')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('message.details')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-button @click="returnForm()" class="button_return" size="mini">{{$t('message.return')}}</el-button>
    <el-divider></el-divider>
    <el-descriptions class="margin-top menu-details" :title="$t('message.details')" :column="1" :size="mini" border>
        <!--<template #extra>
            <el-button @click="returnForm()">{{$t('message.return')}}</el-button>
        </template>-->
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueMenu.labMenuType')}}
            </template>
            <el-tag size="small" v-if="ruleForm.menuType==1">{{$t('message.vueMenu.radioMenuTypeSideMenu')}}</el-tag>
            <el-tag size="small" v-else-if="ruleForm.menuType==2">{{$t('message.vueMenu.radioMenuTypeButton')}}</el-tag>
            <el-tag size="small" v-else>{{$t('message.vueMenu.radioMenuTypeAPI')}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueMenu.labMenuNameZHCN')}}
            </template>
            {{ruleForm.menuNameZHCN}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueMenu.labMenuNameEN')}}
            </template>
            {{ruleForm.menuNameEN}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueMenu.labMenuNameZHTW')}}
            </template>
            {{ruleForm.menuNameZHTW}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueMenu.labIcon')}}
            </template>
            {{ruleForm.icon}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueMenu.labRoutePath')}}
            </template>
            {{ruleForm.routePath}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueMenu.labRouteName')}}
            </template>
            {{ruleForm.routeName}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueMenu.labRouteComponent')}}
            </template>
            {{ruleForm.routeComponent}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueMenu.labRouteParentName')}}
            </template>
            {{ruleForm.routeParentName}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueMenu.labSort')}}
            </template>
            {{ruleForm.sort}}
        </el-descriptions-item>
    </el-descriptions>
</template>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'MenuDetails',
        data() {
            return {
                ruleForm: {
                    id: 0,
                    menuType: 1,
                    menuNameZHCN: '',
                    menuNameEN: '',
                    menuNameZHTW: '',
                    icon: '',
                    routePath: '',
                    routeName: '',
                    routeComponent: '',
                    routeParentName: 'Home',
                    sort: 0,
                    parentMenuId: 0,
                    userNumber: AppStorage.getNumber()
                }
            }
        },
        methods: {
            getInfo() {
                var infoId = this.$route.query.id;
                if (infoId === '0') {
                    return;
                }
                this.axios
                    .get('/api/menu/getmenubyid', {
                        params: {
                            id: infoId
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.ruleForm.id = info.id;
                            this.ruleForm.menuType = info.type;
                            this.ruleForm.menuNameZHCN = info.menuNameZHCN;
                            this.ruleForm.menuNameEN = info.menuNameEN;
                            this.ruleForm.menuNameZHTW = info.menuNameZHTW;
                            this.ruleForm.icon = info.icon;
                            this.ruleForm.routePath = info.routePath;
                            this.ruleForm.routeName = info.routeName;
                            this.ruleForm.routeComponent = info.routeComponent;
                            this.ruleForm.routeParentName = info.routeParentName;
                            this.ruleForm.sort = info.sort;
                            this.ruleForm.parentMenuId = info.parentMenuId;
                        }
                    });
            },
            returnForm() {
                this.$router.go(-1);
            }
        },
        mounted() {
            this.getInfo();
        }
    }
</script>
<style>
    .menu-details .el-descriptions__label {
        width: 20%;
    }
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }
</style>

