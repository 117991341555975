<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/RoleList' }">{{$t('message.vueRole.crumbAuthorityManager')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('message.details')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-button @click="returnForm()" size="mini" class="button_return">{{$t('message.return')}}</el-button>
    <el-divider></el-divider>
    <el-descriptions class="margin-top role-details" :title="$t('message.details')" :column="1" size="mini" border>
        <!--<template #extra>
            <el-button @click="returnForm()">{{$t('message.return')}}</el-button>
        </template>-->
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueRole.labRoleName')}}
            </template>
            {{ruleForm.roleName}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueRole.labUsers')}}
            </template>
            {{ruleForm.userNames}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueRole.labCompanys')}}
            </template>
            {{ruleForm.companyNames}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueRole.labDepartments')}}
            </template>
            {{ruleForm.departmentNames}}
        </el-descriptions-item>
    </el-descriptions>
    <el-tabs type="border-card">
        <el-tab-pane :label="$t('message.vueRole.labMenu')">
            <el-checkbox v-model="isAllChecked" @change="checkAll()" disabled>{{$t('message.checkAll')}}</el-checkbox>
            <el-tree :data="menuTree"
                     show-checkbox
                     node-key="id"
                     ref="tree"
                     default-expand-all
                     :expand-on-click-node="false"
                     :default-checked-keys="ruleForm.menuIds"
                     :props="defaultProps"
                     :check-strictly="true"
                     @check-change="checkChange"
                     class="role-tree">
                <template #default="{ node, data }">
                    <span class="menu-tree-node">
                        <span class="node-label">{{ node.label }}</span>
                        <el-tag type="success" effect="plain" size="mini" v-if="data.type== 1">{{$t('message.menu')}}</el-tag>
                        <el-tag type="" effect="plain" size="mini" v-if="data.type== 2">{{$t('message.button')}}</el-tag>
                    </span>
                    <el-checkbox v-model="data.allData" class="node-check" disabled v-if="data.isData==true">{{$t('message.vueRole.labMenuAllData')}}</el-checkbox>
                </template>
            </el-tree>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'RoleDetails',
        data() {
            return {
                ruleForm: {
                    id: 0,
                    roleName: '',
                    companys: '',
                    companyNames: '',
                    departments: '',
                    departmentNames: '',
                    users: '',
                    userNames: '',
                    menuIds: null,
                    menuAllData: null,
                },
                isAllChecked: false,
                allMenuIds: null,
                menuTree: null,
                defaultProps: {
                    children: 'submenuList',
                    disabled: function () {
                        return true;
                    },
                    label: function (data) {
                        var label = '';
                        var language = AppStorage.getLanguage();
                        if (language == 'en') {
                            label += data.menuNameEN;
                        } else {
                            label += data.menuNameZHTW;
                        }
                        return label;
                    }
                },
            }
        },
        methods: {
            getMenuTree() {
                this.axios
                    .get('/api/menu/getmenutree')
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.menuTree = infos;
                            this.allMenuIds = this.getAllMenuIds(null, this.menuTree);
                        }
                    });
            },
            getInfo() {
                var infoId = this.$route.query.id;
                if (infoId === '0') {
                    return;
                }
                this.axios
                    .get('/api/role/getrolebyid', {
                        params: {
                            id: infoId
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.ruleForm.id = info.id;
                            this.ruleForm.roleName = info.roleName;
                            this.ruleForm.companys = info.companys == null || info.companys == '' ? '' : info.companys.split(',');
                            this.ruleForm.companyNames = info.companyNames;
                            this.ruleForm.departments = info.departments == null || info.departments == '' ? '' : info.departments.split(',');
                            this.ruleForm.departmentNames = info.departmentNames;
                            this.ruleForm.users = info.users == null || info.users == '' ? '' : info.users.split(',');
                            this.ruleForm.userNames = info.userNames;
                            this.ruleForm.menuIds = info.menuIds == null || info.menuIds == '' ? '' : info.menuIds.split(',');
                            this.ruleForm.menuAllData = info.menuAllData == null || info.menuAllData == '' ? '' : info.menuAllData.split(',');
                            this.allMenuIds = this.getAllMenuIds(null, this.menuTree);
                        }
                    });
            },
            returnForm() {
                this.$router.go(-1);
            },
            checkChange() {
                if (this.allMenuIds != null && this.allMenuIds.length == this.$refs.tree.getCheckedKeys().length) {
                    this.isAllChecked = true;
                } else {
                    this.isAllChecked = false;
                }
            },
            getAllMenuIds(menuIds, menus) {
                if (menuIds == null) {
                    menuIds = [];
                }
                var menuAllData = "," + this.ruleForm.menuAllData?.toString() + ",";
                menus.forEach((item) => {
                    item.allData = false;
                    if (menuAllData.indexOf("," + item.id + ",") > -1) {
                        item.allData = true;
                    }
                    menuIds.push(item.id);
                    if (item.submenuList != null && item.submenuList.length > 0) {
                        menuIds = this.getAllMenuIds(menuIds, item.submenuList);
                    }
                });
                return menuIds;
            },
        },
        mounted() {
            this.getMenuTree();
            this.getInfo();
            this.checkChange();
        }
    }
</script>
<style>
    .role-details .el-descriptions__label {
        width: 100px
    }

    .role-tree .node-check {
        margin-left: 20px;
    }
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }
</style>

