<template>
    <el-empty description="Sorry ! 404 Not Found !">
        <el-button @click="goHome">{{$t('message.return')}}</el-button>
    </el-empty>
</template>

<script>
    export default {
        name: '404',
        methods: {
            goHome() {
                this.$router.push({ path: "/" });
            }
        }
    };
</script>