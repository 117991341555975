<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ProductCol' }">{{$t('message.vueProductCol.productcolpage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="productcolour">
        <el-form ref="productcolForm" :model="productcolForm" :inline="true" class="productcolForm-inline">
            <el-form-item :label="$t('message.vueCompany.labIsDisabled')+' :'">
                <el-select size="mini" v-model="productcolForm.isDisabled" :placeholder="$t('message.pleaseSelect')" style="width:100px">
                    <el-option :label="$t('message.pleaseSelect')" value=""></el-option>
                    <el-option :label="$t('message.vueCompany.selIsDisabledYes')" value="true"></el-option>
                    <el-option :label="$t('message.vueCompany.selIsDisabledNo')" value="false"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('message.vueProductCol.ProcuctColour')+' :'">
                <el-input size="mini" v-model="productcolForm.productcolourCondition"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button size="mini" class="btn_search" @click="submitSearch" icon="el-icon-search">
                    {{$t('message.vueCategory.button_search')}}
                </el-button>
            </el-form-item>
            <!--<el-row>
                <el-form-item>
                    <el-button size="mini" type="primary" @click="goAddProductCol"
                               icon="el-icon-circle-plus-outline" class="btn_add">{{$t('message.vueProduct.button_add')}}</el-button>
                </el-form-item>
            </el-row>-->
        </el-form>
        <el-table :data="tableData" border size="mini">
            <el-table-column prop="Id" :label="$t('message.vueProductCol.ProductColourID')" width="60">
                <template #default="scope">
                    <span>{{scope.row.id}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="ProcuctCol" :label="$t('message.vueProductCol.ProcuctColour')" width="170">
                <template #default="scope">
                    <span>{{scope.row.productCol}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="ProductColNa" :label="$t('message.vueProductCol.ProductColourNumber')" width="120">
                <template #default="scope">
                    <span>{{scope.row.productColNa}}</span>
                </template>
            </el-table-column>
            
        </el-table>
        <el-divider></el-divider>
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="pageIndex"
                       :page-sizes="[10, 25, 50, 75, 100]"
                       :page-size="pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="count">
        </el-pagination>
    </div>
</template>

<style>
    /*.productcolour {
        align-content: center;
        margin: 100px 5px 5px 20%;
        font-size: 120%;
    }*/
    .productcolForm-inline {
        margin: 5px;
        font-size: 90%;
    }

    .label-productcolour {
        margin: 5px;
        font-size: 90%;
    }

    .input-productcolour {
        margin: 5px;
    }

    /*.el-row {
        background: #00a7d0;
    }*/
    .btn_search {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }

    .btn_add {
        font-weight: bolder;
    }

    .button_edit {
        color: #fff;
        background-color: orange;
        font-weight: bolder;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'ProductCol',
        data() {
            return {
                tableData: null,
                language: AppStorage.getLanguage(),
                productcolForm: {
                    isDisabled: "false",
                    productcolourCondition: ''
                },
                pageIndex: 1,
                pageSize: 50,
                count: 0
            }
        },
        methods: {
            goAddProductCol() {
                this.$router.push({ path: "/AddProductCol" });
            },
            goEdit(row) {
                this.$router.push({
                    path: "/EditProductCol",
                    query: { Id: row.id }
                });
            },
            loadPage() {
                this.axios
                    .get('/api/ProductCol/getproductcolpage', {
                        params: {
                            isDisabled: this.productcolForm.isDisabled,
                            ProductCol: this.productcolForm.productcolourCondition,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.tableData = infos.items;
                            this.count = infos.count;
                        } else {
                            this.tableData = null;
                            this.count = 0;
                        }
                    });
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            },
            changeIsDisabled(row) {
                var isDisabled = false;
                if (row.isDisabled == false) {
                    isDisabled = true;
                }
                row.isLoading = true;
                return new Promise(resolve => {
                    this.axios
                        .post('/api/ProductCol/disabledproductcol', {
                            ProductCol: row.productCol,
                            IsDisabled: isDisabled,
                            UserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isLoading = false;
                            if (response.data.code != 1) {
                                this.$message.error(this.$t('message.error'));
                                return resolve(false);
                            } else {
                                this.$message.success(this.$t('message.success'));
                                this.loadPage();
                                return resolve(true);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isLoading = false;
                            this.$message.error(this.$t('message.messageBox.connecterror'));
                            return resolve(false);
                        });
                })
            }
        },
        mounted() {
            this.loadPage();
        }
    };
</script>
