import { createApp} from 'vue'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import App from './App.vue';
import AppStorage from '@/common/storage/app-storage'

import "@/utils/commonData.css";

import { createI18n } from 'vue-i18n'
import enLocale from 'element-plus/lib/locale/lang/en'
import zhtwLocale from 'element-plus/lib/locale/lang/zh-tw'
import 'dayjs/locale/en'
import 'dayjs/locale/zh-tw'

const messages = {
    [enLocale.name]: {
        el: enLocale.el,
        message: require('/src/common/langs/en'),
    },
    [zhtwLocale.name]: {
        el: zhtwLocale.el,
        message: require('/src/common/langs/zh-tw'),
    }
}
const language = AppStorage.getLanguage();
const i18n = createI18n({
    locale: language || enLocale.name,
    fallbackLocale: zhtwLocale.name,
    messages,
})
//路由vue-router
import router from './router'

//axios
import axios from 'axios'
import VueAxios from 'vue-axios'
axios.defaults.baseURL = '';
axios.defaults.timeout = 60000;
// 路由请求拦截
// http request 拦截器
axios.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json;charset=UTF-8';
        if (AppStorage.getToken()!="") {
            config.headers['Authorization'] = AppStorage.getToken();
        }
        return config;
    },
    error => {
        return Promise.reject(error.response);
    });
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    if (response.data.code == 0 && response.data.msg == 'token无效') {
        //alert(i18n.global.t('message.vueLogin.verifyTokenRequired'));
        AppStorage.remove();
        location.href = '/login';
    }
    if (response.data.code == 0 && response.data.msg == "404") {
        location.href = '/404';
    }
    if (response.data.code == 0 && response.data.msg.indexOf("401,") > -1) {
        location.href = '/401';
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

const app = createApp(App);
app.use(ElementPlus, {
    i18n: i18n.global.t,
});
app.use(i18n);
app.use(router);
app.use(VueAxios, axios)
app.use(AppStorage);
app.mount('#app');
