import axios from 'axios';

export function GetUserList(companyNumber, departmentNumber, keyword, isAll) {
    return axios
           .get('/api/user/getuserlist', {
               params: {
                   companyNumber: companyNumber,
                   departmentNumber: departmentNumber,
                   keyword: keyword,
                   isAll: isAll
               }
           })
}