import { createRouter, createWebHistory   } from 'vue-router'
import AppStorage from '@/common/storage/app-storage'
import axios from 'axios'
const routes = [
    {
        //默认首页
        path: '/',
        name: 'Home',
        component: () => import('../views/Home/Home.vue'),
    },
    {
        path: '/UserChangePassword',
        name: 'UserChangePassword',
        component: () => import('../views/User/UserChangePassword.vue'),
        meta: { noRequiresAuth: true }

    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/User/Login.vue'),
        meta: { noRequiresAuth: true }
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../views/Error/404.vue'),
    },
    {
        path: '/401',
        name: '401',
        component: () => import('../views/Error/401.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.noRequiresAuth) == false) {
        var isAuthenticated = AppStorage.isAuthenticated();//验证
        if (to.name == 'UserChangePassword') {
            next({ path: '/UserChangePassword', query: { redirect: to.fullPath } })
            return;
        } else {
        if (to.name != 'Login' && !isAuthenticated) {
            next({ path: '/login', query: { redirect: to.fullPath } })
            return;
        }
        if (to.matched.length == 0) {
            axios.get('/api/menu/getmenusbyuser', {
                params: {
                    userNumber: AppStorage.getNumber(),
                    routePath: to.path,
                }
            }).then(response => {
                if (response.data.code == 0 && response.data.msg.indexOf("401,") > -1) {
                    next({
                        path: '/401',
                        query: { redirect: to.fullPath }
                    })
                    return;
                }
                else if (response.data.code == 0) {
                    next({
                        path: '/404',
                        query: { redirect: to.fullPath }
                    })
                    return;
                }
                var info = response.data.data;
                var storage = JSON.parse(AppStorage.get());
                if (info != null) {
                    storage.sideMenuList = response.data.data.sideMenuList;
                    AppStorage.set(JSON.stringify(storage));
                    var item = info.currentMenu;
                    const thisComponent = () => Promise.resolve(require(`@/${item.routeComponent}.vue`));
                    router.addRoute(item.routeParentName, { path: item.routePath, name: item.routeName, component: thisComponent });
                    router.push({ path: to.path, query: to.query }).catch(() => {
                        next({
                            path: '/404',
                            query: { redirect: to.fullPath }
                        })
                    });
                    return;
                }
            });
            return;
        } else {
            next();
        }
        }
        
    } else {
        if (to.matched.length == 0) {
            next({
                path: '/404',
                query: { redirect: to.fullPath }
            })
            return;
        }
        next();
    }
})
// 4 导出router实例
export default router