<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/SalesTargetList' }">{{$t('message.vueSales.SalesTarget')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="tableData.id==0">{{$t('message.add')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-else>{{$t('message.edit')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="SalesTargetForm" :model="SalesTargetForm" :rules="rules" label-width="100px" size="small" class="demo-ruleForm">
        <el-row>
            <el-col :span="7">
                <el-form-item :label="$t('message.vueSales.year')" prop="year">
                    <el-date-picker v-model="SalesTargetForm.year" type="year" :start-placeholder="$t('message.pleaseSelect')" :end-placeholder="$t('message.pleaseSelect')"
                                    format="YYYY" value-format="YYYY" :disabled="dis" @change="conditionsChange"></el-date-picker>
                </el-form-item>
            </el-col>
            <el-col :span="7">
                <el-form-item :label="$t('message.vueSales.month')" prop="month">
                    <el-date-picker v-model="SalesTargetForm.month" type="month" :start-placeholder="$t('message.pleaseSelect')" :end-placeholder="$t('message.pleaseSelect')"
                                    format="MM" value-format="MM" :disabled="dis" @change="conditionsChange" :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
            </el-col>
            <el-col :span="7">
                <el-form-item :label="$t('message.vueSales.rule')" prop="rule">
                    <el-select v-model="SalesTargetForm.rule" :placeholder="$t('message.pleaseSelect')" style="width:220px" :disabled="dis"
                               :loading="SalesTargetForm.isloading" @change="conditionsChange">
                        <el-option :label="$t('message.pleaseSelect')" :value="0"></el-option>
                        <el-option :label="$t('message.vueSales.byQuantity')" :value="1"></el-option>
                        <el-option :label="$t('message.vueSales.byAmount')" :value="2"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="7">
                <el-form-item :label="$t('message.vueSales.country')" prop="country">
                    <el-select v-model="SalesTargetForm.country" filterable remote reserve-keyword :placeholder="$t('message.pleaseSelect')" @change="seletCountryChange" :disabled="dis"
                               :remote-method="(query)=>{ getCountrys(query) }" :loading="SalesTargetForm.isloading" style="width:220px">
                        <el-option v-for="item in allCountrys" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row v-if="SalesTargetForm.rule == '2'">
            <el-col :span="24">
                <div class="cc-calendar">
                    <ul class="calendar-week">
                        <li v-for="(item, index) in calendarTitleArr" :key="index" class="week-item">{{item}}</li>
                    </ul>
                    <ul class="calendar-view">
                        <li v-for="(item, index) in calendatArr"
                            :key="index"
                            class="date-view"
                            :class="[
                              {'month-class': !isCurrentMonth(item.Date)},
                              {todayBg: isCurrentDay(item.Date)},
                              {handleDay: item.ClickDay}
                            ]"
                            @click="handleClickDay(item)">
                            <span class="date-day"
                                  :style="dayStyle"
                                  :class="[{'opacity-class': !isCurrentMonth(item.Date)}]">
                                {{item.day}}
                            </span>
                            <span class="calendar-num">
                                <el-form-item :label="$t('message.vueSDPReport.corporateLabel')+' :'" prop="Corporate" v-if="!operate && isCurrentMonth(item.Date)">
                                    <el-input v-model="item.CorporatAmount" v-if="!operate && isCurrentMonth(item.Date)"
                                              oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
                                              :text-align="right" style="width: 90%" @blur="getAmout"
                                              :disabled="dis">{{item.CorporatAmount}}</el-input>
                                </el-form-item>

                                <el-form-item :label="$t('message.vueSDPReport.offlineLabel')+' :'" prop="Offline" v-if="!operate && isCurrentMonth(item.Date)">
                                    <el-input v-model="item.OfflineAmount" v-if="!operate && isCurrentMonth(item.Date)"
                                              oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
                                              :text-align="right" style="width: 90%" @blur="getAmout"
                                              :disabled="dis">{{item.OfflineAmount}}</el-input>
                                </el-form-item>
                            </span>
                        </li>
                    </ul>
                </div>
            </el-col>
        </el-row>

        <el-row v-if="SalesTargetForm.rule == '2'">
            <el-col :span="24" class="totalData">
                <el-form-item :label="$t('message.vueSDPReport.totalCorporateData')" prop="totalCorporate">
                    <el-input v-model="SalesTargetForm.totalCorporateData" style="width:100px" readonly></el-input>
                </el-form-item>
                <el-form-item :label="$t('message.vueSDPReport.totalOfflineData')" prop="totalOffline">
                    <el-input v-model="SalesTargetForm.totalOfflineData" style="width: 100px" readonly></el-input>
                </el-form-item>

                <el-form-item :label="$t('message.vueSDPReport.totalDataLebel')" prop="totalData">
                    <el-input v-model="SalesTargetForm.totalData" style="width: 100px" readonly></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item>
                    <el-button @click="copyData('SalesTargetForm',1)"
                               :disabled="disabledSubmit" size="mini" class="button_submit" v-if="operatecopy">
                        {{$t('message.copy')}}
                    </el-button>

                    <el-button @click="submitForm('SalesTargetForm',1)"
                               :disabled="disabledSubmit" size="mini" class="button_submit">
                        {{$t('message.save')}}
                    </el-button>

                    <!--<el-button @click="submitForm('SalesTargetForm',2)"
               :disabled="disabledSubmit" size="mini" class="button_submit">
        {{$t('message.vueSales.buttonrelease')}}
    </el-button>-->

                    <el-button @click="returnForm()" size="mini" class="button_return">{{$t('message.return')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
        <ProductsSelect ref="msgBtn" :loadTimesProduct="loadTimesProduct" v-on:getProductsEven="getProduct"></ProductsSelect>
    </el-form>
</template>

<style>
    .el-form .el-select {
        width: 100%;
    }
    .totalData {
        font-size: 20px;
    }
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }

    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }

    .cc-calendar {        
        width: 100%;
        height: 100%;
        background: #F9FAFC;
        box-sizing: border-box;
    }

    .cc-calendar .calendar-week {
        display: flex;
        width: 100%;
        height: 46px;
        line-height: 46px;
        border: 1px solid #E4E7EA;
        border-right: none;
        padding-inline-start: 0px;
    }

    .cc-calendar .calendar-week .week-item {
        width: 14.285%;
        text-align: center;
        font-size: 14px;
        color: #424953;
        border-right: 1px solid #E4E7EA;
        font-weight: 600;
        display: inline;
    }

    .calendar-view {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-left: 1px solid #E4E7EA;
        padding-inline-start: 0px;
    }

    .calendar-view .date-view {
        width: 14.285%;
        height: 160px;
        border-right: 1px solid #E4E7EA;
        border-bottom: 1px solid #E4E7EA;
        box-sizing: border-box;
        cursor: pointer;
        display: inline;
    }

        /*.calendar-view .date-view .date-day {
        padding: 8px 0px 8px 0px;
        display: block;
        width: 100%;
        font-size: 14px;
        color: #7F8794;
    }*/
        .calendar-view .date-view .date-day {
            padding: 8px 0px 8px 0px;
            display: block;
            width: 100%;
            font-size: 18px;
            color: red;
        }

    .calendar-view .date-view .calendar-num {
        margin-top: 6px;
        display: block;
        width: 100%;
        text-align: center;
        font-size: 30px;
        color: #424953;
    }

    .opacity-class {
        opacity: .5;
    }

    .month-class {
        background-image: linear-gradient(45deg,rgba(000, 000, 000, .03) 25%,transparent 25%,transparent 50%,rgba(000, 000, 000, .03) 50%,rgba(000, 000, 000, .03) 75%,transparent 75%,transparent);
        background-size: 20px 20px;
    }

    .todayBg {
        background: #FFFDEF;
    }

    .handleDay {
        background: #2061FF !important;
    }

    .handleDay .date-day {
        color: #BCCFFF !important;
    }

    .handleDay .calendar-num {
        color: #fff !important;
    }

</style>

<script>
    import AppStorage from '@/common/storage/app-storage';
    import { GetCompanyList } from '@/api/system/company';
    import { GetProductList } from '@/api/system/product';
    import ProductsSelect from '../../components/Products/ProductsPurchaseSelect'
    import { getNewDate, getDate } from '@/common/utils/datetime';

    export default {
        name: '',
        components: {
            //挂载组件
            ProductsSelect, //自定义的标签
        },
        data() {
            let { year, month, day } = getNewDate(new Date());
            return {
                loadTimesProduct: 0,
                loadTimes: 0,
                dialogWorkFlow: false,
                disabledSubmit: false,
                SalesTargetForm: {
                    id: 0,
                    year: '',
                    month: '',
                    rule: '',
                    totalCorporateData: 0,
                    totalOfflineData: 0,
                    totalData: 0,
                    tableData: [],
                    userNumber: AppStorage.getNumber()
                },
                workFlowInfo: null,
                formType: 0,
                tableData: [{
                    Id: 0,
                    Date: '',
                    TargetAmount: 0.0,
                    CorporatAmount: 0.0,
                    OfflineAmount: 0.0,
                    ProductId: [{
                        Id: '',
                        ProductCode: '',
                        ProductName: '',
                    }],
                    productCode: '',
                    TargetQuantity: 0,
                    IsDisabled: false,
                    AddTime: new Date(),
                    AddUserNumber: '',
                    EditTime: new Date(),
                    EditUserNumber: '',
                    DisabledTime: new Date(),
                    DisabledUserNumber: '',
                    SalesTargetId: 0,
                    ClickDay: false
                }],
                tableDataTemp: [{
                    Id: 0,
                    Date: '',
                    TargetAmount: 0.0,
                    CorporatAmount: 0.0,
                    OfflineAmount: 0.0,
                    ProductId: [{
                        Id: '',
                        ProductCode: '',
                        ProductName: '',
                    }],
                    productCode: '',
                    TargetQuantity: 0,
                    IsDisabled: false,
                    AddTime: new Date(),
                    AddUserNumber: '',
                    EditTime: new Date(),
                    EditUserNumber: '',
                    DisabledTime: new Date(),
                    DisabledUserNumber: '',
                    SalesTargetId: 0,
                    ClickDay: false
                }],
                dis: this.$route.query.readonly == "true" ? true : false,
                operate: this.$route.query.operate == "query" ? true : false,
                operatecopy: this.$route.query.operate == "copy" ? true : false,
                allCountrys: [],
                allproduct: [],
                calendarTitleArr: [
                    'MON',
                    'TUE',
                    'WED',
                    'THU',
                    'FRI',
                    'SAT',
                    'SUN '
                ],
                time: { year, month, day },
                calendatArr: [],
            }
        },

        computed: {
            rules() {
                const rules = {
                    year: [
                        { required: true, message: this.$t('message.vueSales.verifySalesTargetRequired'), trigger: 'blur' }
                    ],
                    month: [
                        { required: true, message: this.$t('message.vueSales.verifySalesTargetRequired'), trigger: 'blur' }
                    ],
                    rule: [
                        { required: true, message: this.$t('message.vueSales.verifySalesTargetRequired'), trigger: 'blur' }
                    ],
                    country: [
                        { required: true, message: this.$t('message.vueSales.verifySalesTargetRequired'), trigger: 'blur' }
                    ]
                };
                return rules;
            },            
        },

        methods: {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
            getAmout() {
                console.log(this.calendatArr.length);
                var totalamount2 = 0;
                var totalamount3 = 0;
                for (var i = 0; i < this.calendatArr.length; i++) {
                    totalamount2 = totalamount2 + (this.calendatArr[i].CorporatAmount * 1);
                    totalamount3 = totalamount3 + (this.calendatArr[i].OfflineAmount * 1);
                }
                this.SalesTargetForm.totalCorporateData = totalamount2;
                this.SalesTargetForm.totalOfflineData = totalamount3;
                this.SalesTargetForm.totalData = totalamount2 + totalamount3;
            },
            getProduct(product) {
                if (product.length > 0) {
                    for (var i = 0; i < product.length; i++) {
                        var list = {
                            Id: 0,
                            Date: '',
                            TargetAmount: 0.0,
                            CorporatAmount: 0.0,
                            OfflineAmount: 0.0,
                            ProductId: product[i].id,
                            productCode: product[i].productCode + "(" + product[i].productName + ")",
                            TargetQuantity: 0,
                            IsDisabled: false,
                            AddTime: new Date(),
                            AddUserNumber: '',
                            EditTime: new Date(),
                            EditUserNumber: '',
                            DisabledTime: new Date(),
                            DisabledUserNumber: '',
                            SalesTargetId: 0,
                        };
                        this.tableData.push(list);
                    }
                } else {
                    this.tableData.push({
                        Id: 0,
                        Date: '',
                        TargetAmount: 0.0,
                        CorporatAmount: 0.0,
                        OfflineAmount: 0.0,
                        ProductId: 0,
                        productCode: '',
                        TargetQuantity: 0,
                        IsDisabled: false,
                        AddTime: new Date(),
                        AddUserNumber: '',
                        EditTime: new Date(),
                        EditUserNumber: '',
                        DisabledTime: new Date(),
                        DisabledUserNumber: '',
                        SalesTargetId: 0,
                    });
                }
            },
            examineBtn(row) {
                this.loadTimesProduct += 1;
                this.row = row;
                this.$refs.msgBtn.init();
            },
            getInfo() {
                this.SalesTargetForm.id = this.$route.query.id;

                if (this.SalesTargetForm.id > 0) {
                    this.SalesTargetForm.year = this.$route.query.year;
                    this.SalesTargetForm.month = this.$route.query.month;
                    this.SalesTargetForm.rule = this.$route.query.rule;
                    this.SalesTargetForm.country = this.$route.query.country;

                    var totalamount = 0;
                    var totalamount1 = 0;
                    var totalamount2 = 0;

                    this.axios
                        .get('/api/salestarget/getsalestargetdetailbysalestargetid', {
                            params: {
                                salesTargetId: this.SalesTargetForm.id,
                            }
                        })
                        .then(response => {
                            var info = response.data.data;
                            if (response.data.code == 1 && info != null) {
                                this.GenerateCalendar(info);
                                for (var i = 0; i < info.length; i++) {                                    
                                    this.tableData[i].Id = info[i].id;
                                    this.tableData[i].ProductId = info[i].productId;

                                    this.tableData[i].productCode = info[i].productName;

                                    this.tableData[i].TargetAmount = info[i].targetAmount;
                                    this.tableData[i].CorporatAmount = info[i].corporatAmount;
                                    this.tableData[i].OfflineAmount = info[i].offlineAmount;
                                    this.tableData[i].Date = info[i].date;
                                    this.tableData[i].TargetQuantity = info[i].targetQuantity;
                                    this.tableData[i].IsDisabled = info[i].isDisabled;
                                    this.tableData[i].AddTime = info[i].addTime;
                                    this.tableData[i].AddUserNumber = info[i].addUserNumber;
                                    this.tableData[i].EditTime = info[i].editTime;
                                    this.tableData[i].EditUserNumber = info[i].editUserNumber;
                                    this.tableData[i].DisabledTime = info[i].disabledTime;
                                    this.tableData[i].DisabledUserNumber = info[i].disabledUserNumber;
                                    this.tableData[i].SalesTargetId = info[i].salesTargetId;
                                    this.addTableData();

                                    totalamount = totalamount + this.tableData[i].TargetAmount;
                                    totalamount1 = totalamount1 + this.tableData[i].CorporatAmount;
                                    totalamount2 = totalamount2 + this.tableData[i].OfflineAmount;
                                }
                                this.tableData.splice(i, 1);
                                this.SalesTargetForm.totalCorporateData = totalamount1;
                                this.SalesTargetForm.totalOfflineData = totalamount2;
                                this.SalesTargetForm.totalData = totalamount1 + totalamount2;

                                if (this.$route.query.operate == "copy") {
                                    var now = new Date();
                                    var monthn = now.getMonth() + 2;
                                    if (monthn < 10) {
                                        monthn = "0" + monthn;
                                    }
                                    this.SalesTargetForm.month = monthn;
                                    this.GenerateCalendar(info);
                                }
                            }
                        });
                }
                else {
                    this.tableData.splice(0, 1);
                }
                
            },

            getCountrys(query, isAll = true) {
                this.SalesTargetForm.isloading= true;
                GetCompanyList(null, 0, true).then(response => {
                    var infos = response.data.data;
                    if (response.data.code == 1 && infos != null) {
                        this.allCountrys = infos.map(function (m) {
                            return {
                                value: m.companyNumber,
                                label: m.companyName + "(" + m.companyNumber + ")"
                            }
                        });
                        this.allCountrys.unshift({
                            value: "",
                            label: this.$t('message.all')
                        });
                    }
                    this.SalesTargetForm.isloading = false;
                })
                .catch(error => {
                    console.log(error);
                    this.SalesTargetForm.isloading = false;
                });
            },

            seletCountryChange(val) {
                var obj = {}
                obj = this.allCountrys.find(function (i) {
                    return i.value === val
                });
                this.SalesTargetForm.country = obj.value;
                this.getProductId('', '');

                this.loadTimes += 1;
                this.dialogWorkFlow = true;
            },

            getProductId(query, row, isAll = true) {
                this.SalesTargetForm.isloading = true;
                GetProductList(query, this.SalesTargetForm.country, true).then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allproduct = infos.map(function (m) {
                                return  {
                                    value: m.id,
                                    label: m.productCode + "(" + m.productName + ")"
                                }
                            });
                            this.allproduct.unshift({
                                value: 0,
                                label: this.$t('message.all')
                            });
                            this.allproduct.isLoading;
                        }
                        this.$forceUpdate();
                        this.SalesTargetForm.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.SalesTargetForm.isloading = false;
                    });
            },

            delForm(index) {
                this.tableData.splice(index, 1);
            },

            changeIsDisabled(row) {
                var isDisabled = false;
                if (row.isDisabled == false) {
                    isDisabled = true;
                }
                row.isLoading = true;
                return new Promise(resolve => {
                    this.axios
                        .post('/api/salestarget/disabledsalestargetdetail', {
                            Id: row.Id,                            
                            IsDisabled: row.IsDisabled,
                            UserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isLoading = false;
                            if (response.data.code != 1) {
                                this.$message.error(this.$t('message.error') + ':' + response.data.msg);
                                return resolve(false);
                            } else {
                                this.$message.success(this.$t('message.success'));
                                return resolve(true);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isLoading = false;
                            this.$message.error(this.$t('message.exception') + ':' + error);
                            return resolve(false);
                        });
                })
            },

            addForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.axios
                            .post('/api/salestarget/salestargetexist', {
                                Year: this.SalesTargetForm.year,
                                Month: this.SalesTargetForm.month,
                                Rule: this.SalesTargetForm.rule,
                                Country: this.SalesTargetForm.country,
                            })
                            .then(response => {
                                var info = response.data.data;

                                if (response.data.code != 1) {
                                    if (response.data.msg == 'Data duplication') {
                                        this.$message.error(this.$t('message.vueExchangeRate.dataduplication'));
                                    }
                                }

                                if (response.data.code == 1 && info != null) {
                                    if (response.data.data && this.SalesTargetForm.id == 0) {
                                        this.$message.error(this.$t('message.vueSales.hasTarget'));
                                        this.SalesTargetForm.month = '';
                                        return;
                                    }
                                    else {
                                        if (!(this.SalesTargetForm.rule == 2 && this.tableData.length >= 28)) {
                                            this.addTableData();
                                        }                                      
                                    }
                                }
                            })
                    }
                    else {
                        return false;
                    }
                });
            },

            conditionsChange() {
                if ((this.SalesTargetForm.year !== 'undefined' && this.SalesTargetForm.year != null && this.SalesTargetForm.year != '')
                    && (this.SalesTargetForm.month !== 'undefined' && this.SalesTargetForm.month != null && this.SalesTargetForm.month != '')
                    && (this.SalesTargetForm.rule !== 'undefined' && this.SalesTargetForm.rule != null && this.SalesTargetForm.rule != '')) {

                    this.tableData.splice(0, this.tableData.length);
                    this.addTableData();
                }
                else {
                    return;
                }
            },

            addTableData() {
                if (this.SalesTargetForm.rule == 2 && this.SalesTargetForm.id <= 0) {
                    this.GenerateCalendar(null);
                }
                else {
                    this.tableData.push({
                        Id: 0,
                        Date: '',
                        TargetAmount: 0.0,
                        CorporatAmount: 0.0,
                        OfflineAmount: 0.0,
                        ProductId: 0,
                        TargetQuantity: 0,
                        IsDisabled: false,
                        AddTime: new Date(),
                        AddUserNumber: '',
                        EditTime: new Date(),
                        EditUserNumber: '',
                        DisabledTime: new Date(),
                        DisabledUserNumber: '',
                        SalesTargetId: 0,
                    });
                }
            },

            GenerateCalendar(dataArr) {
                if (this.SalesTargetForm.rule == 2) {
                    this.calendatArr.splice(0, this.calendatArr.length);
                    let { year, month, day } = getNewDate(getDate(this.SalesTargetForm.year, this.SalesTargetForm.month, 1));

                    let currentFirstDay = getDate(year, month - 1, 1);
                    // 获取当前月第一天星期几
                    let weekDay = currentFirstDay.getDay();
                    if (weekDay == 0) {
                        weekDay += 7;
                    }
                    let startTime = currentFirstDay - (weekDay - 1) * 24 * 60 * 60 * 1000;
                    let monthDayNum;
                    if (weekDay == 5 || weekDay == 6 || weekDay == 7) {
                        monthDayNum = 42
                    } else {
                        monthDayNum = 35
                    }
                    for (let i = 0; i < monthDayNum; i++) {
                        var date = new Date(startTime + i * 24 * 60 * 60 * 1000);
                        var targetAmount = 0.0;
                        var corporatAmount = 0.0;
                        var offlineAmount = 0.0;

                        var salesTargetId = 0;
                        var id = 0;
                        if (dataArr != null && dataArr.length > 0 && this.isCurrentMonth(date)) {
                            var tempDate = date.getDate() <= 9 ? "0" + date.getDate().toString() : date.getDate().toString();
                            var data = dataArr.find(item => item.date == tempDate);
                            if (data != null) {
                                id = data.id;
                                //targetAmount = data.targetAmount;
                                corporatAmount = data.corporatAmount;
                                offlineAmount = data.offlineAmount;
                                targetAmount = data.corporatAmount + data.offlineAmount;
                                salesTargetId = data.salesTargetId;
                            }
                        }
                        this.calendatArr.push({
                            Id: id,
                            Date: date,
                            TargetAmount: targetAmount,
                            CorporatAmount: corporatAmount,
                            OfflineAmount: offlineAmount,
                            year: year,
                            month: month,
                            day: date.getDate(),
                            ClickDay: false,
                            IsCurrentMonth: this.isCurrentMonth(date),
                            SalesTargetId: salesTargetId
                        })
                    }
                }
            },

            submitForm(formName, signment){
                if (this.addTableData.length >= 0 && (this.tableData.rule == 2 && this.tableData.find(a => a.TargetAmount == 0).TargetAmount == 0
                    || this.tableData.rule == 1 && this.tableData.find(a => a.TargetQuantity == 0).TargetQuantity == 0)) {
                    this.$message.error(this.$t('message.vueSales.zeroMessage'));
                    return;
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;                        
                        this.axios
                            .post('/api/salestarget/salestargetexist', {
                                Year: this.SalesTargetForm.year,
                                Month: this.SalesTargetForm.month,
                                Rule: this.SalesTargetForm.rule,
                                Country: this.SalesTargetForm.country,
                            })
                            .then(response => {
                                var info = response.data.data;
                                if (response.data.code == 1 && info != null) {
                                    if (response.data.data && this.SalesTargetForm.id == 0) {
                                        this.$message.error(this.$t('message.vueSales.hasTarget'));                                        
                                        return;
                                    }
                                    else {
                                        if (this.SalesTargetForm.rule == '2') {
                                            this.tableData.splice(0, this.tableData.length);
                                            for (var i = 0; i < this.calendatArr.length; i++) {
                                                var tempArr = this.calendatArr[i];
                                                if (tempArr.IsCurrentMonth) {
                                                    this.tableData.push({
                                                        Id: tempArr.Id,
                                                        Date: tempArr.day <= 9 ? "0" + tempArr.day.toString() : tempArr.day.toString(),
                                                        TargetAmount: tempArr.TargetAmount,
                                                        CorporatAmount: tempArr.CorporatAmount,
                                                        OfflineAmount: tempArr.OfflineAmount,
                                                        ProductId: 0,
                                                        TargetQuantity: 0,
                                                        IsDisabled: false,
                                                        AddTime: new Date(),
                                                        AddUserNumber: '',
                                                        EditTime: new Date(),
                                                        EditUserNumber: '',
                                                        DisabledTime: new Date(),
                                                        DisabledUserNumber: '',
                                                        SalesTargetId: tempArr.SalesTargetId,
                                                    });
                                                }
                                            }
                                        }
                                        this.axios
                                            .post('/api/salestarget/addoreditsalestarget', {
                                                Id: this.SalesTargetForm.id,
                                                Year: this.SalesTargetForm.year,
                                                Month: this.SalesTargetForm.month,
                                                Rule: this.SalesTargetForm.rule,
                                                Country: this.SalesTargetForm.country,
                                                UserNumber: this.SalesTargetForm.userNumber,
                                                DataStatus: signment,
                                                SalesTargetDetailEntities: this.tableData,
                                            })
                                            .then(response => {
                                                if (response.data.code != 1) {
                                                    this.$message.error(this.$t(response.data.msg));

                                                } else {
                                                    this.$message.success(this.$t('message.success'));
                                                    this.$router.push("/SalesTargetList");
                                                }
                                                this.disabledSubmit = false;
                                            }).catch(error => {
                                                console.log(error);
                                                this.$message.error(this.$t('message.exception') + ':' + error);
                                                this.disabledSubmit = false;
                                            });
                                    }
                                }
                            })
                    } else {
                        return false;
                    }
                });
            },
            copyData(formName, signment) {
                if (this.addTableData.length >= 0 && (this.tableData.rule == 2 && this.tableData.find(a => a.TargetAmount == 0).TargetAmount == 0
                    || this.tableData.rule == 1 && this.tableData.find(a => a.TargetQuantity == 0).TargetQuantity == 0)) {
                    this.$message.error(this.$t('message.vueSales.zeroMessage'));
                    return;
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/salestarget/salestargetexist', {
                                Year: this.SalesTargetForm.year,
                                Month: this.SalesTargetForm.month,
                                Rule: this.SalesTargetForm.rule,
                                Country: this.SalesTargetForm.country,
                            })
                            .then(response => {
                                var info = response.data.data;
                                if (response.data.code == 1 && info != null) {
                                    if (response.data.data && this.SalesTargetForm.id == 0) {
                                        this.$message.error(this.$t('message.vueSales.hasTarget'));
                                        return;
                                    }
                                    else {
                                        if (this.SalesTargetForm.rule == '2') {
                                            this.tableData.splice(0, this.tableData.length);
                                            for (var i = 0; i < this.calendatArr.length; i++) {
                                                var tempArr = this.calendatArr[i];
                                                if (tempArr.IsCurrentMonth) {
                                                    this.tableData.push({
                                                        Id: tempArr.Id,
                                                        Date: tempArr.day <= 9 ? "0" + tempArr.day.toString() : tempArr.day.toString(),
                                                        TargetAmount: tempArr.TargetAmount,
                                                        CorporatAmount: tempArr.CorporatAmount,
                                                        OfflineAmount: tempArr.OfflineAmount,
                                                        ProductId: 0,
                                                        TargetQuantity: 0,
                                                        IsDisabled: false,
                                                        AddTime: new Date(),
                                                        AddUserNumber: '',
                                                        EditTime: new Date(),
                                                        EditUserNumber: '',
                                                        DisabledTime: new Date(),
                                                        DisabledUserNumber: '',
                                                        SalesTargetId: tempArr.SalesTargetId,
                                                    });
                                                }
                                            }
                                        }
                                        this.axios
                                            .post('/api/salestarget/copysalestarget', {
                                                Id: 0,
                                                Year: this.SalesTargetForm.year,
                                                Month: this.SalesTargetForm.month,
                                                Rule: this.SalesTargetForm.rule,
                                                Country: this.SalesTargetForm.country,
                                                UserNumber: this.SalesTargetForm.userNumber,
                                                DataStatus: signment,
                                                SalesTargetDetailEntities: this.tableData,
                                            })
                                            .then(response => {
                                                if (response.data.code != 1) {
                                                    this.$message.error(this.$t(response.data.msg));

                                                } else {
                                                    this.$message.success(this.$t('message.success'));
                                                    this.$router.push("/SalesTargetList");
                                                }
                                                this.disabledSubmit = false;
                                            }).catch(error => {
                                                console.log(error);
                                                this.$message.error(this.$t('message.exception') + ':' + error);
                                                this.disabledSubmit = false;
                                            });
                                    }
                                }
                            })

                    } else {
                        return false;
                    }
                });
            },
            returnForm() {
                this.$router.go(-1);
            },

            // 是否是当前月
            isCurrentMonth(date) {
                if (date == undefined) {
                    return false;
                }

                let { year: currentYear, month: currentMonth } = getNewDate(getDate(this.SalesTargetForm.year, this.SalesTargetForm.month, 1));
                let { year, month } = getNewDate(date);
                return currentYear == year && currentMonth == month + 1
            },
            // 是否是今天 
            isCurrentDay(date) {
                if (date == undefined) {
                    return false;
                }
                let { year: currentYear, month: currentMonth, day: currentDay } = getNewDate(new Date());
                let { year, month, day } = getNewDate(date);
                return currentYear == year && currentMonth == month && currentDay == day;
            },
            // 点击某一天
            handleClickDay(item) {
                let that = this;
                that.$forceUpdate();
                that.$emit('handleClickDay', item);
                that.$set(item, 'ClickDay', true);
            }
        },
        mounted() {
            this.getInfo();
        },
        created() {
            this.getCountrys();
            this.getProductId('', '');
        }
    }
</script>