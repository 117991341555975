<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/SalesTargetTotalReport' }">{{$t('message.vueSDPReport.SalesTargetTotalReportPage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="SalesDataReportForm" :model="SalesDataReportForm" :inline="true" class="demo-form-inline" label-width="100px">
        <el-form-item prop="yearmonth" :label="$t('message.vueSDPReport.dateLabel')+' :'">
            <el-date-picker v-model="SalesDataReportForm.yearmonth" type="month" value-format="YYYY-MM" size="mini" style="width: 200px"></el-date-picker>
        </el-form-item>

        <el-form-item :label="$t('message.vueSales.country')+' :'" prop="country">
            <el-input v-model="SalesDataReportForm.country" size="mini" disabled></el-input>
        </el-form-item>

        <el-form-item>
            <el-button class="btn_search" id="btn_search" @click="submitSearch"
                       icon="el-icon-search" size="mini">{{$t('message.search')}}</el-button>
            <el-button type="primary" @click="exportToExcel" size="mini">{{$t('message.vueSDPReport.excelButton')}}</el-button>
        </el-form-item>
    </el-form>

    <el-table id="SalesData2" :data="tableData" border size="mini" style="width: 100%"
              :header-cell-style="columnbackgroundStyle"
              :cell-style="cellStyle">
        <el-table-column :label="$t('message.vueSDPReport.fieldName')+''" prop="fieldName" width="190" fixed="left">
            <template #default="scope">
                <span>{{scope.row.fieldName}}</span>
            </template>
        </el-table-column>

        <el-table-column v-for="(item, index) in tableLabelOption2"
                         :key="index"
                         :label="item.label" :prop="item.weekNum"
                         align="center" width="200">
            <el-table-column v-for="(itemdetail, indexdetail) in tableLabelOption2[index].datedayInfo"
                              :key="indexdetail"
                             :label="itemdetail.ExchangeRate" width="100" align="right">
                <el-table-column :label="itemdetail.Company" width="100" align="right">
                    <template #default="scope">
                        <span :style="'color:'+(scope.row.salesDateDay[item.iType].salesAmountSum[itemdetail.iTypeDetail].UploadAmount<0?'red':'black')+';' ">
                            {{scope.row.salesDateDay[item.iType].salesAmountSum[itemdetail.iTypeDetail].UploadAmount}}
                        </span>
                    </template>
                </el-table-column>
            </el-table-column>
            
        </el-table-column>
         <el-table-column :label="$t('message.vueSDPReport.totalDataLebel')+''" prop="totalData" width="90" align="center">
             <el-table-column :label="$t('message.vueSales.uploadAmount')+''" prop="totalUploadAmount" width="90" align="right">
                 <template #default="scope">
                     <span>{{scope.row.totalUploadAmount}}</span>
                 </template>
             </el-table-column>
         </el-table-column>
    </el-table>
</template>
<style>
    .btn_search {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }

    .table {
        height: 100vh;
        width: 100%;
        padding: 40px;
        box-sizing: border-box;
        background-color: #fff;
        direction: rtl;
    }

    .tableqty {
        direction: rtl;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage';
    import { parseTime, getWeekNum } from '@/common/utils/datetime';
    import { GetCompanyList } from '@/api/system/company';
    import FileSaver from 'file-saver'
    export default {
        name: 'SalesTargetTotalReport',
        data() {
            return {
                tableData: [],
                SalesDataReportForm: {
                    yearmonth: '',
                    country: 'OIH',
                    modelLevel: '',
                    modelType: '',
                    model: '',
                    userNumber: AppStorage.getNumber()
                },
                SalesDataDetailSubs: [
                ],
                tableLabelOption: [
                ],
                tableLabelOption2: [
                ],
                language: AppStorage.getLanguage(),
                whereModelLevel: [{
                    value: 'High',
                    label: 'High'
                }, {
                    value: 'Mid',
                    label: 'Mid'
                }, {
                    value: 'Low',
                    label: 'Low'
                }],
                whereModelType: [{
                    value: 'CHAIR',
                    label: 'CHAIR'
                }, {
                    value: 'FOOT',
                    label: 'FOOT'
                }, {
                    value: 'OTHES',
                    label: 'OTHES'
                }],
                modellist: [],
                pageIndex: 1,
                pageSize: 20,
                oldPageIndex: 1,
                oldPageSize: 0,
                count: 0
            }
        },
        methods: {
            columnbackgroundStyle({ row, column, rowIndex, columnIndex }) {
                if (columnIndex >= 0 && (rowIndex == 0 || rowIndex == 1) && (column.property == 1 || column.property == 7)) {
                    //让下标为1的列数背景颜色显示为红色（颜色自定义根据大家需求来）
                    return 'background:pink;'
                }
            },
            cellStyle({ row, column, rowIndex, columnIndex })
            {
                if (rowIndex == 1 || rowIndex == 4 || rowIndex == 7 || rowIndex == 10) {
                    //return 'background:blue;'
                    return {
                        backgroundColor: "#DE6",
                        color: "red",
                    };
                }
            },
            exportToExcel() {
                this.oldPageSize = this.pageSize;
                this.oldPageIndex = this.pageIndex;
                // 然后将整表数据条数赋值给分页大小，页码赋值为1，加载表格数据
                this.pageIndex = 1;
                this.pageSize = this.count;
                this.loadPage();

                setTimeout(() => {

                    const XLSX = require('xlsx')
                    let fix = document.querySelector('.el-table__fixed');
                    let fileName = 'SalesTargetTotal' + new Date().getTime() + '.xlsx'
                    let wb;
                    if (fix) { //判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
                        wb = XLSX.utils.table_to_book(document.querySelector('#SalesData2').removeChild(fix));
                        document.querySelector('#SalesData2').appendChild(fix);
                    } else {
                        wb = XLSX.utils.table_to_book(document.querySelector('#SalesData2'));
                    }
                    let wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
                    try {
                        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName)
                    } catch (e) {
                        if (typeof console !== 'undefined') console.log(e, wbout)
                    }

                    this.pageSize = this.oldPageSize;
                    this.pageIndex = this.oldPageIndex;
                    this.loadPage();

                    return wbout
                }, 1000);
            },
            async initData() {
                //获取当前时间
                var now = new Date();
                var monthn = now.getMonth() + 1;
                var yearn = now.getFullYear();
                if (monthn < 10) {
                    monthn = "0" + monthn;
                }
                this.SalesDataReportForm.yearmonth = yearn + "-" + monthn;
                this.loadPage();
            },
            getCountrys(query, isAll = true) {
                this.SalesDataReportForm.isloading = true;
                GetCompanyList(null, 0, true).then(response => {
                    var infos = response.data.data;
                    if (response.data.code == 1 && infos != null) {
                        this.allCountrys = infos.map(function (m) {
                            return {
                                value: m.companyNumber,
                                label: m.companyName + "(" + m.companyNumber + ")"
                            }
                        });
                        this.allCountrys.unshift({
                            value: "",
                            label: this.$t('message.all')
                        });
                    }
                    this.SalesDataReportForm.isloading = false;
                })
                    .catch(error => {
                        console.log(error);
                        this.SalesDataReportForm.isloading = false;
                    });
            },

            seletCountryChange(val) {
                var obj = {}
                obj = this.allCountrys.find(function (i) {
                    return i.value === val
                });
                this.SalesDataReportForm.country = obj.value;
            },
            loadPage() {
                this.axios
                    .get('/api/SalesTargetAnalysisReport/GetSumReportData', {
                        params: {
                            YearMonth: this.SalesDataReportForm.yearmonth,
                            Country: this.SalesDataReportForm.country
                        }
                    }).then(response => {
                        this.tableData = [];
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.tableLabelOption2.splice(0, this.tableLabelOption2.length);

                            var datedayTable = [];

                            for (var m = 0; m < info[0].salesDateDay.length; m++) {
                                var date = new Date(info[0].salesDateDay[m].dateDay);
                                var dateday = date.getDay() + 1;

                                datedayTable = [];
                                for (var n = 0; n < info[0].salesDateDay[0].salesAmountSum.length; n++) {
                                    datedayTable.push({
                                        iTypeDetail: n,
                                        Company: info[0].salesDateDay[0].salesAmountSum[n].company,
                                        ExchangeRate: info[0].salesDateDay[0].salesAmountSum[n].exchangeRate,
                                    })
                                }

                                this.tableLabelOption2.push({
                                    label: parseTime(info[0].salesDateDay[m].dateDay, '{m}月{d}日'),
                                    datedayInfo: datedayTable,
                                    iType: m,
                                    weekNum: dateday,
                                });
                            }

                            var uploadmmount1 = 0.0;
                            for (var a = 0; a < info.length; a++) {
                                
                                uploadmmount1 = 0.0;
                                this.addTableData(info[a].salesDateDay.length, info[a].salesDateDay[0].salesAmountSum.length);

                                this.tableData[a].fieldName = info[a].fileName;
                                this.tableData[a].salesDateDay = info[a].salesDateDay;
                                for (var b = 0; b < info[a].salesDateDay.length; b++) {

                                    this.tableData[a].salesDateDay[b] = info[a].salesDateDay[b];
                                    for (var c = 0; c < info[a].salesDateDay[b].salesAmountSum.length; c++) {
                                        this.tableData[a].salesDateDay[b].salesAmountSum[c] = info[a].salesDateDay[b].salesAmountSum[c];

                                        if (a == 2 || a==5 || a==8) {
                                            if (info[a - 2].salesDateDay[b].salesAmountSum[c].uploadAmount == 0) {
                                                this.tableData[a].salesDateDay[b].salesAmountSum[c].UploadAmount = (info[a].salesDateDay[b].salesAmountSum[c].uploadAmount);
                                            } else {
                                                this.tableData[a].salesDateDay[b].salesAmountSum[c].UploadAmount = (info[a - 1].salesDateDay[b].salesAmountSum[c].uploadAmount / info[a - 2].salesDateDay[b].salesAmountSum[c].uploadAmount);
                                                this.tableData[a].salesDateDay[b].salesAmountSum[c].UploadAmount = parseFloat(this.tableData[a].salesDateDay[b].salesAmountSum[c].UploadAmount).toFixed(2);
                                            }
                                        } else {
                                            //a == 11 计算每日销售数据和销售目标之间的差距
                                            if (a == 11) {
                                                this.tableData[a].salesDateDay[b].salesAmountSum[c].UploadAmount = parseFloat((info[a - 1].salesDateDay[b].salesAmountSum[c].uploadAmount) * 1 - (info[a - 2].salesDateDay[b].salesAmountSum[c].uploadAmount) * 1).toFixed(0);
                                            } else {
                                                this.tableData[a].salesDateDay[b].salesAmountSum[c].UploadAmount = parseFloat(info[a].salesDateDay[b].salesAmountSum[c].uploadAmount).toFixed(0);
                                            }
                                        }
                                        uploadmmount1 = uploadmmount1 + (this.tableData[a].salesDateDay[b].salesAmountSum[c].UploadAmount)*1;
                                    }
                                }
                                this.tableData[a].totalUploadAmount = parseFloat(uploadmmount1).toFixed(0);
                            }
                        }
                    })
            },
            addTableData(datalength, datadetaillength) {
                this.salesDateDayTable = [];
                this.salesDateDetailTable = [];

                for (var i = 0; i < datadetaillength; i++) {
                    this.salesDateDetailTable.push({
                        UploadAmount: 0.0,
                    })

                }

                for (var j = 0; j < datalength; j++) {
                    this.salesDateDayTable.push({
                        salesAmountSum: this.salesDateDetailTable,
                    })

                }

                this.tableData.push({
                    fieldName: "",
                    totalUploadAmount: 0.0,
                    salesDateDay: this.salesDateDayTable,
                });
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            }
        },
        mounted() {
            this.initData();
        },
        created() {
            this.getCountrys();
        },
        watch: {
            $route() {
                //响应路由参数的变化
                this.submitSearch();
            }
        }
    };
</script>