<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/MenuList' }">{{$t('message.vueMenu.crumbMenuManager')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-button @click="returnList()" size="mini" class="button_return">{{$t('message.return')}}</el-button>
    <el-divider></el-divider>
    <div v-if="isParentMenu">
        <el-descriptions class="margin-top" :title="$t('message.vueMenu.labParentMenu')" :column="2" size="mini">
            <el-descriptions-item :label="$t('message.vueMenu.labMenuNameZHCN')" v-if="language === 'zh-ch'">{{parentMenuInfo.menuNameZHCN}}</el-descriptions-item>
            <el-descriptions-item :label="$t('message.vueMenu.labMenuNameEN')" v-else-if="language === 'en'">{{parentMenuInfo.menuNameEN}}</el-descriptions-item>
            <el-descriptions-item :label="$t('message.vueMenu.labMenuNameZHTW')" v-else>{{parentMenuInfo.menuNameZHTW}}</el-descriptions-item>
            <el-descriptions-item :label="$t('message.vueMenu.labRoutePath')">{{parentMenuInfo.routePath}}</el-descriptions-item>
            <el-descriptions-item :label="$t('message.vueMenu.labRouteName')">{{parentMenuInfo.routeName}}</el-descriptions-item>
            <el-descriptions-item :label="$t('message.vueMenu.labRouteComponent')">{{parentMenuInfo.routeComponent}}</el-descriptions-item>
        </el-descriptions>
        <el-divider></el-divider>
    </div>
    <el-form :inline="true" :model="formSearch" class="demo-form-inline" size="mini">
        <el-form-item :label="$t('message.vueMenu.labIsDisabled')+' :'">
            <el-select v-model="formSearch.isDisabled" :placeholder="$t('message.all')" style="width:100px" size="mini">
                <el-option :label="$t('message.all')" value=""></el-option>
                <el-option :label="$t('message.vueMenu.selIsDisabledYes')" value="true"></el-option>
                <el-option :label="$t('message.vueMenu.selIsDisabledNo')" value="false"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button @click="submitSearch" icon="el-icon-search" size="mini" class="btn_search">{{$t('message.search')}}</el-button>
        </el-form-item>
        <el-row>
            <el-form-item>
                <el-button type="primary" @click="add" icon="el-icon-circle-plus-outline" size="mini" class="btn_add">
                    {{$t('message.vueProduct.button_add')}}
                </el-button>
                <el-button type="primary" @click="addSubmenu(this.formSearch.parentMenuId)"
                           size="mini" icon="el-icon-circle-plus-outline"
                           v-if="isParentMenu" class="btn_add">{{$t('message.vueMenu.btnAddSubmenu')}}</el-button>
            </el-form-item>
        </el-row>
    </el-form>
    <el-table :data="tableData"
              border
              style="width: 100%"
              size="mini"
              highlight-current-row
              :stripe="true"
              :header-cell-style="{'background':'#81761852','color':'#2F4F4F','font-size': '12px','text-align':'center'}">
        <el-table-column prop="menuNameZHCN"
                         :label="$t('message.vueMenu.labMenuNameZHCN')"
                         width="200" v-if="language === 'zh-ch'">
        </el-table-column>
        <el-table-column prop="menuNameEN"
                         :label="$t('message.vueMenu.labMenuNameEN')"
                         width="200" v-else-if="language === 'en'">
        </el-table-column>
        <el-table-column prop="menuNameZHTW"
                         :label="$t('message.vueMenu.labMenuNameZHTW')"
                         width="200" v-else>
        </el-table-column>
        <el-table-column prop="routePath"
                         :label="$t('message.vueMenu.labRoutePath')"
                         width="200">
        </el-table-column>
        <el-table-column prop="routeName"
                         :label="$t('message.vueMenu.labRouteName')"
                         width="200">
        </el-table-column>
        <el-table-column prop="routeComponent"
                         :label="$t('message.vueMenu.labRouteComponent')"
                         width="200">
        </el-table-column>
        <el-table-column prop="routeParentName"
                         :label="$t('message.vueMenu.labRouteParentName')"
                         width="200">
        </el-table-column>
        <el-table-column prop="sort"
                         :label="$t('message.vueMenu.labSort')"
                         width="60">
        </el-table-column>
        <el-table-column prop="isDisabled"
                         :label="$t('message.vueMenu.labDisabled')"
                         width="100">
            <template #default="scope">
                <el-tooltip :content="$t('message.vueMenu.labIsDisabled')" placement="top" style="margin-right:10px">
                    <el-switch v-model="scope.row.isDisabled" active-color="#ff4949" inactive-color="#13ce66" :loading="scope.row.isLoading" :before-change="()=>changeIsDisabled(scope.row)"></el-switch>
                </el-tooltip>
                <span v-if="scope.row.isDisabled===true">
                    {{$t('message.vueMenu.selIsDisabledYes')}}
                </span>
                <span v-else>
                    {{$t('message.vueMenu.selIsDisabledNo')}}
                </span>
            </template>
        </el-table-column>
        <el-table-column :label="$t('message.operate')"
                         min-width="300">
            <template #default="scope">
                <el-button size="mini"
                           icon="el-icon-tickets"
                           class="button_detail"
                           @click="query(scope.row)">{{$t('message.details')}}</el-button>
                <el-button size="mini"
                           icon="el-icon-edit"
                           class="button_edit"
                           @click="edit(scope.row)">{{$t('message.edit')}}</el-button>
                <el-button size="mini"
                           icon="el-icon-delete"
                           class="btn_delete"
                           :disabled="scope.row.isDisabledDelete"
                           @click="del(scope.$index,scope.row)">{{$t('message.delete')}}</el-button>
                <el-button size="mini"
                           icon="el-icon-search"
                           class="btn_search"
                           @click="querySubmenu(scope.row)">{{$t('message.vueMenu.btnQuerySubmenu')}}</el-button>
                <el-button size="mini"
                           type="primary"
                           class="btn_add"
                           @click="addSubmenu(scope.row.id)"
                           icon="el-icon-circle-plus-outline">{{$t('message.vueMenu.btnAddSubmenu')}}</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="pageIndex"
                   :page-sizes="[10, 25, 50, 75, 100]"
                   :page-size="pageSize"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="count">
    </el-pagination>
</template>

<style>
    .el-table .success-row {
        background: #DFEAF5;
    }
    .btn_search {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }
    .btn_add {
        font-weight: bolder;
    }
    .btn_delete {
        color: #fff;
        font-weight: bolder;
        background-color: red;
    }
    .button_edit {
        color: #fff;
        background-color: orange;
        font-weight: bolder;
    }
    .button_detail {
        font-weight: bolder;
    }
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'MenuList',
        data() {
            return {
                tableData: null,
                language: AppStorage.getLanguage(),
                pageIndex: 1,
                pageSize: 10,
                count:0,
                formSearch: {
                    isDisabled: 'false',
                    parentMenuId: 0
                },
                parentMenuInfo: null,
                isParentMenu: false
            }
        },
        methods: {
            loadPage() {
                this.axios
                    .get('/api/menu/getmenupage', {
                        params: {
                            isDisabled: this.formSearch.isDisabled,
                            parentMenuId: this.formSearch.parentMenuId,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos!= null) {
                            this.tableData = infos.items;
                            this.count = infos.count;
                        } else {
                            this.tableData = null;
                            this.count = 0;
                        }
                    });
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            },
            add() {
                this.$router.push({
                    path: "/MenuAdd",
                    query: { id: 0} });
            },
            query(row) {
                this.$router.push({
                    path: "/MenuDetails",
                    query: { id: row.id }
                });
            },
            edit(row) {
                this.$router.push({
                    path: "/MenuEdit",
                    query: { id: row.id }
                });
            },
            del(index,row) {
                this.$confirm(this.$t('message.messageBox.msgDelete'), this.$t('message.messageBox.titleTips'), {
                    confirmButtonText: this.$t('message.messageBox.btnConfirm'),
                    cancelButtonText: this.$t('message.messageBox.btnCancel'),
                    type: 'warning'
                }).then(() => {
                    row.isDisabledDelete = true;
                    this.axios
                        .post('/api/menu/delmenu', {
                            Id: row.id,
                            UserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isDisabledDelete  = false;
                            if (response.data.code != 1) {
                                if (response.data.msg == '存在子菜单') {
                                    this.$message.error(this.$t('message.vueMenu.msgDeleteSubmenuCount'));
                                    return;
                                }
                                this.$message.error(this.$t('message.error'));
                            } else {
                                this.$message.success(this.$t('message.successfullyDeleted'));
                                this.tableData.splice(index, 1);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isDisabledDelete = false;
                            this.$message.error(this.$t('message.exception'));
                        });
                }).catch(() => {
                });
            },
            changeIsDisabled(row) {
                var isDisabled = false;
                if (row.isDisabled == false) {
                    isDisabled = true;
                }
                row.isLoading = true;
                return new Promise(resolve => {
                    this.axios
                        .post('/api/menu/disabledmenu', {
                            Id: row.id,
                            IsDisabled: isDisabled,
                            UserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isLoading = false;
                            if (response.data.code != 1) {
                                this.$message.error(this.$t('message.error'));
                                return resolve(false)
                            } else {
                                this.$message.success(this.$t('message.success'));
                                return resolve(true);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isLoading = false;
                            this.$message.error(this.$t('message.exception'));
                            return resolve(false);
                        });
                })
            },
            addSubmenu(parentId) {
                this.$router.push({
                    path: "/MenuAddSubmenu",
                    query: { parentMenuId: parentId }
                });
            },
            querySubmenu(row) {
                this.$router.push({
                    path: "/MenuQuerySubmenu",
                    query: { parentMenuId: row.id }
                });
            },
            returnList() {
                var returnPath = '/MenuList';
                if (this.parentMenuInfo.parentMenuId == 0) {
                    returnPath = '/MenuQuerySubmenu';
                }
                this.$router.push({
                    path: returnPath,
                    query: { parentMenuId: this.parentMenuInfo.parentMenuId }
                });
            },
            getParentInfo() {
                this.parentMenuInfo = null;
                var parentId = parseInt(this.$route.query.parentMenuId);
                if (parentId === 0 || isNaN(parentId)) {
                    this.formSearch.parentMenuId = 0;
                    this.isParentMenu = false;
                    return;
                }
                this.formSearch.parentMenuId = parentId;
                this.axios
                    .get('/api/menu/getmenubyid', {
                        params: {
                            id: parentId
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.parentMenuInfo = info;
                            this.isParentMenu = true;
                        }
                    });
            },
        },
        mounted() {
            this.getParentInfo();
            this.submitSearch();
        },
        watch: {
            $route() {
                //响应路由参数的变化
                this.getParentInfo();
                this.submitSearch();
            }
        }

    };
</script>