<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/DepartmentList' }">{{$t('message.vueDepartment.crumbDepartmentManager')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="departmentForm" :model="departmentForm" :inline="true" class="demo-form-inline" size="mini">
        <el-form-item :label="$t('message.vueDepartment.labIsDisabled')+' :'" >
            <el-select v-model="departmentForm.isDisabled" :placeholder="$t('message.pleaseSelect')" style="width:100px" size="mini">
                <el-option :label="$t('message.vueDepartment.selIsDisabledYes')" value="true"></el-option>
                <el-option :label="$t('message.vueDepartment.selIsDisabledNo')" value="false"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('message.vueDepartment.labCompanyName')+' :'" prop="company" >
            <el-select v-model="departmentForm.company" value-key="id" :placeholder="$t('message.pleaseSelect')" style="width:100px" filterable size="mini">
                <el-option :label="this.$t('message.all')" value=""></el-option>
                <el-option v-for="item in options"
                           :key="item.id"
                           :label="item.companyName"
                           :value="item">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('message.vueDepartment.labKeyword')+' :'">
            <el-input v-model="departmentForm.keyword" :placeholder="$t('message.vueDepartment.plaSearch')" size="mini"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button id="btn_search" @click="submitSearch" icon="el-icon-search" size="mini" class="btn_search">{{$t('message.search')}}</el-button>
        </el-form-item>
        <el-row>
            <el-form-item>
                <el-button type="primary" id="btn_add" @click="add" icon="el-icon-circle-plus-outline" size="mini" class="btn_add">
                    {{$t('message.vueProduct.button_add')}}
                </el-button>
            </el-form-item>
        </el-row>
    </el-form>
    <el-table :data="tableData" style="width: 100%" border size="mini"
              highlight-current-row
              :stripe="true"
              :header-cell-style="{'background':'#81761852','color':'#2F4F4F','font-size': '12px','text-align':'center'}">
        <el-table-column prop="departmentNumber" :label="$t('message.vueDepartment.labDepartmentNumber')"
                         width="160">
            <template #default="scope">
                <span>{{scope.row.departmentNumber}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="Company" :label="$t('message.vueDepartment.labCompanyName')"
                         width="150">
            <template #default="scope">
                <span>{{scope.row.companyName}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="Department" :label="$t('message.vueDepartment.labDepartmentName')"
                         width="110">
            <template #default="scope">
                <span>{{scope.row.departmentName}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="DepartmentPath" :label="$t('message.vueDepartment.labDepartmentPath')"
                         width="300">
            <template #default="scope">
                <span>{{scope.row.departmentPath}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="Leader" :label="$t('message.vueDepartment.labLeader')"
                         width="110">
            <template #default="scope">
                <span>{{scope.row.leader}}</span>
            </template>
        </el-table-column>
        
    </el-table>
    <div class="block">
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="pageIndex"
                       :page-sizes="[10, 25, 50, 75, 100]"
                       :page-size="pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="count">
        </el-pagination>
    </div>
</template>
<style>
    .el-table .success-row {
        background: #DFEAF5;
    }
    .btn_search {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }

    .btn_add {
        font-weight: bolder;
    }

    .button_edit {
        color: #fff;
        background-color: orange;
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'Department',
        data() {

            return {
                tableData: null,
                options: [],
                language: AppStorage.getLanguage(),
                departmentForm: {
                    company: {},
                    keyword: "",
                    isDisabled: "false",
                },
                pageIndex: 1,
                pageSize: 50,
                count: 0,
            }
        },
        methods: {
            getCompany() {
                this.axios
                    .get('/api/company/getcompanylist', {
                        params: {
                            isFactory: 2,
                            userNumber: AppStorage.getNumber(),
                            isAll:false
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.options=infos;
                        }
                    })
            },
            loadPage() {                
                this.axios
                    .get('/api/department/getdepartmentpage', {
                        params: {
                            isDisabled: this.departmentForm.isDisabled,
                            companyNumber: this.departmentForm.company.companyNumber,
                            keyword: this.departmentForm.keyword,
                            userNumber: AppStorage.getNumber(),
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.tableData = infos.items;
                            this.count = infos.count;
                        }
                    })
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            },
            add() {
                this.$router.push({
                    path: "/DepartmentAdd",
                    query: { id: 0 }
                });
            },
            edit(row) {
                this.$router.push({
                    path: "/DepartmentEdit",
                    query: { id: row.id }
                });
            },
            changeIsDisabled(row) {
                var isDisabled = false;
                if (row.isDisabled == false) {
                    isDisabled = true;
                }
                row.isLoading = true;
                return new Promise(resolve => {
                    this.axios
                        .post('/api/department/disableddepartment', {
                            Id: row.id,
                            CompanyNumber: row.companyNumber,
                            IsDisabled: isDisabled,
                            UserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isLoading = false;
                            if (response.data.code != 1) {
                                if (response.data.msg == '存在子部门') {
                                    this.$message.error(this.$t('message.vueDepartment.msgExistSubDepartment'));
                                    return resolve(false);
                                }
                                else if (response.data.msg == '存在人员') {
                                    this.$message.error(this.$t('message.vueDepartment.msgExistUser'));
                                    return resolve(false);
                                } 
                                else if (response.data.msg == '该公司已被禁用') {
                                    this.$message.error(this.$t('message.vueDepartment.msgDisabledCompany'));
                                    return resolve(false);
                                } else if (response.data.msg == '上级部门已被禁用') {
                                    this.$message.error(this.$t('message.vueDepartment.msgDisabledParentDepartment'));
                                    return resolve(false);
                                }
                                this.$message.error(this.$t('message.error'));
                                return resolve(false);
                            } else {
                                this.$message.success(this.$t('message.success'));
                                return resolve(true);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isLoading = false;
                            this.$message.error(this.$t('message.exception'));
                            return resolve(false);
                        });
                })
            },
        },
        mounted() {
            this.getCompany();
            this.submitSearch();  
        },
        watch: {
            $route() {
                //响应路由参数的变化
                this.submitSearch();
            }
        }

    };
</script>