<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/Vendor' }">{{$t('message.vueVendor.vendorpage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/AddVendor' }">{{$t('message.vueVendor.addvendorpage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="addvendorForm" :model="addvendorForm" class="demo-addvendorForm"
             :label-position="labelPosition"
             label-width="200px"
             :rules="rules">
                <el-row align="center" :gutter="5">
                    <el-col :span="9">
                <el-form-item :label="$t('message.vueVendor.companyType')+' :'">
                            <el-select v-model="addvendorForm.companyType" size="mini" :placeholder="$t('message.pleaseSelect')" style="width:100%">
                        <el-option v-for="item in companyTypelist"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value" />
                    </el-select>
                </el-form-item>
            </el-col>
                    <el-col :span="9">
                        <el-form-item :label="$t('message.vueVendor.vendor_oihnumber')+' :'" prop="oihVendorNumber">
                            <el-input size="mini" v-model="addvendorForm.oihVendorNumber" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
                <el-row align="center" :gutter="5">
                    <el-col :span="18">
                        <el-form-item :label="$t('message.vueVendor.vendor_name')+' :'" prop="input_vendorname">
                            <el-input size="mini" v-model="addvendorForm.input_vendorname" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
                <el-row align="center" :gutter="5">
                    <el-col :span="18">
                        <el-form-item :label="$t('message.vueVendor.paymentterm')+' :'" prop="paymentterm">
                            <el-input size="mini" v-model="addvendorForm.paymentterm" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

                <el-row align="center" :gutter="5">
                    <el-col :span="18">
                <el-form-item :label="$t('message.vueVendor.vendor_address')+' :'" prop="input_address">
                            <el-input size="mini" v-model="addvendorForm.input_address" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
                <el-row align="center" :gutter="5">
                    <el-col :span="9">
                <el-form-item :label="$t('message.vueVendor.vendor_contact')+' :'" prop="input_contact">
                            <el-input size="mini" v-model="addvendorForm.input_contact" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
                    <el-col :span="9">
                        <el-form-item :label="$t('message.vueVendor.vendor_email')+' :'" prop="vendor_email">
                            <el-input size="mini" v-model="addvendorForm.vendor_email" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
                <el-row align="center" :gutter="5">
                    <el-col :span="9">
                        <el-form-item :label="$t('message.vueVendor.vendorContact_finance')+' :'" prop="vendorContact_finance">
                            <el-input size="mini" v-model="addvendorForm.vendorContact_finance" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
                    <el-col :span="9">
                <el-form-item :label="$t('message.vueVendor.vendorEmail_p')+' :'" prop="vendoremail_p">
                            <el-input size="mini" v-model="addvendorForm.vendorEmail_p" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

                <el-row align="center" :gutter="5">
                    <el-col :span="9">
                <el-form-item :label="$t('message.vueVendor.country')+' :'" prop="country">
                            <el-input size="mini" v-model="addvendorForm.country" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
                    <el-col :span="9">
                <el-form-item :label="$t('message.vueVendor.fullname')+' :'" prop="fullname">
                            <el-input size="mini" v-model="addvendorForm.fullname" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

                <el-row align="center" :gutter="5">
                    <el-col :span="9">
                        <el-form-item :label="$t('message.vueVendor.currency')+' :'">
                            <el-select v-model="addvendorForm.currency" size="mini" :placeholder="$t('message.pleaseSelect')" style="width:100%">
                                <el-option v-for="item in currencylist"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9">
                <el-form-item :label="$t('message.vueWorkFlow.labCompanys')+' :'" prop="companys">
                    <el-select v-model="addvendorForm.companys"
                                       style="width:100%"
                               size="mini"
                               multiple
                               filterable
                               remote
                               reserve-keyword
                               :placeholder="$t('message.pleaseSelect')"
                               :remote-method="(query)=>{getCompanys(query)}"
                               :loading="addvendorForm.isloading">
                        <el-option v-for="o in allCompanys"
                                   :key="o.value"
                                   :label="o.label"
                                   :value="o.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>

                <el-row align="center" :gutter="5">
                    <el-col :span="18">
                <el-form-item>
                    <el-button class="button_submit" size="mini"
                               @click="submitForm('addvendorForm')" :disabled="disabledSubmit">{{$t('message.vueCategory.button_sava')}}</el-button>
                            <el-button @click="returnForm()" class="button_return" size="mini">{{$t('message.return')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>
<style>
    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'AddVendor',
        data() {
            return {
                language: AppStorage.getLanguage(),
                companyTypelist: [{
                    value: 'Group',
                    label: 'Group'
                }, {
                        value: 'External',
                        label: 'External'
                }],
                currencylist: [{
                    value: 'CNY',
                    label: 'CNY'
                }, {
                        value: 'USD',
                        label: 'USD'
                    }, {
                        value: 'RMB',
                        label: 'RMB'
                    }, {
                        value: 'AUD',
                        label: 'AUD'
                    }],
                addvendorForm: {
                    isloading: false,
                    companyType:'',
                    input_vendorname: '',
                    oihVendorNumber: '',
                    paymentterm: '',
                    currency: '',
                    input_address: '',
                    input_contact: '',
                    vendorContact_finance: '',
                    input_vendortel: '',
                    vendor_email: '',
                    vendorEmail_p: '',
                    country: '',
                    fullname: '',
                    companys: '',
                    userNumber: AppStorage.getNumber()
                },
                seen: true,
            }
        },
        computed: {
            rules() {
                const rules = {
                    input_vendorname: [
                        { required: true, message: this.$t('message.vueVendor.verifyVendorRequired'), trigger: 'blur' }
                    ],
                    paymentterm: [
                        { required: true, message: this.$t('message.vueVendor.verifyVendorRequired'), trigger: 'blur' }
                    ],
                    input_address: [
                        { required: true, message: this.$t('message.vueVendor.verifyVendorRequired'), trigger: 'blur' }
                    ],
                    input_contact: [
                        { required: true, message: this.$t('message.vueVendor.verifyVendorRequired'), trigger: 'blur' }
                    ],
                    input_email: [
                        { required: true, message: this.$t('message.vueVendor.verifyVendorRequired'), trigger: 'blur' }
                    ]
                };
                return rules;
            }
        },
        methods: {
            returnForm() {
                this.$router.go(-1);
            },
            getCompanys(query, isAll = true) {
                this.addvendorForm.isloading = true;
                this.axios
                    .get('/api/company/getcompanylist', {
                        params: {
                            isFactory: 2,
                            userNumber: AppStorage.getNumber(),
                            keyword: query,
                            isAll: isAll
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allCompanys = infos.map(function (m) {
                                return {
                                    value: m.companyNumber,
                                    label: m.companyName + "(" + m.companyNumber + ")"
                                }
                            });
                        }
                        this.addvendorForm.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.addvendorForm.isloading = false;
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.addvendorForm.input_vendorname != '') {
                            this.disabledSubmit = true;
                            this.axios
                                .post('/api/Vendor/addvendor', {
                                    CompanyType: this.addvendorForm.companyType,
                                    VendorName: this.addvendorForm.input_vendorname,
                                    OIHVendorNumber: this.addvendorForm.oihVendorNumber,
                                    VendorAddress: this.addvendorForm.input_address,
                                    Paymentterm: this.addvendorForm.paymentterm,
                                    Currency: this.addvendorForm.currency,
                                    VendorContact: this.addvendorForm.input_contact,
                                    VendorContact_finance: this.addvendorForm.vendorContact_finance,
                                    VendorTel: this.addvendorForm.input_vendortel,
                                    VendorEmail_p: this.addvendorForm.vendorEmail_p,
                                    EmailFin: this.addvendorForm.vendor_email,
                                    Country: this.addvendorForm.country,
                                    Fullname: this.addvendorForm.fullname,
                                    Companys: this.addvendorForm.companys?.toString(),
                                    UserNumber: this.addvendorForm.userNumber
                                })
                                .then(response => {
                                    if (response.data.code != 1) {
                                        if (response.data.msg == '供应商已存在') {
                                            this.$message.error(this.$t('message.vueVendor.msgExistVendorNumber'));
                                        } else {
                                            this.$message.error(this.$t('message.errer'));
                                        }
                                    } else {
                                        this.$message.success(this.$t('message.success'));
                                        this.$router.push({ path: "/Vendor" });
                                    }
                                    this.disabledSubmit = false;
                                }).catch(error => {
                                    console.log(error);
                                    this.$message.error(this.$t('message.messageBox.connecterror'));
                                    this.disabledSubmit = false;
                                });
                        } else {
                            alert(this.$t('message.vueVendor.addvendorerror'));
                        }
                    } else {
                        return false;
                    }
                });
            }
        },
        //这个组件内的方法，函数
        mounted() {
            this.getCompanys('', true);
        }
    };
</script>
