<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/DepartmentList' }">{{$t('message.vueDepartment.crumbDepartmentManager')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="departmentForm.id==0">{{$t('message.add')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-else>{{$t('message.edit')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="departmentForm" :model="departmentForm" :rules="rules" label-width="200px" class="demo-ruleForm">
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueDepartment.labCompanyName')" prop="company">
                    <el-select v-model="departmentForm.company" value-key="id" :placeholder="$t('message.pleaseSelect')" @change="getDepartment($event)" filterable>
                        <el-option :label="$t('message.pleaseSelect')" value=""></el-option>
                        <el-option v-for="item in companylist"
                                   :key="item.id"
                                   :label="item.companyName"
                                   :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueDepartment.labParentDepartmentName')" prop="department">
                    <el-select v-model="departmentForm.department" value-key="id" :placeholder="$t('message.pleaseSelect')" filterable>
                        <el-option :label="$t('message.pleaseSelect')" value=""></el-option>
                        <el-option v-for="item in departmentlist"
                                   :key="item.id"
                                   :label="item.departmentName"
                                   :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueDepartment.labDepartmentName')" prop="departmentName">
                    <el-input v-model="departmentForm.departmentName" style="max-width:217px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueDepartment.labLeader')" prop="leader">
                    <el-input v-model="departmentForm.leader" @click="examineBtn" readonly style="max-width:217px"></el-input>
                    <el-input v-model="departmentForm.leaderNumber" style="display:none"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item>
                    <el-button type="primary" @click="submitForm('departmentForm')"
                               :disabled="disabledSubmit" size="mini"
                               class="button_submit">{{$t('message.submit')}}</el-button>
                    <el-button @click="returnForm()" class="button_return" size="mini">{{$t('message.return')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
        <userSelect ref="msgBtn" v-on:getUserEven="getUser"></userSelect>
    </el-form>
</template>

<style>
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }

    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    import userSelect from '../../components/User/UserSelect'
    export default {
        name: '',
        components: {
            //挂载组件
            userSelect, //自定义的标签
        },
        data() {
            return {
                disabledSubmit: false,
                companylist: [],
                departmentlist: [],
                departmentForm: {
                    id: 0,
                    company: null,
                    department: null,
                    departmentName: '',
                    leader: '',
                    leaderNumber: '',
                    userNumber: AppStorage.getNumber()
                },
            }
        },
        computed: {
            rules() {
                const rules = {
                    company: [
                        { required: true, message: this.$t('message.vueDepartment.verifyCompanyRequired'), trigger: 'change', type: 'object' }
                    ],
                    departmentName: [
                        { required: true, message: this.$t('message.vueDepartment.verifyDepartmentRequired'), trigger: 'blur' }
                    ]
                };
                return rules;
            }
        },
        methods: {
            getCompany() {
                this.axios
                    .get('/api/company/getcompanylist', {
                        params: {
                            //isFactory: 2,
                            isFactory: 0,
                            userNumber: AppStorage.getNumber(),
                            isAll: true
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.companylist = infos;
                        }
                    });
            },
            getDepartment(opt) {
                this.departmentlist = [];
                this.departmentForm.department = {};
                if (opt.id != "") {
                    this.axios
                        .get('/api/department/getdepartmentlist', {
                            params: {
                                companyNumber: opt.companyNumber,
                                userNumber: AppStorage.getNumber(),
                                isAll: false
                            }
                        }).then(response => {
                            if (response.data.code == 1) {
                                var infos = response.data.data;
                                this.departmentlist = infos;
                            }
                        });
                }
            },
            getInfo() {
                var infoId = this.$route.query.id;
                if (infoId === '0') {
                    return;
                }
                this.axios
                    .get('/api/department/getdepartment', {
                        params: {
                            id: infoId
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.departmentForm.company = {
                                id: info.companyId,
                                companyName: info.companyName,
                                companyNumber: info.companyNumber
                            };
                            this.getDepartment(this.departmentForm.company);
                            this.departmentForm.department = {
                                id: info.parentId,
                                departmentName: info.parentName,
                            };
                            this.departmentForm.id = info.id;
                            this.departmentForm.departmentName = info.departmentName;
                            this.departmentForm.leader = info.leader;
                            this.departmentForm.leaderNumber = info.leaderNumber;
                        }
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/department/addoreditdepartment', {
                                Id: this.departmentForm.id,
                                CompanyId: this.departmentForm.company.id,
                                CompanyNumber: this.departmentForm.company.companyNumber,
                                DepartmentName: this.departmentForm.departmentName,
                                UserNumber: this.departmentForm.userNumber,
                                ParentId: this.departmentForm.department.id,
                                ParentName: this.departmentForm.department.departmentName,
                                leaderNumber: this.departmentForm.leaderNumber,
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    if (response.data.msg == '存在子部门') {
                                        this.$message.error(this.$t('message.vueDepartment.msgEditDepartment'));
                                    }
                                    else if (response.data.msg == '该部门已存在') {
                                        this.$message.error(this.$t('message.vueDepartment.msgExistDepartment'));
                                    } else {
                                        this.$message.error(this.$t('message.errer'));
                                    }
                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    this.$router.push("/DepartmentList");
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });

                    } else {
                        return false;
                    }
                });
            },
            returnForm() {
                this.$router.go(-1);
            },
            examineBtn() {
                this.$refs.msgBtn.init();
            },
            getUser(user) {
                if (user.length > 0) {
                    this.departmentForm.leader = user[0].name;
                    this.departmentForm.leaderNumber = user[0].number;
                } else {
                    this.departmentForm.leader = "";
                    this.departmentForm.leaderNumber = "";
                }
            },
        },
        mounted() {
            this.getCompany();
            this.getInfo();
        },
    }

</script>