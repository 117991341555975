<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/Vendor' }">{{$t('message.vueVendor.vendorpage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/VendorDetail' }">{{$t('message.vueVendor.vendordetailpage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-button @click="returnForm()" size="mini" class="button_return">{{$t('message.return')}}</el-button>
    <el-divider></el-divider>
    <el-descriptions class="vendorDetailForm" :column="1" :size="mini" border>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueVendor.companyType')}}
            </template>
            {{vendorDetailForm.companyType}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueVendor.vendor_number')}}
            </template>
            {{vendorDetailForm.vendorNumber}}
        </el-descriptions-item>

        <el-descriptions-item>
            <template #label>
                {{$t('message.vueVendor.vendor_oihnumber')}}
            </template>
            {{vendorDetailForm.oihVendorNumber}}
        </el-descriptions-item>

        <el-descriptions-item>
            <template #label>
                {{$t('message.vueVendor.vendor_name')}}
            </template>
            {{vendorDetailForm.vendorName}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueVendor.paymentterm')}}
            </template>
            {{vendorDetailForm.paymentterm}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueVendor.currency')}}
            </template>
            {{vendorDetailForm.currency}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueVendor.vendor_address')}}
            </template>
            {{vendorDetailForm.vendorAddress}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueVendor.vendor_contact')}}
            </template>
            {{vendorDetailForm.vendorContact}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueVendor.vendorContact_finance')}}
            </template>
            {{vendorDetailForm.vendorContactFinance}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueVendor.vendor_email')}}
            </template>
            {{vendorDetailForm.vendorEmail}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueVendor.vendorEmail_p')}}
            </template>
            {{vendorDetailForm.vendorEmailPurchasing}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueVendor.country')}}
            </template>
            {{vendorDetailForm.country}}
        </el-descriptions-item>

        <el-descriptions-item>
            <template #label>
                {{$t('message.vueVendor.fullname')}}
            </template>
            {{vendorDetailForm.fullname}}
        </el-descriptions-item>

        <el-descriptions-item>
            <template #label>
                {{$t('message.vueWorkFlow.labCompanys')}}
            </template>
            {{vendorDetailForm.companys}}
        </el-descriptions-item>

    </el-descriptions>
</template>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'VendorDetail',
        data() {
            return {
                language: AppStorage.getLanguage(),
                companyTypelist: [{
                    value: 'Group',
                    label: 'Group'
                }, {
                    value: 'External',
                    label: 'External'
                }],
                currencylist: [{
                    value: 'CNY',
                    label: 'CNY'
                }, {
                        value: 'USD',
                        label: 'USD'
                    }, {
                        value: 'RMB',
                        label: 'RMB'
                    }, {
                        value: 'AUD',
                        label: 'AUD'
                    }],
                vendorDetailForm: {
                    vendorId: '',
                    companyType: '',
                    vendorName: '',
                    vendorNumber: '',
                    oihVendorNumber: '',
                    paymentterm: '',
                    currency: '',
                    vendorAddress: '',
                    vendorContact: '',
                    vendorContactFinance: '',
                    vendorEmail: '',
                    vendorEmailPurchasing: '',
                    country: '',
                    fullname: '',
                    userNumber: AppStorage.getNumber()
                },
                seen: true,
            }
        },
        methods: {
            getInfo() {
                var id = this.$route.query.Id;
                if (id == 0) {
                    return;
                }
                this.axios
                    .get('/api/Vendor/getvendorbyid', {
                        params: {
                            Id: id
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.vendorDetailForm.vendorId = info.id;
                            this.vendorDetailForm.companyType = info.companyType;
                            this.vendorDetailForm.vendorName = info.vendorName;
                            this.vendorDetailForm.vendorNumber = info.vendorNumber;
                            this.vendorDetailForm.oihVendorNumber = info.oihVendorNumber;
                            this.vendorDetailForm.paymentterm = info.paymentterm;
                            this.vendorDetailForm.currency = info.currency;
                            this.vendorDetailForm.vendorAddress = info.vendorAddress;
                            this.vendorDetailForm.vendorContact = info.vendorContact;
                            this.vendorDetailForm.vendorContactFinance = info.vendorContact_finance;
                            this.vendorDetailForm.vendorEmail = info.emailFin;
                            this.vendorDetailForm.vendorEmailPurchasing = info.vendorEmail_p;
                            this.vendorDetailForm.country = info.country;
                            this.vendorDetailForm.fullname = info.fullname;
                            this.vendorDetailForm.companys = info.companys;
                        }
                    });
            },
            returnForm() {
                this.$router.go(-1);
            }
        },
        //这个组件内的方法，函数
        mounted() {
            this.getInfo();
        }
    };
</script>
<style>
    .vendorDetailForm .el-descriptions__label {
        width: 220px;
    }
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }
</style>
