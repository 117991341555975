<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/MailRecordList' }">{{$t('message.vueMailRecord.mailrecordpage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="mailRecord">
        <el-form ref="mailRecordForm" :model="mailRecordForm" :inline="true" class="mailrecordform-inline">
            
            <el-form-item :label="$t('message.vueMailRecord.mailrecordPO')+' :'">
                <el-input size="mini" v-model="mailRecordForm.poNumber"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button size="mini" @click="submitSearch" icon="el-icon-search" class="btn_search">{{$t('message.vueCategory.button_search')}}</el-button>
            </el-form-item>

        </el-form>

        <el-table :data="tableData" border size="mini"
                  :stripe="true"
                  :header-cell-style="{'background':'#81761852','color':'#2F4F4F','font-size': '12px','text-align':'center'}">
            <el-table-column prop="AddTime" :label="$t('message.vueMailRecord.mailsendtime')" width="200">
                <template #default="scope">
                    <span>{{scope.row.addTime}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="AddUserName" :label="$t('message.vueMailRecord.mailSender')" width="140">
                <template #default="scope">
                    <span>{{scope.row.addUserName}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="AddresSeeName" :label="$t('message.vueMailRecord.mailaddressee')" width="140">
                <template #default="scope">
                    <span>{{scope.row.addresSeeName}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="PONumber" :label="$t('message.vueMailRecord.mailPONumber')" width="140">
                <template #default="scope">
                    <span>{{scope.row.poNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="SAPT9Number" :label="$t('message.vueMailRecord.mailSAPT9Number')" width="140">
                <template #default="scope">
                    <span>{{scope.row.sapT9Number}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="Content" :label="$t('message.vueMailRecord.mailContent')" width="140">
                <template #default="scope">
                    <span>{{scope.row.content}}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$t('message.operate')" min-width="300">
                <template #default="scope">
                    <el-button size="mini"
                               icon="el-icon-edit"
                               class = "button_edit"
                               @click="goEditcategory(scope.row)">{{$t('message.vueMailRecord.btnresend')}}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="pageIndex"
                       :page-sizes="[10, 25, 50, 75, 100]"
                       :page-size="pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="count">
        </el-pagination>
    </div>
</template>

<style>
    .btn_search {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }

    .button_edit {
        color: #fff;
        background-color: orange;
        font-weight: bolder;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'MailRecordList',
        data() {
            return {
                tableData: null,
                language: AppStorage.getLanguage(),
                mailRecordForm: {
                    poNumber: ''
                },
                pageIndex: 1,
                pageSize: 50,
                count: 0
            }
        },
        methods: {
            loadPage() {                
                this.axios
                    .get('/api/MailRecord/getmailrecordpage', {
                        params: {
                            PONumber: this.mailRecordForm.poNumber,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        },
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.tableData = infos.items;
                            this.count = infos.count;
                        }
                    })
            },
            goEditcategory(row) {
                this.$router.push({
                    path: "/POOIHCheck",
                    query: { id: row.formId }
                });
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            }
        },
        mounted() {
            this.loadPage();
        },
        watch: {
            $route() {
                //响应路由参数的变化
                this.submitSearch();
            }
        }
    };
</script>
