<template>
    <el-container style="height: 100%;">

        <el-header style="height:56px">
            <el-row>
                <el-col :span="12" style="text-align: left; font-size: 12px">
                    <div class="toggleDiv" @click="toggleCollapse"><i :class="isCollapse==true ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i></div>
                </el-col>
                <el-col :span="12" style="text-align: right; font-size: 12px">
                    <el-dropdown>
                        <span class="el-dropdown-link">
                            <span class="head-portrait"><el-avatar icon="el-icon-user-solid"></el-avatar></span>
                            {{userName}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="logout(lang)">{{$t('message.vueMenu.btnLogout')}}</el-dropdown-item>
                                <el-dropdown-item @click="changepassword(lang)">{{$t('message.vueMenu.btnPassword')}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <el-dropdown @command="handleCommandLanguage">
                        <span class="el-dropdown-link">
                            {{languageText}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item command="en">English</el-dropdown-item>
                                <el-dropdown-item command="zh-tw">繁體中文</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </el-col>
            </el-row>
        </el-header>
        <el-container>
            <el-aside width="auto" style="background-color: rgb(238, 241, 246)" class="menu-sideMenu">
                <el-menu default-active="0-0" default-openeds="['1','69','331','451']"
                         class="el-menu-vertical-demo" :collapse="isCollapse" router>
                    <MenuSideSub :items="sideMenuList" />
                </el-menu>
            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
    <userChangePassword ref="msgBtn" :formType="userId"></userChangePassword>
</template>

<script>
    import AppStorage from '@/common/storage/app-storage'
    import userChangePassword from '@/components/User/UserChangePassword'
    import MenuSideSub from "./MenuSideSub.vue";
    var userId = 0
    export default {
        name: 'MenuSide',
        components: { MenuSideSub, userChangePassword },
        data() {
            return {
                userName: AppStorage.getName(),
                language: AppStorage.getLanguage(),
                languageText: AppStorage.getLanguage() == 'en' ? 'English' :'繁體中文',
                isCollapse: false,
                sideMenuList: AppStorage.getSideMenuList() || [],
            }
        },
        methods: {
            logout() {
                AppStorage.remove();
                this.$i18n.local = this.language;
                this.$router.push('/login');
            },
            getInfo() {
                this.axios
                    .get('/api/user/getuserbyname', {
                        params: {
                            userName: AppStorage.getName(),
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            userId = info.id;
                        }
                    });
            },
            changepassword() {
                console.log(this);
                this.$refs.msgBtn.init(userId);
            },
            toggleCollapse() {
                this.isCollapse = !this.isCollapse;
            },
            handleCommandLanguage(command) {
                localStorage.setItem('language', command);
                AppStorage.setLanguage(command);
                this.language = command;
                location.reload();
            }
        },
        mounted() {
            this.getInfo();
        },
    }
</script>

<style>
    html, body {
        margin: 0;
        height: 100%;
        font-family: Tahoma,Arial, Helvetica, sans-serif;
        font-size: 12px;
    }

    #app {
        height: 100%;
    }

    .el-header {
        padding: 0 20px 0 0;
        background-color: #333;
        color: #fff;
        line-height: 56px;
    }

    .el-aside {
        color: #333;
    }

    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 260px;
    }

    .toggleDiv {
        font-size: 20px;
        height: 56px;
        padding: 0 20px;
        display: inline-block;
        color: #eee;
    }

        .toggleDiv i {
            margin: 0;
            vertical-align: middle;
            width: 24px;
            text-align: center;
        }

    .el-dropdown {
        margin-left: 15px;
        color: #eee;
    }

    .el-dropdown-link {
        cursor: pointer;
        font-size:12px;
    }

    .head-portrait .el-avatar {
        float: left;
        margin: -15px 5px 0 0;
    }

    .head-portrait:after {
        content: "";
        clear: both;
    }
    .menu-sideMenu .el-menu-item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        background-color: grey;
        color: white;
    }
    .menu-sideMenu .el-submenu__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bolder;
        font-size: 14px;
        background-color: darkgrey;
        color: white;
    }

    .menu-sideMenu .el-menu-item.is-active {
        font-weight: bold;
        font-size: 12px;
        background-color: #29adfa;
    }
</style>
