<template>
    <MenuSide/>
</template>
  
<script>
    import MenuSide from "@/components/Menu/MenuSide.vue";
    export default {
        name: 'Home',
        components: { MenuSide }
    };
</script>