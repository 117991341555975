<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/Vendor' }">{{$t('message.vueVendor.vendorpage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/EditVendor' }">{{$t('message.vueVendor.editvendorpage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="editvendorForm" :model="editvendorForm" class="demo-editvendorForm"
             :label-position="labelPosition"
             label-width="200px"
             :rules="rules">
                <el-row align="center" :gutter="5">
                    <el-col :span="9">
                <el-form-item :label="$t('message.vueVendor.companyType')+' :'">
                            <el-select v-model="editvendorForm.companyType" size="mini"
                                       :disabled="true" :placeholder="$t('message.pleaseSelect')" style="width:100%">
                        <el-option v-for="item in companyTypelist"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value" />
                    </el-select>
                </el-form-item>
            </el-col>
                    <el-col :span="9">
                <el-form-item :label="$t('message.vueVendor.vendor_number')+' :'">
                            <el-input size="mini" v-model="editvendorForm.input_vendornumber" :disabled="true" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

                <el-row align="center" :gutter="5">
                    <el-col :span="9">
                <el-form-item :label="$t('message.vueVendor.vendor_oihnumber')+' :'">
                            <el-input size="mini" v-model="editvendorForm.oihVendorNumber" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
                    <el-col :span="9">
                <el-form-item :label="$t('message.vueVendor.currency')+' :'">
                            <el-select v-model="editvendorForm.currency" size="mini" :placeholder="$t('message.pleaseSelect')" style="width:100%">
                        <el-option v-for="item in currencylist"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value" />
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>

                <el-row align="center" :gutter="5">
                    <el-col :span="18">
                        <el-form-item :label="$t('message.vueVendor.vendor_name')+' :'">
                            <el-input size="mini" v-model="editvendorForm.input_vendorname" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
                <el-row align="center" :gutter="5">
                    <el-col :span="18">
                        <el-form-item :label="$t('message.vueVendor.paymentterm')+' :'" prop="paymentterm">
                            <el-input size="mini" v-model="editvendorForm.paymentterm" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
                <el-row align="center" :gutter="5">
                    <el-col :span="18">
                        <el-form-item :label="$t('message.vueVendor.vendor_address')+' :'">
                            <el-input size="mini" v-model="editvendorForm.input_address" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
                <el-row align="center" :gutter="5">
                    <el-col :span="9">
                        <el-form-item :label="$t('message.vueVendor.vendor_contact')+' :'" prop="input_contact">
                            <el-input size="mini" v-model="editvendorForm.input_contact" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
                    <el-col :span="9">
                <el-form-item :label="$t('message.vueVendor.vendor_email')+' :'" prop="vendor_email">
                            <el-input size="mini" v-model="editvendorForm.vendor_email" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
                <el-row align="center" :gutter="5">
                    <el-col :span="9">
                        <el-form-item :label="$t('message.vueVendor.vendorContact_finance')+' :'" prop="vendorContact_finance">
                            <el-input size="mini" v-model="editvendorForm.vendorContact_finance" style="width:100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9">
                <el-form-item :label="$t('message.vueVendor.vendorEmail_p')+' :'" prop="vendoremail_p">
                            <el-input size="mini" v-model="editvendorForm.vendorEmail_p" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

                <el-row align="center" :gutter="5">
                    <el-col :span="9">
                <el-form-item :label="$t('message.vueVendor.country')+' :'" prop="country">
                            <el-input size="mini" v-model="editvendorForm.country" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
                    <el-col :span="9">
                <el-form-item :label="$t('message.vueVendor.fullname')+' :'" prop="fullname">
                            <el-input size="mini" v-model="editvendorForm.fullname" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
        </el-row>


                <el-row align="center" :gutter="5">
                    <el-col :span="18">
                <el-form-item :label="$t('message.vueWorkFlow.labCompanys')+' :'" prop="companys">
                    <el-select v-model="editvendorForm.companys"
                                       style="width:100%"
                               size="mini"
                               multiple
                               filterable
                               remote
                               reserve-keyword
                               :placeholder="$t('message.pleaseSelect')"
                               :remote-method="(query)=>{getCompanys(query)}"
                               :loading="editvendorForm.isloading">
                        <el-option v-for="o in allCompanys"
                                   :key="o.value"
                                   :label="o.label"
                                   :value="o.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>

                <el-row  align="center" :gutter="5">
                    <el-col :span="18">
                <el-form-item>
                    <el-button class="button_submit" size="mini"
                               @click="submitForm('editvendorForm')" :disabled="disabledSubmit">{{$t('message.vueCategory.button_sava')}}</el-button>
                            <el-button @click="returnForm()" class="button_return" size="mini">{{$t('message.return')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>
<style>
    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'EditVendor',
        data() {
            return {
                language: AppStorage.getLanguage(),
                companyTypelist: [{
                    value: 'Group',
                    label: 'Group'
                }, {
                    value: 'External',
                    label: 'External'
                }],
                currencylist: [{
                    value: 'CNY',
                    label: 'CNY'
                }, {
                        value: 'USD',
                        label: 'USD'
                    }, {
                        value: 'RMB',
                        label: 'RMB'
                    }, {
                        value: 'AUD',
                        label: 'AUD'
                    }],
                editvendorForm: {
                    isloading: false,
                    input_vendorid: '',
                    companyType: '',
                    input_vendorname: '',
                    input_vendornumber: '',
                    oihVendorNumber: '',
                    paymentterm: '',
                    currency: '',
                    input_address: '',
                    input_contact: '',
                    vendorContact_finance: '',
                    vendor_email: '',
                    vendorEmail_p: '',
                    country: '',
                    fullname: '',
                    companys: '',
                    userNumber: AppStorage.getNumber()
                },
                seen: true,
            }
        },
        methods: {
            returnForm() {
                this.$router.go(-1);
            },
            getCompanys(query, isAll = true) {
                this.editvendorForm.isloading = true;
                this.axios
                    .get('/api/company/getcompanylist', {
                        params: {
                            isFactory: 2,
                            userNumber: AppStorage.getNumber(),
                            keyword: query,
                            isAll: isAll
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allCompanys = infos.map(function (m) {
                                return {
                                    value: m.companyNumber,
                                    label: m.companyName + "(" + m.companyNumber + ")"
                                }
                            });
                        }
                        this.editvendorForm.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.editvendorForm.isloading = false;
                    });
            },
            getInfo() {
                var id = this.$route.query.Id;
                if (id == 0) {
                    return;
                }
                this.axios
                    .get('/api/Vendor/getvendorbyid', {
                        params: {
                            Id: id
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.editvendorForm.input_vendorid = info.id;
                            this.editvendorForm.companyType = info.companyType;
                            this.editvendorForm.input_vendorname = info.vendorName;
                            this.editvendorForm.input_vendornumber = info.vendorNumber;
                            this.editvendorForm.oihVendorNumber = info.oihVendorNumber;
                            this.editvendorForm.paymentterm = info.paymentterm;
                            this.editvendorForm.currency = info.currency;
                            this.editvendorForm.input_address = info.vendorAddress;
                            this.editvendorForm.input_contact = info.vendorContact;
                            this.editvendorForm.vendorContact_finance = info.vendorContact_finance;
                            this.editvendorForm.vendor_email = info.emailFin;
                            this.editvendorForm.vendorEmail_p = info.vendorEmail_p;
                            this.editvendorForm.country = info.country;
                            this.editvendorForm.fullname = info.fullname;
                            this.editvendorForm.companys = info.companys == null || info.companys == '' ? '' : info.companys.split(',');
                        }
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.editvendorForm.input_vendornumber != '' && this.editvendorForm.input_vendorname != '') {
                            this.disabledSubmit = true;
                            this.axios
                                .post('/api/Vendor/addvendor', {
                                    Id: this.editvendorForm.input_vendorid,
                                    CompanyType: this.editvendorForm.companyType,
                                    VendorName: this.editvendorForm.input_vendorname,
                                    VendorNumber: this.editvendorForm.input_vendornumber,
                                    OIHVendorNumber: this.editvendorForm.oihVendorNumber,
                                    VendorAddress: this.editvendorForm.input_address,
                                    VendorContact: this.editvendorForm.input_contact,
                                    VendorContact_finance: this.editvendorForm.vendorContact_finance,
                                    EmailFin: this.editvendorForm.vendor_email,
                                    Paymentterm: this.editvendorForm.paymentterm,
                                    Currency: this.editvendorForm.currency,
                                    VendorEmail_p: this.editvendorForm.vendorEmail_p,
                                    Country: this.editvendorForm.country,
                                    Fullname: this.editvendorForm.fullname,
                                    Companys: this.editvendorForm.companys?.toString(),
                                    UserNumber: AppStorage.getNumber()
                                })
                                .then(response => {
                                    if (response.data.code != 1) {
                                        this.$message.error(this.$t('message.errer'));
                                    } else {
                                        this.$message.success(this.$t('message.success'));
                                        this.$router.push({ path: "/Vendor" });
                                    }
                                    this.disabledSubmit = false;
                                }).catch(error => {
                                    console.log(error);
                                    this.$message.error(this.$t('message.messageBox.connecterror'));
                                    this.disabledSubmit = false;
                                });
                        } else {
                            alert(this.$t('message.vueVendor.addvendorerror'));
                        }
                    } else {
                        return false;
                    }
                });
            }
        },
        //这个组件内的方法，函数
        mounted() {
            this.getInfo();
            this.getCompanys('', true);
        }
    };
</script>
