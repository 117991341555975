<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/Cgy' }">{{$t('message.vueCategory.categorypage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/AddCategory' }">{{$t('message.vueCategory.addcategorypage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="addcategoryForm" :model="addcategoryForm" :rules="rules" label-width="200px" class="adddata">
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueCategory.category')+' :'" prop="input_category">
                    <el-input size="mini" v-model="addcategoryForm.input_category" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueCategory.category_number')+' :'" prop="input_categorynumber">
                    <el-input size="mini" v-model="addcategoryForm.input_categorynumber" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item>
                    <el-button class="addcategory-button" size="mini"
                               @click="submitForm('addcategoryForm')"
                               :disabled="disabledSubmit">
                        {{$t('message.vueCategory.button_sava')}}
                    </el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<style>
    .addcategory-button {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'AddCategory',
        data() {
            return {
                language: AppStorage.getLanguage(),
                addcategoryForm: {
                    input_category: '',
                    input_categorynumber: ''
                }
            }
        },
        computed: {
            rules() {
                const rules = {
                    input_category: [
                        { required: true, message: this.$t('message.vueCategory.verifyCategoryRequired'), trigger: 'blur' }
                    ],
                    input_categorynumber: [
                        { required: true, message: this.$t('message.vueCategory.verifyCategoryNumberRequired'), trigger: 'blur' }
                    ]
                };
                return rules;
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/Category/addcategory', {
                                Id: this.addcategoryForm.Id,
                                Category: this.addcategoryForm.input_category,
                                CategoryNumber: this.addcategoryForm.input_categorynumber,
                                UserNumber: AppStorage.getNumber()
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    if (response.data.msg == '该品类已经存在,请重新录入') {
                                        this.$message.error(this.$t('message.vueCategory.msgExistCategory'));
                                    } else if (response.data.msg == '该编号已经存在,请重新录入') {
                                        this.$message.error(this.$t('message.vueCategory.msgExistEditCategory'));
                                    } else {
                                        this.$message.error(this.$t('message.errer'));
                                    }
                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    this.$router.push({ path: "/Cgy" });
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });
                    } else {
                        return false;
                    }
                });
            } 
        },
        //这个组件内的方法，函数
        mounted() {
        }
    };
</script>
