<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/MenuList' }">{{$t('message.vueMenu.crumbMenuManager')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="ruleForm.id==0">{{$t('message.add')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-else>{{$t('message.edit')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div v-if="isParentMenu">
        <el-descriptions class="margin-top" :title="$t('message.vueMenu.labParentMenu')" :column="2" :size="size">
            <el-descriptions-item :label="$t('message.vueMenu.labMenuNameZHCN')" v-if="language === 'zh-ch'">{{parentMenuInfo.menuNameZHCN}}</el-descriptions-item>
            <el-descriptions-item :label="$t('message.vueMenu.labMenuNameEN')" v-else-if="language === 'en'">{{parentMenuInfo.menuNameEN}}</el-descriptions-item>
            <el-descriptions-item :label="$t('message.vueMenu.labMenuNameZHTW')" v-else>{{parentMenuInfo.menuNameZHTW}}</el-descriptions-item>
            <el-descriptions-item :label="$t('message.vueMenu.labRoutePath')">{{parentMenuInfo.routePath}}</el-descriptions-item>
            <el-descriptions-item :label="$t('message.vueMenu.labRouteName')">{{parentMenuInfo.routeName}}</el-descriptions-item>
            <el-descriptions-item :label="$t('message.vueMenu.labRouteComponent')">{{parentMenuInfo.routeComponent}}</el-descriptions-item>
        </el-descriptions>
        <el-divider></el-divider>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-form-item :label="$t('message.vueMenu.labMenuType')" prop="menuType">
            <el-radio-group v-model="ruleForm.menuType">
                <el-radio :label="1">{{$t('message.vueMenu.radioMenuTypeSideMenu')}}</el-radio>
                <el-radio :label="2">{{$t('message.vueMenu.radioMenuTypeButton')}}</el-radio>
                <el-radio :label="3">{{$t('message.vueMenu.radioMenuTypeAPI')}}</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('message.vueMenu.labMenuNameZHCN')" prop="menuNameZHCN">
            <el-input v-model="ruleForm.menuNameZHCN"></el-input>
        </el-form-item>
        <el-form-item :label="$t('message.vueMenu.labMenuNameEN')" prop="menuNameEN">
            <el-input v-model="ruleForm.menuNameEN"></el-input>
        </el-form-item>
        <el-form-item :label="$t('message.vueMenu.labMenuNameZHTW')" prop="menuNameZHTW">
            <el-input v-model="ruleForm.menuNameZHTW"></el-input>
        </el-form-item>
        <el-form-item :label="$t('message.vueMenu.labIcon')" prop="icon">
            <el-input v-model="ruleForm.icon"></el-input>
        </el-form-item>
        <el-form-item :label="$t('message.vueMenu.labRoutePath')" prop="routePath">
            <el-input v-model="ruleForm.routePath"></el-input>
        </el-form-item>
        <el-form-item :label="$t('message.vueMenu.labRouteName')" prop="routeName">
            <el-input v-model="ruleForm.routeName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('message.vueMenu.labRouteComponent')" prop="routeComponent">
            <el-input v-model="ruleForm.routeComponent"></el-input>
        </el-form-item>
        <el-form-item :label="$t('message.vueMenu.labRouteParentName')" prop="routeParentName">
            <el-input v-model="ruleForm.routeParentName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('message.vueMenu.labSort')" prop="sort">
            <el-input-number v-model="ruleForm.sort" controls-position="right" :min="0" :max="99999"></el-input-number>
        </el-form-item>
        <el-form-item :label="$t('message.vueMenu.labNoRequiresAuth')" prop="noRequiresAuth">
            <el-tooltip :content="$t('message.vueMenu.labNoRequiresAuth')" placement="top" style="margin-right:10px">
                <el-switch v-model="ruleForm.noRequiresAuth" active-color="#ff4949" inactive-color="#13ce66"></el-switch>
            </el-tooltip>
            <span v-if="ruleForm.noRequiresAuth===true">
                {{$t('message.vueMenu.selIsDisabledYes')}}
            </span>
            <span v-else>
                {{$t('message.vueMenu.selIsDisabledNo')}}
            </span>
        </el-form-item>
        <el-form-item :label="$t('message.vueMenu.labIsData')" prop="isData">
            <el-tooltip :content="$t('message.vueMenu.labIsData')" placement="top" style="margin-right:10px">
                <el-switch v-model="ruleForm.isData" active-color="#ff4949" inactive-color="#13ce66"></el-switch>
            </el-tooltip>
            <span v-if="ruleForm.isData===true">
                {{$t('message.vueMenu.selIsDisabledYes')}}
            </span>
            <span v-else>
                {{$t('message.vueMenu.selIsDisabledNo')}}
            </span>
        </el-form-item>
        <el-form-item>
            <el-button @click="submitForm('ruleForm')" :disabled="disabledSubmit" class="button_submit" size="mini">
                {{$t('message.submit')}}
            </el-button>
            <el-button @click="returnForm()" class="button_return" size="mini">{{$t('message.return')}}</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: '',
        data() {
            return {
                disabledSubmit: false,
                ruleForm: {
                    id: 0,
                    menuType: 1,
                    menuNameZHCN: '',
                    menuNameEN: '',
                    menuNameZHTW: '',
                    icon: '',
                    routePath: '',
                    routeName: '',
                    routeComponent: '',
                    routeParentName: 'Home',
                    sort: 0,
                    parentMenuId: 0,
                    noRequiresAuth: false,
                    isData: false,
                    userNumber: AppStorage.getNumber()
                },
                parentMenuInfo: null,
                isParentMenu: false
            }
        },
        computed: {
            rules() {
                const rules = {
                    menuType: [
                    ],
                    menuNameZHCN: [
                    ],
                    menuNameEN: [
                        { required: true, message: this.$t('message.vueMenu.verifyMenuNameENRequired'), trigger: 'blur' }
                    ],
                    menuNameZHTW: [
                        { required: true, message: this.$t('message.vueMenu.verifyMenuNameZHTWRequired'), trigger: 'blur' }
                    ],
                    routePath: [
                    ],
                    routeName: [
                    ],
                    routeComponent: [
                    ],
                    routeParentName: [
                    ],
                    sort: [
                    ]
                };
                return rules;
            }
        },
        methods: {
            getInfo() {
                var infoId = this.$route.query.id;
                if (infoId === '0') {
                    return;
                }
                this.axios
                    .get('/api/menu/getmenubyid', {
                        params: {
                            id: infoId
                        }
                    })
                    .then(response => {
                        var info=response.data.data;
                        if (response.data.code == 1 && info!=null) {
                            this.ruleForm.id = info.id;
                            this.ruleForm.menuType = info.type;
                            this.ruleForm.menuNameZHCN = info.menuNameZHCN;
                            this.ruleForm.menuNameEN = info.menuNameEN;
                            this.ruleForm.menuNameZHTW = info.menuNameZHTW;
                            this.ruleForm.icon = info.icon;
                            this.ruleForm.routePath = info.routePath;
                            this.ruleForm.routeName = info.routeName;
                            this.ruleForm.routeComponent = info.routeComponent;
                            this.ruleForm.routeParentName = info.routeParentName;
                            this.ruleForm.sort = info.sort;
                            this.ruleForm.parentMenuId = info.parentMenuId;
                            this.ruleForm.noRequiresAuth = info.noRequiresAuth;
                            this.ruleForm.isData = info.isData;
                        }
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/menu/addoreditmenu', {
                                Id: this.ruleForm.id,
                                Type: this.ruleForm.menuType,
                                MenuNameZHCN: this.ruleForm.menuNameZHCN,
                                MenuNameEN: this.ruleForm.menuNameEN,
                                MenuNameZHTW: this.ruleForm.menuNameZHTW,
                                Icon: this.ruleForm.icon,
                                RoutePath: this.ruleForm.routePath,
                                RouteName: this.ruleForm.routeName,
                                RouteComponent: this.ruleForm.routeComponent,
                                RouteParentName: this.ruleForm.routeParentName,
                                Sort: this.ruleForm.sort,
                                ParentMenuId: this.ruleForm.parentMenuId,
                                NoRequiresAuth: this.ruleForm.noRequiresAuth,
                                IsData: this.ruleForm.isData,
                                UserNumber: this.ruleForm.userNumber
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    this.$message.error(this.$t('message.errer'));
                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    if (this.ruleForm.parentMenuId > 0) {
                                        this.$router.push({
                                            path: "/MenuQuerySubmenu",
                                            query: { parentMenuId: this.ruleForm.parentMenuId }
                                        });
                                        return;
                                    }
                                    this.$router.push("/MenuList");
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });

                    } else {
                        return false;
                    }
                });
            },
            returnForm() {
                this.$router.go(-1);
            },
            getParentInfo() {
                if (this.ruleForm.id > 0) {
                    return;
                }
                this.parentMenuInfo = null;
                var parentId = parseInt(this.$route.query.parentMenuId);
                if (parentId === 0 || isNaN(parentId)) {
                    this.ruleForm.parentMenuId = 0;
                    this.isParentMenu = false;
                    return;
                }
                this.ruleForm.parentMenuId = parentId;
                this.axios
                    .get('/api/menu/getmenubyid', {
                        params: {
                            id: parentId
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.parentMenuInfo = info;
                            this.isParentMenu = true;
                        }
                    });
            }
        },
        mounted() {
            this.getInfo();
            this.getParentInfo();
        }
    }
</script>
<style>
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }

    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>

