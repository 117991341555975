<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/WeekSalesDataList' }">{{$t('message.vueSales.weeksalesdatapage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/EditWeekSalesData' }">{{$t('message.vueSales.editWeekSalesData')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="WeekSalesDataForm" :model="WeekSalesDataForm" :rules="rules" label-width="200px" class="adddata">
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueSales.year')+' :'">
                    <el-date-picker v-model="WeekSalesDataForm.year" prop="year" type="year" :start-placeholder="$t('message.pleaseSelect')"
                                    :end-placeholder="$t('message.pleaseSelect')" format="YYYY" value-format="YYYY"></el-date-picker>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueSales.month')+' :'">
                    <el-date-picker v-model="WeekSalesDataForm.month" prop="month" type="month" :start-placeholder="$t('message.pleaseSelect')"
                                    :end-placeholder="$t('message.pleaseSelect')" format="MM" value-format="MM"></el-date-picker>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueSales.datePeriod')+' :'">
                    <el-input size="mini" v-model="WeekSalesDataForm.datePeriod" :disabled="true" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueSales.weekAmount')+' :'">
                    <el-input size="mini" v-model="WeekSalesDataForm.weekAmount" :disabled="true" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item>
                    <el-button class="editcategory-button" size="mini"
                               @click="submitForm('WeekSalesDataForm')"
                               :disabled="disabledSubmit">
                        {{$t('message.vueCategory.button_sava')}}
                    </el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<style>
    .editcategory-button {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'EditWeekSalesData',
        data() {
            return {
                language: AppStorage.getLanguage(),
                WeekSalesDataForm: {
                    id: 0,
                    year: '',
                    month: '',
                    datePeriod: '',
                    weekAmount: '',
                }
            }
        },
        computed: {
            rules() {
                const rules = {
                };
                return rules;
            }
        },
        methods: {
            getInfo() {
                var dataId = this.$route.query.Id;
                if (dataId == 0) {
                    return;
                }
                this.axios
                    .get('/api/salesData/getweeksalesdata', {
                        params: {
                            Id: dataId
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.WeekSalesDataForm.id = info.id;
                            this.WeekSalesDataForm.year = info.yearS;
                            this.WeekSalesDataForm.month = info.monthS;
                            this.WeekSalesDataForm.datePeriod = info.datePeriod;
                            this.WeekSalesDataForm.weekAmount = info.weekAmount;
                        }
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                            this.disabledSubmit = true;
                            this.axios
                            .post('/api/Category/addcategory', {
                                Id: this.WeekSalesDataForm.id,
                                Year: this.WeekSalesDataForm.year,
                                Month: this.WeekSalesDataForm.month,
                                UserNumber: AppStorage.getNumber()
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    if (response.data.msg == '该品类已经存在,请重新录入') {
                                        this.$message.error(this.$t('message.vueCategory.msgExistCategory'));
                                    } else {
                                        this.$message.error(this.$t('message.errer'));
                                    }
                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    this.$router.push({ path: "/WeekSalesDataList" });
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });
                    } else {
                        return false;
                    }
                });
            } 
        },
        //这个组件内的方法，函数
        mounted() {
            this.getInfo();
        }
    };
</script>
