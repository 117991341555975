<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ExchangeRateList' }">{{$t('message.vueExchangeRate.ExchangeRateListPage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="ExchangeRateForm" :model="ExchangeRateForm" :inline="true" class="demo-form-inline" size="mini">
        <el-form-item prop="yearmonth" :label="$t('message.vueSDPReport.dateLabel')+' :'">
            <el-date-picker v-model="ExchangeRateForm.yearmonth" type="month" value-format="YYYY-MM" size="mini" style="width: 200px"></el-date-picker>
        </el-form-item>

        <el-form-item :label="$t('message.vueExchangeRate.oriCurrencyLabel')+' :'">
            <el-input v-model="ExchangeRateForm.currency" size="mini" disabled></el-input>
        </el-form-item>

        <el-form-item>
            <el-button id="btn_search" @click="submitSearch" icon="el-icon-search" size="mini" class="btn_search">
                {{$t('message.search')}}
            </el-button>
        </el-form-item>
        <el-row>
            <el-form-item>
                <el-button type="primary" id="btn_add" @click="add" class="btn_add" icon="el-icon-circle-plus-outline" size="mini">{{$t('message.vueProduct.button_add')}}</el-button>
            </el-form-item>
        </el-row>
    </el-form>
    <el-table :data="tableData" style="width: 100%" border size="mini"
              :stripe="true"
              :header-cell-style="{'background':'#81761852','color':'#2F4F4F','font-size': '12px','text-align':'center'}">
        <el-table-column prop="date" :label="$t('message.vueSDPReport.dateLabel')" width="100">
            <template #default="scope">
                <span>{{scope.row.yearMonth}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="company" :label="$t('message.vueDepartment.labCompanyName')" width="100">
            <template #default="scope">
                <span>{{scope.row.country}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="OriCurrency" :label="$t('message.vueExchangeRate.oriCurrencyLabel')"
                         width="120">
            <template #default="scope">
                <span>{{scope.row.oriCurrency}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="LocCurrency" :label="$t('message.vueExchangeRate.locCurrencyLabel')"
                         width="120">
            <template #default="scope">
                <span>{{scope.row.locCurrency}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="ChangeRate" :label="$t('message.vueExchangeRate.ChangeRateLabel')"
                         width="100">
            <template #default="scope">
                <span>{{scope.row.exchangerate}}</span>
            </template>
        </el-table-column>
        <el-table-column :label="$t('message.operate')" min-width="300">
            <template #default="scope">
                <el-button size="mini"
                           icon="el-icon-edit"
                           class="button_edit"
                           @click="goEdit(scope.row)">{{$t('message.vueCategory.button_update')}}</el-button>
            </template>
        </el-table-column>
    </el-table>
</template>

<style>
    .btn_search {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }
    .btn_add {
        font-weight: bolder;
    }
    .button_edit {
        color: #fff;
        background-color: orange;
        font-weight: bolder;
    }
    .button_detail {
        font-weight: bolder;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'ExchangeRateList',
        data() {

            return {
                tableData: null,
                language: AppStorage.getLanguage(),
                ExchangeRateForm: {
                    yearmonth: '',
                    currency: 'RMB',
                },
                pageIndex: 1,
                pageSize: 10,
                count: 0,
            }
        },
        methods: {
            goEdit(row) {
                this.$router.push({
                    path: "/ExchangeRateEdit",
                    query: { Id: row.id }
                });
            },
            initData() {
                //获取当前时间
                var now = new Date();
                var monthn = now.getMonth() + 1;
                var yearn = now.getFullYear();
                if (monthn < 10) {
                    monthn = "0" + monthn;
                }
                this.ExchangeRateForm.yearmonth = yearn + "-" + monthn;
                console.log(this.ExchangeRateForm.yearmonth);
                this.loadPage();
            },
            loadPage() {
                this.axios
                    .get('/api/ExchangeRate/getexchangeratepage', {
                        params: {
                            keyword: this.ExchangeRateForm.yearmonth,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        }
                    }) .then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.tableData = infos.items;
                            this.count = infos.count;
                        }
                    })
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            add() {
                this.$router.push({
                    path: "/ExchangeRateAdd",
                    query: { id: 0, yearmonthcondition:this.ExchangeRateForm.yearmonth }
                });
            },
            edit(row) {
                this.$router.push({
                    path: "/CompanyEdit",
                    query: { id: row.id }
                });
            },
            query(row) {
                this.$router.push({
                    path: "/CompanyDetails",
                    query: { id: row.id }
                });
            }
        },
        mounted() {
            this.initData();
        },
        watch: {
            $route() {
                //响应路由参数的变化
                this.submitSearch();
            }
        }

    };
</script>