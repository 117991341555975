<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/CompanyList' }">{{$t('message.vueCompany.crumbCompanyManager')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="companyForm.id==0">{{$t('message.add')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-else>{{$t('message.edit')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="companyForm" :model="companyForm" :rules="rules" label-width="200px" class="demo-ruleForm">
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueCompany.labCompanyName')" prop="companyName">
                    <el-input v-model="companyForm.companyName" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueCompany.labCompanyNumber')" prop="companyNumber">
                    <el-input v-model="companyForm.companyNumber" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueCompany.isFactory')" prop="isFactory">
                    <el-radio-group v-model="companyForm.isFactory">
                        <el-radio :label="1">{{$t('message.vueCompany.selIsDisabledYes')}}</el-radio>
                        <el-radio :label="2">{{$t('message.vueCompany.selIsDisabledNo')}}</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueVendor.paymentterm')" prop="paymentterm">
                    <el-input v-model="companyForm.paymentterm" style="width:480px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item>
                    <el-button @click="submitForm('companyForm')"
                               :disabled="disabledSubmit" size="mini" class="button_submit">
                        {{$t('message.submit')}}
                    </el-button>
                    <el-button @click="returnForm()" size="mini" class="button_return">{{$t('message.return')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<style>
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }
    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: '',
        data() {
            return {
                disabledSubmit: false,
                companyForm: {
                    id:0,
                    companyName: '',
                    companyNumber: '',
                    isFactory: 2,
                    paymentterm: '',
                    userNumber: AppStorage.getNumber()
                },
            }
        },
        computed: {
            rules() {
                const rules = {
                    companyName: [
                        { required: true, message: this.$t('message.vueCompany.verifyCompanyRequired'), trigger: 'blur' }
                    ],
                    companyNumber: [
                        { required: true, message: this.$t('message.vueCompany.verifyCompanyNumberRequired'), trigger: 'blur' }
                    ]
                };
                return rules;
            }
        },
        methods: {
            getInfo() {
                var infoId = this.$route.query.id;
                if (infoId === '0') {
                    return;
                }
                this.axios
                    .get('/api/company/getcompany', {
                        params: {
                            id: infoId
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.companyForm.id = info.id;
                            this.companyForm.companyName = info.companyName;
                            this.companyForm.companyNumber = info.companyNumber;
                            this.companyForm.paymentterm = info.paymentterm;
                            this.companyForm.isFactory = info.isFactory;
                        }
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/company/addoreditcompany', {
                                Id: this.companyForm.id,
                                companyName: this.companyForm.companyName,
                                CompanyNumber: this.companyForm.companyNumber,
                                Paymentterm: this.companyForm.paymentterm,
                                IsFactory: this.companyForm.isFactory,
                                UserNumber: this.companyForm.userNumber
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    if (response.data.msg == '公司编码重复') {
                                        this.$message.error(this.$t('message.vueCompany.msgExistCompany'));
                                    } else if (response.data.msg == '存在部门') {
                                        this.$message.error(this.$t('message.vueCompany.msgExistEditCompany'));
                                    }else {
                                        this.$message.error(this.$t('message.errer'));
                                    }
                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    this.$router.push("/CompanyList");
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });

                    } else {
                        return false;
                    }
                });
            },
            returnForm() {
                this.$router.go(-1);
            },
        },
        mounted() {
            this.getInfo();
        },
    }
    
</script>