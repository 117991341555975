<template>
    <el-empty description="Sorry ! 401 Unauthorized !">
        <el-button @click="goHome">{{$t('message.return')}}</el-button>
    </el-empty>
</template>

<script>
    export default {
        name: '401',
        methods: {
            goHome() {
                this.$router.push({ path: "/" });
            }
        }
    };
</script>