<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/CompanyList' }">{{$t('message.vueCompany.crumbCompanyManager')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('message.details')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-button size="mini" @click="returnForm()" class="button_return">{{$t('message.return')}}</el-button>
    <el-divider></el-divider>
    <el-descriptions class="margin-top menu-details" :title="$t('message.details')" :column="1" :size="mini" border>
        <!--<template #extra>
            <el-button @click="returnForm()">{{$t('message.return')}}</el-button>
        </template>-->
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueCompany.labCompanyName')}}
            </template>
            {{companyForm.companyName}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueCompany.labCompanyNumber')}}
            </template>
            {{companyForm.companyNumber}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueVendor.paymentterm')}}
            </template>
            {{companyForm.paymentterm}}
        </el-descriptions-item>
    </el-descriptions>
    <el-divider></el-divider>
    <el-table :data="tableData" border :header-cell-style="{background:'#FAFAFA'}">
        <el-table-column prop="department" :label="$t('message.vueDepartment.labDepartmentName')">
            <template #default="scope">
                <span>{{scope.row.departmentName}}</span>
            </template>
        </el-table-column>
    </el-table>
</template>

<style>
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: '',
        data() {
            return {
                tableData: null,
                language: AppStorage.getLanguage(),
                companyForm: {
                    Id: 0,
                    companyName: '',
                    companyNumber: '',
                    paymentterm: '',
                },
            }
        },
        methods: {
            GetDepartment() {
                this.axios
                    .get('/api/department/getdepartmentlist', {
                        params: {
                            companyId: this.$route.query.id,
                            isAll: false,
                            userNumber: AppStorage.getNumber(),
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.tableData = infos;
                        }
                    })
            },
            GetCompanyDetial() {
                var infoId = this.$route.query.id
                this.axios
                    .get('/api/company/getcompany', {
                        params: {
                            Id: infoId,
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var info = response.data.data;
                            this.companyForm.companyName = info.companyName;
                            this.companyForm.companyNumber = info.companyNumber;
                            this.companyForm.paymentterm = info.paymentterm;
                        }
                    })
            },
            returnForm() {
                this.$router.go(-1);
            }
        },
        mounted() {
            this.GetCompanyDetial();
            this.GetDepartment();

        },
    }
</script>
<style>
    .menu-details .el-descriptions__label {
        width: 20%;
    }
</style>
