<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/WorkFlowTemplateList' }">{{$t('message.vueWorkFlow.crumbTemplateManager')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="ruleForm.id==0">{{$t('message.add')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-else>{{$t('message.edit')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-form-item :label="$t('message.vueWorkFlow.labFormType')" prop="formType">
            <el-select v-model="ruleForm.formType" :placeholder="$t('message.pleaseSelect')">
                <el-option v-for="o in formTypes"
                           :key="o.value"
                           :label="o.label"
                           :value="o.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('message.vueWorkFlow.labTemplateName')" prop="templateName">
            <el-input v-model="ruleForm.templateName" :placeholder="$t('message.pleaseEnter')" style="max-width:217px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('message.vueWorkFlow.labCompanys')" prop="companys">
            <el-select v-model="ruleForm.companys"
                       multiple
                       filterable
                       remote
                       reserve-keyword
                       :placeholder="$t('message.pleaseSelect')"
                       :remote-method="(query)=>{getCompanys(query)}"
                       :loading="ruleForm.isloading">
                <el-option v-for="o in allCompanys"
                           :key="o.value"
                           :label="o.label"
                           :value="o.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('message.vueWorkFlow.labDepartments')" prop="departments">
            <el-select v-model="ruleForm.departments"
                       multiple
                       filterable
                       remote
                       reserve-keyword
                       :placeholder="$t('message.pleaseSelect')"
                       :remote-method="(query)=>{getDepartments(query)}"
                       :loading="ruleForm.isloading">
                <el-option v-for="o in allDepartments"
                           :key="o.value"
                           :label="o.label"
                           :value="o.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('message.vueWorkFlow.labUsers')" prop="users">
            <el-select v-model="ruleForm.users"
                       multiple
                       filterable
                       remote
                       reserve-keyword
                       :placeholder="$t('message.pleaseSelect')"
                       :remote-method="(query)=>{getUsers(query)}"
                       :loading="ruleForm.isloading">
                <el-option v-for="o in allUsers"
                           :key="o.value"
                           :label="o.label"
                           :value="o.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('message.vueWorkFlow.labSort')" prop="sort">
            <el-input-number v-model="ruleForm.sort" controls-position="right" :min="0" :max="99999"></el-input-number>
        </el-form-item>
        <el-form-item>
            <el-button class="button_submit" size="mini" @click="submitForm('ruleForm')"
                       :disabled="disabledSubmit">{{$t('message.submit')}}</el-button>
            <el-button @click="returnForm()" class="button_return" size="mini">{{$t('message.return')}}</el-button>
        </el-form-item>
    </el-form>
</template>
<style>
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }

    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'TemplateAddOrEdit',
        data() {
            return {
                disabledSubmit: false,
                ruleForm: {
                    isloading: false,
                    id: 0,
                    formType: '',
                    templateName: '',
                    companys: '',
                    departments: '',
                    users: '',
                    sort: 0,
                    userNumber: AppStorage.getNumber()
                },
                formTypes:[],
                allCompanys: [],
                allDepartments: [],
                allUsers: [],
            }
        },
        computed: {
            rules() {
                const rules = {
                    formType: [
                        { required: true, message: this.$t('message.vueWorkFlow.verifyFormTypeRequired'), trigger: 'blur' }
                    ],
                    templateName: [
                        { required: true, message: this.$t('message.vueWorkFlow.verifyTemplateNameRequired'), trigger: 'blur' }
                    ],
                    sort: [
                        { required: true, message: this.$t('message.vueWorkFlow.verifySortRequired'), trigger: 'blur' }
                    ]
                };
                return rules;
            }
        },
        methods: {
            getInfo() {
                var infoId = this.$route.query.id;
                if (infoId === '0') {
                    return;
                }
                this.axios
                    .get('/api/workflow/gettemplatebyid', {
                        params: {
                            id: infoId
                        }
                    })
                    .then(response => {
                        var info=response.data.data;
                        if (response.data.code == 1 && info!=null) {
                            this.ruleForm.id = info.id;
                            this.ruleForm.formType = info.formType;
                            this.ruleForm.templateName = info.templateName;
                            this.ruleForm.companys = info.companys == null || info.companys == '' ? '' : info.companys.split(',');
                            this.ruleForm.departments = info.departments == null || info.departments == '' ? '' : info.departments.split(',');
                            this.ruleForm.users = info.users == null || info.users == '' ? '' : info.users.split(',');
                            this.ruleForm.sort = info.sort;
                        }
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/workflow/addoredittemplate', {
                                Id: this.ruleForm.id,
                                FormType: this.ruleForm.formType,
                                TemplateName: this.ruleForm.templateName,
                                Companys: this.ruleForm.companys?.toString(),
                                Departments: this.ruleForm.departments?.toString(),
                                Users: this.ruleForm.users?.toString(),
                                Sort: this.ruleForm.sort,
                                UserNumber: this.ruleForm.userNumber
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    if (response.data.msg == '模板名称已存在') {
                                        this.$message.error(this.$t('message.vueWorkFlow.msgAddTemplateNameExist'));
                                        return;
                                    }
                                    this.$message.error(this.$t('message.error'));
                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    this.$router.push("/WorkFlowTemplateList");
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });

                    } else {
                        return false;
                    }
                });
            },
            returnForm() {
                this.$router.go(-1);
            },
            getFormTypes() {
                this.ruleForm.isloading = true;
                this.axios
                    .get('/api/workflow/getformtypes')
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.formTypes = infos;
                        }
                        this.ruleForm.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.ruleForm.isloading = false;
                    });
            },
            getUsers(query, isAll = true) {
                this.ruleForm.isloading = true;
                this.axios
                    .get('/api/user/getuserlist', {
                        params: {
                            companyId: null,
                            departmentId: null,
                            keyword: query,
                            isAll: isAll
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allUsers = infos.map(function (m) {
                                return {
                                    value: m.number,
                                    label: m.name + "(" + m.number + ")"
                                }
                            });
                        }
                        this.ruleForm.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.ruleForm.isloading = false;
                    });
            },
            getDepartments(query, isAll = true) {
                this.ruleForm.isloading = true;
                this.axios
                    .get('/api/department/getdepartmentlist', {
                        params: {
                            companyId: null,
                            keyword: query,
                            userNumber: AppStorage.getNumber(),
                            isAll: isAll
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allDepartments = infos.map(function (m) {
                                return {
                                    value: m.departmentNumber,
                                    label: m.departmentName + "(" + m.departmentNumber + ")"
                                }
                            });
                        }
                        this.ruleForm.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.ruleForm.isloading = false;
                    });
            },
            getCompanys(query, isAll = true) {
                this.ruleForm.isloading = true;
                this.axios
                    .get('/api/company/getcompanylist', {
                        params: {
                            userNumber: AppStorage.getNumber(),
                            keyword: query,
                            isAll: isAll
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allCompanys = infos.map(function (m) {
                                return {
                                    value: m.companyNumber,
                                    label: m.companyName + "(" + m.companyNumber + ")"
                                }
                            });
                        }
                        this.ruleForm.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.ruleForm.isloading = false;
                    });
            },
           
        },
        mounted() {
            this.getFormTypes();
            this.getUsers('', true);
            this.getDepartments('', true);
            this.getCompanys('', true);
            this.getInfo();
        }
    }
</script>

