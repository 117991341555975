<template>
    <div id="msgbox">
        <el-dialog v-model="dialogTableVisible"
                   width="100%"  @opened="openDialog">
            <el-form ref="userForm" :model="userForm" :inline="true" class="demo-form-inline">
                <el-form-item :label="$t('message.vueUser.labCompanyName')+' :'" prop="company">
                    <el-select v-model="userForm.company" value-key="id" :placeholder="$t('message.pleaseSelect')" style="width:100px" @change="getDepartment($event)" filterable>
                        <el-option :label="$t('message.pleaseSelect')" value=""></el-option>
                        <el-option v-for="item in companylist"
                                   :key="item.id"
                                   :label="item.companyName"
                                   :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('message.vueUser.labDepartmentName')+' :'" prop="department">
                    <el-select v-model="userForm.department" value-key="id" :placeholder="$t('message.pleaseSelect')" style="width:100px" filterable>
                        <el-option :label="$t('message.pleaseSelect')" value=""></el-option>
                        <el-option v-for="item in departmentlist"
                                   :key="item.id"
                                   :label="item.departmentName"
                                   :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('message.vueUser.labKeyword')">
                    <el-input v-model="userForm.keyword" :placeholder="$t('message.vueUser.plaSearch')"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" id="btn_search" @click="submitSearch" icon="el-icon-search">{{$t('message.search')}}</el-button>
                    <el-button type="primary" id="btn_confirm" @click="confirm">{{$t('message.messageBox.btnConfirm')}}</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="multipleTable"
                      :data="tableData"
                      tooltip-effect="dark"
                      style="width: 100%"
                      @selection-change="handleSelectionChange" border>
                <el-table-column type="selection"
                                 width="55">
                </el-table-column>
                <el-table-column prop="Company" :label="$t('message.vueUser.labCompanyName')"
                                 width="100">
                    <template #default="scope">
                        <span>{{scope.row.companyName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Department" :label="$t('message.vueUser.labDepartmentName')"
                                 width="110">
                    <template #default="scope">
                        <span>{{scope.row.departmentName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Account" :label="$t('message.vueUser.labAccount')"
                                 width="180">
                    <template #default="scope">
                        <span>{{scope.row.account}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Name" :label="$t('message.vueUser.labName')"
                                 width="180">
                    <template #default="scope">
                        <span>{{scope.row.name}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Number" :label="$t('message.vueUser.labNumber')"
                                 width="180">
                    <template #default="scope">
                        <span>{{scope.row.number}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Email" :label="$t('message.vueUser.labEmail')"
                                 width="280">
                    <template #default="scope">
                        <span>{{scope.row.email}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               :current-page="pageIndex"
                               :page-sizes="[10, 25, 50, 75, 100]"
                               :page-size="pageSize"
                               layout="total, sizes, prev, pager, next, jumper"
                               :total="count">
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: '',
        data() {
            return {
                tableData: null,
                language: AppStorage.getLanguage(),
                companylist: [],
                departmentlist: [],
                multipleSelection: [],
                userForm: {
                    company: {},
                    department: {},
                    keyword: '',
                    isDisabled: false
                },
                pageIndex: 1,
                pageSize: 10,
                count: 0,
                dialogTableVisible: false,
            }
        },
        methods: {
            getCompany() {
                this.axios
                    .get('/api/company/getcompanylist', {
                        params: {
                            //isFactory: 2,
                            isFactory: 0,
                            userNumber: AppStorage.getNumber(),
                            isAll: false
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.companylist = infos;
                        }
                    })
            },
            getDepartment(opt) {
                this.departmentlist = [];
                this.userForm.departmentId = "";
                if (opt.id != "") {
                    this.axios
                        .get('/api/department/getdepartmentlist', {
                            params: {
                                companyNumber: opt.companyNumber,
                                userNumber: AppStorage.getNumber(),
                                isAll: false
                            }
                        }).then(response => {
                            if (response.data.code == 1) {
                                var infos = response.data.data;
                                this.departmentlist = infos;
                            }
                        })
                }
            },
            loadPage() {
                this.axios
                    .get('/api/user/getuserpage', {
                        params: {
                            isDisabled: this.userForm.isDisabled,
                            companyNumber: this.userForm.company.companyNumber,
                            departmentNumber: this.userForm.department.departmentNumber,
                            keyword: this.userForm.keyword,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.tableData = infos.items;
                            this.count = infos.count;
                        }
                    })
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            },
            handleSelectionChange(val) {
                this.multipleSelection = val; 
            },
            init() {
                this.dialogTableVisible = true;
            },
            confirm() {
                console.log(this.multipleSelection);
                this.$emit("getUserEven", this.multipleSelection);
                this.dialogTableVisible = false;
            },
            openDialog() {
                this.$refs.multipleTable.clearSelection();
            }
        },
        mounted() {
            this.submitSearch();
            this.getCompany();
        },
        watch: {
            $route() {
                //响应路由参数的变化
                this.submitSearch();
            }
        }

    };
</script>