const appKey = 'OGAWAStorage'

let AppStorage = {
    get() {
        return localStorage.getItem(appKey)
    },
    set(value) {
        localStorage.setItem(appKey, value)
    },
    remove() {
        localStorage.removeItem(appKey)
    },
    getToken() {
        var token = '';
        var storage = localStorage.getItem(appKey);
        if (storage != '' && storage != null && storage != 'null') {
            token = JSON.parse(storage).token;
        }
        return token;
    },
    getNumber() {
        var number = '';
        var storage = localStorage.getItem(appKey);
        if (storage != '' && storage != null && storage != 'null') {
            number = JSON.parse(storage).number;
        }
        return number;
    },
    getName() {
        var name = '';
        var storage = localStorage.getItem(appKey);
        if (storage != '' && storage != null && storage != 'null') {
            name = JSON.parse(storage).name;
        }
        return name;
    },
    setLanguage(language) {
        var storage = localStorage.getItem(appKey);
        if (storage != '' && storage != null && storage != 'null') {
            var obj = JSON.parse(storage);
            obj.language = language;
            this.set(JSON.stringify(obj));
        }
    },
    getLanguage() {
        var language = '';
        var storage = localStorage.getItem(appKey);
        if (storage != '' && storage != null && storage != 'null') {
            language = JSON.parse(storage).language;
        }
        return language;
    },
    isAuthenticated() {
        var token = this.getToken();
        if (token != '') {
            return true;
        }
        return false;
    },
    getSideMenuList() {
        var sideMenuList = '';
        var storage = localStorage.getItem(appKey);
        if (storage != '' && storage != null && storage != 'null') {
            sideMenuList = JSON.parse(storage).sideMenuList;
        }
        return sideMenuList;
    }
}

export default AppStorage