<template>
    <div id="msgbox">
        <el-dialog v-model="dialogTableVisible"
                   width="100%" @opened="openDialog">
            <el-form ref="productsForm" :model="productsForm" :inline="true" class="demo-form-inline">
                <el-form-item :label="$t('message.vueCategory.categorypage')+' :'" prop="category">
                    <el-select v-model="productsForm.category" size="mini" value-key="categoryNumber" :placeholder="$t('message.pleaseSelect')" filterable>
                        <el-option value=""></el-option>
                        <el-option v-for="item in categorylist"
                                   :key="item.categoryNumber"
                                   :label="item.category"
                                   :value="item" />
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('message.vueProductMod.productmodpage')+' :'" prop="model">
                    <el-select v-model="productsForm.model" size="mini" value-key="productModNa" :placeholder="$t('message.pleaseSelect')" filterable>
                        <el-option value=""></el-option>
                        <el-option v-for="item in modellist"
                                   :key="item.productModNa"
                                   :label="item.productMod"
                                   :value="item" />
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('message.vueProduct.labProductCode')+' :'">
                    <el-input v-model="productsForm.productCode" size="mini"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" id="btn_search" @click="submitSearch" icon="el-icon-search">{{$t('message.search')}}</el-button>
                    <el-button type="primary" id="btn_confirm" @click="confirm">{{$t('message.messageBox.btnConfirm')}}</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="multipleTable"
                      :data="tableData"
                      tooltip-effect="dark"
                      style="width: 100%"
                      size ="mini"
                      @selection-change="handleSelectionChange" border>
                <el-table-column type="selection"
                                 width="55">
                </el-table-column>
                <el-table-column prop="ProductCode" :label="$t('message.vueProduct.product_code')"
                                 width="140">
                    <template #default="scope">
                        <span>{{scope.row.productCode}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="ItemCode" :label="$t('message.vueProduct.item_code')" width="130">
                    <template #default="scope">
                        <span>{{scope.row.itemCode}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="ModelNo" :label="$t('message.vueProduct.model_no')"
                                 width="350">
                    <template #default="scope">
                        <span>{{scope.row.productMod}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="ProductDescription" :label="$t('message.vueProduct.product_name')"
                                 width="480">
                    <template #default="scope">
                        <span>{{scope.row.productName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="ProductColour" :label="$t('message.vueProductCol.ProcuctColour')"
                                 width="160">
                    <template #default="scope">
                        <span>{{scope.row.productCol}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="UnitPrice" :label="$t('message.vueProduct.unit_price')"
                                 width="100">
                    <template #default="scope">
                        <span>{{scope.row.unitPrice}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Currency" :label="$t('message.vueProduct.product_currency')"
                                 width="90">
                    <template #default="scope">
                        <span>{{scope.row.currency}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Uom" :label="$t('message.vueProduct.uom')"
                                 width="70">
                    <template #default="scope">
                        <span>{{scope.row.uom}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               :current-page="pageIndex"
                               :page-sizes="[10, 25, 50, 75, 100]"
                               :page-size="pageSize"
                               layout="total, sizes, prev, pager, next, jumper"
                               :total="count">
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: '',
        props: {
            loadTimesProduct: {
                type: Number,
                default: 0
            },
            country: {
                type: String,
                default: ''
            },
            vendor: {
                type: String,
                default: ''
            },
            podate: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                tableData: null,
                language: AppStorage.getLanguage(),
                categorylist: [],
                modellist: [],
                multipleSelection: [],
                productsForm: {
                    productCode: '',
                    category: '',
                    model:''
                },
                pageIndex: 1,
                pageSize: 10,
                count: 0,
                dialogTableVisible: false,
            }
        },
        methods: {
            loadPage() {
                this.axios
                    .get('/api/Product/getproductpage', {
                        params: {
                            category: this.productsForm.category.categoryNumber,
                            modelNo: this.productsForm.model.productModNa,
                            productCode: this.productsForm.productCode,
                            Country: this.country,
                            Vendor: this.vendor,
                            PoDate: this.podate,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        }
                    }) .then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.tableData = infos.items;
                            this.count = infos.count;
                        }
                    })
            },
            getCategory() {
                this.axios
                    .get('/api/Category/getcategorylist', {
                        params: {
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.categorylist = infos;
                        }
                    })
            },
            getModel() {
                this.axios
                    .get('/api/ProductMod/getproductmodlist', {
                        params: {
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.modellist = infos;
                        }
                    })
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            init() {
                this.dialogTableVisible = true;
            },
            confirm() {
                console.log(this.multipleSelection);
                this.$emit("getProductsEven", this.multipleSelection);
                this.dialogTableVisible = false;
            },
            openDialog() {
                this.$refs.multipleTable.clearSelection();
            }
        },
        mounted() {
            this.getCategory();
            this.getModel();
            this.submitSearch();
        },
        watch: {
            loadTimesProduct() {
                this.submitSearch();
            }
            //$route() {
            //    //响应路由参数的变化
            //    this.submitSearch();
            //}
        }

    };
</script>
