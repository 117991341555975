<template>
    <div id="msgbox">
        <el-dialog v-model="dialogTableVisible"
                   width="550px"
                   @open="openDialog">
            <el-form ref="userForm" :model="userForm" :rules="rules" class="demo-ruleForm" :inline="true">
                <el-form-item :label="$t('message.vueUser.labNewPassword')+':'" prop="password">
                    <el-input v-model="userForm.password"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" id="btn_confirm" @click="confirm">{{$t('message.messageBox.btnConfirm')}}</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        </div>
</template>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: '',
        data() {
            return {
                language: AppStorage.getLanguage(),
                userForm: {
                    id:0,
                    password: '',
                },
                dialogTableVisible: false,
            }
        },
        computed: {
            rules() {
                const rules = {
                    password: [
                        { required: true, message: this.$t('message.vueUser.verifyPasswordRequired'), trigger: 'blur' },
                        { min: 6, max: 20, message: this.$t('message.vueUser.verifyPasswordLen'), trigger: 'blur' }
                    ],
                };
                return rules;
            }
        },
        methods: {
            init(id) {
                this.userForm.id = id;
                this.dialogTableVisible = true;
            },
            confirm() {
                this.axios
                    .post('/api/user/edituserpassword', {
                        Id: this.userForm.id,
                        Password: this.userForm.password,
                        UserNumber: AppStorage.getNumber(),
                    })
                    .then(response => {
                        if (response.data.code != 1) {
                            if (response.data.msg == '密码长度错误') {
                                this.$message.error(this.$t('message.vueUser.verifyPasswordLen'));
                            } else {
                                this.$message.error(this.$t('message.error'));
                            }
                        } else {
                            this.$message.success(this.$t('message.success'));
                        }
                    }).catch(error => {
                        console.log(error);
                        this.$message.error(this.$t('message.messageBox.connecterror'));
                    });
                this.dialogTableVisible = false;
            },
            openDialog() {
                this.userForm.password = "";
            }
        }
    };
</script>
