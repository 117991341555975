<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/Currency' }">{{$t('message.vueCurrency.currencypage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/EditCurrency' }">{{$t('message.vueCurrency.editcurrencypage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="editcurrencyForm" :model="editcurrencyForm" :rules="rules" label-width="200px" class="demo-editcurrencyForm">
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueCurrency.currencyid')+' :'">
                    <el-input size="mini" v-model="editcurrencyForm.currencyId" :disabled="true" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueCurrency.currency')+' :'" prop="currency">
                    <el-input size="mini" v-model="editcurrencyForm.currency" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueCurrency.remarks')+' :'" prop="remarks">
                    <el-input size="mini" v-model="editcurrencyForm.remarks" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item>
                    <el-button type="primary" size="mini" @click="submitForm('editcurrencyForm')"
                               :disabled="disabledSubmit" class="button_sava">
                        {{$t('message.submit')}}
                    </el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>
<style>
    .button_sava {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'EditCurrency',
        data() {
            return {
                language: AppStorage.getLanguage(),
                editcurrencyForm: {
                    currencyId: '',
                    currency: '',
                    remarks: '',
                    userNumber: AppStorage.getNumber()
                },
                seen: true,
            }
        },
        computed: {
            rules() {
                const rules = {
                    currency: [
                        { required: true, message: this.$t('message.vueCurrency.verifyCurrencyRequired'), trigger: 'blur' }
                    ]
                };
                return rules;
            }
        },
        methods: {
            getInfo() {
                var currencyid = this.$route.query.Id;
                if (currencyid == 0) {
                    return;
                }
                this.axios
                    .get('/api/Currency/getcurrencybyid', {
                        params: {
                            Id: currencyid
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.editcurrencyForm.currencyId = info.id;
                            this.editcurrencyForm.currency = info.currency;
                            this.editcurrencyForm.remarks = info.remarks;
                        }
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/Currency/addcurrency', {
                                Id: this.editcurrencyForm.currencyId,
                                Currency: this.editcurrencyForm.currency,
                                Remarks: this.editcurrencyForm.remarks,
                                UserNumber: AppStorage.getNumber()
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    if (response.data.msg == 'this currency is exist') {
                                        this.$message.error(this.$t('message.vueCurrency.msgExistCurrency'));
                                    } else {
                                        this.$message.error(this.$t('message.errer'));
                                    }
                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    this.$router.push({ path: "/Currency" });
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });
                    } else {
                        return false;
                    }
                });
            }
        },
        mounted() {
            this.getInfo();
        }
    };
</script>
