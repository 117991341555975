import AppStorage from '@/common/storage/app-storage';
import axios from 'axios';

export function GetCompanyList(keyword, isFactory, isAll) {
    return axios
           .get('/api/company/getcompanylist', {
               params: {
                   keyword: keyword,
                   isFactory: 2,
                   userNumber: AppStorage.getNumber(),
                   isAll: isAll
               }
           })
}