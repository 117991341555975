<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/SalesTargetList' }">{{$t('message.vueSales.SalesList')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>

    <div style="height:850px"
         v-loading="loading"
         element-loading-text="Is Releasing..."
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)">

        <el-form ref="salestarget" :model="salestarget" :inline="true" class="demo-form-inline" size="small" :rules="rules" label-width="80px">
            <div id="tag_1665471880367" :style="{textAlign: 'left'}">
                <!--年-->
                <el-form-item :label="$t('message.vueSales.year')">
                    <el-date-picker v-model="salestarget.year" prop="year" type="year" :start-placeholder="$t('message.pleaseSelect')" :end-placeholder="$t('message.pleaseSelect')" format="YYYY" value-format="YYYY"></el-date-picker>
                </el-form-item>
                <!--月-->
                <el-form-item :label="$t('message.vueSales.month')">
                    <el-date-picker v-model="salestarget.month" prop="month" type="month" :start-placeholder="$t('message.pleaseSelect')" :end-placeholder="$t('message.pleaseSelect')" format="MM" value-format="MM"></el-date-picker>
                </el-form-item>
                <!--指标-->
                <el-form-item :label="$t('message.vueSales.rule')">
                    <el-select v-model="salestarget.rule" property="rule" :placeholder="$t('message.pleaseSelect')">
                        <el-option :label="$t('message.pleaseSelect')" :value="0"></el-option>
                        <el-option :label="$t('message.vueSales.byQuantity')" :value="1"></el-option>
                        <el-option :label="$t('message.vueSales.byAmount')" :value="2"></el-option>
                    </el-select>
                </el-form-item>

                <!--失效-->
                <el-form-item :label="$t('message.vueSales.isDisabled')" placement="top" style="margin-right:10px">
                    <el-switch v-model="salestarget.disable" active-color="#ff4949" inactive-color="#13ce66"></el-switch>
                    <span v-if="salestarget.disable===true">
                        {{$t('message.vueSales.IsDisabledYes')}}
                    </span>
                    <span v-else>
                        {{$t('message.vueSales.IsDisabledNo')}}
                    </span>
                </el-form-item>

                <el-form-item>
                    <el-button size="mini" type="primary" id="btn_search" class="btn_search" icon="el-icon-circle-check" :disabled="disabledSubmit" @click="submitSearch()">{{$t('message.vueSales.btn_query')}}</el-button>
                </el-form-item>
                <el-row>
                    <el-form-item>
                        <el-button type="primary" @click="add" icon="el-icon-circle-plus-outline" size="mini" class="btn_add">{{$t('message.vueSales.btn_add')}}</el-button>
                    </el-form-item>
                </el-row>

            </div>
            <el-table :data="tableData" tooltip-effect="dark" :stripe="false" border size="mini"
                      fit @cell-mouse-enter="(row, column, cell, event) => row.__buttonVisible__ = true"
                      @cell-mouse-leave="(row, column, cell, event) => row.__buttonVisible__ = false"
                      :header-cell-style="{'background':'#81761852','color':'#2F4F4F','font-size': '12px','text-align':'center'}">
                <!--序号-->
                <el-table-column :label="$t('message.vueSales.indexNo')" prop="" :show-overflow-tooltip="false" align="left" width="60">
                    <template #default="scope">
                        <span>{{scope.$index+1 + (pageIndex - 1) * pageSize}}</span>
                    </template>
                </el-table-column>
                <!--年度-->
                <el-table-column :label="$t('message.vueSales.year')" prop="year" :show-overflow-tooltip="false" align="left" width="60">
                    <template #default="scope">
                        <span>{{scope.row.year}}</span>
                    </template>
                </el-table-column>
                <!--月度-->
                <el-table-column :label="$t('message.vueSales.month')" prop="month" :show-overflow-tooltip="false" align="left" width="70">
                    <template #default="scope">
                        <span>{{scope.row.month}}</span>
                    </template>
                </el-table-column>
                <!--指标-->
                <el-table-column :label="$t('message.vueSales.rule')" prop="rule" :show-overflow-tooltip="false" align="left" width="80">
                    <template #default="scope">
                        <span v-if="scope.row.rule===1">
                            {{$t('message.vueSales.quantity')}}
                        </span>
                        <span v-else>
                            {{$t('message.vueSales.amount')}}
                        </span>
                    </template>
                </el-table-column>

                <!--国家-->
                <el-table-column :label="$t('message.vueSales.country')" prop="country" :show-overflow-tooltip="false" align="left" width="80">
                    <template #default="scope">
                        <span>{{scope.row.country}}</span>
                    </template>
                </el-table-column>

                <el-table-column :label="$t('message.vueSales.OIHTargetAmount')" prop="OIHTargetAmount"
                                 :show-overflow-tooltip="false" align="right" width="150">
                    <template #default="scope">
                        <span>{{scope.row.oihTargetAmount}}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('message.vueSales.OIHCorporateAmount')" prop="OIHCorporateAmount"
                                 :show-overflow-tooltip="false" align="right" width="170">
                    <template #default="scope">
                        <span>{{scope.row.oihCorporateAmount}}</span>
                    </template>
                </el-table-column>

                <!--是否生效-->
                <el-table-column :label="$t('message.vueSales.isDisabled')" prop="isDisabled" :show-overflow-tooltip="false" align="left" width="150">
                    <template #default="scope">
                        <span>{{scope.row.isDisable}}</span>
                        <el-tooltip :content="$t('message.vueSales.isDisabled')" placement="top" style="margin-right:10px">
                            <el-switch v-model="scope.row.isDisabled" active-color="#ff4949" inactive-color="#13ce66" :loading="scope.row.isLoading" :before-change="()=>changeIsDisabled(scope.row)"></el-switch>
                        </el-tooltip>
                        <span v-if="scope.row.isDisabled===false">
                            {{$t('message.vueSales.IsDisabledNo')}}
                        </span>
                        <span v-else>
                            {{$t('message.vueSales.IsDisabledYes')}}
                        </span>
                    </template>
                </el-table-column>

                <!--是否发布-->
                <el-table-column :label="$t('message.vueSales.statusLabel')" prop="status" :show-overflow-tooltip="false" align="left" width="80">
                    <template #default="scope">
                        <span>{{scope.row.dataStatusName}}</span>
                    </template>
                </el-table-column>

                <!--操作(1查看每日目标、2编辑每日目标)-->
                <el-table-column :label="$t('message.operate')"
                                 min-width="120">
                    <template #default="scope">
                        <el-button size="mini"
                                   icon="el-icon-edit-outline"
                                   class="button_detail"
                                   @click="query(scope.row)">{{$t('message.details')}}</el-button>
                        <el-button size="mini"
                                   icon="el-icon-edit"
                                   class="button_edit"
                                   @click="edit(scope.row)" v-if="scope.row.dataStatus == '0' || salestarget.addUserNumber == 'OIH0010' || salestarget.addUserNumber == 'TW0005' || salestarget.addUserNumber == 'OIH0012'">{{$t('message.edit')}}</el-button>
                        <el-button @click="submitForm(scope.row)"
                                   :disabled="disabledSubmit" size="mini" class="button_submit" v-if="scope.row.dataStatus == '0' && scope.row.rule==1">
                            {{$t('message.vueSales.buttonrelease')}}
                        </el-button>
                        <el-button size="mini"
                                   icon="el-icon-edit"
                                   class="button_edit"
                                   @click="copy(scope.row)">
                            {{$t('message.copy')}}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               :current-page="pageIndex"
                               :page-sizes="[10, 25, 50, 75, 100]"
                               :page-size="pageSize"
                               layout="total, sizes, prev, pager, next, jumper"
                               :total="count">
                </el-pagination>
            </div>

        </el-form>

    </div>
</template>
<script>
    import AppStorage from '@/common/storage/app-storage';
    import { parseTime } from '@/common/utils/datetime';
    import { GetCompanyList } from '@/api/system/company';
    import { GetUserList } from '@/api/system/user';

    export default {
        name: "SalesTarget",
        components: {

        },
        data() {
            return {

                tableData: null,

                salestarget: {
                    year: '',
                    month: '',
                    country: '',
                    rule: 0,
                    addTime: '',
                    addUserNumber: AppStorage.getNumber(),
                    isDisabled: false,
                    xiao_shou_biao_dan: []
                },
                user_data: [],
                country: [],
                submit_loading: false,
                pageIndex: 1,
                pageSize: 24,
                count: 0,
                language: AppStorage.getLanguage(),
                allCountrys: [],
                allUsers: [],
                loading: false,
            };
        },
        computed: {
            rules() {
                const rules = {
                };
                return rules;
            }
        },
        methods: {
            async initData() {
                //获取当前时间
                var now = new Date();
                var yearn = now.getFullYear();

                this.salestarget.year = yearn.toString();
                this.loadPage();
            },
            submitForm(row) {
                var _this = this;
                _this.loading = true;

                this.axios
                    .get('/api/salestarget/releasesalestarget', {
                        params: {
                            id: row.id,
                            year: row.year,
                            month: row.month,
                            country: row.country,
                            addUserNumber: this.salestarget.addUserNumber,
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            this.$message.success(this.$t('message.success'));
                            this.loadPage();
                        }
                        _this.loading = false;
                    })
            },
            loadPage() {   
                this.axios
                    .get('/api/salestarget/getsalestargetpage', {
                        params: {
                            isDisabled: this.salestarget.disable,
                            Rule: this.salestarget.rule,
                            Year: this.salestarget.year,
                            Month: this.salestarget.month,
                            addUserNumber: this.salestarget.addUserNumber,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.tableData = infos.items;
                            this.count = infos.count;

                            for (var i = 0; i < infos.items.length; i++) {
                                infos.items[i].dataStatusName = this.$t('message.vueSales.releaseStatus' + infos.items[i].dataStatus);
                            }
                        }
                    })
            },
            datetimeFormat(row) {
                if (row.addTime.length > 0) {
                    return parseTime(row.addTime, '{y}-{m}-{d} {h}:{m}:{s}');
                }
                else { return ""; }                
            },
            getCountrys(query, isAll = true) {
                this.salestarget.isloading = true;
                GetCompanyList(null, 0, true).then(response => {
                    var infos = response.data.data;
                    if (response.data.code == 1 && infos != null) {
                        this.allCountrys = infos.map(function (m) {
                            return {
                                value: m.companyNumber,
                                label: m.companyName + "(" + m.companyNumber + ")"
                            }
                        });
                        this.allCountrys.unshift({
                            value: "",
                            label: this.$t('message.all')
                        });
                    }
                    this.salestarget.isloading = false;
                })
                .catch(error => {
                    console.log(error);
                    this.salestarget.isloading = false;
                });
            },

            seletCountryChange(val) {
                var obj = {}
                obj = this.allCountrys.find(function (i) {
                    return i.value === val
                });
                this.salestarget.country = obj.value;
                this.getUsers('', '');
            },

            getUsers(query, isAll = true) {
                this.salestarget.isloading = true;
                GetUserList(this.salestarget.country, null, null, true).then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allUsers = infos.map(function (m) {
                                return {
                                    value: m.number,
                                    label: m.name + "(" + m.number + ")"
                                }
                            });
                            this.allUsers.unshift({
                                value: "",
                                label: this.$t('message.all')
                            });
                        }
                        this.salestarget.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.salestarget.isloading = false;
                    });
            },
            add() {
                this.$router.push({
                    path: "/SalesTargetAdd",
                    query: {
                        id: 0,
                        country: this.salestarget.country,
                        readonly: false,
                        operate: "add"
                    }
                });
            },
            edit(row) {
                this.$router.push({
                    path: "/SalesTargetEdit",
                    query: {
                        id: row.id,
                        year: row.year,
                        month: row.month,
                        country: row.country,
                        oihTargetAmount: row.oihTargetAmount,
                        oihCorporateAmount: row.oihCorporateAmount,
                        brandCollaboration: row.brandCollaboration,
                        webSales: row.webSales,
                        headOffice: row.headOffice,
                        rule: row.rule,
                        readonly: false,
                        operate: "edit"
                    }
                });
            },
            copy(row) {
                this.$router.push({
                    path: "/SalesTargetEdit",
                    query: {
                        id: row.id,
                        year: row.year,
                        month: row.month,
                        country: row.country,
                        oihTargetAmount: row.oihTargetAmount,
                        oihCorporateAmount: row.oihCorporateAmount,
                        brandCollaboration: row.brandCollaboration,
                        webSales: row.webSales,
                        headOffice: row.headOffice,
                        rule: row.rule,
                        readonly: false,
                        operate: "copy"
                    }
                });
            },
            query(row) {
                this.$router.push({
                    path: "/SalesTargetEdit",
                    query: {
                        id: row.id,
                        year: row.year,
                        month: row.month,
                        country: row.country,
                        oihTargetAmount: row.oihTargetAmount,
                        oihCorporateAmount: row.oihCorporateAmount,
                        brandCollaboration: row.brandCollaboration,
                        webSales: row.webSales,
                        headOffice: row.headOffice,
                        rule: row.rule,
                        readonly: true,
                        operate: "edit"
                    }
                });
            },
            changeIsDisabled(row) {
                var isDisabled = false;
                if (row.isDisabled == false) {
                    isDisabled = true;
                }
                row.isLoading = true;
                return new Promise(resolve => {
                    this.axios
                        .post('/api/salestarget/disabledsalestarget', {
                            Id: row.id,
                            IsDisabled: row.isDisabled,
                            DisabledUserNumber: AppStorage.getNumber(),
                        })
                        .then(response => {
                            row.isLoading = false;
                            if (response.data.code != 1) {                                
                                this.$message.error(this.$t('message.error'));
                                return resolve(false);
                            } else {
                                this.$message.success(this.$t('message.success'));
                                return resolve(true);
                            }
                        }).catch(error => {
                            console.log(error);
                            row.isLoading = false;
                            this.$message.error(this.$t('message.exception' + ':' + error));
                            return resolve(false);
                        });
                })
            },
            submitSearch() {
                this.pageIndex = 1;
                this.loadPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.loadPage();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.loadPage();
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            init() {
                this.dialogTableVisible = true;
            },
            openDialog() {
                this.$refs.multipleTable.clearSelection();
            }
        },
        created() {
            this.getCountrys();
            this.getUsers();
        },
        mounted() {
            this.initData();
            //this.submitSearch();
        },
        watch: {
            $route() {
                //响应路由参数的变化
                this.submitSearch();
            }
        }
    };
</script>
<style scoped>
    .btn_search {
        color: #fff;
        background-color: green;
        font-weight: bolder;
    }

    .button_detail {
        font-weight: bolder;
    }

    .btn_add {
        font-weight: bolder;
    }

    .j-object-array-form > .el-form-item__label::before {
        content: '' !important;
        color: unset !important;
        margin-right: unset !important;
    }

    .j-object-array-form > .j-object-array-form-header.required::before {
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
    }
</style>