<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ExchangeRateList' }">{{$t('message.vueExchangeRate.ExchangeRateListPage')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="ExchangeRateForm.id==0">{{$t('message.add')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-else>{{$t('message.edit')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="ExchangeRateForm" :model="ExchangeRateForm" :rules="rules" label-width="200px" class="demo-ruleForm">
        <el-row>
            <el-col :span="8">
                <el-form-item :label="$t('message.vueSDPReport.dateLabel')" prop="YearMonth">
                    <el-date-picker v-model="ExchangeRateForm.yearmonth" type="month" value-format="YYYY-MM" style="width: 300px"></el-date-picker>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item :label="$t('message.vueDepartment.labCompanyName')" prop="company">
                    <el-select v-model="ExchangeRateForm.company" value-key="companyNumber" :placeholder="$t('message.pleaseSelect')" filterable>
                        <el-option v-for="item in companylist"
                                   :key="item.companyNumber"
                                   :label="item.companyName"
                                   :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="8">
                <el-form-item :label="$t('message.vueExchangeRate.oriCurrencyLabel')" prop="OriCurrency">
                    <el-input v-model="ExchangeRateForm.oriCurrency" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item :label="$t('message.vueExchangeRate.locCurrencyLabel')+' :'" prop="locCurrency">
                    <el-select v-model="ExchangeRateForm.locCurrency" value-key="currencyNumber" :placeholder="$t('message.pleaseSelect')" filterable>
                        <el-option v-for="item in currencylist"
                                   :key="item.currencyNumber"
                                   :label="item.currencyNumber"
                                   :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="8">
                <el-form-item :label="$t('message.vueExchangeRate.ChangeRateLabel')" prop="ChangeRate">
                    <el-input v-model="ExchangeRateForm.changeRate" style="width: 300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <el-form-item>
                    <el-button @click="submitForm('ExchangeRateForm')"
                               :disabled="disabledSubmit" size="mini" class="button_submit">
                        {{$t('message.submit')}}
                    </el-button>
                    <el-button @click="returnForm()" size="mini" class="button_return">{{$t('message.return')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<style>
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }
    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
</style>

<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: '',
        data() {
            return {
                disabledSubmit: false,
                companylist: [],
                currencylist: [],
                ExchangeRateForm: {
                    id:0,
                    yearmonth: '',
                    company: null,
                    oriCurrency: 'RMB',
                    locCurrency: '',
                    changeRate: 0,
                    userNumber: AppStorage.getNumber()
                },
            }
        },
        computed: {
            rules() {
                const rules = {
                };
                return rules;
            }
        },
        methods: {
            getCompany() {
                this.axios
                    .get('/api/company/getcompanylist', {
                        params: {
                            isFactory: 2,
                            userNumber: AppStorage.getNumber(),
                            isAll: true
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.companylist = infos;
                        }
                    });
            },
            getCurrency() {
                this.axios
                    .get('/api/Currency/getcurrencylist', {
                        params: {
                            isAll: true
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var infos = response.data.data;
                            this.currencylist = infos;
                        }
                    });
            },
            getInfo() {
                var infoId = this.$route.query.Id;
                this.ExchangeRateForm.yearmonth = this.$route.query.yearmonthcondition;

                if (infoId == 0) {
                    return;
                }
                this.axios
                    .get('/api/ExchangeRate/getexchangeratebyid', {
                        params: {
                            Id: infoId
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.ExchangeRateForm.id = info.id;
                            this.ExchangeRateForm.yearmonth = info.yearMonth;

                            this.ExchangeRateForm.company = {
                                companyNumber: info.country
                            };
                            this.ExchangeRateForm.locCurrency = {
                                currencyNumber: info.locCurrency
                            };
                            this.ExchangeRateForm.oriCurrency = info.oriCurrency;
                            this.ExchangeRateForm.changeRate = info.exchangerate;

                        }
                    });

            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.disabledSubmit = true;
                        this.axios
                            .post('/api/ExchangeRate/addexchangerate', {
                                Id: this.ExchangeRateForm.id,
                                YearMonth: this.ExchangeRateForm.yearmonth,
                                Country: this.ExchangeRateForm.company.companyNumber,
                                OriCurrency: this.ExchangeRateForm.oriCurrency,
                                LocCurrency: this.ExchangeRateForm.locCurrency.currencyNumber,
                                Exchangerate: this.ExchangeRateForm.changeRate,
                                UserNumber: this.ExchangeRateForm.userNumber
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    if (response.data.msg == 'Data duplication') {
                                        this.$message.error(this.$t('message.vueExchangeRate.dataduplication'));
                                    } else {
                                        this.$message.error(this.$t('message.errer'));
                                    }
                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    this.$router.push("/ExchangeRateList");
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });

                    } else {
                        return false;
                    }
                });
            },
            returnForm() {
                this.$router.go(-1);
            },
        },
        mounted() {
            this.getInfo();
            this.getCompany();
            this.getCurrency();
        },
    }
    
</script>