<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ProductMod' }">{{$t('message.vueProductMod.productmodpage')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/EditProductMod' }">{{$t('message.vueProductMod.editproductmodpage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="editproductmodForm" :model="editproductmodForm" :rules="rules" label-width="200px" class="demo-editproductmodForm">
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductMod.ModelType')+' :'" prop="ModelType">
                    <el-input size="mini" v-model="editproductmodForm.ModelType" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductMod.ModelName')+' :'" prop="ModelName">
                    <el-input size="mini" v-model="editproductmodForm.ModelName" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductMod.ModelLevel')+' :'" prop="ModelLevel">
                    <el-select v-model="editproductmodForm.ModelLevel" size="mini" :placeholder="$t('message.pleaseSelect')" style="width:300px">
                        <el-option :label="$t('message.vueReport.pleaseSelectModelLevel')" value=""></el-option>
                        <el-option v-for="item in whereModelLevel"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductMod.DataType')+' :'" prop="DataType">
                    <el-select v-model="editproductmodForm.DataType" size="mini" :placeholder="$t('message.vueReport.pleaseSelectModelLevel')" style="width:300px">
                        <el-option :label="$t('message.vueReport.pleaseSelectModelLevel')" value=""></el-option>
                        <el-option v-for="item in whereModelType"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductMod.ProcuctModel')+' :'" prop="input_productmod">
                    <el-input size="mini" v-model="editproductmodForm.input_productmod" :disabled="true" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductMod.ProductModelNumber')+' :'">
                    <el-input size="mini" v-model="editproductmodForm.input_productmodn" style="width:300px"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item :label="$t('message.vueProductMod.remarks')+' :'" prop="Remarks">
                    <el-select v-model="editproductmodForm.Remarks"
                               style="width:300px"
                               size="mini"
                               multiple
                               filterable
                               remote
                               reserve-keyword
                               :placeholder="$t('message.pleaseSelect')"
                               :remote-method="(query)=>{getCompanys(query)}"
                               :loading="editproductmodForm.isloading">
                        <el-option v-for="o in allCompanys"
                                   :key="o.value"
                                   :label="o.label"
                                   :value="o.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-form-item>
                    <el-button class="button_submit" size="mini"
                               @click="submitForm('editproductmodForm')" :disabled="disabledSubmit">{{$t('message.vueCategory.button_sava')}}</el-button>
                    <el-button @click="returnForm()" class="button_return" size="mini">{{$t('message.return')}}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>
<style>
    .button_submit {
        color: #fff;
        background-color: royalblue;
        font-weight: bolder;
    }
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }
</style>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: 'EditProductMod',
        data() {
            return {
                language: AppStorage.getLanguage(),
                editproductmodForm: {
                    isloading: false,
                    input_id: 0,
                    input_productmod: '',
                    input_productmodn: '',
                    ModelType: '',
                    ModelName: '',
                    Remarks: '',
                    ModelLevel: '',
                    DataType: '', 
                    userNumber: AppStorage.getNumber()
                },
                whereModelLevel: [{
                    value: 'High',
                    label: 'High'
                }, {
                    value: 'Mid',
                    label: 'Mid'
                    },
                    {
                        value: 'Sofa',
                        label: 'Sofa'
                    },{
                    value: 'Low',
                    label: 'Low'
                }],
                whereModelType: [{
                    value: 'CHAIR',
                    label: 'CHAIR'
                }, {
                    value: 'FOOT',
                    label: 'FOOT'
                }, {
                    value: 'OTHES',
                    label: 'OTHES'
                }],
                seen: true,
            }
        },
        computed: {
            rules() {
                const rules = {
                };
                return rules;
            }
        },
        methods: {
            getCompanys(query, isAll = true) {
                this.editproductmodForm.isloading = true;
                this.axios
                    .get('/api/company/getcompanylist', {
                        params: {
                            isFactory: 2,
                            userNumber: AppStorage.getNumber(),
                            keyword: query,
                            isAll: isAll
                        }
                    })
                    .then(response => {
                        var infos = response.data.data;
                        if (response.data.code == 1 && infos != null) {
                            this.allCompanys = infos.map(function (m) {
                                return {
                                    value: m.companyNumber,
                                    label: m.companyName + "(" + m.companyNumber + ")"
                                }
                            });
                        }
                        this.editproductmodForm.isloading = false;
                    }).catch(error => {
                        console.log(error);
                        this.editproductmodForm.isloading = false;
                    });
            },
            getInfo() {
                var productModId = this.$route.query.Id;
                if (productModId == 0) {
                    return;
                }
                this.axios
                    .get('/api/ProductMod/getproductmodbyid', {
                        params: {
                            Id: productModId
                        }
                    })
                    .then(response => {
                        var info = response.data.data;
                        if (response.data.code == 1 && info != null) {
                            this.editproductmodForm.input_id = info.id;
                            this.editproductmodForm.input_productmod = info.productMod;
                            this.editproductmodForm.input_productmodn = info.productModNa;
							this.editproductmodForm.ModelType = info.modelType;
                            this.editproductmodForm.ModelName = info.modelName;
                            this.editproductmodForm.Remarks = info.remarks == null || info.remarks == '' ? '' : info.remarks.split(',');
                            this.editproductmodForm.ModelLevel = info.modelLevel;
                            this.editproductmodForm.DataType = info.dataType;												   
                        }
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                            this.disabledSubmit = true;
                            this.axios
                            .post('/api/ProductMod/addproductmod', {
                                Id: this.editproductmodForm.input_id,
                                ProductMod: this.editproductmodForm.input_productmod,
                                ProductModNa: this.editproductmodForm.input_productmodn,
								ModelType : this.editproductmodForm.ModelType,
                                ModelName : this.editproductmodForm.ModelName,
                                Remarks: this.editproductmodForm.Remarks?.toString(),
                                ModelLevel: this.editproductmodForm.ModelLevel == '' ? null : this.editproductmodForm.ModelLevel,
                                DataType: this.editproductmodForm.DataType == '' ? null : this.editproductmodForm.DataType,									  
                                UserNumber: AppStorage.getNumber()
                            })
                            .then(response => {
                                if (response.data.code != 1) {
                                    if (response.data.msg == 'this model is exist') {
                                        this.$message.error(this.$t('message.vueProductMod.msgExistProductMod'));
                                    } else {
                                        this.$message.error(this.$t('message.errer'));
                                    }
                                } else {
                                    this.$message.success(this.$t('message.success'));
                                    this.$router.push({ path: "/ProductMod" });
                                }
                                this.disabledSubmit = false;
                            }).catch(error => {
                                console.log(error);
                                this.$message.error(this.$t('message.messageBox.connecterror'));
                                this.disabledSubmit = false;
                            });
                    } else {
                        return false;
                    }
                });
            },
            returnForm() {
                this.$router.go(-1);
            },
        },
        mounted() {
            this.getInfo();
            this.getCompanys('', true);
        }
    };
</script>
