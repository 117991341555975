<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/UserList' }">{{$t('message.vueUser.crumbUserManager')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('message.details')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-button @click="returnForm()" class="button_return" size="mini">{{$t('message.return')}}</el-button>
    <el-divider></el-divider>
    <el-descriptions class="userinfodetails" :title="$t('message.details')" :column="1" :size="mini" border>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueUser.labCompanyName')}}
            </template>
            {{userForm.companyName}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueUser.labDepartmentName')}}
            </template>
            {{userForm.departmentName}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueUser.labAccount')}}
            </template>
            {{userForm.account}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueUser.labName')}}
            </template>
            {{userForm.name}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueUser.labSex')}}
            </template>
            {{userForm.sex}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueUser.labRank')}}
            </template>
            {{userForm.rank}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueUser.labLeader')}}
            </template>
            {{userForm.leader}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueUser.labEmail')}}
            </template>
            {{userForm.email}}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                {{$t('message.vueUser.labLanguage')}}
            </template>
            {{userForm.language}}
        </el-descriptions-item>
    </el-descriptions>
</template>
<script>
    import AppStorage from '@/common/storage/app-storage'
    export default {
        name: '',
        data() {
            return {
                tableData: null,
                language: AppStorage.getLanguage(),
                userForm: {
                    id: 0,
                    companyName: '',
                    departmentName: '',
                    sex: '',
                    account:'',
                    name: '',
                    email: '',
                    language: '',
                    number: '',
                    password: '',
                    leader: '',
                    rank: '',
                    grade: '',
                }
            }
        },
        methods: {
            GetUserDetial() {
                var infoId = this.$route.query.id
                this.axios
                    .get('/api/user/getuserbyid', {
                        params: {
                            Id: infoId,
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            var info = response.data.data;
                            this.userForm.companyName = info.companyName;
                            this.userForm.departmentName = info.departmentName;
                            this.userForm.name = info.name;
                            this.userForm.account = info.account;
                            this.userForm.sex = info.sex == 1 ? this.$t('message.vueUser.selMan') : this.$t('message.vueUser.selWoman');
                            this.userForm.address = info.address;
                            this.userForm.email = info.email;
                            this.userForm.language = info.language == "en" ? "English" : "繁體中文";
                            this.userForm.leader = info.leader;
                            this.userForm.grade = info.gradeName;
                            this.userForm.rank = info.rank == null ? "": this.$t('message.vueUser.whereRanks' + info.rank);
                        }
                    })
            },
            returnForm() {
                this.$router.go(-1);
            }
        },
        mounted() {
            this.GetUserDetial();
        },
    }
</script>
<style>
    .userinfodetails .el-descriptions__label {
        width: 110px;
    }
    .button_return {
        color: #fff;
        background-color: grey;
        font-weight: bolder;
    }
</style>